import { HttpWrapper, AppConfig } from '../../../../../core'
import { LinkResourcesResponseModel, FileUploadModel, FileUploadResponseModel } from '../../../../../shared/models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { CustomerLogoListResponseModel, CustomerLogoDetailModel } from '../models';
export class CustomerLogoService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getCustomerLogoList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<CustomerLogoListResponseModel>(AppConfig.apiEndpoint + '/v1/customer_logos/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getCustomerLogoById(id: number) {
      return this.wrapper.get<CustomerLogoDetailModel>(AppConfig.apiEndpoint + '/v1/customer_logos/' + id);
    }  
  
    postCustomerLogo(data:FileUploadModel) {
      return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/customer_logos',data);
    }  
      
    getLinkedResourcesById(id: number) {
      return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/customer_logos/' + id + '/linked_resources');
    } 
    
  
    exportCustomerLogo(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/customer_logos/export', exportData);
    }
  
    postCustomerLogoGCS(data: any) {
      return this.wrapper.uploadFileByPost<FileUploadResponseModel>(
        AppConfig.apiEndpoint + "/v1/customer_logos",
        data
      )
    } 
  
  }