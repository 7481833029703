import React, { Component, Fragment } from 'react'
import { StaqEventsService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../shared/helpers';
import { NotFoundResponseArea, SchemasType, SourceType } from '../../../shared/enums';
import { HttpResponse } from '../../../core';
import { NavLink } from "react-router-dom";

import { StaqEventsDetailModel } from './models';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: StaqEventsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class StaqEventsDetail extends Component<any, IState> {
    private service: StaqEventsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new StaqEventsService();
    }

    initialState: Partial<IState> = {
        result: {             
          id: 0,        
          name:"",
          created_at:new Date(),
          processed_at:new Date(),
          table_name:"",
          table_operation:"",
          table_record_id:0,
          triggered_by:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getSchemasById(this.props.match.params.id)
            .then((res: HttpResponse<StaqEventsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                 
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Schemas,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for STAQ Event &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">         
                        {!this.state.isLoading &&  <>                     
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.name && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.name}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.triggered_by && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Triggered by</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.triggered_by}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.created_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created at</b>
                                            </div>
                                            <div className="card-body">
                                            {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.processed_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Processed at</b>
                                            </div>
                                            <div className="card-body">
                                            {ConvertDateTime(this.state.result?.processed_at, 'LLL')}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.table_name && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Table name</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result.table_name}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.table_operation && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Table Operation</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result.table_operation}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.table_record_id && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Table Record</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result.table_record_id}
                                            </div>
                                        </div>
                                </div>}
                                   
                               
                            </div>
 

                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 