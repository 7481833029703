import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';

interface IProps {
    id: number,
    url: string
}
export class InstalledApplicationTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {IsAuthorize([Role.TAM, Role.Dev, Role.AM, Role.Support ,Role.Manager]) && <li className="nav-item">
                        <NavLink to={"/installed-report-application/installed-report-application-form/" + this.props.id} className={this.props.url == "/installed-report-application/installed-report-application-form/" ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                            aria-controls="edit" aria-selected={this.props.url == "/installed-report-application/installed-report-application-form/" ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                    </li>}
                    {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) &&  <li className="nav-item">
                        <NavLink to={"/installed-report-application/delete/" + this.props.id} className={this.props.url == "/installed-report-application/delete/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#delete" role="tab"
                            aria-controls="delete" aria-selected={this.props.url == "/installed-report-application/delete/" ? "true" : "false"}><i className="far fa-trash-alt"></i>&nbsp;Delete</NavLink>
                    </li>}
                    {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) && <li className="nav-item">
                        <NavLink to={"/installed-report-application/detail/" + this.props.id} className={this.props.url == "/installed-report-application/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                            aria-controls="show" aria-selected={this.props.url == "/installed-report-application/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                    </li>}
                </ul>
                <br />
            </Fragment>
        )
    }
}
