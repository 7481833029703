import React, { Component, Fragment } from 'react'

import { EmailLayoutsService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { EmailLayoutsTabs } from './EmailLayoutsTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { AddUpdateEmailLayoutsModel } from './models';
import { DropdownItemModel, FlaggleItems } from '../../../../shared/models';
import { CommonService } from '../../../../shared/services/common.service';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Editor } from '../../../../shared';

interface IProps {
    handleClose: Function,
    id?:number;
    isNew?:boolean;    
    updateEmailLayout:Function
}

interface IState {
    EmailLayouts?: AddUpdateEmailLayoutsModel;
    id?: number;
    name?: string;
    html?: string;
    text?: string;
    nameError?: string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    textError?: string;
    htmlError?: string;
}
export class EmailLayoutsFormPopup extends Component<IProps, IState> {
    private service: EmailLayoutsService;
     commonService: CommonService;
    constructor(props:IProps) {
        super(props)
        this.service = new EmailLayoutsService();
        this.commonService = new CommonService();
        this.editorhandleParams = this.editorhandleParams.bind(this);
        this.editorhandleHtml = this.editorhandleHtml.bind(this);
        this.state = this.initialState;
    }

    initialState: Partial<IState> = {
        name: "",
        html: "",
        text: "",
        id: 0,
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        nameError: "",
        textError: "",
        htmlError:""
    }

    handleClose = event => {
        event.preventDefault();
        this.props.handleClose();        
    }

    componentDidMount() {
        if (!this.props.isNew) {     
            this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        } 
      
    }
   

     loadData() {
        this.service.editEmailLayouts(this.props.id)
            .then((res: HttpResponse<AddUpdateEmailLayoutsModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        name: res.result?.name,
                        html: res.result?.html,
                        text: res.result?.text,
                    }, () => {
                         
                    });
                   
                }
                
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.EmailLayouts, this.props)
            });
            
    }


    updateData(data: AddUpdateEmailLayoutsModel) {
        this.setSavingFlag(true);
        this.service.updateEmailLayouts(data)
            .then((res: HttpResponse<AddUpdateEmailLayoutsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        EmailLayouts: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Email Layout successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.setState(this.initialState);
                            this.props.updateEmailLayout(true,this.state.name,this.state.id);
                            this.props.handleClose();
                        }
                        
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateEmailLayoutsModel) {
        this.setSavingFlag(true);
        this.service.postEmailLayouts(data)
            .then((res: HttpResponse<AddUpdateEmailLayoutsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        EmailLayouts: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Email Layout successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.setState(this.initialState);
                            this.props.updateEmailLayout(true,this.state.name,this.state.id);
                            this.props.handleClose();
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
           
            });;
    }

    editorhandleParams(data) {
        this.setState({
          text: data,
        });
      }
      editorhandleHtml(data) {
        this.setState({
          html: data,
        });
      }

    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    validate = () => {
        let nameError = "";
        let textError = "";
        let htmlError = "";
       
        if (!this.state.name) {
            nameError = "Name can't be blank";
        }
        if (!this.state.html?.match('<%= yield %>')) {
            htmlError = "HTML must contain the location to yield a template";
        }
        if (!this.state.text?.match('<%= yield %>')) {
            textError = "Text must contain the location to yield a template";
        }
        
       
        if (nameError || textError || htmlError ) {
            this.setState({ nameError: nameError, htmlError:htmlError, textError:textError})
            return false;
        }
        else {
            nameError = "";
             textError = "";
            htmlError = "";
           this.setState({ nameError: nameError,  htmlError:htmlError, textError:textError })
            return true;
        }
        
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdateEmailLayoutsModel = {
            id: this.state.id,
            name: this.state.name,
            html: this.state.html,
            text: this.state.text
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else {
                this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value },()=>{
            this.props.updateEmailLayout(true,this.state.name,this.state.id);
        })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
                return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Email Layout '{this.state.name}'</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {/* {this.props.id != 0 && <EmailLayoutsTabs id={this.props.id} url="/email-layout/email-layout-form/" />} */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Name<span className=" text-danger">*</span> </label>
                                                    <input type="text" maxLength={255} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">    
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                <label>HTML<span className=" text-danger">*</span>
                                                <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                    Must identify where EmailTemplate should be inserted, '&lt;%= yield %&gt;'.
                                                    </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> 
                                                </label>
                                                <Editor  StringData={this.state.html}  FormateType="yaml"  onChange={this.editorhandleHtml}  />
                                                {this.state.htmlError != "" && (
                                                        <small className="text-danger">
                                                        {this.state.htmlError}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="form-group p-3 shadow-sm">
                                                    <label>Text<span className=" text-danger">*</span>
                                                     <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                    Must identify where EmailTemplate should be inserted, .'&lt;%= yield %&gt;'.
                                                    </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <Editor  StringData={this.state.text}  FormateType="yaml"  onChange={this.editorhandleParams} />
                                                    {this.state.textError != "" && (
                                                        <small className="text-danger">
                                                        {this.state.textError}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                    <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                                {!this.state.isSaving && <Fragment>
                                    <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                    
                                </Fragment>
                                }
                                <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                    </div>
                    </Modal.Body>
                </form>
            </Fragment>
        )
    }
}
