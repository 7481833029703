import React, { Component, Fragment, createRef } from 'react'
import { HttpResponse } from '../../../../core';
import { HandleNotFoundResponse, ShowSuccessMessage } from '../../../../shared/helpers';
import { NotFoundResponseArea, ButtonType } from '../../../../shared/enums';
import { Link } from "react-router-dom";
import { Loader } from "../../../../shared/loaders";
import { EditUpdateEnginesModel } from './models/edit.update.extraction.engines.model';
import { ExtractionEnginesService } from './services/extraction.engines.service';
import { ExtractionEnginesTabs } from './ExtractionEnginesTabs';
import { Modal } from 'react-bootstrap';

interface IProps {
    handleClose: Function,
    id?: number;
    isNew?: boolean;
    updateExtractionEngine: Function
}
interface IState {
    extractionEngines?: EditUpdateEnginesModel,
    editExtractionEngines?: EditUpdateEnginesModel,
    id?: number;
    label?: string;
    description?: string;
    descriptionError?: string;
    image_url?: string;
    is_public?: boolean;
    auth_provider?: string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class ExtractionEnginesFormPopup extends Component<IProps, IState> {
    //declaring service type
    private service: ExtractionEnginesService;
    private decriptionRef: any;

    constructor(props: IProps) {
        super(props)
        this.service = new ExtractionEnginesService();
        this.state = this.initialState;
        this.decriptionRef = createRef();

    }

    initialState: Partial<IState> = {
        extractionEngines: {
            id: 0,
            auth_provider: "",
            description: "",
            image_url: "",
            is_public: false,
            label: ""
        },
        id: 0,
        auth_provider: "",
        description: "",
        image_url: "",
        is_public: false,
        label: "",
        descriptionError: "",
        isShowtext: "Edit",
        isSave: "",
        isSaving: false
    }

    handleClose = event => {
        event.preventDefault();
        this.props.handleClose();
    }
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.editExtractionEngines(this.props.id)
            .then((res: HttpResponse<EditUpdateEnginesModel>) => {
                if (res && res.result) {
                    this.setState({
                        auth_provider: res.result?.auth_provider,
                        description: res.result?.description,
                        image_url: res.result?.image_url,
                        is_public: res.result?.is_public,
                        label: res.result?.label,
                        id: res.result?.id
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.ExtractionEngine, this.props)
            });
    }

    updateData(data: EditUpdateEnginesModel) {
        this.setSavingFlag(true);
        this.service.updateExtractionEngines(data)
            .then((res: HttpResponse<EditUpdateEnginesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        extractionEngines: res.result
                    }, () => {
                        ShowSuccessMessage("Extraction engine successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.setState(this.initialState);
                            this.props.updateExtractionEngine(true, this.state.label, this.state.id);
                            this.props.handleClose();
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }

    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
        if (this.decriptionRef.current.value) {
            this.setState({ descriptionError: "" });
        }

    }

    validate = () => {
        let decriptionRef = "";

        if (!this.decriptionRef.current.value) {
            decriptionRef = "Description can't be blank";
        }
        if (decriptionRef) {
            this.setState({ descriptionError: decriptionRef })
            return false;
        }
        else {
            decriptionRef = "";
            this.setState({ descriptionError: decriptionRef })
            return true;
        }
        return;
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: EditUpdateEnginesModel = {
            id: this.props.id,
            auth_provider: this.state.auth_provider,
            description: this.state.description,
            image_url: this.state.image_url,
            is_public: this.state.is_public,
            label: this.state.label

        };

        if (isValid) {
            this.updateData(data)
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value }, () => {
            this.props.updateExtractionEngine(true, this.state.label, this.state.id);
        })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" value={this.state.id} />
                        <div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4>Edit Extraction Engine '{this.state.label}</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xl-12">
                                    
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>Label </label>
                                                        <input
                                                            ref={this.decriptionRef}
                                                            type="label"
                                                            name="label"
                                                            value={this.state.label}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                        <small>Optional. This value is shown to users in staqweb. If not set, type is used.</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>Image Url </label>
                                                        <input
                                                            ref={this.decriptionRef}
                                                            type="image_url"
                                                            name="image_url"
                                                            value={this.state.image_url}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                        <small>Do not include http: at the beginning; start the URL with //, as in //adstaq-assets.s3.amazonaws.com/platform-favicons/...</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>Auth Provider </label>
                                                        <input
                                                            ref={this.decriptionRef}
                                                            type="auth_provider"
                                                            name="auth_provider"
                                                            value={this.state.auth_provider}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                        <small>For Extraction Engines that utilize OAuth authentication, as detailed in Staqnowledged (e.g adwords_oauth2, salesforce, etc)</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3 mb-4">
                                                        <label>Description</label>
                                                        <textarea
                                                            rows={10}
                                                            ref={this.decriptionRef}
                                                            name="description"
                                                            value={this.state.description}
                                                            onChange={this.handleChange}
                                                            className={!this.state.descriptionError ? 'form-control' : 'form-control  is-invalid'}
                                                        ></textarea>
                                                        <div className="invalid-feedback">
                                                            {this.state.descriptionError}
                                                        </div>
                                                        <small>Required. This is a description shown to users when creating a new connection</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3 mb-4 ">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="is_public"
                                                                checked={this.state.is_public}
                                                                onChange={this.handleChange}
                                                                className="custom-control-input"
                                                                id="is_public"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="is_public"
                                                            >Is Public
                                                            </label>
                                                        </div>
                                                        <small>If you uncheck this box, users will not be able to create new or view existing connections in connection manager. </small>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </Modal.Body>
                </form>
            </Fragment>
        )
    }
}
