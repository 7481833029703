import React, { Component, Fragment } from 'react'
import { AccountCurrentDataEngineJobsModel, CurrentDataEngineJobsListModel } from './models'
import { AccountService } from './services/account.service';
import { HttpResponse } from '../../core';
import AccountTabs from './AccountTabs';
import { HandleNotFoundResponse } from '../../shared/helpers';
import { NotFoundResponseArea } from '../../shared/enums';

interface IState {
    result?: AccountCurrentDataEngineJobsModel;
}
export class AccountDataEngineJobs extends Component<any, IState> {
    private service: AccountService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AccountService();
    }
    initialState: Partial<IState> = {
        result: {
            id: 0,
            running_jobs: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getAccountDataEngineJobsById(this.props.match.params.id)
            .then((res: HttpResponse<AccountCurrentDataEngineJobsModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Accounts,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Current Jobs</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountTabs id={this.props.match.params.id} url="/account/data-engine-jobs/" />
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Currently Running/Queued Data Engine Jobs</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Job</th>
                                                        <th scope="col">Job Type</th>
                                                        <th scope="col">Target</th>
                                                        <th scope="col">Priority</th>
                                                        <th scope="col">Age</th>
                                                        <th scope="col">Duration</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Time Range</th>
                                                    </tr>
                                                </thead>
                                                {this.state.result?.running_jobs.length != 0 && <tbody>
                                                    {this.state.result?.running_jobs.map((item: CurrentDataEngineJobsListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td>{item.job_type}</td>
                                                                <td>{item.job_target}</td>
                                                                <td>{item.priority}</td>
                                                                <td>{item.age}</td>
                                                                <td>{item.duration}</td>
                                                                <td>{item.state}</td>
                                                                <td>{item.time_range}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
