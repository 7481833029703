import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ExtractionEnginesListResponseModel, ExtractionEnginesDetailModel, EditUpdateEnginesModel } from "../models";
import { DeleteResponseModel, LinkResourcesResponseModel } from "../../../../../shared/models";


export class ExtractionEnginesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getExtractionEnginesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ExtractionEnginesListResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_engines/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getExtractionEnginesById(id: number) {
    return this.wrapper.get<ExtractionEnginesDetailModel>(AppConfig.apiEndpoint + '/v1/extraction_engines/' + id);
  }

  editExtractionEngines(id?: number) {
    return this.wrapper.get<EditUpdateEnginesModel>(AppConfig.apiEndpoint + '/v1/extraction_engines/edit/' + id);
  }

  updateExtractionEngines(ExtractionEngines: EditUpdateEnginesModel) {
    return this.wrapper.put<EditUpdateEnginesModel>(AppConfig.apiEndpoint + '/v1/extraction_engines/' + ExtractionEngines.id, ExtractionEngines);
  }

  getLinkedResourcesById(id: number) {
    return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_engines/' + id + '/linked_resources');
  }

  deleteRecordById(id: number) {
    return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_engines/' + id);
  }

  exportExtractionEngines(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extraction_engines/export', exportData);
  }


}