import React, { Component, Fragment } from 'react'
import { ReportDefinitionModel } from '../reports/models'
import { UserReportsService } from './services';
import { HttpResponse } from '../../../core';
import { Editor } from '../../../shared'
import { ReportTabs } from './ReportTabs';
import { HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';

interface IState {
    result?: ReportDefinitionModel;
    parsedData?: string;
}
export class ReportDefinition extends Component<any, IState>  {
    private service: UserReportsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new UserReportsService();
    }

    initialState: Partial<IState> = {
        result: {
            data: ""
        },
        parsedData: ""
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.geReportDefinitionById(this.props.match.params.id)
            .then((res: HttpResponse<ReportDefinitionModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        parsedData: JSON.stringify(JSON.parse(res.result.data), null, '\t')
                    }, () => {

                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });
    }
    render() {
        return (
            <Fragment>
                {this.props.match.params.id && <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Report Definition</h4>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportTabs id={this.props.match.params.id} url="/report/report_definition/" />
                                    <div className="row">
                                        {this.props.match.params.id && this.state.result?.data != null && this.state.result?.data != "" && <div className="col-12">
                                            
                                                <Editor Height="550px" StringData={this.state.parsedData} FormateType="json" />
                                           
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>}
            </Fragment>
        )
    }
}
