import React, { Component, Fragment } from 'react'
import { FieldClassificationService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { FieldClassificationDetailModel } from './models';
import { FieldClassificationTabs } from './FieldClassificationTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: FieldClassificationDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class FieldClassificationDetail extends Component<any, IState> {
    private service: FieldClassificationService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new FieldClassificationService();
    }

    initialState: Partial<IState> = {
        result: {             
            id:0,           
            name:"",
            created_at:null,
            updated_at:null,
            fields_:[],
            notes:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getFieldClassificationById(this.props.match.params.id)
            .then((res: HttpResponse<FieldClassificationDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {       
                                                
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.FieldsClassification,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Field Classification &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <FieldClassificationTabs id={this.props.match.params.id} url="/field-classification/detail/" /> 
                        {!this.state.isLoading &&  <>                            
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.name && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.name}
                                            </div>
                                        </div>
                                    </div>}                             
                                   
                                    {this.state.result?.created_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.notes && <div className="col-12">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Notes</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.notes}
                                            </div>
                                        </div>
                                    </div>}  
                            </div>  

                            <div className="row">                                      
                          

                                    {this.state.result?.fields_.length!=0 && (
                    <div className="col-12">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header font-weight-bold">
                        Fields
                        </div>
                        <div className="card-body">
                          {this.state.result?.fields_.map(
                            (item: DropdownItemModel, i, arr) => {
                              return (
                                <span key={item.value}>
                                  <NavLink
                                    className="badge badge-light m-1"
                                    to={"/field/detail/" + item.value+"/"+""+"/"+ 0}
                                  >
                                    <strong>{i + 1}:</strong> {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                  </NavLink>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                                    </div>                          
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 