import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { ConfigurationsListResponseModel, ConfigurationsDetailModel, AddUpdateConfigurationsModel, ConfigurationsDeleteResponseModel, ConfigurationsDeleteModel } from "../models";



export class ConfigurationsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getConfigurationsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ConfigurationsListResponseModel>(AppConfig.apiEndpoint + '/v1/configurations/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getConfigurationsById(id: number) {
    return this.wrapper.get<ConfigurationsDetailModel>(AppConfig.apiEndpoint + '/v1/configurations/' + id);
  }  

  postConfigurations(Configurations: AddUpdateConfigurationsModel) {
    return this.wrapper.post<AddUpdateConfigurationsModel>(AppConfig.apiEndpoint + '/v1/configurations', Configurations);
}

editConfigurations(id?: number) {
    return this.wrapper.get<AddUpdateConfigurationsModel>(AppConfig.apiEndpoint + '/v1/configurations/edit/' + id);
}

updateConfigurations(Configurations: AddUpdateConfigurationsModel) {
    return this.wrapper.put<AddUpdateConfigurationsModel>(AppConfig.apiEndpoint + '/v1/configurations/' + Configurations.id, Configurations);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<ConfigurationsDeleteModel>(AppConfig.apiEndpoint + '/v1/configurations/' + id+'/linked_resource');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<ConfigurationsDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/configurations/' + id);
}

  exportConfigurations(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/configurations/export', exportData);
  }


} 