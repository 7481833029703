import { FilterItemInputRenderType } from ".";

export interface PostFilterModel {
    Filters: FilterModel[];
}


export interface FilterModel {
    name: any;
    op: any;
    value: any;
}

export interface Columns {
    id: number;
    columnName: string;
    type: ColumnType;
    displayText: any;
    value: any;
}

export enum ColumnType {
    Number = "number",
    String = "string",
    Date = "date",
    Boolean = "boolean",
    Days = "days",
    Time = "time",
    InclusionExclusion = "inclusion_exclusion",
    State = "state",
    ExtractorStyle="extractorStyle",
    DataEngineJobsState = "dataEngineJobsState",
    JobType = "jobType",
    SchemasType = "SchemasType",
    FieldType="FieldType",
    FieldColumnType="FieldColumnType"
}

export interface FilterOperatorType {
    name: string,
    operator: string,
    renderType: FilterItemInputRenderType
}
export const OperatorList = {
    number: [{ name: 'Number', operator: 'eq', renderType: FilterItemInputRenderType.SingleBox },
    { name: 'Between ... and ...', operator: 'range', renderType: FilterItemInputRenderType.RangeBox },
    { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
    { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox }
    ],
    string: [
        { name: 'Contains', operator: 'ilike', renderType: FilterItemInputRenderType.SingleBox },
        { name: 'Is Exactly', operator: 'eq', renderType: FilterItemInputRenderType.SingleBox },
        { name: 'Start With', operator: 'startswith', renderType: FilterItemInputRenderType.SingleBox },
        { name: 'End With', operator: 'endswith', renderType: FilterItemInputRenderType.SingleBox },
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox }
    ],
    date: [
        { name: 'Date', operator: 'dateeq', renderType: FilterItemInputRenderType.SingleBox },
        { name: 'Today', operator: 'tdate', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Yesterday', operator: 'ydate', renderType: FilterItemInputRenderType.NoBox },
        { name: 'This Week', operator: 'twdate', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Last Week', operator: 'lwdate', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Between date ... and ...', operator: 'range', renderType: FilterItemInputRenderType.RangeBox }
    ],
    boolean: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'True', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'False', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }
    ],
    days: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Sunday', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { operator: 'eq', name: 'Monday', renderType: FilterItemInputRenderType.NoBox },
        { operator: 'eq', name: 'Tuesday', renderType: FilterItemInputRenderType.NoBox },
        { operator: 'eq', name: 'Wednesday', renderType: FilterItemInputRenderType.NoBox },
        { operator: 'eq', name: 'Thursday', renderType: FilterItemInputRenderType.NoBox },
        { operator: 'eq', name: 'Friday', renderType: FilterItemInputRenderType.NoBox },
        { operator: 'eq', name: 'Saturday', renderType: FilterItemInputRenderType.NoBox },
    ],
    time: [
        { name: 'Contains', operator: 'eq', renderType: FilterItemInputRenderType.SingleBox },
    ],
    inclusion_exclusion: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Inclusion', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Exclusion', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }
    ],
    state: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'New', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Available', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Unavailable', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Archived', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }
    ],
    extractorStyle: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Global', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Configurable', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
    ],
    dataEngineJobsState: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'new', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'preparing', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'delayed_start', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'skipped', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }, 
        { name: 'retryable_failure', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'obviated', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'running', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'finalizing', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'canceled', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'success', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'failure', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'unable_to_process', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'new_recollection', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }
    ],
    jobType: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'new_collection', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'scope_rebuild', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'data_source', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'analyze', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'preview', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }

    ],

    schemasType: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Extractor', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Report', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Custom Connection', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }        

    ],

    fieldType: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'big_integer', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'boolean', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'currency', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }, 
        { name: 'date', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'float', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'integer', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }, 
        { name: 'raw', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'string', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'text', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }, 
        { name: 'time', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'url', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }        

    ],
    fieldColumnType: [
        { name: 'Is Present', operator: 'isnot', renderType: FilterItemInputRenderType.NoBox },
        { name: 'Is Blank', operator: 'is', renderType: FilterItemInputRenderType.NoBox },
        { name: 'dimension', operator: 'eq', renderType: FilterItemInputRenderType.NoBox },
        { name: 'metric', operator: 'eq', renderType: FilterItemInputRenderType.NoBox }             

    ],

}




