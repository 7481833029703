import React, { Component, Fragment } from 'react'
import { FeatureFlagsService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink, Link } from "react-router-dom";

import { FeatureFlagsDetailModel } from './models';
import { FeatureFlagsTabs } from './FeatureFlagsTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: FeatureFlagsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class FeatureFlagsDetail extends Component<any, IState> {
    private service: FeatureFlagsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new FeatureFlagsService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,
            name: "",
            flaggable_type: "",
            flaggable_id: 0,
            created_at: new Date(),
            updated_at: new Date(),
            flaggable:{}
        },
        isLoading: false,
    }

    

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getFeatureFlagsById(this.props.match.params.id)
            .then((res: HttpResponse<FeatureFlagsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.FeatureFlags,this.props)
            });
    }
    
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Feature flag &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <FeatureFlagsTabs id={this.props.match.params.id} url="/feature-flag/detail/" />      
                        {!this.state.isLoading &&  <>                            
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.name && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.name} 
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.flaggable_id && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Flaggable</b>
                                            </div>
                                            <div className="card-body">
                                                    {/* <NavLink className="badge badge-light text-link" to={"/" + this.state.result?.flaggable_type.toLowerCase() +"/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</NavLink>  */}
                                             {(() => {
                                                                if (this.state.result?.flaggable_type.toLowerCase() == 'user') {
                                                                    return (
                                                                        <Link className="badge badge-light text-link" to={"/user/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                    )
                                                                    } 
                                                                    else if (this.state.result?.flaggable_type.toLowerCase() == 'account') {
                                                                        return (
                                                                            <Link to={"/account/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                        )
                                                                        }else if (this.state.result?.flaggable_type.toLowerCase() == 'report') {
                                                                            return (
                                                                                <Link to={"/report/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }else if (this.state.result?.flaggable_type == 'ReportChangeHistory') {
                                                                            return (
                                                                                <Link to={"/report-change-history/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        } 
                                                                        else if (this.state.result?.flaggable_type == 'ReportColumn') {
                                                                            return (
                                                                                <Link to={"/report-change-history/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'AccountDomain') {
                                                                            return (
                                                                                <Link to={"/account-domain/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'AccountSourceOverride') {
                                                                            return (
                                                                                <Link to={"/account-source-override/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Connection') {
                                                                            return (
                                                                                <Link to={"/connection/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ConnectionSemaphore') {
                                                                            return (
                                                                                <Link to={"/connection-semaphore/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Platform') {
                                                                            return (
                                                                                <Link to={"/platform/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ExtractorRateLimit') {
                                                                            return (
                                                                                <Link to={"/extractor-rate-limit/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ExtractorSemaphore') {
                                                                            return (
                                                                                <Link to={"/extractor-semaphore/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ExtractorTimeZoneSpecification') {
                                                                            return (
                                                                                <Link to={"/extractor-time-zone-specification/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'CustomConnection') {
                                                                            return (
                                                                                <Link to={"/custom-connection/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'InboundFile') {
                                                                            return (
                                                                                <Link to={"/inbound-file/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ExtractionEngine') {
                                                                            return (
                                                                                <Link to={"/extraction-engine/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'InboundEmailAddress') {
                                                                            return (
                                                                                <Link to={"/inbound-email-address/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'InboundEmailMessage') {
                                                                            return (
                                                                                <Link to={"/inbound-email-message/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Dashboard') {
                                                                            return (
                                                                                <Link to={"/dashboard/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'InstalledReportApplication') {
                                                                            return (
                                                                                <Link to={"/installed-report-application/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportChangeHistory') {
                                                                            return (
                                                                                <Link to={"/report-change-history/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportColumn') {
                                                                            return (
                                                                                <Link to={"/report-column/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportColumnField') {
                                                                            return (
                                                                                <Link to={"/report-column-field/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportColumnFilter') {
                                                                            return (
                                                                                <Link to={"/report-column-filter/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportFilter') {
                                                                            return (
                                                                                <Link to={"/report-filter/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportDataSourceFilter') {
                                                                            return (
                                                                                <Link to={"/report-data-source-filter/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportRunSchedule') {
                                                                            return (
                                                                                <Link to={"/report-run-schedule/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ReportView') {
                                                                            return (
                                                                                <Link to={"/report-view/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Applet') {
                                                                            return (
                                                                                <Link to={"/applet/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'SourceReport') {
                                                                            return (
                                                                                <Link to={"/source-report/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ExtractionSchedulingRecipe') {
                                                                            return (
                                                                                <Link to={"/extraction-scheduling-recipe/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ExtractionSchedulingRecipeChange') {
                                                                            return (
                                                                                <Link to={"/extraction-scheduling-recipe-change/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Application') {
                                                                            return (
                                                                                <Link to={"/application/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'InvocationSchedule') {
                                                                            return (
                                                                                <Link to={"/invocation-schedule/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Schema') {
                                                                            return (
                                                                                <Link to={"/schema/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Scope') {
                                                                            return (
                                                                                <Link to={"/scope/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Field') {
                                                                            return (
                                                                                <Link to={"/field/detail/" + this.state.result?.flaggable?.value+"/"+""+"/"+0}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'FieldClassification') {
                                                                            return (
                                                                                <Link to={"/field-classification/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ScopeSchemaChange') {
                                                                            return (
                                                                                <Link to={"/scope-schema-change/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'ScopeFieldSchemaChange') {
                                                                            return (
                                                                                <Link to={"/scope-field-schema-change/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'DatabaseEngine') {
                                                                            return (
                                                                                <Link to={"/database-engine/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'DataEngine') {
                                                                            return (
                                                                                <Link to={"/data-engine/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'DataEngineJob') {
                                                                            return (
                                                                                <Link to={"/data-engine-job/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'EmailTemplate') {
                                                                            return (
                                                                                <Link to={"/email-template/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'EmailLayout') {
                                                                            return (
                                                                                <Link to={"/email-layout/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'FeatureFlag') {
                                                                            return (
                                                                                <Link to={"/feature-flag/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'PlanCategory') {
                                                                            return (
                                                                                <Link to={"/plan-category/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Role') {
                                                                            return (
                                                                                <Link to={"/role/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Subscription') {
                                                                            return (
                                                                                <Link to={"/subscription/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'SubscriptionCategory') {
                                                                            return (
                                                                                <Link to={"/subscription-category/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'CustomerFavicon') {
                                                                            return (
                                                                                <Link to={"/customer-favicon/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'CustomerLogo') {
                                                                            return (
                                                                                <Link to={"/customer-logo/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'TagMatcher') {
                                                                            return (
                                                                                <Link to={"/tag-matcher/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'TagLocation') {
                                                                            return (
                                                                                <Link to={"/tag-location/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'TagLocationCategory') {
                                                                            return (
                                                                                <Link to={"/tag-location-category/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Category') {
                                                                            return (
                                                                                <Link to={"/category/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        else if (this.state.result?.flaggable_type == 'Configuration') {
                                                                            return (
                                                                                <Link to={"/configuration/detail/" + this.state.result?.flaggable?.value}>{this.state.result?.flaggable?.text}</Link>
                                                                            )
                                                                        }
                                                                        
                                                                    else {
                                                                    return (
                                                                        <span className="badge badge-light">{this.state.result?.flaggable?.text}</span>
                                                                    )
                                                                    }
                                                })()}
                                        </div></div>
                                    </div>}                             
                             
                                   
                                    {this.state.result?.created_at && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>                          
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 