import { DataEngineJobState } from "../enums";

export const GetDataEngineJobsStateList = [
    { text: DataEngineJobState.preparing, value: DataEngineJobState.preparing },
    { text: DataEngineJobState.retryable_failure, value: DataEngineJobState.retryable_failure },
    { text: DataEngineJobState.running, value: DataEngineJobState.running },
    { text: DataEngineJobState.skipped, value: DataEngineJobState.skipped },
    { text: DataEngineJobState.success, value: DataEngineJobState.success },
    { text: DataEngineJobState.unable_to_process, value: DataEngineJobState.unable_to_process },
    { text: DataEngineJobState.canceled, value: DataEngineJobState.canceled },
    { text: DataEngineJobState.delayed_start, value: DataEngineJobState.delayed_start },
    { text: DataEngineJobState.failure, value: DataEngineJobState.failure },
    { text: DataEngineJobState.finalizing, value: DataEngineJobState.finalizing },
    { text: DataEngineJobState.new, value: DataEngineJobState.new },
    { text: DataEngineJobState.new_recollection, value: DataEngineJobState.new_recollection },
    { text: DataEngineJobState.obviated, value: DataEngineJobState.obviated }
]