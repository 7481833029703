import { IExportColumnModel } from "../../../shared/models";

export const ColumnList: IExportColumnModel[] = [
    {
        isAllChecked: true,
        name: "Fields from users",
        value: "users",

        columns: [
            {
                name: "Id",
                value: "id",
                checked: true,
            },
            {
                name: "Name",
                value: "name",
                checked: true,
            },
            {
                name: "Email",
                value: "email",
                checked: true,
            },
            {
                name: "Title",
                value: "title",
                checked: true,
            },
            {
                name: "Phone",
                value: "phone",
                checked: true,
            },
            {
                name: "Remember Created At",
                value: "remember created at",
                checked: true,
            },
            {
                name: "Signin Count",
                value: "sign in count",
                checked: true,
            },
            {
                name: "Current SignIn At",
                value: "current sign in at",
                checked: true,
            },
            {
                name: "Last SignIn At",
                value: "last sign in at",
                checked: true,
            },
            {
                name: "Current SignIn Ip",
                value: "current sign in ip",
                checked: true,
            },
            {
                name: "Last SignIn IP",
                value: "last sign in ip",
                checked: true,
            },
            {
                name: "Created At",
                value: "created at",
                checked: true,
            },
            {
                name: "Updated At",
                value: "updated at",
                checked: true,
            },           
            {
                name: "Welcomed At",
                value: "welcomed at",
                checked: true,
            },

            {
                name: "Demo",
                value: "demo",
                checked: true,
            },
            {
                name: "Needs Setup",
                value: "needs setup",
                checked: true,
            },
            {
                name: "Failed Attempts",
                value: "failed attempts",
                checked: true,
            },
            {
                name: "Locked At",
                value: "locked at",
                checked: true,
            },
            {
                name: "Invited By User",
                value: "invited by user id",
                checked: true,
            },
            {
                name: "Billable",
                value: "billable",
                checked: true,
            },
            {
                name: "Staq Admin",
                value: "staq admin",
                checked: true,
            }
        ]
    },
    {
        isAllChecked: true,
        name: "Fields from Accounts",
        value: "accounts",
        columns:[
            {
                name: "Name",
                value: "name",
                checked: true,
            }
        ]

    }
]

