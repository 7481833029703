import { ConnectionState } from "../enums"

 export const GetLicenseList = [
    { text: "Free", value: "free" },
    { text: "Standard", value: "standard" },
    { text: "Enterprise", value: "enterprise" }    
]



export const GetConnectionStateList = [
    { text: ConnectionState.NEW, value: ConnectionState.NEW },
    { text: ConnectionState.AVAILABLE, value: ConnectionState.AVAILABLE },
    { text: ConnectionState.UNAVAILABLE, value: ConnectionState.UNAVAILABLE },
    { text: ConnectionState.ARCHIVED, value: ConnectionState.ARCHIVED }        
]

    
