import React, { Component, Fragment } from 'react'
import { EmailTemplatesService } from './services';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { EmailTemplatesTabs } from './EmailTemplatesTabs'
import { DropdownItemModel } from '../../../../shared/models';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { EmailTemplatesDeleteModel, EmailTemplatesDeleteResponseModel } from './models';

interface IState {
    result?: EmailTemplatesDeleteModel;
    deleteResult?: EmailTemplatesDeleteResponseModel;
    isDoubleleClick?: boolean;
    isSubscriptionsNotifications?:boolean;
}
export class DeleteEmailTemplates extends Component<any, IState> {
    private service: EmailTemplatesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new EmailTemplatesService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            name: "",
            notifications: [],
            subscriptions:[]            
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false,
        isSubscriptionsNotifications:false
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<EmailTemplatesDeleteModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.EmailTemplates,this.props) 
            });
    }

    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<EmailTemplatesDeleteResponseModel>) => {
                    if (res && res.result) {
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Email Template Successfully deleted.");
                                this.props.history.push('/email-template/list');
                            }
                        });
                    }
                });
        }
    }

    render() {
        return (
            <Fragment>
                {/* {this.state.result?.scheduled_reports.value == this.props.match.params.id && <div> */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Delete  Email Template '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <EmailTemplatesTabs id={this.props.match.params.id} url="/email-template/delete/" />
                                <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this email template <strong>“{this.state.result?.name}”</strong> ?</div>
                                {(this.state.result?.notifications.length==0 || this.state.result?.subscriptions.length==0) &&<p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>}
                               {(this.state.result?.notifications.length!=0 || this.state.result?.subscriptions.length!=0) &&<p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> You cannot delete this record because it is linked with other resources.</p>}
                                <div className="row"> 
                                    <div className="col">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Email Template</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/email-template/detail/" + this.state.result?.id}>{this.state.result?.name} </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>

                                <div className="row">
                                {this.state.result?.subscriptions.length!=0 && <div className="col-12">                                       
                                       <div className="card card-info shadow-sm">
                                           <div className="card-header"><h4>Subscriptions </h4> </div>
                                           {this.state.result?.subscriptions && <div className="card-body">
                                           {this.state.result?.subscriptions.map((item: DropdownItemModel, index) => {
                                               return (
                                                   <span key={index}>
                                                       {item && <NavLink to={"/subscription/detail/" + item.value} className="badge badge-light m-1"><strong>{index + 1}:</strong> Subscriptions {item.text}</NavLink>}
                                                   </span>
                                               );
                                           })}
                                       </div>}
                                       </div>
                                   
                           </div>}
                                </div>

                                
                                <div className="row">
                                {this.state.result?.notifications.length!=0 && <div className="col-12">                                       
                                       <div className="card card-info shadow-sm">
                                           <div className="card-header"><h4>Subscription Notifications </h4> </div>
                                           {this.state.result?.notifications && <div className="card-body">
                                           {this.state.result?.notifications.map((item: number, index) => {
                                               return (
                                                   <span key={index}>
                                                       {item && <span className="badge badge-light m-1"><strong>{index + 1}:</strong> Subscription notifications Notification #{item}</span>}
                                                   </span>
                                               );
                                           })}
                                       </div>}
                                       </div>
                                   
                           </div>}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick || this.state.result?.notifications.length!=0 || this.state.result?.subscriptions.length!=0} onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/email-template/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>} */}
            </Fragment>
        )
    }
}
