interface IBreadcrumb {
  name: string,
  url: string
}
export interface IRouteBreadcrumb {
  path: string[],
  breadcrumbs: IBreadcrumb[]
}
export const routes: IRouteBreadcrumb[] = [

  // Account BrudCrumbs Start//
  {
    path: [
      "/account/list", "/account/account-form/:id", "/account/detail/:id", "/account/connections/:id", "/account/sources/:id", "/account/installed-apps/:id", "/account/data-engine-jobs/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' }
    ]
  },
  {
    path: [
      "/account-domain/list",
      "/account-domain/detail/:id",
      "/account-domain/account-domain-form/:id",
      "/account-domain/delete/:id/:name"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Account Domains', url: '/account-domain/list' }
    ]
  },
  
  {
    path: [
      "/account-source-override/list",
      "/account-source-override/detail/:id",
      "/account-source-override/account-source-override-form/:id",
      "/account-source-override/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Account Source Overrides', url: '/account-source-override/list' }
    ]
  },
  {
    path: [
      "/connection/list",
      "/connection/detail/:id",
      "/connection/connection-form/:id",
      "/connection/delete/:id",
      "/connection/backups/:id",
      "/connection/data-engine-jobs/:id",
      "/connection/maintain/:id",
      "/connection/visualize/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' }
    ]
  }, 
  {
    path: [
      "/connection/collection-mission/:id"
    ],
    breadcrumbs: [
      { name: 'Collection Mission', url: "/connection/collection-mission/:id" }
    ]
  },
  {
    path: [
      "/collection-mission-state-transition/:id"
    ],
    breadcrumbs: [
      { name: 'Collection Mission State Transition', url: "/collection-mission-state-transition/:id" }
    ]
  },
  {
    path: [
      "/custom-connection/collection-mission/:id"
    ],
    breadcrumbs: [
      { name: 'Collection Mission', url: "/custom-connection/collection-mission/:id" }
    ]
  }, 
  {
    path: [
      "/collection-mission-task/:id"
    ],
    breadcrumbs: [
      { name: 'Collection Mission Task', url: "/collection-mission-task/:id" }      
    ]
  },
  {
    path:[
      "/control_mission/:id"
    ],
    breadcrumbs: [
      { name: 'Control Mission', url: "/control_mission/:id" }
    ]
  },
  {
    path: [
      "/connection-semaphore/list",
      "/connection-semaphore/detail/:id",
      "/connection-semaphore/connection-semaphore-form/:id",
      "/connection-semaphore/delete/:id"     
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' },
      { name: 'Connections Semaphores', url: '/connection-semaphore/list' }
    ]
  }, 

  {
    path: [
      "/custom-connection/list",
      "/custom-connection/detail/:id",
      "/custom-connection/custom-connection-form/:id",
      "/custom-connection/delete/:id",
      "/custom-connection/backups/:id",
      "/custom-connection/data-engine-jobs/:id",
      "/custom-connection/maintain/:id",      
      "/custom-connection/file-upload/:id"      
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Custom Connections', url: '/custom-connection/list' }
    ]
  }, 

  {
    path: [
      "/platform/list", 
      "/platform/detail/:id",
      "/platform/platform-form/:id",
      "/platform/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' },
      { name: 'Platforms', url: '/platform/list' }
    ]
  },
  {
    path: [
      "/extractor/list",
      "/extractor/detail/:id",
      "/extractor/extractor-form/:id",
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' },
      { name: 'Platforms', url: '/platform/list' },
      { name: 'Extractors', url: '/extractor/list' }
    ]
  },

  {
    path: [
      "/extractor-rate-limit/list",
      "/extractor-rate-limit/detail/:id",
      "/extractor-rate-limit/extractor-rate-limit-form/:id",
      "/extractor-rate-limit/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' },
      { name: 'Platforms', url: '/platform/list' },
      { name: 'Extractors', url: '/extractor/list' },
      { name: 'Extractor Rate Limit', url: '/extractor-rate-limit/list' }
    ]
  },
  {
    path: [
      "/extractor-time-zone-specification/list",
      "/extractor-time-zone-specification/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' },
      { name: 'Platforms', url: '/platform/list' },
      { name: 'Extractors', url: '/extractor/list' },
      { name: 'Time Zone Specifications', url: '/extractor-time-zone-specification/list' }
    ]
  },
  {
    path: [
      "/extractor-semaphore/list",
      "/extractor-semaphore/detail/:id",
      "/extractor-semaphore/extractor-semaphore-form/:id",
      "/extractor-semaphore/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Connections', url: '/connection/list' },
      { name: 'Platforms', url: '/platform/list' },
      { name: 'Extractors', url: '/extractor/list' },
      { name: 'Extractor Semaphores', url: '/extractor-semaphore/list' }
    ]
  },

  {
    path: [
      "/inbound-file/list",
      "/inbound-file/detail/:id"         
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Custom Connections', url: '/custom-connection/list' },
      { name: 'Inbound Files', url: '/inbound-file/list' }
    ]
  }, 

  {
    path: [
      "/inbound-email-address/list",  "/inbound-email-address/detail/:id", "/inbound-email-address/inbound-email-address-form/:id","/inbound-email-address/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Custom Connections', url: '/custom-connection/list' },
      { name: 'Inbound Email Addresses', url: '/inbound-email-address/list' }
    ]
  },
  {
    path: [
      "/inbound-email-message/list",  "/inbound-email-message/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Custom Connections', url: '/custom-connection/list' },
      { name: 'Inbound Email Addresses', url: '/inbound-email-address/list' },
      { name: 'Inbound Email Messages', url: '/inbound-email-message/list' }
    ]
  },



  // User BrudCrumbs Start//
  {
    path: [
      "/user/list", "/user/user-form/:id", "/user/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' }
    ]


  },
  {
    path: [
      "/dashboard/list",
      "/dashboard/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Dashboards', url: '/dashboard/list' }
    ]


  },

  {
    path: [
      "/report/list",
      "/report/report-form/:id",
      "/report/detail/:id",
      "/report/report_definition/:id",
      "/report/report_data/:id",
      "/report/report_change_history/:id",
      "/report/data_engine_jobs/:id",
      "/report/data_engine_report_jobs/:id",
      "/report/report_table_storage/:id",
      "/report/backups/:id",
      "/report/reportreadiness/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' }
    ]


  },

  {
    path: [
      "/installed-report-application/list",
      "/installed-report-application/detail/:id",
      "/installed-report-application/installed-report-application-form/:id",
      "/installed-report-application/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Installed Applications', url: '/installed-report-application/list' }
    ]


  },
  {
    path: [
      "/report-change-history/list",
      "/report-change-history/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Change Histories', url: '/report-change-history/list' }
    ]


  },
  {
    path: [
      "/report-column/list",
      "/report-column/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Columns', url: '/report-column/list' }
    ]


  },
  {
    path: [
      "/report-column-field/list",
      "/report-column-field/detail/:id",
      "/field/schema-manager/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Columns', url: '/report-column/list' },
      { name: 'Report Column Fields', url: '/report-column-field/list' }
    ]


  },
  {
    path: [
      "/report-column-filter/list",
      "/report-column-filter/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Columns', url: '/report-column/list' },
      { name: 'Report Column Filters', url: '/report-column-filter/list' }
    ]


  },
  {
    path: [
      "/report-filter/list",
      "/report-filter/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Filters', url: '/report-filter/list' }
    ]
  },
  {
    path: [
      "/report-data-source-filter/list",
      "/report-data-source-filter/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Filters', url: '/report-filter/list' },
      { name: 'Report Data Source', url: '/report-data-source-filter/list' }
    ]
  },
  {
    path: [
      "/report-run-schedule/list",
      "/report-run-schedule/report-run-schedule-form/:id",
      "/report-run-schedule/detail/:id",
      "/report-run-schedule/report-run-schedule/:id",
      "/report-run-schedule/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Run Schedules', url: '/report-run-schedule/list' }
    ]


  },
  {
    path: [
      "/report-view/list",
      "/report-view/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Views', url: '/report-view/list' }
    ]


  },
  {
    path: [
      "/applet/list",
      "/applet/detail/:id",
      "/applet/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Report Views', url: '/report-view/list' },
      { name: 'Applets', url: '/applet/list' }
    ]


  },
  {
    path: [
      "/source-report/list",
      "/source-report/detail/:id",
      "/source-report/edit/:id"
    ],
    breadcrumbs: [
      { name: 'Accounts', url: '/account/list' },
      { name: 'Users', url: '/user/list' },
      { name: 'Reports', url: '/report/list' },
      { name: 'Source Reports', url: '/source-report/list' }
    ]


  },
  // User BrudCrumbs End//



  // Individual BrudCrumbs Start//
  {
    path: [
      "/application/list", "/application/application-form/:id", "/application/detail/:id", "/application/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Applications', url: '/application/list' }
    ]
  },
  
  {
    path: [
      "/admin/list", "/admin/admin-form/:id", "/admin/detail/:id","/admin/delete/:id"
    ],
    breadcrumbs: [
      { name: 'STAQ Contacts', url: '/admin/list' }
    ]
  },
  {
    path: [
      "/category/list", "/category/category-form/:id", "/category/detail/:id", "/category/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Category', url: '/category/list' }
    ]
  },
  {
    path: [
      "/staq-event/list", "/staq-event/detail/:id"
    ],
    breadcrumbs: [
      { name: 'STAQ Events', url: '/staq-events/list' }
    ]
  },

  {
    path: [
      "/customer-favicon/list", "/customer-favicon/customer-favicon-form/:id", "/customer-favicon/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Customer Favicons', url: '/customer-favicon/list' }
    ]
  },
  {
    path: [
      "/customer-logo/list", "/customer-logo/customer-logo-form/:id", "/customer-logo/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Customer Logos', url: '/customer-logo/list' }      
    ],
   
  },

  {
    path: [
      "/database-engine/list", "/database-engine/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Database Engines', url: '/database-engine/list' }
    ]
  },

  {
    path: [
      "/data-engine/list", "/data-engine/detail/:id"
    ],
    breadcrumbs: [
      { name: 'Database Engines', url: '/database-engine/list' },
      { name: 'Data Engines', url: '/data-engine/list' }
    ]
  },
  {
    path: [
      "/data-engine-job/list", "/data-engine-job/detail/:id","/data-engine-job/data-engine-job-form/:id","/data-engine-job/state-transition/:id"
    ],
    breadcrumbs: [
      { name: 'Database Engines', url: '/database-engine/list' },
      { name: 'Data Engines', url: '/data-engine/list' },
      { name: 'Data Engines Jobs', url: '/data-engine-job/list' }
    ]
  },
  {
    path: [
      "/invocation-schedule/list",
      "/invocation-schedule/detail/:id",
      "/invocation-schedule/invocation-schedule-form/:id",
      "/invocation-schedule/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Applications', url: '/application/list' },
      { name: 'Invocation Schedules', url: '/invocation-schedule/list' }
    ]
  },
  {
    path: [
      "/role/list",
      "/role/detail/:id",
      "/role/role-form/:id",
    ],
    breadcrumbs: [
      { name: 'Roles', url: '/role/list' }
    ]
  },
  {
    path: [
      "/subscription/list",
      "/subscription/detail/:id",
      "/subscription/subscription-form/:id",
      "/subscription/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Subscriptions', url: '/subscription/list' }
    ]
  },
  {
    path: [
      "/subscription-category/list",
      "/subscription-category/detail/:id",
      "/subscription-category/subscription-category-form/:id",
      "/subscription-category/delete/:id"
    ],
    breadcrumbs: [
      { name: 'Subscriptions', url: '/subscription/list' },
      {name: 'Subscription Category', url: '/subscription-category/list'}
    ]
  },
  {
    path: [
      "/extraction-engine/list", "/extraction-engine/detail/:id","/extraction-engine/extraction-engine-form/:id","/extraction-engine/delete/:id"
    ],
    breadcrumbs: [      
      { name: 'Extraction Engines', url: '/extraction-engine/list' }
    ]
  },
  {
    path: [
      "/extraction-scheduling-recipe/list", "/extraction-scheduling-recipe/detail/:id","/extraction-scheduling-recipe/extraction-scheduling-recipe-form/:id","/extraction-scheduling-recipe/delete/:id"
    ],
    breadcrumbs: [      
      { name: 'Extraction Scheduling Recipes', url: '/extraction-scheduling-recipe/list' }
    ]
  },

  {
    path: [
      "/extraction-scheduling-recipe-change/list", "/extraction-scheduling-recipe-change/detail/:id"
    ],
    breadcrumbs: [      
      { name: 'Extraction Scheduling Recipe Changes', url: '/extraction-scheduling-recipe-change/list' }
    ]
  },
  {
    path: [
      "/schema/list", "/schema/detail/:id"
    ],
    breadcrumbs: [      
      { name: 'Schemas', url: '/schema/list' }
    ]
  },
  {
    path: [
      "/scope/list", "/scope/detail/:id","/scope/scope-form/:id/:schemaName","/scope/schema-manager/:id/:name/:schemaName"
    ],
    breadcrumbs: [     
      { name: 'Schemas', url: '/schema/list' },  
      { name: 'Scopes', url: '/scope/list' }
    ]
  },
  { 
    path: [
      "/field/list", "/field/detail/:id/:schemaName?/:scopeId?","/field/field-form/:id/:schemaName/:scopeId","/field/schema-manager/:id/:schemaName/:scopeId"
    ],
    breadcrumbs: [     
      { name: 'Schemas', url: '/schema/list' }, 
      { name: 'Scopes', url: '/scope/list' },
      { name: 'Fields', url: '/field/list' }
    ]
  },
  {
    path: [
      "/field-classification/list", "/field-classification/detail/:id","/field-classification/field-classification-form/:id"
    ],
    breadcrumbs: [     
      { name: 'Schemas', url: '/schema/list' }, 
      { name: 'Scopes', url: '/scope/list' },
      { name: 'Fields', url: '/field/list' },
      { name: 'Field Classifications', url: '/field-classification/list' }
    ]
  },
  {
    path: [
      "/scope-schema-change/list", "/scope-schema-change/detail/:id"
    ],
    breadcrumbs: [      
      { name: 'Schemas', url: '/schema/list' },
      { name: 'Scope Schema Changes', url: '/scope-schema-change/list' }
    ]
  },
  {
    path: [
      "/scope-field-schema-change/list", "/scope-field-schema-change/detail/:id"
    ],
    breadcrumbs: [      
      { name: 'Schemas', url: '/schema/list' },
      { name: 'Scope Schema Changes', url: '/scope-schema-change/list' },
      { name: 'Scope Field Schema Changes', url: '/scope-field-schema-change/list' }
    ]
  },
  {
    path: [
      "/tag-location-category/list", "/tag-location-category/detail/:id","/tag-location-category/tag-location-category-form/:id","/tag-location-category/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Tag Matcher', url: '/tag-matcher/list' },
      { name: 'Tag Location Category', url: '/tag-location-category/list' }
    ]
  },
  {
    path: [
      "/tag-location/list", "/tag-location/detail/:id","/tag-location/tag-location-form/:id","/tag-location/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Tag Matcher', url: '/tag-matcher/list' },
      { name: 'Tag Locations', url: '/tag-location/list' },
    ]
  },
  {
    path: [
      "/tag-matcher/list", "/tag-matcher/detail/:id","/tag-matcher/tag-matcher-form/:id","/tag-matcher/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Tag Matchers', url: '/tag-matcher/list' }
    ]
  },
  {
    path: [
      "/feature-flag/list", "/feature-flag/detail/:id","/feature-flag/feature-flag-form/:id","/feature-flag/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Feature Flags', url: '/feature-flag/list' }
    ]
  },
  {
    path: [
      "/email-layout/list", "/email-layout/detail/:id","/email-layout/email-layout-form/:id","/email-layout/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Email Layouts', url: '/email-layout/list' }
    ]
  },
  {
    path: [
      "/email-template/list", "/email-template/detail/:id","/email-template/email-template-form/:id","/email-template/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Email Templates', url: '/email-template/list' }
    ]
  },

  {
    path: [
      "/protected-operation/list", "/protected-operation/detail/:id","/protected-operation/protected-operation-form/:id","/protected-operation/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Protected Operations', url: '/protected-operation/list' }
    ]
  },
  {
    path: [
      "/plan-category/list", "/plan-category/detail/:id","/plan-category/plan-category-form/:id","/plan-category/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Plan Categories', url: '/plan-category/list' },
    ]
  },
  {
    path: [
      "/configuration/list", "/configuration/detail/:id","/configuration/configuration-form/:id","/configuration/delete/:id"
    ],
    breadcrumbs: [  
      { name: 'Configurations', url: '/configuration/list' },
    ]
  },
];
