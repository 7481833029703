import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import { SubscriptionsRoute } from "../../routing/IndividualRoutes";

export class SubscriptionsPage extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumbs />
        <SubscriptionsRoute />
      </Fragment>
    );
  }
}
