import React, { Component, Fragment } from 'react'
import { ConnectionsService } from './services/connection.service';
import { HttpResponse } from '../../../core';
import { ConnectionTabs } from '.';
import { DataEngineJobsListModel } from './models';
import { ConvertDateTime, HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { Link, NavLink } from "react-router-dom";

interface IState {
    result?:Array<DataEngineJobsListModel>;
}
export class ConnectionDataEngineJobs extends Component<any, IState>  {
    private service: ConnectionsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ConnectionsService();
    }
    initialState: Partial<IState> = {
       result:[]
    }
 
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getDataEngineJobsById(this.props.match.params.id)
            .then((res: HttpResponse<Array<DataEngineJobsListModel>>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Connection,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Data Engine Jobs</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <ConnectionTabs id={this.props.match.params.id} url="/connection/data-engine-jobs/" />
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <h5></h5>
                                    <div className="table-responsive">
                                        <table className="table table table-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">State</th>
                                                    <th scope="col">Runtime</th>
                                                    <th scope="col">Scope</th>
                                                    <th scope="col">Mission Id</th>
                                                    <th scope="col">Triggered By</th>
                                                    <th scope="col">Job Type</th>
                                                    <th scope="col">Time Range Override</th>                                                    
                                                    <th scope="col">Created At</th>                                                    
                                                </tr>
                                            </thead>
                                            {this.state.result && <tbody>
                                                {this.state.result.map((item: DataEngineJobsListModel, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td><NavLink to={"/data-engine-job/detail/" + item.id}>{item.state}</NavLink></td>
                                                            <td>{item.runtime}</td>
                                                          {item.scope &&<td><Link to={"/scope/detail/" + item.scope?.value}>{item.scope?item.scope.text:""}</Link></td>}
                                                          {!item.scope &&<td></td>}
                                                            <td>{item.collection_mission_id}</td>
                                                            <td>{item.triggered_by}</td>
                                                            <td>{item.job_type}</td>
                                                            <td>{item.time_range_override}</td>
                                                            <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}
 