import React, { Component, Fragment } from 'react'
import { ReportDataSourceFilterService } from './services/report.data.source.filter.service';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../../../shared/helpers';
import { NotFoundResponseArea} from '../../../../../shared/enums';
import { HttpResponse } from '../../../../../core';

import { ReportDataSourceFilterDetailModel } from './models/report.data.source.filter.detail.model';
import { Loader } from '../../../../../shared/loaders';

interface IState {
    result?: ReportDataSourceFilterDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ReportDataSourceFilterDetail extends Component<any, IState> {
    private service: ReportDataSourceFilterService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportDataSourceFilterService();
    }

    initialState: Partial<IState> = {
        result: {             
          id: 0,        
          report_data_source_id:0,
          display_order: 0,
          report_filter: {
            id: 0,
            filter_type: "",
            comparator: "",
            operator: "",
            logical_operator: "",
            values: []
          },
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getReportDataSourceFilterById(this.props.match.params.id)
            .then((res: HttpResponse<ReportDataSourceFilterDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Report_Data_Source_Filter,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Report data source filter &nbsp;'ReportDataSourceFilter #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                        {!this.state.isLoading &&  <>                              
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.report_data_source_id && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Report data source</b>
                                            </div>
                                            <div className="card-body">
                                           ReportDataSource #{this.state.result?.report_data_source_id}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.report_filter?.filter_type && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Filter type</b>
                                            </div>
                                            <div className="card-body" style={{textTransform: 'capitalize'}}>
                                              {this.state.result?.report_filter?.filter_type}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.report_filter?.comparator && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Comparator</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.report_filter?.comparator}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.report_filter?.operator && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Operator</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.report_filter.operator}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.report_filter?.logical_operator && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Logical operator</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.report_filter.logical_operator}
                                            </div>
                                        </div>
                                </div>}
                                {<div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Display order</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.display_order}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.report_filter?.values && this.state.result?.report_filter?.values.length > 0 && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Values</b>
                                            </div>
                                            <div className="card-body">
                                              <ul>
                                              {this.state.result?.report_filter.values.map(item => (
                                                <li key={item}>{item}</li>
                                              ))}
                                              </ul>
                                            </div>
                                        </div>
                                </div>}  
                                </div>  
                                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}      
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 