import { HttpWrapper, AppConfig } from '../../../../core'
import { StaqContactListResponseModel,StaqContactDetailModel,PostStaqContactModel,EditStaqContactModel, StaqContactDeleteModel } from '../models';
import { DeleteResponseModel, LinkResourcesResponseModel } from '../../../../shared/models';
import { PostExportModel } from "../../../../shared/export/post.export.model";
export class StaqContactService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getStaqContactList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<StaqContactListResponseModel>(AppConfig.apiEndpoint + '/v1/admins/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getStaqContactById(id: number) {
      return this.wrapper.get<StaqContactDetailModel>(AppConfig.apiEndpoint + '/v1/admins/' + id);
    }
  
  
    postStaqContact(admin: PostStaqContactModel) {
      return this.wrapper.post<PostStaqContactModel>(AppConfig.apiEndpoint + '/v1/admins', admin);
    }
  
    editStaqContact(id: number) {
      return this.wrapper.get<EditStaqContactModel>(AppConfig.apiEndpoint + '/v1/admins/edit/' + id);
    }
  
    updateStaqContact(admin: PostStaqContactModel) {
      return this.wrapper.put<PostStaqContactModel>(AppConfig.apiEndpoint + '/v1/admins/' + admin.id, admin);
    }
  
    
    deleteRecordById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/admins/' + id);
    }
    getLinkedResourcesById(id: number) {
      return this.wrapper.get<StaqContactDeleteModel>(AppConfig.apiEndpoint + '/v1/admins/' + id + '/linked_resources');
    } 
    
  
    exportStaqContact(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/StaqContacts/export', exportData);
    }
  
  
  }