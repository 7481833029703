import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { ExtractionSchedulingRecipesListResponseModel, ExtractionSchedulingRecipesDetailModel, EditUpdateExtractionSchedulingRecipesModel, DeleteLinkResource } from "../models";
import { DeleteResponseModel, LinkResourcesResponseModel } from "../../../../shared/models";


export class ExtractionSchedulingRecipesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getExtractionSchedulingRecipesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ExtractionSchedulingRecipesListResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getExtractionSchedulingRecipesById(id: number) {
    return this.wrapper.get<ExtractionSchedulingRecipesDetailModel>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/' + id);
  }

  editExtractionSchedulingRecipes(id?: number) {
    return this.wrapper.get<EditUpdateExtractionSchedulingRecipesModel>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/edit/' + id);
  }
  postExtractionSchedulingRecipes(ExtractionSchedulingRecipes: EditUpdateExtractionSchedulingRecipesModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/',ExtractionSchedulingRecipes);
  }

  updateExtractionSchedulingRecipes(ExtractionSchedulingRecipes: EditUpdateExtractionSchedulingRecipesModel) {
    return this.wrapper.put<any>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/' + ExtractionSchedulingRecipes.id, ExtractionSchedulingRecipes);
  }

  getLinkedResourcesById(id: number) {
    return this.wrapper.get<DeleteLinkResource>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/' + id + '/linked_resources');
  }

  deleteRecordById(id: number) {
    return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/' + id);
  }

  exportExtractionSchedulingRecipes(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/export', exportData);
  }


}