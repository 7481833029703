import React, { Component, Fragment, createRef } from 'react'

import { TagMatchersService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse, GetDataEngineJobsStateList, GetRejexOptionsList } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { TagMatchersTabs } from './TagMatchersTabs';
import { NotFoundResponseArea, RegexOptionsEnum } from '../../../../shared/enums';
import { AddUpdateTagMatchersModel } from './models';

interface IState {
    editTagMatchers?: AddUpdateTagMatchersModel,
    id?:number;
    name?:string;   
    position?:number;
    regex?:string;
    options?:number;
    handler?:string;
    sample?:string;
    enabled?:boolean;
    category?:string;     
    nameError?: string,
    regexError?: string,
    handlerError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class TagMatchersForm extends Component<any, IState> {
    private service: TagMatchersService;
    private nameRef: any;
    private regexRef: any;
    private handlerRef: any;

    constructor(props) {
        super(props)
        this.service = new TagMatchersService();
        this.state = this.initialState;
        this.nameRef = createRef();
        this.regexRef = createRef();
        this.handlerRef = createRef();
    }

    initialState: Partial<IState> = {
        editTagMatchers: {           
            id: 0,            
            name:"",
            category:"Unknown",
            enabled:true,
            handler:"",
            options:0,
            position:0,
            regex:"",
            sample:""
            
        },                       
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        id: 0,            
        name:"",
        category:"Unknown",
        enabled:true,
        handler:"",
        options:0,
        position:0,
        regex:"",
        sample:"",    
        nameError:"",
        handlerError:"",
        regexError:""
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
    }

    loadData() {
        this.service.editTagMatchers(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdateTagMatchersModel>) => {
                if (res && res.result) {
                    this.setState({                       
                        id:res.result?.id,
                        name:res.result?.name,
                        category:res.result?.category,
                        enabled:res.result?.enabled,
                        handler:res.result?.handler,
                        options:res.result?.options,
                        position:res.result?.position,
                        sample:res.result?.sample,
                        regex:res.result?.regex
                    }, () => {
                        
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.TagMatchers,this.props) 
            });
    }


    updateData(data: AddUpdateTagMatchersModel) {
        this.setSavingFlag(true);
        this.service.updateTagMatchers(data)
            .then((res: HttpResponse<AddUpdateTagMatchersModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Tag Matcher successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/tag-matcher/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/tag-matcher/tag-matcher-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/tag-matcher/tag-matcher-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateTagMatchersModel) {
        this.setSavingFlag(true);
        this.service.postTagMatchers(data)
            .then((res: HttpResponse<AddUpdateTagMatchersModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({                        
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Tag Matcher successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/tag-matcher/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/tag-matcher/tag-matcher-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/tag-matcher/tag-matcher-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }


    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
        if (this.nameRef.current.value) {
            this.setState({ nameError: "" });
          }
          if (this.regexRef.current.value) {
            this.setState({ regexError: "" });
          }
          if (this.handlerRef.current.value) {
            this.setState({ handlerError: "" });
          }
    }

    validate = () => {
        let nameError = "";   
        let regexError = "";   
        let handlerError = "";      
        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }

        if (!this.regexRef.current.value) {
            regexError = "Regex can't be blank";
        }

        if (!this.handlerRef.current.value) {
            handlerError = "Handler can't be blank";
        }
        if (nameError || regexError || handlerError) {
            this.setState({ nameError: nameError,regexError:regexError,handlerError:handlerError })
            return false;
        }
        if (!nameError || !regexError || !handlerError) {
            nameError = "";
            regexError = "";
            handlerError = "";
            this.setState({ nameError: nameError,regexError:regexError,handlerError:handlerError })
            return true;
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdateTagMatchersModel = {                        
            id: this.state.id,
            name:this.state.name,
            category:this.state.category,
            enabled:this.state.enabled,
            handler:this.state.handler,
            options:this.state.options,
            position:this.state.position,
            regex:this.state.regex,
            sample:this.state.sample            
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else
            {
        this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">                            
                            <h4>{this.state.isShowtext} Tag Matcher {this.state.name && <span> '{this.state.name}'</span>}</h4> 
                        </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                {this.props.match.params.id != 0 && <TagMatchersTabs id={this.props.match.params.id} url="/tag-matcher/TagMatchers-form/" />}
                                    <div className="row mt-4">
                                        <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Name<span className=" text-danger">*</span></label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" maxLength={255}  ref={this.nameRef} name="name" value={this.state.name} onChange={this.handleChange} className= {!this.state.nameError ? 'form-control' : 'form-control  is-invalid'}/>
                                                <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Position</label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="number" maxLength={255}  name="position" value={this.state.position} onChange={this.handleChange} className= 'form-control'/>                                               
                                                </div>
                                            </div>                                          
                                        </div>
                                        <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="enabled"
                              checked={this.state.enabled}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="enabled"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="enabled"
                            >Enabled
                            </label>
                          </div>                          
                        </div>
                      </div>
                                       
                                    </div>

                                    <div className="row mt-4">
                                    <div className="col-md-4">
                                <div className="form-group shadow-sm p-3 mb-0">
                                  <label>Category</label>
                                  <textarea
                                    rows={5}
                                    name="category"
                                    value={this.state.category}
                                    onChange={this.handleChange}
                                    className="form-control" style={{'height':'160px'}}
                                  ></textarea>                                  
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group shadow-sm p-3 mb-0">
                                  <label>Regex<span className=" text-danger">*</span></label>
                                  <textarea
                                    rows={5}
                                    name="regex"
                                    ref={this.regexRef}
                                    value={this.state.regex}
                                    onChange={this.handleChange}
                                    className={!this.state.regexError ? 'form-control' : 'form-control  is-invalid'}
                                    style={{'height':'160px'}}
                                  ></textarea>   
                                  <div className="invalid-feedback">
                                                        {this.state.regexError}
                                                    </div>                               
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group shadow-sm p-3 mb-0">
                                  <label>Handler<span className=" text-danger">*</span></label>
                                  <textarea
                                    rows={5}
                                    ref={this.handlerRef}
                                    name="handler"
                                    value={this.state.handler}
                                    onChange={this.handleChange}
                                    className={!this.state.handlerError ? 'form-control' : 'form-control  is-invalid'}
                                    style={{'height':'160px'}}
                                  ></textarea> 
                                  <div className="invalid-feedback">
                                                        {this.state.handlerError}
                                                    </div>                                 
                                </div>
                              </div>
                                    </div>

                                    <div className="row mt-4">
                                    <div className="col-md-4">
                                <div className="form-group shadow-sm p-3 mb-0">
                                  <label>Sample</label>
                                  <textarea
                                    rows={5}
                                    name="sample"
                                    value={this.state.sample}
                                    onChange={this.handleChange}
                                    className="form-control" style={{'height':'160px'}}
                                  ></textarea>                                  
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3">
                          <label>Options </label>
                          <select
                            className="form-control"
                            name="options"
                            value={this.state.options}
                            onChange={this.handleChange}
                          >
                            {GetRejexOptionsList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {RegexOptionsEnum[item.text]}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                              </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                        <Link type="button" className="btn btn-lg btn-default" to="/tag-matcher/list">{ButtonType.Cancel}</Link>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />      
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />                      
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }
}
