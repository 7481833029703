import React, { Component, Fragment } from 'react'
import { ChangeHistoryDetailModel } from './models/change.history.detail.model'
import { ChangeHistoryService } from './services/change.history.service';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { ConvertDateTime, HandleNotFoundResponse } from '../../../../shared/helpers';
import { Editor } from '../../../../shared';
import { ReportChangeHistoryTabs } from './ReportChangeHistoryTabs'
import { NotFoundResponseArea } from '../../../../shared/enums';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ChangeHistoryDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ReportChangeHistoryDetail extends Component<any, IState> {
    private service: ChangeHistoryService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ChangeHistoryService();
    }

    initialState: Partial<IState> = {
        result: {
            description: "",
            created_at: new Date(),
            ip: "",
            last_definition: JSON,
            id: 0,
            admin_id: 0,
            admin_name: "",
            report_id: 0,
            report_name: "",
            user_id: 0,
            user_name: ""
        },
        parsedData: "",
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getChangeHistoryById(this.props.match.params.id)
            .then((res: HttpResponse<ChangeHistoryDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    }, () => {
                        this.setState({
                            parsedData: JSON.stringify(this.state.result?.last_definition, null, '\t')
                        })
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportChangeHistories,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                 <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for Report Change History '{ConvertDateTime(this.state.result?.created_at, 'LLL')}'</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportChangeHistoryTabs id={this.props.match.params.id} url="/report-change-history/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Report </div>
                                                <div className="card-body"><NavLink to={"/report/detail/" + this.state.result?.report_id}>{this.state.result?.report_name}</NavLink></div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Edited By </div>
                                                <div className="card-body"><NavLink to={"/user/detail/" + this.state.result?.user_id}>{this.state.result?.user_name}</NavLink></div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Edited At </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Ip </div>
                                                <div className="card-body">{this.state.result?.ip}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.result?.id == this.props.match.params.id && this.state.result?.description != null && <div className="col-md-6  mt-3">
                                            <h6>Description</h6>
                                            <Editor StringData={this.state.result?.description} FormateType="json" />
                                        </div>}
                                        {this.props.match.params.id && this.state.result?.last_definition != null && this.state.result?.last_definition && <div className="col-md-6  mt-3">
                                            <h6>Report Definition Before Changes</h6>
                                            <Editor StringData={this.state.parsedData} FormateType="json" />
                                        </div>}
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
