import React, { Component, Fragment } from 'react'
import { BrowserRouter } from "react-router-dom";
import { UserDashboardRoute } from '../../routing/UserRoutes'
import Breadcrumbs from '../../shared/Breadcrumbs';

export  class UserDashboardPage extends Component {
    render() {
        return (
           
                <Fragment>
                    <Breadcrumbs />   
                    <UserDashboardRoute /> 
                </Fragment>

        )
    }
}
