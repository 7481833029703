import React, { Component, Fragment } from 'react'
import { ReportViewsDetailModel, ReportViewUserModel } from './models'
import { ReportViewsService } from './services/report.views.service';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { ConvertDateTime, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ViewReportTabs } from './ViewReportTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ReportViewsDetailModel;
    isLoading?: boolean;
}
export class ReportViewsDetail extends Component<any, IState>  {
    private service: ReportViewsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportViewsService();
    }

    initialState: Partial<IState> = {
        result: {
            report_id: 0,
            id: 0,
            parent_report_view: {
                text: "",
                value: 0
            },
            parent_report_view_id: 0,
            report: {
                text: "",
                value: 0
            },
            report_view_users: [],
            user: {
                text: "",
                value: 0
            },
            user_id: 0,
            created_at: new Date(),
            updated_at: new Date(),
            filters: ""
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData(this.props.match.params.id);
    }

    componentWillReceiveProps(nextprops) {
        this.loadData(nextprops.match.params.id);
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData(id) {
        this.setLoading(true);
        this.service.getReportViewsById(id)
            .then((res: HttpResponse<ReportViewsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportViews,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Details for Report View '{this.state.result?.report.text===""?"-":this.state.result?.report.text}'</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ViewReportTabs id={this.props.match.params.id} url="/report-view/detail/" />
                                    {!this.state.isLoading &&  <> 
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Id</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.id}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Report</b>
                                                </div>
                                                {this.state.result?.report && <div className="card-body">
                                                    <NavLink to={"/report/detail/" + this.state.result?.report.value}>{this.state.result?.report.text}</NavLink>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created By</b>
                                                </div>
                                                {this.state.result?.user && <div className="card-body">
                                                    <NavLink to={"/user/detail/" + this.state.result?.user.value}>{this.state.result?.user.text}</NavLink>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Parent Report View</b>
                                                </div>
                                                {this.state.result?.parent_report_view && <div className="card-body">
                                                    <NavLink to={"/report-view/detail/" + this.state.result?.parent_report_view.value}>{this.state.result?.parent_report_view.text}</NavLink>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.result?.report_view_users.length != 0 && <div className="row">
                                        <div className="col-md-12  mt-3"> <h6>Report View Users</h6></div>
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table table-md table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">User</th>
                                                            <th scope="col">Sharer</th>
                                                            <th scope="col">Follower</th>
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Updated At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.report_view_users.map((item: ReportViewUserModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {item.user && <td> <NavLink to={"/user/detail/" + item.user.value}>{item.user.text}</NavLink></td>}
                                                                    {!item.user && <td></td>}
                                                                    {item.sharer_user && <td> <NavLink to={"/user/detail/" + item.sharer_user.value}>{item.sharer_user.text}</NavLink></td>}
                                                                    {!item.sharer_user && <td></td>}
                                                                    {item.follower && <td>True</td>}
                                                                    {!item.follower && <td>False</td>}
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                    <td>{ConvertDateTime(item.updated_at, 'LLL')}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Filters</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.filters}</div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated At</b>
                                                </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
