import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { FieldsListResponseModel, FieldsDetailModel, EditUpdateFieldsModel, FieldschemaChangeModel } from "../models";



export class FieldsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getFieldsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<FieldsListResponseModel>(AppConfig.apiEndpoint + '/v1/fields/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getFieldsById(id: number) {
    return this.wrapper.get<FieldsDetailModel>(AppConfig.apiEndpoint + '/v1/fields/' + id);
  }


  editFields(id?: number) {
    return this.wrapper.get<EditUpdateFieldsModel>(AppConfig.apiEndpoint + '/v1/fields/edit/' + id);
  }

  updateFields(fields: EditUpdateFieldsModel) {
    return this.wrapper.put<EditUpdateFieldsModel>(AppConfig.apiEndpoint + '/v1/fields/' + fields.id, fields);
  }

  postSchemaChanges(TagMatchers: FieldschemaChangeModel) {
    return this.wrapper.post<FieldschemaChangeModel>(AppConfig.apiEndpoint + '/v1/scopes/schema_changes', TagMatchers);
}

  exportFields(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/fields/export', exportData);
  }


}