import { HttpWrapper } from '../../../../core'
import {AppConfig} from '../../../../core/app.config'
import { DashboardListResponseModel, DashboardDetailModel } from '../models';
import { PostExportModel } from '../../../../shared/export/post.export.model';


export class UserDashboardService
{
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
}

getUserDashboardList(currentPage:number,pageSize:number,sortBy:string,sortOrder:boolean, filters:any) {
    return this.wrapper.post<DashboardListResponseModel>(AppConfig.apiEndpoint+'/v1/dashboard/list?current_page='+currentPage+'&page_size='+pageSize+'&sort_by='+sortBy+'&is_desc='+sortOrder,{filters:filters});    
   } 

   exportDashboard(exportData?: PostExportModel)
   {
     return this.wrapper.getFileByPost(AppConfig.apiEndpoint+'/v1/dashboard/export', exportData);
   }

  getUserDashboardById(id:number)
  {
    return this.wrapper.get<DashboardDetailModel>(AppConfig.apiEndpoint+'/v1/dashboard/'+id);
  }

}