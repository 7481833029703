import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ReportViewsRoute } from '../../routing'

export  class ReportViewsPage extends Component {
    render() {
        return (
            <Fragment>
            <Breadcrumbs />
            <ReportViewsRoute />
        </Fragment>
        )
    }
}
