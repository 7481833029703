import React, { Component, Fragment } from 'react'
import { ExtractionSchedulingRecipesChangesService } from './services';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { ExtractionSchedulingRecipesChangesDetailModel } from './models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ExtractionSchedulingRecipesChangesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ExtractionSchedulingRecipesChangesDetail extends Component<any, IState> {
    private service: ExtractionSchedulingRecipesChangesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ExtractionSchedulingRecipesChangesService();
    }

    initialState: Partial<IState> = {
        result: { 
            admin:{
                id:0,
                email:""
            },
           extraction_scheduling_recipe_id:0,
            id:0,
            summary:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getExtractionSchedulingRecipesChangesById(this.props.match.params.id)
            .then((res: HttpResponse<ExtractionSchedulingRecipesChangesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ExtractionSchedulingRecipesChanges,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Extraction scheduling recipe change 'ExtractionSchedulingRecipeChange #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">    
                        {!this.state.isLoading &&  <>                          
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.admin && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Admin</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/admin/detail/" + this.state.result?.admin.id}>{this.state.result?.admin.email}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.extraction_scheduling_recipe_id!=0 && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Extraction Scheduling Recipe</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/extraction-scheduling-recipe/detail/" + this.state.result?.extraction_scheduling_recipe_id}>ExtractionSchedulingRecipe #{this.state.result?.extraction_scheduling_recipe_id}</NavLink>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.summary &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Summary</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.summary}
                                        </div>
                                    </div>
                                </div>}
                               
                            </div>

                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                            
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 