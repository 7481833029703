import React, { Component } from 'react'
import { IFilterItemProps, OperatorList, FilterItemInputRenderType } from './models'
import { ConnectionState, DataEngineJobState } from '../enums';


interface IState {
    id: string,
    op: string,
    value: string
}
export class FilterItemDataEngineJobState extends Component<IFilterItemProps, IState> {
    constructor(props) {
        super(props);
    }

    oprators = OperatorList.dataEngineJobsState;
    inputRenderType: any = FilterItemInputRenderType.NoBox;

    selectedOptionValue = this.oprators[0].operator;
    selectedValue = '';

    handleDropDownChange = (event: any) => {
        let selectedObject = this.oprators.find(x => x.name == event.target.value);
        this.selectedOptionValue = selectedObject != null ? selectedObject.operator : "";
        this.inputRenderType = selectedObject?.renderType;
        if (this.selectedOptionValue == 'is' || this.selectedOptionValue == 'isnot') {
            this.selectedValue = 'null';
        }
        if (this.selectedOptionValue === 'eq') {
            switch (selectedObject?.name) {
                case DataEngineJobState.new:
                    this.selectedValue = DataEngineJobState.new
                    break;
                case DataEngineJobState.new_recollection:
                    this.selectedValue = DataEngineJobState.new_recollection
                    break;
                case DataEngineJobState.preparing:
                    this.selectedValue = DataEngineJobState.preparing
                    break;
                case DataEngineJobState.obviated:
                    this.selectedValue = DataEngineJobState.obviated
                    break;
                    case DataEngineJobState.retryable_failure:
                        this.selectedValue = DataEngineJobState.retryable_failure
                        break;
                    case DataEngineJobState.running:
                        this.selectedValue = DataEngineJobState.running
                        break;
                    case DataEngineJobState.skipped:
                        this.selectedValue = DataEngineJobState.skipped
                        break;
                    case DataEngineJobState.success:
                        this.selectedValue = DataEngineJobState.success
                        break;
                        case DataEngineJobState.unable_to_process:
                        this.selectedValue = DataEngineJobState.unable_to_process
                        break;
                    case DataEngineJobState.canceled:
                        this.selectedValue = DataEngineJobState.canceled
                        break;
                    case DataEngineJobState.delayed_start:
                        this.selectedValue = DataEngineJobState.delayed_start
                        break;
                    case DataEngineJobState.failure:
                        this.selectedValue = DataEngineJobState.failure
                        break;
                        case DataEngineJobState.finalizing:
                            this.selectedValue = DataEngineJobState.finalizing
                            break;
            }

        }
        this.brodCastValue();        
    }

    brodCastValue = () => {
        this.setState({ id: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
        this.props.onValueChange({ column: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
    }
    render() {
        return (
            <div className="form-group mb-3">
                <label>{this.props.column.displayText}</label>
                <div className="row">
                    <div className="col-5">
                        <select className="form-control form-control-sm mb-1" name={this.props.column.columnName} onChange={this.handleDropDownChange}>
                            {this.oprators.map((num, index) => {
                                return (
                                    <option key={index} value={num.name} className='form-control'>{num.name}</option>
                                );
                            })}

                        </select>
                    </div>
                </div>
            </div>
        )
    }
}
