import React, { Component, Fragment } from "react";
import { ExtractorSemaphoresDetailModel } from "./models";
import { ExtractorSemaphoresService } from "./services/extractor.semaphores.service";
import { HttpResponse } from "../../../../../../core";
import {
  HandleNotFoundResponse
} from "../../../../../../shared/helpers";
import { NotFoundResponseArea } from "../../../../../../shared/enums";
import { ExtractorSemaphoresTabs } from "./ExtractorSemaphoresTabs";
import {Link} from 'react-router-dom'
import { Loader } from '../../../../../../shared/loaders';

interface IState {
  result?: ExtractorSemaphoresDetailModel;
  isLoading?: boolean;

}

export class ExtractorSemaphoresDetail extends Component<any, IState> {
  private service: ExtractorSemaphoresService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ExtractorSemaphoresService();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      size: 0,
    timeout_seconds: 0,
    extractor_id: 0,
    extractor_name: "",
    },
    isLoading: false,

  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }

  //function to load the initial view data coming from the API.
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
loadData() {
    this.setLoading(true);
    this.service
      .getExtractorSemaphoresById(this.props.match.params.id)
      .then((res: HttpResponse<ExtractorSemaphoresDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Extractor_Semaphores, this.props);
      });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Extractor Semaphore 'ExtractorSemaphore #{this.state.result?.id}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <ExtractorSemaphoresTabs
                  id={this.props.match.params.id}
                  url="/extractor-semaphore/detail/"
                />
                {!this.state.isLoading &&  <>  
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.extractor_name && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Extractor</b>
                        </div>
                        <div className="card-body">
                          <Link to={"/extractor/detail/"+ this.state.result?.extractor_id}>{this.state.result?.extractor_name}</Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.size && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Size</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.size}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.timeout_seconds && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Timeout Seconds</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.timeout_seconds}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}         
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
