import React, { Component, Fragment } from 'react'
import { TagMatchersService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { TagMatchersDetailModel } from './models';
import { TagMatchersTabs } from './TagMatchersTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: TagMatchersDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class TagMatchersDetail extends Component<any, IState> {
    private service: TagMatchersService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new TagMatchersService();
    }

    initialState: Partial<IState> = {
        result: {             
            id:0,           
            name:"",
            created_at:null,
            updated_at:null,
            enabled:false,
            handler:"",
            options:0,
            position:0,
            regex:"",
            sample:"",
            category:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getTagMatchersById(this.props.match.params.id)
            .then((res: HttpResponse<TagMatchersDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.TagMatchers,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Tag Matcher &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <TagMatchersTabs id={this.props.match.params.id} url="/tag-matcher/detail/" /> 
                        {!this.state.isLoading &&  <>                          
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.name && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink className="badge badge-light text-link" to={"/tag-matcher/detail/" + this.state.result?.id}>{this.state.result?.name} </NavLink>
                                            </div>
                                        </div>
                                    </div>}   

                                     <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Position</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.position}
                                            </div>
                                        </div>
                                    </div>   

                                     {this.state.result?.category && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Category</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.category}
                                            </div>
                                        </div>
                                    </div>}   
                                    {this.state.result?.enabled && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Enabled</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    }                          
                                   
                                   
                            </div>   

                            <div className="row"> 
                            {this.state.result?.regex && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Regex</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.regex}
                                            </div>
                                        </div>
                                    </div>}  

                                    {this.state.result?.sample && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Sample</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.sample}
                                            </div>
                                        </div>
                                    </div>}  
                            </div>
                            <div className="row"> 
                            <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Options</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.options}
                                            </div>
                                        </div>
                                    </div>  

                                    {this.state.result?.handler && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Handler</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.handler}
                                            </div>
                                        </div>
                                    </div>} 


                            {this.state.result?.created_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>                       
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 