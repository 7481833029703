import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { AppletsRoute } from '../../routing'

export class AppletsPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <AppletsRoute />
            </Fragment>
        )
    }
}
