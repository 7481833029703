import * as React from 'react';
import { NavLink, Redirect } from "react-router-dom";
import { AppConfig } from '../../core/app.config';
import { GetUserInfo, setCookie } from '../../shared/helpers';

function LogOut() {
  localStorage.clear();
  // set cookie expiry time to now, so browser will automatically clear the cookie
  setCookie(AppConfig.RefreshTokenKey, null, -1);
}
function TopHeader() {

  return (
    <div className="navbar navbar-expand-lg main-navbar sticky text-white">
      <div className="form-inline mr-auto">
        <ul className="navbar-nav mr-3">
          <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg collapse-btn"> <i className="fas fa-bars"></i></a></li>
          <li><a href="#" className="nav-link nav-link-lg fullscreen-btn"><i className="fas fa-expand"></i></a></li>
        </ul>
      </div>
      <ul className="navbar-nav navbar-right float-right">
        <li>
          {/* <a href="#" className="btn btn-outline-light text-dark bg-white">Logout</a> */}
          <span className="nav-link nav-link-lg">Welcome {GetUserInfo().name}</span>
        </li>
        <li className="dropdown"><a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user" aria-expanded="false"> <i className="fas fa-ellipsis-v"></i><span className="d-sm-none d-lg-inline-block"></span></a>
          <div className="dropdown-menu dropdown-menu-right pullDown">
            {/* <div className="dropdown-title">Hello Sarah Smith</div> */}
            {/* <a href="" className="dropdown-item has-icon"> <i className="far
										fa-user"></i> Profile
              </a> <a href="" className="dropdown-item has-icon"> <i className="fas fa-cog"></i>
                Settings
              </a>
            <div className="dropdown-divider"></div> */}
            <NavLink to={'/login'} onClick={LogOut} className="dropdown-item has-icon text-danger"> <i className="fas fa-sign-out-alt"></i>
                Logout
              </NavLink>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default TopHeader 
