import { NotFoundResponseArea } from "../enums/not.found.response.area.enum";

export const HandleNotFoundResponse = (
  ex: any,
  area: NotFoundResponseArea,
  props: any
) => {
  if (ex.status == 404) {
    switch (area) {
      case NotFoundResponseArea.Accounts:
        return props.history.push("/account/list");
      case NotFoundResponseArea.AccountDomain:
        return props.history.push("/account-domain/list");
      case NotFoundResponseArea.AccountSourceOverrides:
        return props.history.push("/account-source-override/list");
      case NotFoundResponseArea.Connection:
        return props.history.push("/connection/list");
      case NotFoundResponseArea.ConnectionSemaphores:
        return props.history.push("/connection-semaphore/list");
      case NotFoundResponseArea.CustomConnection:
        return props.history.push("/custom-connection/list");
      case NotFoundResponseArea.InboundFiles:
        return props.history.push("/inbound-file/list");
      case NotFoundResponseArea.Users:
        return props.history.push("/user/list");
      case NotFoundResponseArea.Dashboards:
        return props.history.push("/dashboard/list");
      case NotFoundResponseArea.Reports:
        return props.history.push("/report/list");
      case NotFoundResponseArea.InstalledApplications:
        return props.history.push("/installed-report-application/list");
      case NotFoundResponseArea.ReportChangeHistories:
        return props.history.push("/report-change-history/list");
      case NotFoundResponseArea.ReportColumns:
        return props.history.push("/report-column/list");
      case NotFoundResponseArea.ReportColumnFields:
        return props.history.push("/report-column-field/list");
      case NotFoundResponseArea.ReportColumnFilters:
        return props.history.push("/report-column-filter/list");
      case NotFoundResponseArea.ReportFilters:
        return props.history.push("/report-filter/list");
      case NotFoundResponseArea.ReportRunSchedules:
        return props.history.push("/report-run-schedule/list");
      case NotFoundResponseArea.ReportViews:
        return props.history.push("/report-view/list");
      case NotFoundResponseArea.Applets:
        return props.history.push("/applet/list");
      case NotFoundResponseArea.SourceReports:
        return props.history.push("/source-report/list");
      case NotFoundResponseArea.StaqContacts:
        return props.history.push("/admin/list");
      case NotFoundResponseArea.CustomerFavicon:
        return props.history.push("/customer-favicon/list");
      case NotFoundResponseArea.CustomerLogo:
        return props.history.push("/customer-logo/list");
      case NotFoundResponseArea.Applications:
        return props.history.push("/application/list");
      case NotFoundResponseArea.DatabaseEngine:
        return props.history.push("/database-engine/list");
      case NotFoundResponseArea.DataEngine:
        return props.history.push("/data-engine/list");
      case NotFoundResponseArea.DataEngineJob:
        return props.history.push("/data-engine-job/list");
      case NotFoundResponseArea.ExtractionEngine:
        return props.history.push("/extraction-engine/list");
      case NotFoundResponseArea.ExtractionSchedulingRecipesChanges:
        return props.history.push("/extraction-scheduling-recipe/list");
      case NotFoundResponseArea.ExtractionSchedulingRecipes:
        return props.history.push("/extraction-scheduling-recipe/list");
      case NotFoundResponseArea.Schemas:
        return props.history.push("/schema/list");
      case NotFoundResponseArea.Fields:
        return props.history.push("/field/list");
      case NotFoundResponseArea.FieldsClassification:
        return props.history.push("/field-classification/list");
      case NotFoundResponseArea.Scopes:
        return props.history.push("/scope/list");
      case NotFoundResponseArea.TagLocationCategory:
        return props.history.push("/tag-location-category/list");
      case NotFoundResponseArea.TagMatchers:
        return props.history.push("/tag-matcher/list");
      case NotFoundResponseArea.EmailTemplates:
        return props.history.push("/email-template/list");
      case NotFoundResponseArea.ProtectedOperation:
        return props.history.push("/protected-operation/list");
      case NotFoundResponseArea.Extractor_Rate_Limit:
        return props.history.push("/extractor-rate-limit/list");
      case NotFoundResponseArea.Roles:
        return props.history.push("/role/list");
      case NotFoundResponseArea.InvocationSchedules:
        return props.history.push("/invocation-schedule/list");
      case NotFoundResponseArea.Extractor_Time_Zone_Specifications:
        return props.history.push("/extractor-time-zone-specification/list");
      case NotFoundResponseArea.Extractor_Semaphores:
        return props.history.push("/extractor-semaphore/list");
      case NotFoundResponseArea.Subscriptions:
        return props.history.push("/subscription/list");
      case NotFoundResponseArea.SubscriptionCategory:
        return props.history.push("/subscription-category/list");
      case NotFoundResponseArea.Category:
        return props.history.push("/category/list");
      case NotFoundResponseArea.Extractor:
        return props.history.push("/extractor/list");
        case NotFoundResponseArea.InboundEmailAddresses:
          return props.history.push("/inbound-email-address/list");
          case NotFoundResponseArea.InboundEmailMessages:
            return props.history.push("/inbound-email-message/list");
        case NotFoundResponseArea.TagLocation:
          return props.history.push("/tag-location/list");
        case NotFoundResponseArea.PlanCategories:
          return props.history.push("/plan-category/list");
        case NotFoundResponseArea.FeatureFlags:
          return props.history.push("/feature-flag/list");
        case NotFoundResponseArea.Configurations:
          return props.history.push("/configuration/list");
          case NotFoundResponseArea.Platforms:
          return props.history.push("/platform/list");
          case NotFoundResponseArea.CollectionMission:
          return props.history.push("/connection/collection-mission/"+props.match.params.id);
    }
  }
  if (ex.status == 403 && props!==null) {
    if(props.location.pathname.includes("/detail/"))
    {
      return props.history.goBack();
    }
    else
    {
    return props.history.push("/");
    }
  }
};
