import { userModel, UserResponseModel, UserListModel, UserDetailModel, EditUserModel, ResetPasswordResponseModel } from '../models';
import { HttpWrapper } from '../../../core'
import {AppConfig} from '../../../core/app.config'
import { PostExportModel } from '../../../shared/export/post.export.model';

export  class UserService  {    
    private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
    
  }

  getUserList(currentPage:number,pageSize:number,sortBy:string,sortOrder:boolean, filters:any) {
   return this.wrapper.post<UserResponseModel>(AppConfig.apiEndpoint+'/v1/users/list?current_page='+currentPage+'&page_size='+pageSize+'&sort_by='+sortBy+'&is_desc='+sortOrder,{filters:filters});    
  }  

  postUser(user:userModel) {    
   return this.wrapper.post<userModel>(AppConfig.apiEndpoint+'/v1/users',user);
  }  

  editUser(id:number)
  {
    return this.wrapper.get<EditUserModel>(AppConfig.apiEndpoint+'/v1/users/edit/'+id);
  }

  updateuser(user:userModel)
  {
    return this.wrapper.put<userModel>(AppConfig.apiEndpoint+'/v1/users/'+user.id,user);
  }

  getUserById(id:number)
  {
    return this.wrapper.get<UserDetailModel>(AppConfig.apiEndpoint+'/v1/users/'+id);
  }

  resetPasswordById(id:number)
  {
    return this.wrapper.put<ResetPasswordResponseModel>(AppConfig.apiEndpoint+'/v1/users/'+id+'/reset-password',{});
  }

  exportUser(exportData?: PostExportModel)
  {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint+'/v1/users/export', exportData);
  }


}
