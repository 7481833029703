export enum ChangeType
{
    add="add",
    change_type="change",
    delete="delete",    
    rename="rename"

}

export enum SchemaChangeFieldTypesEnum
{
    big_integer = "big_integer",
	boolean = "boolean",
	currency = "currency",
	date = "date",
	float = "float",
	integer = "integer",
	string = "string",
	text = "text",
	time = "time",
	url = "url"

}