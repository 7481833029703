import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ConnectionsSemaphoresRoute } from '../../routing'



export  class ConnectionSemaphoresPage extends Component {
    render() {
        return (
            <Fragment>
            <Breadcrumbs />
            <ConnectionsSemaphoresRoute />
        </Fragment>
        )
    }
}
