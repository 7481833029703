import React, { Component, Fragment } from 'react'
import { CustomerFaviconDetailModel } from './models/customer.favicons.detail.model'
import { CustomerFaviconService } from './services/customer.favicons.service';
import { HttpResponse } from '../../../../core';
import { HandleNotFoundResponse, formatBytes, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { CustomerFaviconTabs } from './CustomerFaviconTabs';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: CustomerFaviconDetailModel;
    isLoading?: boolean;
}
export class CustomerFaviconDetail extends Component<any, IState>  {
    private service: CustomerFaviconService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new CustomerFaviconService();
    }
    initialState: Partial<IState> = {
        result: {             
            id: 0,            
            container:"",
            created_at:null,
            filename:"",
            key:"",
            location:"",
             mimetype:"",
             size:"",
             updated_at:null,
             public_s3_url:""
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getCustomerFaviconById(this.props.match.params.id)
            .then((res: HttpResponse<CustomerFaviconDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.CustomerFavicon,this.props)
            });
    }
    render() {
        return (
            <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Customer Favicon 'CustomerFavicon #{this.props.match.params.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <CustomerFaviconTabs id={this.props.match.params.id} url="/customer-favicon/detail/" />
                            {!this.state.isLoading &&  <>  
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">
                                <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>URL</b>
                                        </div>
                                        <div className="card-body">
                                        <img src={this.state.result?.public_s3_url} height="16" />&nbsp;&nbsp;<a href={this.state.result?.public_s3_url}>{this.state.result?.public_s3_url}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>File Name</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.filename}
                                        </div>
                                    </div>
                                </div>
                               {this.state.result?.size && <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Size</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.size!=null?formatBytes(this.state.result?.size):"0 Bytes"}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Mimetype</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.mimetype}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Location</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.location}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Container</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.container}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">                                
                                <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Key</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.key}
                                        </div>
                                    </div>
                                </div>
                                {this.state.result?.created_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}
 