import React, { Component, Fragment, createRef } from 'react'
import { HttpResponse } from '../../../core';
import { HandleNotFoundResponse, ShowSuccessMessage, GetStrategyList,GetRunAtHourList } from '../../../shared/helpers';
import { NotFoundResponseArea, ButtonType, Strategy } from '../../../shared/enums';
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { EditUpdateExtractionSchedulingRecipesModel } from './models/edit.update.extraction.scheduling.recipes.model';
import { ExtractionSchedulingRecipesService } from './services/extraction.scheduling.recipes.service';
import { ExtractionSchedulingRecipesTabs } from './ExtractionSchedulingRecipesTabs';

interface IState {
    extractionSchedulingRecipes?: EditUpdateExtractionSchedulingRecipesModel,
    editExtractionSchedulingRecipes?: EditUpdateExtractionSchedulingRecipesModel,
    id?: number,
  strategy?: string,
  locked?: boolean,
  notes?: string,
  run_at_hour?: number,
  max_duration?: string,
  earliest_available_data?: string,
  latest_available_data?: string,
  extra_duration?: string,
  spread_mission_period?: number,
  spreadMissionPeriodError?:string,
  includes_today?: boolean,

  selectedMaxDuration?: string,
  selectedExtraDuration?: string,
  maxDurationError?: string,
  

  maxDurationSelectedError?: string,
  extraDurationSelectedError?: string,


    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
} 
export  class ExtractionSchedulingRecipesForm extends Component<any, IState> {
     //declaring service type
     private service: ExtractionSchedulingRecipesService;  
     private maxDurationRef: any;
     private maxSelectedDurationRef: any;

     
     private extraSelectedDurationRef: any;

 
     constructor(props) {
         super(props)
         this.service = new ExtractionSchedulingRecipesService();         
         this.state = this.initialState;
         this.maxDurationRef = createRef();
         this.maxSelectedDurationRef = createRef();         
         this.extraSelectedDurationRef = createRef();
 
     }
 
     initialState: Partial<IState> = {
         extractionSchedulingRecipes:{
            id: 0,
            earliest_available_data:"",
            extra_duration:"",
            includes_today:false,
            latest_available_data  :"",
            locked:false,
            max_duration:"",
            notes:"",
            run_at_hour:0,
             spread_mission_period:0        
         },         
         id: 0,
         earliest_available_data:"",
         extra_duration:"",
         includes_today:false,
         latest_available_data  :"",
         locked:false,
         max_duration:"",
         notes:"",
         run_at_hour:0,
          spread_mission_period:0,
          spreadMissionPeriodError:"",
         isShowtext: "Edit",
         isSave: "",
         isSaving: false,

         selectedExtraDuration:"",
         selectedMaxDuration:"",         
         maxDurationError:"",
         maxDurationSelectedError:"",
         extraDurationSelectedError:""
     }

     componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.loadData();
        }        
    }

    loadData() {
        this.service.editExtractionSchedulingRecipes(this.props.match.params.id)
            .then((res: HttpResponse<EditUpdateExtractionSchedulingRecipesModel>) => {
                if (res && res.result) {   
                  if(res.result?.max_duration)                               
                  {
                    this.splitMaxDuration(res.result?.max_duration);
                  }
                  if(res.result?.extra_duration)                               
                  {
                    this.splitExtraDuration(res.result?.extra_duration);
                  }
                    this.setState({
                        spread_mission_period:res.result?.spread_mission_period,
                        earliest_available_data:res.result?.earliest_available_data,                        
                        includes_today:res.result?.includes_today,
                        id:res.result?.id,
                        latest_available_data:res.result?.latest_available_data,                        
                        locked:res.result?.locked,
                        notes:res.result?.notes,
                        run_at_hour:res.result?.run_at_hour,
                        strategy:res.result?.strategy
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ExtractionEngine,this.props)
            });
    }

    updateData(data: EditUpdateExtractionSchedulingRecipesModel) {
        this.setSavingFlag(true);
        this.service.updateExtractionSchedulingRecipes(data)
            .then((res: HttpResponse<EditUpdateExtractionSchedulingRecipesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        extractionSchedulingRecipes: res.result
                    }, () => {
                        ShowSuccessMessage("Extraction scheduling recipe successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/extraction-scheduling-recipe/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/extraction-scheduling-recipe/extraction-scheduling-recipe-form/' + this.state?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }

    handleChange = (event: any) => {
            const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            }) 

            if (this.state.strategy==Strategy.CUSTOM && this.maxDurationRef.current.value) {              
              this.setState({ maxDurationError: "" });
            }

            if (this.maxSelectedDurationRef.current.value) {              
              this.setState({ maxDurationSelectedError: "" });
            }


            if (this.extraSelectedDurationRef.current.value) {              
              this.setState({ extraDurationSelectedError: "" });
            }

    }

    splitMaxDuration(arr:string)
    {
    let data =  arr.split(" ");
    this.setState({max_duration:data[0],selectedMaxDuration:data[1].toLowerCase()})    
   
    }

    splitExtraDuration(arr:string)
    {
    let data =  arr.split(" ");
    this.setState({extra_duration:data[0],selectedExtraDuration:data[1].toLowerCase()})    
    }

    validate = () => {    
      let maxDurationError = "";
      let maxDurationSelectedError = "";
      
      let extraDurationSelectedError = "";
      let spreadMissionPeriodError="" ;       
        if (this.state.spread_mission_period!<10) {
          spreadMissionPeriodError = "Spread mission period must be greater than or equal to 10";
        }
        if (this.state.spread_mission_period!>1440) {
          spreadMissionPeriodError = "Spread mission period must be less than or equal to 1440";
        }
       
        if (this.maxDurationRef.current.value && !this.state.selectedMaxDuration) {
          maxDurationSelectedError = "Please Select Duration";
        }

        if (this.state.extra_duration && !this.extraSelectedDurationRef.current.value) {
          extraDurationSelectedError = "Please Select Duration";
        }
      

        if (spreadMissionPeriodError|| maxDurationSelectedError || extraDurationSelectedError) {
            this.setState({ spreadMissionPeriodError: spreadMissionPeriodError, maxDurationSelectedError:maxDurationSelectedError, extraDurationSelectedError:extraDurationSelectedError })
            return false;
        }
        else {            
          spreadMissionPeriodError = "";
          maxDurationError="";
          maxDurationSelectedError="";
          extraDurationSelectedError="";
            this.setState({ spreadMissionPeriodError: spreadMissionPeriodError, maxDurationSelectedError:maxDurationSelectedError,extraDurationSelectedError:extraDurationSelectedError })
            return true;
        }
        return true;
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: EditUpdateExtractionSchedulingRecipesModel = {            
            id:this.props.match.params.id,            
           earliest_available_data:this.state.earliest_available_data,
           extra_duration:this.state.extra_duration==""?" ": this.state.extra_duration+" "+this.state.selectedExtraDuration,
           includes_today:this.state.includes_today,
           latest_available_data:this.state.latest_available_data,
           locked:this.state.locked,
           max_duration:this.state.max_duration==""?" ": this.state.max_duration+" "+this.state.selectedMaxDuration,
           notes:this.state.notes,
           run_at_hour:this.state.run_at_hour,
           spread_mission_period:this.state.spread_mission_period,
           strategy:this.state.strategy            
        };

        if (isValid) {
            this.updateData(data)
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>Edit Extraction Scheduling Recipe 'ExtractionSchedulingRecipe #{this.state.id}'</h4>
                        </div>
                        <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.match.params.id != 0 && (
                      <ExtractionSchedulingRecipesTabs
                        id={this.props.match.params.id}
                        url="/extraction-scheduling-recipe/extraction-scheduling-recipe-form/"
                      />
                    )}

                        <div className="row">
                        <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                        <label>Strategy </label>
                          <select
                            className="form-control"
                            name="strategy"
                            value={this.state.strategy}
                            onChange={this.handleChange}
                          >
                            {GetStrategyList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                       {this.state.locked && <small>This recipe is locked to protect the extractor from dangerous scheduling changes.  For more information see <a href='https://sites.google.com/a/staq.com/staqnowledged/home/4---our-product/staq-admin/how_to/how-to-change-schedules'>wiki</a></small>}
                       {!this.state.locked && <small>This list comes from <a href='https://github.com/staqapp/staq_scheduling/tree/master/lib/staq_scheduling/strategies'>StaqScheduling::Strategies</a></small>}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                          <label>Run At Hour </label>
                          <select
                            className="form-control"
                            name="run_at_hour"
                            value={this.state.run_at_hour}
                            onChange={this.handleChange}
                          >
                            {GetRunAtHourList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                          <small>This is the earliest we should schedule a mission for the daily refresh, in UTC time</small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row  shadow-sm p-3">
                          <div className="col">
                        
                          <label>Max Duration </label>
                          <input                           
                            type="number"
                            ref={this.maxDurationRef}
                            name="max_duration"
                            value={this.state.max_duration}
                            onChange={this.handleChange}
                            className="form-control"
                          /> 
                        </div>
                        <div className="col">                        
                          <label>Duration</label>
                          <select
                          ref={this.maxSelectedDurationRef}
                            className={!this.state.maxDurationSelectedError ? 'form-control' : 'form-control  is-invalid'}
                            name="selectedMaxDuration"
                            value={this.state.selectedMaxDuration}
                            onChange={this.handleChange}
                          >
                            <option value="">-------Select Duration-------</option>
                            <option value="year">Year</option>
                            <option value="month">Month</option>
                            <option value="week">Week</option>
                            <option value="days">Days</option>
                            <option value="hour">Hour</option>
                          </select>     
                          <div className="invalid-feedback">
                                                        {this.state.maxDurationSelectedError}
                                                    </div>                      
                        </div> 
                        
                        <small>A Chronic-parseable string indicating the maximum time range to handle (e.g. '30 days', '12 hours')</small>
                        </div>                        
                      </div> 
                                         
                    </div>

                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                          <label>Earliest Available Data</label>
                          <input                           
                            type="text"
                            name="earliest_available_data"
                            value={this.state.earliest_available_data}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                           <small>A Ruby-evaluatable string indicating how far back we can go (e.g. '100.days.ago.beginning_of_day')</small>
                        </div>
                      </div> 
                      <div className="col-md-4">
                        <div className="row  shadow-sm p-3">
                          <div className="col">
                        
                          <label>Extra Duration </label>
                          <input                           
                            type="number"                            
                            name="extra_duration"
                            value={this.state.extra_duration}
                            onChange={this.handleChange}
                            className='form-control'
                          />                                                      
                        </div>
                        <div className="col">                        
                          <label>Duration</label>
                          <select                          
                            className={!this.state.extraDurationSelectedError ? 'form-control' : 'form-control  is-invalid'}
                            name="selectedExtraDuration"
                            ref={this.extraSelectedDurationRef}
                            value={this.state.selectedExtraDuration}
                            onChange={this.handleChange}
                          >
                            <option value="">-------Select Duration-------</option>
                            <option value="year">Year</option>
                            <option value="month">Month</option>
                            <option value="week">Week</option>
                            <option value="days">Days</option>
                            <option value="hour">Hour</option>
                          </select>     
                          <div className="invalid-feedback">
                                                        {this.state.extraDurationSelectedError}
                                                    </div>                      
                        </div> 
                        
                        <small>A Chronic-parseable string indicating how much extra time each mission will include (e.g. '7 days')</small>
                        </div>                        
                      </div>  
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                          <label>Latest Available Data </label>
                          <input                           
                            type="text"
                            name="latest_available_data"
                            value={this.state.latest_available_data}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                           <small>A Ruby-evaluatable string indicating the latest data that will be available (e.g. '2.days.ago.end_of_day')</small>
                        </div>
                      </div>                    
                    </div>

                    <div className="row">
                    <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="includes_today"
                              checked={this.state.includes_today}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="includes_today"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="includes_today"
                            >Includes Today
                            </label>
                          </div>
                          <small>Tells us whether the extracted data can include today's date</small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                          <label>Spread Mission Period</label>
                          <input                           
                            type="number"
                            name="spread_mission_period"
                            value={this.state.spread_mission_period}
                            onChange={this.handleChange}
                            className={!this.state.spreadMissionPeriodError ? 'form-control' : 'form-control  is-invalid'}
                          />
                          <div className="invalid-feedback">
                                                        {this.state.spreadMissionPeriodError}
                                                    </div>
                           <small>Do NOT change this. It's only for Kate's convenience.</small>
                        </div>
                      </div>   
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Notes</label>
                          <textarea
                                    rows={10}                            
                            name="notes"
                            value={this.state.notes}
                            onChange={this.handleChange}
                            className="form-control"
                          ></textarea>
                          <small>Only visible to STAQ staff. This is a good place to mention why the current settings are the way they are.</small>
                                                    </div>
                          
                          </div>
                          
                      </div>                     
                    </div>

</div>
</div>
</div>
</div>

                        <div className="text-center mt-3 mb-4">

                            {!this.state.isSaving && <Fragment>
                                <Link type="button" className="btn btn-lg btn-default" to="/extraction-scheduling-recipe/list">{ButtonType.Cancel}</Link>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" float="center" width="200px" ></Loader>
                        </div>
                    
                </form>
            </Fragment>
        )
    }
}
