import { HttpWrapper, AppConfig } from "../../../../../core";
import { ChangeHistoryListResponseModel } from "../models/change.history.list.response.model";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ChangeHistoryDetailModel } from "../models/change.history.detail.model";

export class ChangeHistoryService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getChangeHistoryList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ChangeHistoryListResponseModel>(AppConfig.apiEndpoint + '/v1/report_history/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getChangeHistoryById(id: number) {
        return this.wrapper.get<ChangeHistoryDetailModel>(AppConfig.apiEndpoint + '/v1/report_history/' + id);
    }

    
    exportChangeHistory(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_history/export', exportData);
    }

}