import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { DataEgineRoute } from '../../routing'

export  class DataEginePage extends Component {
    render() {
        return (
            <Fragment>
        <Breadcrumbs /> 
        <DataEgineRoute />
      </Fragment>
        )
    }
}
