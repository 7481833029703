import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { AccounSourceOverridesRoute } from '../../routing/AccountRoutes'


export class AccountsSourceOverridesPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <AccounSourceOverridesRoute />
            </Fragment>
        )
    }
}
