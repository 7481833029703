import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import { InvocationSchedulesRoute } from "../../routing/IndividualRoutes";

export class InvocationSchedulesPage extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumbs />
        <InvocationSchedulesRoute />
      </Fragment>
    );
  }
}
