import React, { Component, Fragment } from 'react'
import { AccountConnectionModel, ConnectionListModel } from './models'
import { AccountService } from './services/account.service';
import { HttpResponse } from '../../core';
import AccountTabs from './AccountTabs';
import { ConvertDateTime, HandleNotFoundResponse } from '../../shared/helpers';
import { NotFoundResponseArea } from '../../shared/enums';
import { NavLink } from "react-router-dom";
interface IState {
    result?: AccountConnectionModel;
}
export class AccountConnections extends Component<any, IState> {
    private service: AccountService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AccountService();
    }
    initialState: Partial<IState> = {
        result: {
            connection_billable: 0,
            custom_connection_billable: 0,
            connections: [],
            custom_connections: [],
            connection_overrides: [],
            custom_connection_overrides: [],
            id: 0
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getAccountConnectionsById(this.props.match.params.id)
            .then((res: HttpResponse<AccountConnectionModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Accounts,this.props)    
            });
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Connections / Custom Connections</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountTabs id={this.props.match.params.id} url="/account/connections/" />
                                <div className="row">
                                    <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Billable Connections</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.connection_billable}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Billable Custom Connections</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.custom_connection_billable}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Connections</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Billable</th>
                                                        <th scope="col">Created At</th>

                                                    </tr>
                                                </thead>
                                                {this.state.result?.connections.length != 0 && <tbody>
                                                    {this.state.result?.connections.map((item: ConnectionListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                  {item.name && <td> <NavLink to={"/connection/detail/" + item.id}>{item.name}</NavLink></td>}
                                                                {!item.name && <td></td>}
                                                                <td>{item.state}</td>
                                                                <td>{item.billable && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.billable && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Custom Connections</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Billable</th>
                                                        <th scope="col">Created At</th>

                                                    </tr>
                                                </thead>
                                                {this.state.result?.custom_connections.length != 0 && <tbody>
                                                    {this.state.result?.custom_connections.map((item: ConnectionListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                 {item.name && <td> <NavLink to={"/custom-connection/detail/" + item.id}>{item.name}</NavLink></td>}
                                                                {!item.name && <td></td>}
                                                                <td>{item.state}</td>
                                                                <td>{item.billable && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.billable && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {this.state.result?.connection_overrides.length != 0 && <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Overrides Connections</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Billable</th>
                                                        <th scope="col">Created At</th>

                                                    </tr>
                                                </thead>
                                                {this.state.result?.connection_overrides.length != 0 && <tbody>
                                                    {this.state.result?.connection_overrides.map((item: ConnectionListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><a href="#"> {item.name}</a></td>
                                                                <td>{item.state}</td>
                                                                <td>{item.billable && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.billable && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.custom_connection_overrides.length != 0 && <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Custom Overrides Connections</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Billable</th>
                                                        <th scope="col">Created At</th>

                                                    </tr>
                                                </thead>
                                                {this.state.result?.custom_connection_overrides.length != 0 && <tbody>
                                                    {this.state.result?.custom_connection_overrides.map((item: ConnectionListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><a href="#"> {item.name}</a></td>
                                                                <td>{item.state}</td>
                                                                <td>{item.billable && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.billable && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
