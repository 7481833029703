import React, { Component, Fragment } from 'react'
import { StaqContactDetailModel } from './models'
import { StaqContactService } from './services/staq.contact.service';
import { HttpResponse } from '../../../core';
import { HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { StaqContactTabs } from './StaqContactTabs';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: StaqContactDetailModel;
    isLoading?: boolean;
}

export class StaqContactDetail extends Component<any, IState> {
    private service: StaqContactService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new StaqContactService();
    }

    // Defines the initial state for the values coming from API.
    initialState: Partial<IState> = {
        result: {
            id: 0,
            team: "",
            role: "",
            name: "",
            email: "",
        },
        isLoading: false,
    }

    //Get the detail from the API whenever the page loads.
    componentDidMount() {
        this.loadData();
    }


    //function to load the initial view data coming from the API.
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getStaqContactById(this.props.match.params.id)
            .then((res: HttpResponse<StaqContactDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.StaqContacts, this.props)
            });
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for STAQ Contacts '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {   Number(this.state.result?.id) !== 0 &&
                                    <StaqContactTabs id={this.props.match.params.id} name={this.state.result?.name} role={this.state.result?.role} url="/admin/detail/" />
                                }
                                {!this.state.isLoading &&  <>         
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    {this.state.result?.name &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Name</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.name}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.email &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Email</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.email}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.role &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Role</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.role}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.team &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Team</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.team}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
