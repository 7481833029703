import { IExportColumnModel } from "../../../../../shared/models";

export const ReportColumnsColumnList: IExportColumnModel[] = [
    {
        isAllChecked: true,
        name: "Fields from report columns",
        value: "report_columns",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Column",
                value: "column id",
                checked: true,
            },
            {
                name: "Label",
                value: "Label",
                checked: true,
            },
            {
                name: "Type",
                value: "Type",
                checked: true,
            },
            {
                name: "Column type",
                value: "Column type",
                checked: true,
            },
            {
                name: "Definition",
                value: "Definition",
                checked: true,
            },
            {
                name: "Style",
                value: "Style",
                checked: true,
            },
            {
                name: "Hide",
                value: "Hide",
                checked: true,
            },
            {
                name: "Default",
                value: "Default",
                checked: true,
            },
            {
                name: "Sort direction",
                value: "Sort direction",
                checked: true,
            },
            {
                name: "Sort priority",
                value: "Sort priority",
                checked: true,
            },
            {
                name: "Summarization",
                value: "Summarization",
                checked: true,
            },
            {
                name: "Total",
                value: "Total",
                checked: true,
            },
            {
                name: "Steps",
                value: "Steps",
                checked: true,
            },
            
            {
                name: "Display order",
                value: "Display order",
                checked: true,
            },
            {
                name: "Is unique key",
                value: "Is unique key",
                checked: true,
            },
            {
                name: "Precision",
                value: "Precision",
                checked: true,
            },
            {
                name: "Group by",
                value: "Group by",
                checked: true,
            },
            {
                name: "Base type",
                value: "Base type",
                checked: true,
            },            
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report",
        value: "reports",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Favicon url",
                value: "Favicon url",
                checked: true,
            },

            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Custom sql",
                value: "Custom sql",
                checked: true,
            },
            {
                name: "Checksum",
                value: "Checksum",
                checked: true,
            },
            {
                name: "Last generated at",
                value: "Last generated at",
                checked: true,
            },
            {
                name: "Total row count",
                value: "Total row count",
                checked: true,
            },
            {
                name: "Has row matching",
                value: "Has row matching",
                checked: true,
            },
            {
                name: "Time range",
                value: "Time range",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Edited at",
                value: "Edited at",
                checked: true,
            },
            {
                name: "Viewed at",
                value: "Viewed at",
                checked: true,
            },
            {
                name: "Query strategy",
                value: "Query strategy",
                checked: true,
            },
            {
                name: "Always rebuild",
                value: "Always rebuild",
                checked: true,
            },
            {
                name: "Runs in redshift",
                value: "Runs in redshift",
                checked: true,
            },
            {
                name: "Alert on failure",
                value: "Alert on failure",
                checked: true,
            },
            {
                name: "Skip when priority dependent job",
                value: "Skip when priority dependent job",
                checked: true,
            }

                       
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated field",
        value: "fields",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Label",
                value: "Label",
                checked: true,
            },
            {
                name: "Type",
                value: "Type",
                checked: true,
            },
            {
                name: "Column type",
                value: "Column type",
                checked: true,
            },
            {
                name: "Options",
                value: "Options",
                checked: true,
            },
            {
                name: "Scaling factor",
                value: "Scaling factor",
                checked: true,
            },
            {
                name: "Format",
                value: "Format",
                checked: true,
            },
            {
                name: "Is unique key",
                value: "Is unique key",
                checked: true,
            },
            {
                name: "Exclude from load",
                value: "Exclude from load",
                checked: true,
            },
            {
                name: "Custom load sql",
                value: "Custom load sql",
                checked: true,
            }
                     
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report column fields",
        value: "report_column_fields",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Type",
                value: "Type",
                checked: true,
            },
            {
                name: "Value",
                value: "Value",
                checked: true,
            },
            {
                name: "Display order",
                value: "Display order",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report column filters",
        value: "report_column_filters",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Display order",
                value: "Display order",
                checked: true,
            },
           
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated alert metric report columns",
        value: "alert_metric_report_columns",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },           
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated alert metrics",
        value: "alert_metrics",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Chart type",
                value: "Chart type",
                checked: true,
            },
            {
                name: "Title",
                value: "Title",
                checked: true,
            },
            {
                name: "Description",
                value: "Description",
                checked: true,
            },
            {
                name: "Filter",
                value: "Filter",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },  
            {
                name: "Last run at",
                value: "Last run at",
                checked: true,
            },
            {
                name: "Created by user",
                value: "Created by user id",
                checked: true,
            },
            {
                name: "Alert frequency",
                value: "Alert frequency",
                checked: true,
            },
            {
                name: "Alert type",
                value: "Alert type",
                checked: true,
            },
                     
        ]

    }
]