import React, { Component, Fragment } from 'react'
import { DeleteResponseModel } from '../../../../shared/models'
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, ConvertDateTime, HandleNotFoundResponse } from '../../../../shared/helpers';
import { NavLink, Link } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from '../../../../shared/enums';
import { InboundEmailAddressesService } from './services/inbound.email.addresses.service';
import { InboundEmailAddressesLinkResourcesResponseModel, MessagesModel } from './models'
import { InboundEmailAddressesTabs } from './InboundEmailAddressesTabs';


interface IState {
    result?: InboundEmailAddressesLinkResourcesResponseModel;
    deleteResult?: DeleteResponseModel;
    isDoubleleClick?: boolean;
    isSubscriptionsNotifications?:boolean;
}
export  class InboundEmailAddressesDelete extends Component<any, IState> {
    private service: InboundEmailAddressesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new InboundEmailAddressesService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            to_address: "",
            deleted:false,
            messages:[]
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false

    } 
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<InboundEmailAddressesLinkResourcesResponseModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result                   
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.InboundEmailAddresses,this.props)
                
            });
    }

    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<DeleteResponseModel>) => {
                    if (res && res.result) {
                      
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Inbound Email Address Successfully Deleted.");
                                this.props.history.push('/inbound-email-address/list');
                            }
                        });
                    }
                });
        }
    }

    render() {
        return (
            <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-2">
                {this.state.result?.to_address && <h5>Delete Inbound Email Address '{this.state.result?.to_address}'</h5>}
            </div>
            {!this.state.result?.deleted &&<div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <InboundEmailAddressesTabs id={this.props.match.params.id} url="/inbound-email-address/delete/" />
                            
                            <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this inbound email address <strong>“{this.state.result?.to_address}”</strong> ?</div>
                                {(this.state.result?.messages.length==0) &&<p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>}
                               {(this.state.result?.messages.length!=0) &&<p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> You cannot delete this record because it is linked with other resources.</p>}
                                <div className="row"> 
                                    <div className="col">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Inbound Email Address</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/inbound-email-address/detail/" + this.state.result?.id}>{this.state.result?.to_address} </NavLink>
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>

                                <div className="row">
                                {this.state.result?.messages.length!=0 && <div className="col-12">                                       
                                       <div className="card card-info shadow-sm">
                                           <div className="card-header"><h4>Inbound Email Messages </h4> </div>
                                           {this.state.result?.messages && <div className="card-body">
                                           {this.state.result?.messages.map((item: MessagesModel, index) => {
                                               return (
                                                   <span key={index}>
                                                       {item && <NavLink to={"/inbound-email-message/detail/" + item.id} className="badge badge-light m-1"><strong>{index + 1}:</strong> {item.subject}</NavLink>}
                                                   </span>
                                               );
                                           })}
                                       </div>}
                                       </div>
                                   
                           </div>}
                                </div>
                        </div>
                    </div>
                    
                    <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick || this.state.result?.messages.length!=0 } onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/inbound-email-address/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                     
                </div>

            </div>}

        </Fragment>
        )
    }
}
