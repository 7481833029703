import React, { Component, Fragment } from "react";
import { DeleteResponseModel } from "../../../../shared/models";
import { SubscriptionsService } from "./services/subscriptions.service";
import { HttpResponse } from "../../../../core";
import {
  ShowSuccessMessage,
  HandleNotFoundResponse,
} from "../../../../shared/helpers";
import { SubscriptionsTabs } from "./SubscriptionsTabs";
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from "../../../../shared/enums";
import { SubscriptionsLinkResourcesResponseModel } from "./models/subscriptions.link.resources.response.model";
import { Link } from "react-router-dom";

interface IState {
  result?: SubscriptionsLinkResourcesResponseModel;
  deleteResult?: DeleteResponseModel;
  isDoubleleClick?: boolean;
}
export class DeleteSubscriptions extends Component<any, IState> {
  private service: SubscriptionsService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new SubscriptionsService();
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
      notifications_ids: [],
      user_subscription_ids: [],
      users: [],
    },
    deleteResult: { isdeleted: false },
    isDoubleleClick: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.service
      .getLinkedResourcesById(this.props.match.params.id)
      .then((res: HttpResponse<SubscriptionsLinkResourcesResponseModel>) => {
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.Subscriptions,
          this.props
        );
      });
  }

  deleteRecord() {
    if (!this.state.isDoubleleClick) {
      this.setState({ isDoubleleClick: true });
      this.service
        .deleteSubscriptionById(this.props.match.params.id)
        .then((res: HttpResponse<DeleteResponseModel>) => {
          if (res && res.result) {
            this.setState(
              {
                deleteResult: res.result,
              },
              () => {
                if (this.state.deleteResult?.isdeleted) {
                  ShowSuccessMessage("Subscriptions Successfully Deleted.");
                  this.props.history.push("/subscription/list");
                }
              }
            );
          }
        });
    }
  }
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Delete Subscriptions '{this.state.result?.name}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <SubscriptionsTabs
                  id={this.props.match.params.id}
                  url="/subscription/delete/"
                />
                <div className="alert font-weight-bold  alert-light">
                  {" "}
                  <i className="fas fa-exclamation-circle text1"></i> Are you
                  sure you want to delete this Subscriptions{" "}
                  <strong>"{this.state.result?.name}”</strong> ?
                </div>

                <p className="mt-4 text-danger">
                  <i className="fas fa-exclamation-triangle text1"></i> The
                  following related items may be deleted or orphaned:
                </p>
                <div className="row ">
                  <div className="col-4">
                    <div className="card card-primary shadow-sm">
                      <div className="card-header">
                        <h4>Subscription</h4>
                      </div>
                      <div className="card-body">
                        <NavLink
                          className="badge badge-light text-link"
                          to={
                            "/subscription/detail/" +
                            this.props.match.params.id
                          }
                        >
                          {this.state.result?.name}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {this.state.result?.notifications_ids &&
                    this.state.result?.notifications_ids.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <h4>Notifications</h4>
                          </div>
                          <div className="card-body">
                            <ol className="d-flex flex-wrap">
                              {this.state.result?.notifications_ids.map(
                                (item) => (
                                  <li key={item} className="col-2">
                                    Notification #{item}
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row">
                  {this.state.result?.user_subscription_ids &&
                    this.state.result?.user_subscription_ids.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <h4>User Subscriptions</h4>
                          </div>
                          <div className="card-body">
                            <ol className="d-flex flex-wrap">
                              {this.state.result?.user_subscription_ids.map(
                                (item) => (
                                  <li key={item} className="col-2">
                                    UserSubscription #{item}
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row">
                  {this.state.result?.users &&
                    this.state.result?.users.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <h4>Users</h4>
                          </div>
                          <div className="card-body">
                            <ol className="d-flex flex-wrap">
                              {this.state.result?.users.map((item) => (
                                <li key={item.id} className="col-3">
                                  {item.name.trim().length > 0 ? (
                                    <Link to={"/user/detail/" + item.id}>
                                      {item.name}
                                    </Link>
                                  ) : (
                                    <Link to={"/user/detail/" + item.id}>
                                      {item.email}
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="mx-auto">
                <button
                  className="btn btn-lg btn-danger"
                  disabled={this.state.isDoubleleClick}
                  onClick={this.deleteRecord}
                >
                  {" "}
                  {ButtonType.Delete}
                </button>
                <NavLink
                  type="button"
                  className="btn btn-lg btn-default ml-3"
                  to="/subscription/list"
                >
                  {ButtonType.Cancel}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
