import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { TagLocationListResponseModel, TagLocationDetailModel, AddUpdateTagLocationModel, TagLocationDeleteResponseModel, TagLocationDeleteModel } from "../models";



export class TagLocationService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getTagLocationList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<TagLocationListResponseModel>(AppConfig.apiEndpoint + '/v1/tag_locations/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getTagLocationById(id: number) {
    return this.wrapper.get<TagLocationDetailModel>(AppConfig.apiEndpoint + '/v1/tag_locations/' + id);
  }  

  postTagLocation(TagLocation: AddUpdateTagLocationModel) {
    return this.wrapper.post<AddUpdateTagLocationModel>(AppConfig.apiEndpoint + '/v1/tag_locations', TagLocation);
}

editTagLocation(id?: number) {
    return this.wrapper.get<AddUpdateTagLocationModel>(AppConfig.apiEndpoint + '/v1/tag_locations/edit/' + id);
}

updateTagLocation(TagLocation: AddUpdateTagLocationModel) {
    return this.wrapper.put<AddUpdateTagLocationModel>(AppConfig.apiEndpoint + '/v1/tag_locations/' + TagLocation.id, TagLocation);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<TagLocationDeleteModel>(AppConfig.apiEndpoint + '/v1/tag_locations/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<TagLocationDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/tag_locations/' + id);
}

  exportTagLocation(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/tag_locations/export', exportData);
  }


} 