import React, { Component, Fragment } from "react";
import ReactFilestack from "react-filestack";
import { AppConfig, HttpResponse } from "../../../../core";
import { CustomerFaviconService } from "./services/customer.favicons.service";
import {
  FileUploadResponseModel,
  FileUploadModel,
} from "../../../../shared/models";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import { Loader } from "../../../../shared/loaders";
import { ButtonType } from "../../../../shared/enums";
import { Link, Route } from "react-router-dom";
import Profile from "./Profile"


export class CustomerFaviconForm extends Component<any> {
  private service: CustomerFaviconService;
  constructor(props) {
    super(props);
    this.service = new CustomerFaviconService();    
  }

  
  onSuccessFileUpload(response) {
    if (response.filesUploaded[0].handle) {      
      let url = AppConfig.FilePickerUrl + response.filesUploaded[0].handle;
      let filedataata: FileUploadModel = {
        url: url        
      };
      this.postFile(filedataata);
    }
  }

  postFile(filedatata: FileUploadModel) {      
    this.service
      .postCustomerFavicon(filedatata)
      .then((res: HttpResponse<FileUploadResponseModel>) => {
        if (res && res.result) {
         
          if (res.result.id > 0) {
            ShowSuccessMessage("File Uploaded successfully.");
            this.props.history.push("/customer-favicon/detail/"+ res.result.id);            
          }
        }
      });
  }

  render() {
    const storageOpts = {
      storeTo: {
        "location":"S3",
        "container":"staq-assets",
        "path":"app-favicons/",
        "region":"us-east-1"
      }
    }

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-3"></div>
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4>New Customer Favicon</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Upload New Customer Favicon</label>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Profile th={this} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-3 mb-4">
          <Fragment>
                <Link
                  type="button"
                  className="btn btn-lg btn-default"
                  to="/customer-favicon/list"
                >
                  {ButtonType.Cancel}
                </Link>
              </Fragment>  
          </div>
        </div>
      </Fragment>
    );
  }
}
