import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { DeleteResponseModel, LinkResourcesResponseModel } from "../../../../shared/models";
import { AccountDomainListResponseModel, AccountDomainPostModel, EditAccountDomainModel, AccountDomainDetailModel } from "../models";


export class AccountDomainService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getAccountDomainList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<AccountDomainListResponseModel>(AppConfig.apiEndpoint + '/v1/account_domain/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getAccountDomainById(id: number) {
    return this.wrapper.get<AccountDomainDetailModel>(AppConfig.apiEndpoint + '/v1/account_domain/' + id);
  }


  postAccountDomain(accountDomain: AccountDomainPostModel) {
    return this.wrapper.post<AccountDomainPostModel>(AppConfig.apiEndpoint + '/v1/account_domain', accountDomain);
  }

  editAccountDomain(id: number) {
    return this.wrapper.get<EditAccountDomainModel>(AppConfig.apiEndpoint + '/v1/account_domain/edit/' + id);
  }

  updateAccountDomain(accountDomain: AccountDomainPostModel) {
    return this.wrapper.put<AccountDomainPostModel>(AppConfig.apiEndpoint + '/v1/account_domain/' + accountDomain.id, accountDomain);
  }

  getLinkedResourcesById(id: number) {
    return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/account_domain/' + id + '/linked_resources');
  }

  deleteRecordById(id: number) {
    return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/account_domain/' + id);
  }

  exportAccountDomain(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/accountDomains/export', exportData);
  }


}