import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";

interface IProps {
    id: number,
    url: string,
    name?:string
}
export  class ApplicationsTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                    <NavLink to={"/application/application-form/" + this.props.id} className={this.props.url == "/application/application-form/" ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                        aria-controls="edit" aria-selected={this.props.url == "/application/application-form/" ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                </li>               
                <li className="nav-item">
                    <NavLink to={"/application/delete/" + this.props.id} className={this.props.url == "/application/delete/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                        aria-controls="show" aria-selected={this.props.url == "/application/delete/" ? "true" : "false"}><i className="fas fa-trash-alt"></i>&nbsp;Delete</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={"/application/detail/" + this.props.id} className={this.props.url == "/application/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                        aria-controls="show" aria-selected={this.props.url == "/application/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                </li>
            </ul>
            <br />
        </Fragment>
        )
    }
}
