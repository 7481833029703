import React, { Component, Fragment } from 'react'
import { HttpResponse } from '../../../core';
import { Editor } from '../../../shared';
import { ConvertDateTime } from '../../../shared/helpers';
import { Loader } from '../../../shared/loaders';
import { ControlMissionTaskResponseModel } from './models/control.mission.task.response.model'
import { ControlMissionService } from './services/controlo.mission.service';
import { NavLink } from "react-router-dom";

interface IState {
    result?: ControlMissionTaskResponseModel;
    isLoading?: boolean;
}
export default class ControlMissionTask extends Component<any, IState> {
    private service: ControlMissionService;
    constructor(props) {
        super(props);
        this.service = new ControlMissionService();
        this.state = {
            result: {
                created_at: null,
                id: 0,
                updated_at: new Date(),
                control_mission_id: 0,
                exception_count: 0,
                last_message: "",
                name: "",
                options: "",
                sidekiq_job_id: "",
                state: ""
            }
        }
    }
    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getControlMissionTaskById(this.props.match.params.id)
            .then((res: HttpResponse<ControlMissionTaskResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }

            });
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Control Mission Task '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {!this.state.isLoading && <>
                                    <h5 className="mb-3">Basic Info</h5>

                                    <div className="row">
                                        {this.state.result?.name && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Name</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.name}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.state && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>State</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.state}
                                                    {this.state.result.last_message ? `: ${this.state.result.last_message}` : ''}
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Exception Count</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.exception_count}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.result?.control_mission_id && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Control Mission</b>
                                                </div>
                                                <div className="card-body">
                                                    <NavLink to={"/control_mission/" + this.state.result.control_mission_id}>{this.state.result?.control_mission_id}</NavLink>
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.created_at && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.updated_at && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated  At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.updated_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}


                                    </div>
                                    <div className="row">
                                        {this.state.result?.options != "" && <div className="col-md-12">
                                            {this.state.result?.id == this.props.match.params.id && this.state.result?.options != null && <div className="col-md-6 mt-4    ">
                                                <h5>Options</h5>
                                                <Editor StringData={this.state.result?.options} FormateType="yaml" />
                                            </div>}
                                        </div>}
                                    </div>

                                </>}
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

