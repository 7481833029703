import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ScopeSchemaChangesListModel, ScopeSchemaChangesListResponseModel, ScopeSchemaChangesDetailModel } from "../models";



export class ScopeSchemaChangesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getScopeSchemaChangesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ScopeSchemaChangesListResponseModel>(AppConfig.apiEndpoint + '/v1/scope_schema_changes/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getScopeSchemaChangesById(id: number) {
    return this.wrapper.get<ScopeSchemaChangesDetailModel>(AppConfig.apiEndpoint + '/v1/scope_schema_changes/' + id);
  }


  exportScopeSchemaChanges(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/scope_schema_changes/export', exportData);
  }


}