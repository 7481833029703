import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ConnectionsSemaphoresListResponseModel } from "../models/connections.semaphores.list.response.model";
import { ConnectionsSemaphoresDetailModel } from "../models/connections.semaphores.detail.model";
import { EditConnectionSemaphoresModel } from "../models/edit.connection.semaphores.model";
import { ConnectionSemaphoresPostModel } from "../models/connection.semaphores.post.model";
import { LinkResourcesResponseModel, DeleteResponseModel } from "../../../../../shared/models";

export class ConnectionsSemaphoresService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getConnectionSemaphoresList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ConnectionsSemaphoresListResponseModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }

    getConnectionSemaphoresById(id: number) {
        return this.wrapper.get<ConnectionsSemaphoresDetailModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores/' + id);
    }
    editConnectionSemaphores(id: number) {
        return this.wrapper.get<EditConnectionSemaphoresModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores/edit/' + id);
    }
    postConnectionSemaphores(Connection: ConnectionSemaphoresPostModel) {
        return this.wrapper.post<ConnectionSemaphoresPostModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores', Connection);
      }

    updateConnectionSemaphores(ConnectionSemaphores: ConnectionSemaphoresPostModel) {
        return this.wrapper.put<ConnectionSemaphoresPostModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores/' + ConnectionSemaphores.id, ConnectionSemaphores);
    }
    getLinkedResourcesById(id: number) {
        return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores/' + id + '/linked_resources');
    }

    deleteRecordById(id: number) {
        return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/connection_semaphores/' + id);
    } 

    exportConnectionSemaphores(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/connectionSemaphores/export', exportData);
    }

}