import React, { Component, Fragment } from 'react'
import { EmailLayoutsService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { EmailLayoutsDetailModel } from './models';
import { EmailLayoutsTabs } from './EmailLayoutsTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: EmailLayoutsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class EmailLayoutsDetail extends Component<any, IState> {
    private service: EmailLayoutsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new EmailLayoutsService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,
            name: "",
            text: "",
            html: ""
        },
        isLoading: false,
    }

    

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getEmailLayoutsById(this.props.match.params.id)
            .then((res: HttpResponse<EmailLayoutsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.EmailLayouts,this.props)
            });
    }
    
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Email Layout &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <EmailLayoutsTabs id={this.props.match.params.id} url="/email-layout/detail/" />      
                        {!this.state.isLoading &&  <>                       
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.name && <div className="col-12">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.name} 
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.html && <div className="col-12">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>HTML</b>
                                            </div>
                                            <div className="card-body">
                                            <pre> {this.state.result?.html}</pre>
                                        </div></div>
                                    </div>}                             
                             
                                   
                                    {this.state.result?.text && <div className="col-12">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Text</b>
                                            </div>
                                            <div className="card-body">{this.state.result?.text}</div>
                                        </div>
                                    </div>}
                                 
                            </div>                          
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 