import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ReportColumnsListResponseModel } from "../models";
import { ReportColumnDetailModel } from "../models/report.column.detail.model";


export class ReportColumnsService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getReportColumnsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ReportColumnsListResponseModel>(AppConfig.apiEndpoint + '/v1/report_column/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getReportColumnsById(id: number) {
        return this.wrapper.get<ReportColumnDetailModel>(AppConfig.apiEndpoint + '/v1/report_column/' + id);
    }

    
    exportReportColumns(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_column/export', exportData);
    }

}