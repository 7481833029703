import React, { Component, Fragment } from 'react'
import { ScopeFieldSchemaChangesService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../../shared/helpers';
import { NotFoundResponseArea, SchemaChangeType } from '../../../../../shared/enums';
import { HttpResponse } from '../../../../../core';
import { NavLink } from "react-router-dom";

import { ScopeFieldSchemaChangesDetailModel} from './models';
import { DropdownItemModel } from '../../../../../shared/models';
import { Loader } from '../../../../../shared/loaders';

interface IState {
    result?: ScopeFieldSchemaChangesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ScopeFieldSchemaChangesDetail extends Component<any, IState> {
    private service: ScopeFieldSchemaChangesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ScopeFieldSchemaChangesService();
    }

    initialState: Partial<IState> = {
        result:{
            id: 0,
            scope_schema_change_id: 0,
            change_type: "",
            field_id: 0,
            field: {
              text: "",
              value: ""
            },
            new_name: "",
            new_type: "",
            old_name: "",
            old_type: "",
            created_at: new Date(),
            updated_at: new Date(),
            data_engine_jobs: [],
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getScopeSchemaChangesById(this.props.match.params.id)
            .then((res: HttpResponse<ScopeFieldSchemaChangesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    }, () => {
                       
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ScopeSchemaChanges,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Scope Field Schema Change  &nbsp;'ScopeFieldSchemaChange  #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">            
                        {!this.state.isLoading &&  <>           
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.id && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>ID</b>
                                            </div>
                                            <div className="card-body"> {this.state.result?.id} </div>
                                        </div>
                                </div>}
                                    
                                {this.state.result?.scope_schema_change_id &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Scope Schema Change</b>
                                        </div>
                                        <div className="card-body"><NavLink to={"/scope-schema-change/detail/" + this.state.result?.scope_schema_change_id}> ScopeSchemaChange #{this.state.result?.scope_schema_change_id}</NavLink></div>
                                    </div>
                                </div>}

                                {this.state.result?.field &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Field</b>
                                        </div>
                                        <div className="card-body"><NavLink to={"/field/detail/" + this.state.result?.field.value+"/"+this.state.result?.new_name+"/"+ this.state.result?.scope_schema_change_id}> { this.state.result?.field.text} </NavLink> </div>
                                    </div>
                                </div>}
                                {this.state.result?.change_type &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Change Type</b>
                                        </div>
                                        <div className="card-body">{ this.state.result?.change_type}</div>
                                    </div>
                                </div>}

                                {this.state.result?.old_name &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Old Name</b>
                                        </div>
                                        <div className="card-body">{this.state.result?.old_name}</div>
                                    </div>
                                </div>}
                                {this.state.result?.old_type &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Old Type</b>
                                        </div>
                                        <div className="card-body">{this.state.result?.old_type}</div>
                                    </div>
                                </div>}
                                {this.state.result?.new_name &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>New Name</b>
                                        </div>
                                        <div className="card-body">{this.state.result?.new_name}</div>
                                    </div>
                                </div>}
                                {this.state.result?.new_type &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>New Type</b>
                                        </div>
                                        <div className="card-body">{this.state.result?.new_type}</div>
                                    </div>
                                </div>}
                                {this.state.result?.created_at &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Created at</b>
                                        </div>
                                        <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                    </div>
                                </div>}
                                {this.state.result?.updated_at &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Updated at</b>
                                        </div>
                                        <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                    </div>
                                </div>}
                               
                               
                            </div>
                        

                                    {this.state.result?.data_engine_jobs?.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Data Engine Jobs</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>                                                                                                                   
                                                            <th scope="col">Data source	</th>
                                                            <th scope="col">Scope</th>                                                            
                                                            <th scope="col">State</th>
                                                            <th scope="col">Created at</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.data_engine_jobs?.map((item, index) => {
                                                            return (
                                                                <tr key={index}>                                                                                                                                   
                                                                    <td><NavLink to={"/data-engine-job/detail/" + item.id}>{item.id}</NavLink></td>
                                                                    <td><NavLink to={"/custom-connection/detail/" + item.data_source.id}>{item.data_source.name}</NavLink></td>
                                                                    <td><NavLink to={"/scope/detail/" + item.scope.value}>{item.scope.text}</NavLink></td>
                                                                    <td> {item.state}</td>
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                                                                                                     

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                    {/* <div className="row">
                                    {this.state.result?.dependent_reports && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Dependents Reports</div>
                                                {this.state.result?.dependent_reports && <div className="card-body">
                                                    {this.state.result?.dependent_reports.map((item: DropdownItemModel, index) => {
                                                        return (
                                                            <span key={item.value}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}
                                    </div> */}

</>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}  
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 