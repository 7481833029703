import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ReportRunSchedulesRoute } from '../../routing'

export class ReportRunSchedulesPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <ReportRunSchedulesRoute />
            </Fragment>
        )
    }
}
