import React, { Component, Fragment } from "react";
import { PlatformDetailModel } from "./models";
import { PlatformService } from "./services/platform.service";
import { AppConfig, HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ConvertDateTime,
} from "../../../shared/helpers";
import { NotFoundResponseArea } from "../../../shared/enums";
import { PlatformTabs } from "./PlatformTabs";
import { NavLink, Link } from "react-router-dom";
import { DropdownItemModel } from "../../../shared/models";
import { Loader } from '../../../shared/loaders';

interface IState {
  result?: PlatformDetailModel;
  isLoading?: boolean;
}

export class PlatformDetail extends Component<any, IState> {
  private service: PlatformService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new PlatformService();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      title: "",
      extractor: {
        id: 0,
        type: "",
        last_pushed_by: "",
      },
      is_public: false,
      info: "",
      categories: [],
      connections: [],
      data_source_id: 0,
      dependent_reports: [],
      favicon_url: "",
      image_url: "",
    },
    isLoading: false,
  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }

  //function to load the initial view data coming from the API.
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
loadData() {
    this.setLoading(true);
    this.service
      .getPlatformById(this.props.match.params.id)
      .then((res: HttpResponse<PlatformDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Platforms, this.props);
      });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Platform '{this.state.result?.title}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <PlatformTabs
                  id={this.props.match.params.id}
                  name={this.state.result?.title}
                  url="/platform/detail/"
                />
                 {!this.state.isLoading &&  <>  
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.title && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Name</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.title}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.extractor && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Extractor</b>
                        </div>
                        <div className="card-body">
                          <Link to={"/extractor/detail/" + this.state.result?.extractor.id}>{this.state.result?.extractor.type}</Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.extractor && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Last pushed by</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.extractor.last_pushed_by}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.is_public && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Is public</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.info && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Info</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.info}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.categories && this.state.result.categories.length > 0 && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Categories</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.categories.map((category) => (
                            <NavLink className="badge badge-light m-1" to={"/category/detail/" + category.value}><div key={category.value}>{category.text}</div></NavLink>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {this.state.result?.connections &&
                  this.state.result.connections.length > 0 && (
                    <div className="row">
                      <div className="col-md-12 ">
                        <h5>Connections</h5>
                      </div>

                      <div className="col-md-12 m-t-10">
                        <div className="table-responsive">
                          <table className="table table-md table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Account</th>
                                <th scope="col">Created at</th>
                                <th scope="col">State</th>
                                <th scope="col">State message</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            {this.state.result?.connections && (
                              <tbody>
                                {this.state.result?.connections.map(
                                  (item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td><NavLink
                                      className="badge badge-light m-1"
                                      to={"/connection/detail/" + item.id}>{item.name}</NavLink></td>
                                        <td><NavLink
                                      className="badge badge-light m-1"
                                      to={"/account/detail/" + item.account.value}>{item.account.text}</NavLink></td>
                                        <td>
                                          {" "}
                                          {ConvertDateTime(
                                            item.created_at,
                                            "LLL"
                                          )}
                                        </td>
                                        <td>{item.state}</td>
                                        <td>{item.state_message}</td>
                                        <td>
                                            <a href={AppConfig.AdminStagingUrl+item?.id}><i className="fa fa-list-alt"></i></a>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row">
                  {this.state.result?.data_source_id && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Data Source</b>
                        </div>
                        <div className="card-body">
                         Data Source {this.state.result?.data_source_id}{" "}
                          (Platform source for Platform {this.state.result?.id})
                          
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.dependent_reports &&
                    this.state.result.dependent_reports.length > 0 && (
                      <div className="col">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header font-weight-bold">
                            Dependent Reports
                          </div>
                          <div className="card-body">
                            {this.state.result?.dependent_reports.map(
                              (item: DropdownItemModel, i, arr) => {
                                return (
                                  <span key={item.value}>
                                    <NavLink
                                      className="badge badge-light m-1"
                                      to={"/report/detail/" + item.value}
                                    >
                                      <strong>{i + 1}:</strong> {item.text}
                                      {i !== arr.length - 1 ? ", " : ""}
                                    </NavLink>
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.result?.favicon_url && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Favicon</b>
                        </div>
                        <div className="card-body">
                          <img
                            src={this.state.result?.favicon_url}
                            width="40"
                            className="img-thumbnail"
                            alt="favicon_url"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.image_url && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Image</b>
                        </div>
                        <div className="card-body">
                          <img
                            src={this.state.result?.image_url}
                            width="100"
                            className="img-thumbnail"
                            alt="image_url"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
