import React, { Component, Fragment } from 'react'
import { InboundEmailAttachment, InboundEmailMessagesDetailsModel } from './models'
import { InboundEmailMessagesService } from './services/inbound.email.messages.service';
import { HttpResponse } from '../../../../../core';
import { HandleNotFoundResponse, ConvertDateTime, formatBytes, ShowSuccessMessage } from '../../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../../shared/enums';
import { Editor } from '../../../../../shared';
import { Loader } from '../../../../../shared/loaders';
import { FileDownloadService } from '../../../../../shared/services/file-download.service';
import { Link, NavLink } from "react-router-dom";
interface IState {
    result?: InboundEmailMessagesDetailsModel;
    isLoading?: boolean;
}

export class InboundEmailMessagesDetails extends Component<any, IState> {
    private service: InboundEmailMessagesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new InboundEmailMessagesService();
    }

    // Defines the initial state for the values coming from API.
    initialState: Partial<IState> = {
        result: {
            id: 0,
            from_address: "",
            subject: "",
            recipients: [],
            html_body: "",
            to_address: "",
            headers: { a: "" },
            plain_body: "",
            created_at: new Date(),
            updated_at: new Date(),
            attachments: [],
            inbound_email_address_id:0
        },
        isLoading: false
    }

    //Get the detail from the API whenever the page loads.
    componentDidMount() {
        this.loadData();
    }


    //function to load the initial view data coming from the API.
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getInboudEmailMessagesById(this.props.match.params.id)
            .then((res: HttpResponse<InboundEmailMessagesDetailsModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.InboundEmailMessages, this.props)
            });
    }

    download(url: any, filename: any) {
        new FileDownloadService().downloadFileByStream(url, filename)
    }

    reProcess = (id) => {
        this.service.reProcessById(id)
            .then((res: HttpResponse<any>) => {
                if (res && res.result) {
                    ShowSuccessMessage("Delivered inbound email again successfully");
                }
            })
    }



    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Inbound Email Message '{this.state.result?.subject}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {!this.state.isLoading && <>
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        {this.state.result?.id &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>ID</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.state.result?.id}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.subject &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Subject</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.state.result?.subject}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.to_address &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>To address</b>
                                                    </div>
                                                    <div className="card-body">
                                                        <NavLink to={"/inbound-email-address/detail/" + this.state.result?.inbound_email_address_id}> {this.state.result?.to_address}</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.from_address &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"> 
                                                        <b>From Address</b>
                                                    </div>
                                                    <div className="card-body">
                                                        <NavLink to={"/inbound-email-address/detail/" + this.state.result?.inbound_email_address_id}> {this.state.result?.from_address}</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.headers &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Headers</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.state.result?.headers.a}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.created_at &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Created at</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.updated_at &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Updated at</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {ConvertDateTime(this.state.result?.updated_at, 'LLL')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.recipients.length != 0 &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Recipients</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.state.result?.recipients.map((item, index) => {
                                                            return (
                                                                <span key={index} className="badge badge-light m-1">{item}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.state.result?.html_body &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>HTML Body</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.state.result?.html_body}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.result?.plain_body &&
                                            <div className="col-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Plain Body</b>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.state.result?.plain_body}
                                                    </div>
                                                </div>
                                            </div>
                                        }



                                    </div>
                                </>}

                                <div className="row">
                                    <div className="col-md-12  mt-3">
                                        <h6>Attachments</h6>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-md table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Filename</th>
                                                        <th>View In Browser</th>
                                                        <th>Download</th>
                                                        <th>Content Type</th>
                                                        <th>Size</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.result?.attachments?.map(
                                                        (item: InboundEmailAttachment) => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td><a href="#"> {item.file_name}</a></td>
                                                                    <td><a href={item.url} target="_blank">View</a></td>
                                                                    <td><button onClick={() => this.download(item.url, item.file_name)} className="btn btn-sm  btn-info">Download</button></td>
                                                                    <td>{item.content_type}</td>
                                                                    <td>{item.size != null ? formatBytes(item.size) : "0 Bytes"}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {this.state.result?.id != 0 && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Reprocess</b>
                                            </div>
                                            <div className="card-body">
                                                <button onClick={() => this.reProcess(this.state.result?.id)} className="btn btn-sm  btn-info">
                                                    Deliver Again</button>

                                            </div>
                                        </div>
                                    </div>}
                                </div>

                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
