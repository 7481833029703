import React, { Component, Fragment } from 'react'

import { PlanCategoriesService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { PlanCategoriesTabs } from './PlanCategoriesTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { AddUpdatePlanCategoriesModel } from './models';
import { DropdownItemModel, FlaggleItems } from '../../../../shared/models';
import { CommonService } from '../../../../shared/services/common.service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IState {
    PlanCategories?: AddUpdatePlanCategoriesModel;
    // editPlanCategories?: AddUpdatePlanCategoriesModel;
    id?: number,
    label?: string,
    order?: number,
    free?: string,
    basic?: string,
    professional?: string,
    standard?: string,
    enterprise?: string,
    usage?: boolean,
    visible?: boolean,
    name?:string,
    descriptions?: string,
    nameError?: string;
    flaggableError?:string;
    flaggableTypeError?: string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    flaggableObjectType?:FlaggleItems;

}
export class PlanCategoriesForm extends Component<any, IState> {
    private service: PlanCategoriesService;
     commonService: CommonService;
    constructor(props) {
        super(props)
        this.service = new PlanCategoriesService();
        this.commonService = new CommonService();
        this.state = this.initialState;
      this.getAllFlaggableObjetcs()
    }

    initialState: Partial<IState> = {
        // PlanCategories: {
        //     name: "",
        //     flaggable_type: "",
        //     flaggable_id: 0,
        //     model_classes:[],
        //     id: 0
        // },
        id: 0,
        label: "",
        order: 0,
        free: "",
        basic: "",
        professional: "",
        standard: "",
        enterprise: "",
        usage: false,
        visible: true,
        name:"",
        descriptions: "",
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        nameError: "",
        flaggableTypeError:"",
        flaggableError:"",
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
              
            });
        }
    }
   

 

    loadData() {
        this.service.editPlanCategories(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdatePlanCategoriesModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        label: res.result?.label,
                        order: res.result?.order,
                        free: res.result?.free,
                        basic: res.result?.basic,
                        professional: res.result?.professional,
                        standard: res.result?.standard,
                        enterprise: res.result?.enterprise,
                        usage: res.result?.usage,
                        visible: res.result?.visible,
                        name:res.result?.name,
                        descriptions: res.result?.descriptions
                    }, () => {
                        
                    });
                   
                }
                
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.PlanCategories, this.props)
            });
            
    }


    updateData(data: AddUpdatePlanCategoriesModel) {
        this.setSavingFlag(true);
        this.service.updatePlanCategories(data)
            .then((res: HttpResponse<AddUpdatePlanCategoriesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        // PlanCategories: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Plan Category successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/plan-category/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/plan-category/plan-category-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/plan-category/plan-category-form/0');
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdatePlanCategoriesModel) {
        this.setSavingFlag(true);
        this.service.postPlanCategories(data)
            .then((res: HttpResponse<AddUpdatePlanCategoriesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        // PlanCategories: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Plan Category successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/plan-category/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/plan-category/plan-category-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/plan-category/plan-category-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
           
            });;
    }


    handleChange = (event: any) => {
    //     if (event.target.name == "flaggable_type") {
    //         this.getAllConnection(event.target.value);
    //   } 
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }
    getAllFlaggableObjetcs() {
        this.commonService.getflaggableObjectsType()
            .then((res: HttpResponse<FlaggleItems>) => {
                if (res) {
                    this.setState({
                        flaggableObjectType: res.result
                    });
                    
                }
               
                
            });
        
     
    }
  

    validate = () => {
        let nameError = "";
       
        let flaggableTypeError = "";

        if (!this.state.name) {
            nameError = "Name can't be blank";
        }
       
        if (nameError || flaggableTypeError) {
            this.setState({ nameError: nameError,  flaggableTypeError:flaggableTypeError })
            return false;
        }
        else {
            nameError = "";
            flaggableTypeError = "";
            this.setState({ nameError: nameError, flaggableTypeError:flaggableTypeError })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdatePlanCategoriesModel = {
            id: this.state.id,
            label: this.state.label,
            order: this.state.order,
            free: this.state.free,
            basic: this.state.basic,
            professional: this.state.professional,
            standard: this.state.standard,
            enterprise: this.state.enterprise,
            usage: this.state.usage,
            visible: this.state.visible,
            name:this.state.name,
            descriptions: this.state.descriptions
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else {
                this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
                return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Plan Category '{this.state.name}'</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <PlanCategoriesTabs id={this.props.match.params.id} url="/plan-category/plan-category-form/" />}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Name<span className=" text-danger">*</span> 
                                                    <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                   Must be all lowercase with no spaces.
                                                    </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="text" maxLength={255} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Label </label>
                                                    <input type="text" maxLength={255} name="label" value={this.state.label} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Description </label>
                                                    <input type="text" maxLength={255} name="descriptions" value={this.state.descriptions} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Order  <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                     Determines the order to display the categories in staqweb. Zero being the top. </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="number" maxLength={255} name="order" value={this.state.order} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                       
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Free
                                                    <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                     Optional. This value will be displayed to users in staqweb. If 'true', a check icon will be used.  </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="text" maxLength={255} name="free" value={this.state.free} onChange={this.handleChange} className='form-control' />
                                                   
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Basic   <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                     Optional. This value will be displayed to users in staqweb. If 'true', a check icon will be used.  </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="text" maxLength={255} name="basic" value={this.state.basic} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Professional   <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                     Optional. This value will be displayed to users in staqweb. If 'true', a check icon will be used.  </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger>  </label>
                                                    <input type="text" maxLength={255} name="professional" value={this.state.professional} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Standard   <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                     Optional. This value will be displayed to users in staqweb. If 'true', a check icon will be used.  </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger>  </label>
                                                    <input type="text" maxLength={255} name="standard" value={this.state.standard} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                       
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Enterprise   <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                     Optional. This value will be displayed to users in staqweb. If 'true', a check icon will be used.  </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="text" maxLength={255} name="enterprise" value={this.state.enterprise} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            </div>                                        
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group p-3 shadow-sm">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                    type="checkbox"
                                                    name="usage"
                                                    checked={this.state.usage}
                                                    onChange={this.handleChange}
                                                    className="custom-control-input"
                                                    id="usage"
                                                    />
                                                    <label className="custom-control-label"  htmlFor="usage">Usage</label>
                                                </div>
                                                    <small>
                                                    Usage categories track the number of resources an account has used and allow limits to be applied to block additional use and force the account admin to purchase more. When checked and marked visible, the usage stats and purchase more option will be shown to users in staqweb on the account management plans and usage page.
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                    type="checkbox"
                                                    name="visible"
                                                    checked={this.state.visible}
                                                    onChange={this.handleChange}
                                                    className="custom-control-input"
                                                    id="visible"
                                                    />
                                                    <label className="custom-control-label"  htmlFor="visible">Visible</label>
                                                </div>
                                                    <small>
                                                    When checked, the category will be shown to users in staqweb on the account management plans and usage page.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/plan-category/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
