import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";


interface IProps {
    id: number,
    url: string
}
export  class ConnectionSemaphoresTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
            {/* <TabScrolling /> */}
            {/* <div className="tabscroll" > */}
                <ul className="nav nav-tabs" id="myTab largetab" role="tablist" >
                 <li className="nav-item">
                        <NavLink to={"/connection-semaphore/connection-semaphore-form/" + this.props.id} className={this.props.url == "/connection-semaphore/connection-semaphore-form/" + this.props.id ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                            aria-controls="edit" aria-selected={this.props.url == "/connection-semaphore/connection-semaphore-form/" + this.props.id ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                    </li>                                    
                    <li className="nav-item">
                        <NavLink to={"/connection-semaphore/delete/" + this.props.id} className={this.props.url == "/connection-semaphore/delete/" ? "nav-link active" : "nav-link"} id="storage-tab" data-toggle="tab" href="#storage" role="tab"
                            aria-controls="storage" aria-selected={this.props.url == "/connection-semaphore/delete/" ? "true" : "false"}><i className="far fa-trash-alt"></i>&nbsp;Delete</NavLink>
                    </li>   
                    <li className="nav-item">
                        <NavLink to={"/connection-semaphore/detail/" + this.props.id} className={this.props.url == "/connection-semaphore/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                            aria-controls="show" aria-selected={this.props.url == "/connection-semaphore/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                    </li>                   
                </ul>
            <br />
        </Fragment>
        )
    }
}
