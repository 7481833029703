import React, { Component, Fragment, createRef } from 'react'

import { EmailTemplatesService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { EmailTemplatesTabs } from './EmailTemplatesTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { AddUpdateEmailTemplatesModel, EmialLayoutModel } from './models';
import { Editor } from '../../../../shared';
import { CommonService } from '../../../../shared/services/common.service';
import { DropdownItemModel } from '../../../../shared/models';
import { Modal } from 'react-bootstrap';
import {EmailLayoutsFormPopup} from '../../external-roles-and-communication/email-layouts'

interface IState {
    editEmailTemplates?: AddUpdateEmailTemplatesModel,
    id?: number;   
    name?:string;
    email_layout_id?:number;
    emailLayoutName?:string;
    email_layout?:EmialLayoutModel;
    emailLayoutList?: DropdownItemModel[],
    subject?:string;
    html?:string;
    text?:string;

    nameError?: string;
    emailLayoutError?:string;
    subjectError?:string;
    htmlError?:string;
    textError?:string;

    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;

    isChildUpdate?: boolean;
  showEmailLayoutForm?: boolean;
  isNew?: boolean;
}
export class EmailTemplatesForm extends Component<any, IState> {
    private service: EmailTemplatesService;
    private commonService: CommonService;
    private nameRef: any;
    private subjectRef: any;
    private NetworkCall: any;

    constructor(props) {
        super(props)
        this.service = new EmailTemplatesService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.nameRef = createRef();
        this.subjectRef = createRef();
        this.editorhandleChangeHtml = this.editorhandleChangeHtml.bind(this)
        this.editorhandleChangeText = this.editorhandleChangeText.bind(this)
        this.updateEmailLayout = this.updateEmailLayout.bind(this);
    }

    initialState: Partial<IState> = {
        editEmailTemplates: {           
            id: 0,            
            name:"",
            email_layout:{
                name:"",
                id:0
            },
            email_layout_id:0,
            html:"",
            subject:"",
            text:""
        },        
        id: 0,   
        name:"",   
        email_layout_id:0,
        emailLayoutName:"",
            html:"",
            subject:"",
            text:"",     
        isShowtext: "New",
        isSave: "",
        isSaving: false,

        nameError:"",
        emailLayoutError:"",
        htmlError:"",
        subjectError:"",
        textError:"",

        email_layout:{
            name:"",
            id:0
          },
          isChildUpdate: false,
    showEmailLayoutForm:false,
    isNew: false
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {       
        if (event.path[0].id !== 'emailLayoutName' && event.path[0].id !== 'emailLayout' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                emailLayoutList: []
            }, () => {

            })

        }
    }


    updateEmailLayout(isChildUpdate, name, id) {
        this.setState({
          isChildUpdate: isChildUpdate,
          emailLayoutName: name,
          email_layout_id: id,
        });
      }
    
      handleCloseEmailLayout = () => {
        this.setState({ showEmailLayoutForm: false }, () => {});
      };
    
      handleShowEmailLayout = () => {
        this.setState({ showEmailLayoutForm: true, isNew: true }, () => {});
      };
    
      handleShowEditEmailLayout = () => {
        this.setState({ showEmailLayoutForm: true, isNew: false }, () => {});
      };

    loadData() {
        this.service.editEmailTemplates(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdateEmailTemplatesModel>) => {
                if (res && res.result) {
                    this.setState({                       
                        id:res.result?.id,
                        name:res.result?.name,  
                        email_layout:res.result?.email_layout,                      
                        email_layout_id:res.result?.email_layout==null?0:res.result?.email_layout.id,
                        emailLayoutName:res.result?.email_layout==null?"":res.result?.email_layout.name,
                        subject:res.result?.subject,
                        html:res.result?.html,
                        text:res.result?.text                        
                    }, () => {
                        
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.EmailTemplates,this.props) 
            });
    }


    


     //*************** * Email Layout Start************************* //

     onEmailLayoutChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.NetworkCall) {
                clearTimeout(this.NetworkCall);
            }
            this.NetworkCall = setTimeout(() => {
                this.getEmailLayouts(value)
            }, 600)
        }
        this.setState({
            emailLayoutName: value,
            emailLayoutError: ""
        })
    }

    selectedEmailLayout(item) {
        this.setState({
            email_layout_id: item.value,
            emailLayoutName: item.text,
            emailLayoutList: []
        })
    }

    renderEmailLayout = () => {
        if (this.state.emailLayoutList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.emailLayoutList?.map((item, index) => (<li id="emailLayout" key={index} onClick={() => this.selectedEmailLayout(item)}>{item.text}</li>))
                }
            </ul>
        );
    }

    private getEmailLayouts(text: string) {
        this.commonService.getEmailLayoutsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        emailLayoutList: res.result
                    });
                }
            });

    }
    

    //*************** * Email Layout End************************* //


    updateData(data: AddUpdateEmailTemplatesModel) {
        this.setSavingFlag(true);
        this.service.updateEmailTemplates(data)
            .then((res: HttpResponse<AddUpdateEmailTemplatesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Email Templates successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/email-template/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/email-template/email-template-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/email-template/email-template-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateEmailTemplatesModel) {
        this.setSavingFlag(true);
        this.service.postEmailTemplates(data)
            .then((res: HttpResponse<AddUpdateEmailTemplatesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({                        
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Email Templates successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/email-template/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/email-templates/email-templates-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/email-templates/email-templates-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }


    handleChange = (event: any) => {
        if (event.target.name == "emailLayoutName") {
            this.onEmailLayoutChange(event);
        }

        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
        if (this.nameRef.current.value) {
            this.setState({ nameError: "" });
          }

          if (this.subjectRef.current.value) {
            this.setState({ subjectError: "" });
          }         
    }

    validate = () => {
        let nameError = "";   
        let emailLayoutError = "";
        let subjectError = "";
        let htmlError = "";
        let textError = "";
        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }

        if (!this.state.emailLayoutName) {
            emailLayoutError = "Email layout can't be blank";
        }

        if (!this.subjectRef.current.value) {
            subjectError = "Subject can't be blank";
        }

        if (!this.state.html) {
            htmlError = "Html can't be blank";
        }
        if (!this.state.text) {
            textError = "Text can't be blank";
        }

        if (nameError || emailLayoutError || htmlError || textError || subjectError) {
            this.setState({ nameError: nameError, emailLayoutError:emailLayoutError, htmlError:htmlError, textError:textError, subjectError:subjectError })
            return false;
        }
        if (!nameError || !emailLayoutError || !htmlError || !textError) {
            nameError = "";
            emailLayoutError="";
            htmlError="";
            textError="";
            subjectError="";
            this.setState({ nameError: nameError, emailLayoutError:emailLayoutError, htmlError:htmlError, textError:textError, subjectError:subjectError })
            return true;
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        if(!this.state.isChildUpdate){
        const isValid = this.validate();
        let data: AddUpdateEmailTemplatesModel = {                        
            id: this.state.id,
            name:this.state.name,
            email_layout_id:this.state.email_layout_id==0?null:this.state.email_layout_id,
            html:this.state.html,
            subject:this.state.subject,
             text:this.state.text            
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else
            {
        this.updateData(data);
            }
        }
    }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value, isChildUpdate: false });
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    editorhandleChangeHtml(data) {
        this.setState({
         html:data
        })         
        if (data) {
            this.setState({ htmlError: "" });
          }
    }

    editorhandleChangeText(data) {
        this.setState({
         text:data
        }) 
        if (data) {
            this.setState({ textError: "" });
          }
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Email Template '{this.state.name}'</h5>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showEmailLayoutForm} onHide={this.handleCloseEmailLayout}>
                            <EmailLayoutsFormPopup  updateEmailLayout={this.updateEmailLayout}  handleClose={this.handleCloseEmailLayout} isNew={this.state.isNew} id={this.state?.email_layout_id}/>
                        </Modal>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                {this.props.match.params.id != 0 && <EmailTemplatesTabs id={this.props.match.params.id} url="/email-template/EmailTemplates-form/" />}
                                    <div className="row mt-4">
                                        <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Name<span className="text-danger">*</span></label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" maxLength={255}  ref={this.nameRef} name="name" value={this.state.name} onChange={this.handleChange} className= {!this.state.nameError ? 'form-control' : 'form-control  is-invalid'}/>
                                                <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>

                                        <div className="col-md-4 ">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <label>
                          Email Layout
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              autoComplete="off"
                              id="emailLayoutName"
                              name="emailLayoutName"
                              type="text"
                              onChange={this.handleChange}
                              value={
                                this.state.emailLayoutName
                              }
                              className={!this.state.emailLayoutError ? 'form-control' : 'form-control  is-invalid'}
                            />
                           {this.renderEmailLayout()}
                           
                           <div className="input-group-append">
                            <button type="button" className="btn btn-success" onClick={this.handleShowEmailLayout}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                            <button type="button"  className={`btn btn-primary `} disabled={this.state.email_layout_id==0}  onClick={this.handleShowEditEmailLayout}><i className="far fa-edit"></i></button>
                            </div>
                                                        <div className="invalid-feedback">
                                                            {this.state.emailLayoutError}
                                                        </div>
                                                       
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Subject<span className="text-danger">*</span></label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" ref={this.subjectRef} maxLength={255} name="subject" value={this.state.subject} onChange={this.handleChange} className= {!this.state.subjectError ? 'form-control' : 'form-control  is-invalid'}/>
                                                <div className="invalid-feedback">
                                                        {this.state.subjectError}
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>

                                    </div>

                                    <div className="row  mt-5">
                                            <div className="col-xl-6 col-lg-12 col-md-12 mb-5  shadow-sm p-3 mb-4 ">
                                            <label>Html<span className="text-danger">*</span></label>                                               
                                                <Editor  StringData={this.state.html} FormateType="html" onChange={this.editorhandleChangeHtml} />
                                                {this.state.htmlError != "" && <div style={{ color: 'red' }}>
                                                    {this.state.htmlError}
                                                </div>}
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-12 mb-5  shadow-sm p-3 mb-4 ">
                                            <label>Text<span className="text-danger">*</span></label>                                               
                                                <Editor StringData={this.state.text} FormateType="text" onChange={this.editorhandleChangeText} />
                                                {this.state.textError != "" && <div style={{ color: 'red' }}>
                                                    {this.state.textError}
                                                </div>}
                                            </div>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="text-center mt-3 mb-4">
                        <Link type="button" className="btn btn-lg btn-default" to="/email-template/list">{ButtonType.Cancel}</Link>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />      
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />                      
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }
}
