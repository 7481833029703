import React, { Component, Fragment } from "react";
import { InvocationSchedulesService } from "./services/invocation-schedule.service";
import { EditInvocationSchedulesModel } from "./models/edit.invocationschedule.model";
import { HttpResponse } from "../../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../../shared/enums";
import { PostInvocationSchedulesModel } from "./models";
import { InvocationSchedulesTabs } from "./InvocationSchedulesTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../shared/loaders";
import { DropdownItemModel } from "../../../../shared/models";
import { CommonService } from "../../../../shared/services/common.service";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { InstalledApplicationsFormPopup } from "../../../user/reports/installed-application";
import { IsAuthorize } from "../../../../shared/authorization/check-access";
import { Role } from "../../../../shared/authorization/enums";

interface IState {
  id?: number;
  run_at_time?: string;
  installed_report_application_id?: number;
  installed_report_application_name?: string;
  installed_application_list?: DropdownItemModel[];
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  run_at_time_error?: string;
  installed_application_error?: string;

  showInstalledApplicationForm?: boolean;
    isNew?:boolean;
    isChildUpdate?:boolean;
}

export class InvocationSchedulesForm extends Component<any, IState> {
  private service: InvocationSchedulesService;
  private commonService: CommonService;
  private networkCall: any;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new InvocationSchedulesService();
    this.commonService = new CommonService();
    this.updateInstalledApplication = this.updateInstalledApplication.bind(this);
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    run_at_time: "",
    installed_report_application_id: 0,
    installed_report_application_name: "",
    installed_application_list: [],
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    run_at_time_error: "",
    installed_application_error: "",
  };


  updateInstalledApplication(isChildUpdate,name,id)
  {
    this.setState({isChildUpdate:isChildUpdate,installed_report_application_name:name,installed_report_application_id:id})
  }

  handleClose = () => {
      this.setState({ showInstalledApplicationForm: false }, () => {
      });
  }   

  handleShowInstalledApplication = () => {        
      this.setState({ showInstalledApplicationForm: true, isNew:true }, () => {

      });
  }

  handleShowEditInstalledApplication = () => {        
      this.setState({ showInstalledApplicationForm: true, isNew:false }, () => {

      });
  }

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (Number(this.props.match.params.id) > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "installed_report_application_name" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          installed_application_list: [],
        },
        () => {}
      );
    }
  };

  private getAllInstalledApplications(text: string) {
    this.commonService
      .getInstalledApplicatonByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            installed_application_list: res.result,
          });
        }
      });
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editInvocationSchedule(this.props.match.params.id)
      .then((res: HttpResponse<EditInvocationSchedulesModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            installed_report_application_id:
              res.result?.installed_report_application_id === null
                ? 0
                : res.result?.installed_report_application_id,
            installed_report_application_name:
              res.result?.installed_report_application_name === null
                ? ""
                : res.result?.installed_report_application_name,
            run_at_time:
              res.result?.run_at_time === null ? "" : res.result?.run_at_time,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.InvocationSchedules,
          this.props
        );
      });
  }

  validate = () => {
    let run_time_error = "";
    let installed_application_error = "";
    if (
      this.state.run_at_time && 
      !moment(this.state.run_at_time, "hh:mm", true).isValid()
    ) {
      run_time_error = "Run at time value is wrong";
    }
    if(!this.state.run_at_time && this.state.isSave){
      run_time_error = "Run at time cannot be blank";
    }
    if (!this.state.installed_report_application_name && this.state.isSave) {
      installed_application_error = "Installed Application Cant be blank";
    }
    if (run_time_error || installed_application_error) {
      this.setState({
        run_at_time_error: run_time_error,
        installed_application_error: installed_application_error,
      });
      return false;
    } else {
      run_time_error = "";
      installed_application_error = "";
      this.setState({
        run_at_time_error: run_time_error,
        installed_application_error: installed_application_error,
      });
      return true;
    }
  };

  onInstalledApplicationChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getAllInstalledApplications(value);
      }, 600);
    }
    this.setState({
      installed_report_application_id: value === "" ? 0 : value,
      installed_report_application_name: value,
      installed_application_error: "",
    });
  };

  handleChange = (event: any) => {
    if (event.target.name == "installed_report_application_name") {
      this.onInstalledApplicationChange(event);
    }
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.validate();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate)
    {
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      run_at_time: this.state.run_at_time,
      installed_report_application_id: this.state
        .installed_report_application_id,
    };
    if (isValid) {
      if (
        payload.id === 0 &&
        this.state.installed_report_application_id !== 0
      ) {
        this.postData(payload);
      } else {
        this.updateData(payload);
      }
    }
  }
  };

  postData(invocation_schedule: PostInvocationSchedulesModel) {
    this.setSavingFlag(true);
    this.service.postInvocationSchedule(invocation_schedule).then(
      (res: HttpResponse<PostInvocationSchedulesModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Invocation Schedule successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/invocation-schedule/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/invocation-schedule/invocation-schedule-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/invocation-schedule/invocation-schedule-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(invocation_schedule: PostInvocationSchedulesModel) {
    this.setSavingFlag(true);
    this.service.updateInvocationSchedule(invocation_schedule).then(
      (res: HttpResponse<PostInvocationSchedulesModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Invocation Schedule successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/invocation-schedule/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/invocation-schedule/invocation-schedule-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/invocation-schedule/invocation-schedule-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  selectedAccount(item) {
    this.setState({
      installed_report_application_id: item.value,
      installed_report_application_name: item.text,
      installed_application_list: [],
    });
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  renderInstalledApplication() {
    if (this.state.installed_application_list?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.installed_application_list?.map((item, index) => (
          <li
            id="installed_report_application_name"
            key={index}
            onClick={() => this.selectedAccount(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Invocation Schedule
                {this.state.isShowtext === "Edit" && (
                  <span>
                    {" "}
                    'InvocationSchedule #
                    {this.state.installed_report_application_id}'
                  </span>
                )}
              </h5>
            </div>
            <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showInstalledApplicationForm} onHide={this.handleClose}>
                            <InstalledApplicationsFormPopup  updateInstalledApplication={this.updateInstalledApplication}  handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.installed_report_application_id}/>
                        </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <InvocationSchedulesTabs
                        id={this.props.match.params.id}
                        url="/invocation-schedule/invocation-schedule-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Run at Time (HH:MM)<span className=" text-danger">*</span></label>
                          <div className="input-group ">
                            <input
                              type="text"
                              maxLength={255}
                              name="run_at_time"
                              className={
                                !this.state.run_at_time_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                              value={this.state.run_at_time}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.run_at_time_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Installed report application<span className=" text-danger">*</span></label>
                          <div className="input-group ">
                            <input
                              list="installed_report_application_name"
                              autoComplete="off"
                              id="installed_report_application_name"
                              name="installed_report_application_name"
                              type="text"
                              onChange={this.handleChange}
                              value={
                                this.state.installed_report_application_name
                              }
                              className={
                                !this.state.installed_application_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderInstalledApplication()}
                            {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) &&<div className="input-group-append">
                            <button type="button" className="btn btn-success" onClick={this.handleShowInstalledApplication}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                            {IsAuthorize([Role.TAM, Role.Dev, Role.AM ,Role.Manager]) && <button type="button"  className={`btn btn-primary`} disabled={this.state.installed_report_application_id==0}  onClick={this.handleShowEditInstalledApplication}><i className="far fa-edit"></i></button>}
                            </div>}
                            <div className="invalid-feedback">
                              {this.state.installed_application_error}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/invocation-schedule/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
