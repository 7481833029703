import { HttpWrapper, AppConfig } from "../../../core";
import { PostExportModel } from "../../../shared/export/post.export.model";
import { AccountPostModel, DeactivateUsersPostModel, EditAccountModel } from "../models";
import {AccountCurrentDataEngineJobsModel, AccountListResponseModel, AccountInstalledAppsModel, AccountConnectionModel, AccountSourcesModel } from "../models";
import { AccountDetailModel } from "../models/account.detail.model";

export class AccountService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getAccountList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<AccountListResponseModel>(AppConfig.apiEndpoint + '/v1/accounts/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getAccountById(id: number) {
    return this.wrapper.get<AccountDetailModel>(AppConfig.apiEndpoint + '/v1/accounts/' + id);
  }



  postAccount(account: AccountPostModel) {
    return this.wrapper.post<AccountPostModel>(AppConfig.apiEndpoint + '/v1/accounts', account);
  }

  editAccount(id?: number) {
    return this.wrapper.get<EditAccountModel>(AppConfig.apiEndpoint + '/v1/accounts/edit/' + id);
  }

  updateAccount(account: AccountPostModel) {
    return this.wrapper.put<AccountPostModel>(AppConfig.apiEndpoint + '/v1/accounts/' + account.id, account);
  }
  getAccountInstalledAppsById(id: number) {
    return this.wrapper.get<AccountInstalledAppsModel>(AppConfig.apiEndpoint + '/v1/accounts/' + id+'/account_installed_app');
  }
  getAccountSourcesById(id: number,currentPage: number, pageSize: number) {
    return this.wrapper.get<AccountSourcesModel>(AppConfig.apiEndpoint + '/v1/accounts/' + id + '/account_sources?current_page=' + currentPage + '&page_size=' + pageSize);
  }
  getAccountConnectionsById(id: number) {
    return this.wrapper.get<AccountConnectionModel>(AppConfig.apiEndpoint + '/v1/accounts/' + id + '/account_connections');
  }

  getAccountDataEngineJobsById(id: number) {
    return this.wrapper.get<AccountCurrentDataEngineJobsModel>(AppConfig.apiEndpoint + '/v1/accounts/' + id+'/account_running_data_engine_jobs');
  }

  exportAccount(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/accounts/export', exportData);
  }


  deactivateUsersById(id:number,data:DeactivateUsersPostModel)
  {
    return this.wrapper.put<any>(AppConfig.apiEndpoint+'/v1/accounts/'+id+'/deactivate-users',data);
  } 

}