import React, { Component, Fragment } from 'react'
import { ReportColumnFieldsDetailModel } from './models/report.column.fields.detail.model'
import { ReportColumnFieldsService } from './services/report.column.fields.service';
import { HttpResponse } from '../../../../../core';
import { ReportColumnFieldsTabs } from './ReportColumnFieldsTabs';
import { NavLink } from "react-router-dom";

import { HandleNotFoundResponse, RedirectBySourceType } from '../../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../../shared/enums';
import { Loader } from '../../../../../shared/loaders';

interface IState {
    result?: ReportColumnFieldsDetailModel;
    isLoading?: boolean;
}
export class ReportColumnFieldsDetail extends Component<any, IState> {
    private service: ReportColumnFieldsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportColumnFieldsService();
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            data_source: {
                text: "",
                value: 0
            },
            display_order: 0,
            field: {
                text: "",
                value: 0
            },
            report_column: {
                text: "",
                value: 0
            },
            type: "",
            value: "",
            data_source_id: 0,
            field_id: 0,
            report_column_id: 0

        },
        isLoading: false,
    }
    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getReportColumnFieldsById(this.props.match.params.id)
            .then((res: HttpResponse<ReportColumnFieldsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    }, () => {

                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportColumnFields,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for Report Column Field '{this.state.result?.field.text === "" ? "-" : this.state.result?.field.text}'</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportColumnFieldsTabs id={this.props.match.params.id} url="/report-column-field/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Report Column </div>
                                                {this.state.result?.report_column && <div className="card-body"> <NavLink to={"/report-column/detail/" + this.state.result?.report_column.value}>{this.state.result?.report_column.text}</NavLink></div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Type </div>
                                                <div className="card-body">{this.state.result?.type}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Field </div>
                                                {this.state.result?.field && <div className="card-body">
                                                <NavLink to={"/field/detail/" + this.state.result?.field.value+"/ /"+0}>
                                                        {this.state.result?.field.text}</NavLink></div>}
                                            </div>
                                        </div> 
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Data Source </div>                                              
                                                <div className="card-body"> {RedirectBySourceType(this.state.result?.data_source.type,this.state.result?.data_source.text,this.state.result?.data_source.value)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Display Order</div>
                                                <div className="card-body"><a href="#">{this.state.result?.display_order}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}
