import React, { Component, createRef, Fragment } from "react";
import { PlatformService } from "./services/platform.service";
import { EditPlatformModel } from "./models/edit.platform.model";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
  ValidateLogoUrl,
} from "../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../shared/enums";
import { PostPlatformModel } from "./models";
import { PlatformTabs } from "./PlatformTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { DropdownItemModel, PlatformTypeModel } from "../../../shared/models";
import { CommonService } from "../../../shared/services/common.service";
import { Modal } from "react-bootstrap";
import { CategoryFormPopup } from "../categories/CategoryFormPopup";
import { ExtractorFormPopup } from "../../accounts/connections/platforms/Extractor/ExtractorFornPopup";
import { IsAuthorize } from "../../../shared/authorization/check-access";
import { Role } from "../../../shared/authorization/enums";

interface IState {
  id?: number;
  title?: string;
  is_public?: boolean;
  extractor_list?: DropdownItemModel[];
  extractor_id?: number;
  extractor_name?: string;
  info?: string;
  categories_list?: DropdownItemModel[];
  categories?: Array<PlatformTypeModel>;
  unassigned_categories?: Array<PlatformTypeModel>;
  favicon_url?: string;
  image_url?: string;
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  favUrlError?: string;
  imageUrlError?: string;
  searchList?: DropdownItemModel[];
  isChildUpdate?: boolean;
  showCategoryForm?: boolean;
  isNew?: boolean;
  category_name?: string
  category_id?: number

  showExtractorForm?: boolean;
  faviconUrlError?:string;
  titleError?:string;
  
}

export class PlatformForm extends Component<any, IState> {
  private service: PlatformService;
  private commonService: CommonService;
  private extractorNetworkCall: any;
  private titleRef: any;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new PlatformService();
    this.commonService = new CommonService();
    this.updateCategories = this.updateCategories.bind(this);
    this.updateExtractor = this.updateExtractor.bind(this);
    this.titleRef = createRef();
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    title: "",
    is_public: true,
    extractor_list: [],
    extractor_id: 0,
    extractor_name: "",
    info: "",
    categories_list: [],
    categories: [],
    unassigned_categories: [],
    favicon_url: "",
    image_url: "",
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    favUrlError: "",
    imageUrlError: "",
    searchList: [],
    isChildUpdate: false,
  showCategoryForm: false,
  isNew: false,
  category_name: "",
  category_id: 0,
  faviconUrlError:"",
  showExtractorForm:false,
  titleError:""
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {    
    if (this.props.match.params.id > 0) {
      this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
          this.loadData();
      });
  }
    if (Number(this.props.match.params.id) === 0) {
      this.getAllCategories();
    }
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (event) => {
    if (event.path[0].id !== 'extractor_name' && event.path[0].id !== 'extractor' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
        this.setState({
            extractor_list: []
        }, () => {

        })

    }
}

updateExtractor(isChildUpdate,name,id)
{
  this.setState({isChildUpdate:isChildUpdate,extractor_name:name,extractor_id:id})
}

handleCloseExtractor = () => {
    this.setState({ showExtractorForm: false }, () => {
    });
}   

handleShowExtractor = () => {        
    this.setState({ showExtractorForm: true, isNew:true }, () => {

    });
}

handleShowEditExtractor = () => {        
    this.setState({ showExtractorForm: true, isNew:false }, () => {

    });
}

  getAllCategories() {
    this.commonService
      .getAllCategories()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            unassigned_categories: res.result,
            searchList: res.result,
          });
        }
      });
  } 
 
 

  updateCategories(isChildUpdate, name, id) {    
    this.setState({
      isChildUpdate: isChildUpdate,
      category_name: name,      
      category_id: id,
    });    
    if(id!=0 && name!="")  
    {
      this.getAssignedCategories(id, name);
    }
  }


  getAssignedCategories(id,name) {
    this.commonService
      .getAllPlatforms()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            unassigned_categories: res.result            
          },()=>{
            this.setCategories(id,name)
          });
        }
      });
  }

  setCategories(value?:number, name?:string)
  {   
    this.state.categories?.push({
      text: name,
      value: value      
    });
    this.setState({
      unassigned_categories: this.state.unassigned_categories?.filter(
        (p) => p.value != value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value != value
      ),
      categories: this.state.categories,
    });
  }

  handleClose = () => {
    this.setState({ showCategoryForm: false }, () => {});
  };

  handleShowCategory = () => {
    this.setState({isChildUpdate:false, showCategoryForm: true, isNew: true }, () => {});
  };

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editPlatform(this.props.match.params.id)
      .then((res: HttpResponse<EditPlatformModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            title: res.result?.title == null ? "" : res.result?.title,
            is_public: res.result?.is_public,
            extractor_id:
              res.result?.extractor === null ? 0 : res.result?.extractor.id,
            extractor_name:
              res.result?.extractor === null ? "" : res.result?.extractor.type,
            categories: res.result?.categories,
            unassigned_categories: res.result.unassigned_categories,
            searchList: res.result.unassigned_categories,
            info: res.result?.info === null ? "" : res.result?.info,
            favicon_url:
              res.result?.favicon_url === null ? "" : res.result?.favicon_url,
            image_url:
              res.result?.image_url === null ? "" : res.result?.image_url,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Platforms, this.props);
      });
  }


  private getExtractor(text: string) {
    this.commonService.getExtractorByName(text)
        .then((res: HttpResponse<DropdownItemModel[]>) => {
            if (res) {
                this.setState({
                    extractor_list: res.result
                });
            }
        });

}

//*************** * Accounts************************* //


onExtractorChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
        if (this.extractorNetworkCall) {
            clearTimeout(this.extractorNetworkCall);
        }
        this.extractorNetworkCall = setTimeout(() => {
            this.getExtractor(value)
        }, 600)
    }
    this.setState({
        extractor_name: value        
    }, () => {
        // this.validate();
    })
}

selectedExtractor(item) {
    this.setState({
        extractor_id: item.value,
        extractor_name: item.text,
        extractor_list: []
    })
}

renderExtractor = () => {
    if (this.state.extractor_list?.length === 0) {
        return null;
    }
    return (
        <ul className="list-unstyled auto-suggest">
            {
                this.state.extractor_list?.map((item, index) => (<li id="extractor" key={index} onClick={() => this.selectedExtractor(item)}>{item.text}</li>))
            }
        </ul>
    );
}
//*************** * Accounts************************* //

  handleChange = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.name == "extractor_name") {
      this.onExtractorChange(event);
  }
    if (event.target.name == "searchText") {
      this.handleSearch(name, value);
  }
  else {
    const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            })
  }

  if (event.target.name=="title" && this.titleRef.current.value) {
    this.setState({ titleError: "" });
  }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate){
    const isValid = this.validate();
    let platform:PostPlatformModel = {
      title: this.state.title,
      is_public: this.state.is_public,
      categories: this.state.categories,
      info: this.state.info,
      favicon_url: this.state.favicon_url,
      image_url: this.state.image_url,
      id: this.state.id,
      extractor_id:this.state.extractor_id==0?null:this.state.extractor_id
    };
    if (isValid) {
      if (platform.id == 0) { 
        this.postData(platform)        
      } 
      else
      {
        this.updateData(platform);
      }
    }
  }
  };

  validate = () => {
    let titleError = "";
    let imageUrlError = "";
    let favUrlError = "";

    if (!this.state.title) {
      titleError = "Title can't be blank";
  }

    if (!ValidateLogoUrl(this.state.image_url) && this.state.image_url) {
      imageUrlError = "Must begin with 'https://' and end with '.gif', '.jpeg', '.jpg', or '.png'.";
  }

  if (!ValidateLogoUrl(this.state.favicon_url) && this.state.favicon_url) {
    favUrlError = "Must begin with 'https://' and end with '.gif', '.jpeg', '.jpg', or '.png'.";
}

    if (imageUrlError || favUrlError || titleError) {
      this.setState({
        favUrlError: favUrlError,
        imageUrlError: imageUrlError,
        titleError:titleError
      });
      return false;
    }

    if (!imageUrlError || !favUrlError || !titleError) {
      imageUrlError = "";
      favUrlError = "";
      titleError ="";
      this.setState({
        favUrlError: favUrlError,
        imageUrlError: imageUrlError,
        titleError:titleError
      });
      return true;
    }
  };

  postData(platform: PostPlatformModel) {
    this.setSavingFlag(true);
    this.service.postPlatform(platform).then(
      (res: HttpResponse<PostPlatformModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Platform successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/platform/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/platform/platform-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/platform/platform-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(platform) {
    this.setSavingFlag(true);
    this.service.updatePlatform(platform).then(
      (res: HttpResponse<PostPlatformModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Platform successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/platform/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/platform/platform-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/platform/platform-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {    
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  handleSearch(name, value) {
    if (name === "searchText") {
      let list = this.state.unassigned_categories;
      list = list?.filter((p) =>
        p.text?.toLowerCase().startsWith(value.toLowerCase())
      );

      if (value) {
        this.setState({ unassigned_categories: list,searchText:value });
      } else {
        this.setState({ unassigned_categories: this.state.searchList,searchText:value });
      }
    }
  }

  onLeftSelectionChange(e: any) {
    let data = this.state.unassigned_categories?.find(
      (p) => p.value === e.target.value
    );
    this.state.categories?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      unassigned_categories: this.state.unassigned_categories?.filter(
        (p) => p.value !== e.target.value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value !== e.target.value
      ),
      categories: this.state.categories,
    });
  }

  selectAll() {
    this.state.unassigned_categories?.forEach((p) => {
      this.state.categories?.push(p);
    });
    this.setState({
      unassigned_categories: [],
      categories: this.state.categories,
    });
  }

  onRightSelectionChange(e: any) {
    let data = this.state.categories?.find((p) => p.value === e.target.value);
    this.state.unassigned_categories?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      categories: this.state.categories?.filter(
        (p) => p.value !== e.target.value
      ),
      unassigned_categories: this.state.unassigned_categories,
    });
  }

  clearAll() {
    this.state.categories?.forEach((p) => {
      this.state.unassigned_categories?.push(p);
    });
    this.setState({
      categories: [],
      unassigned_categories: this.state.unassigned_categories,
    });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Platform
                {this.state.title && <span> '{this.state.title}'</span>}
              </h5>
            </div>
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.showCategoryForm}
              onHide={this.handleClose}
            >
              <CategoryFormPopup
                updateCategories={this.updateCategories}
                handleClose={this.handleClose}
                isNew={this.state.isNew}
                id={this.state?.category_id}
              />
            </Modal>
            <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showExtractorForm} onHide={this.handleCloseExtractor}>
                            <ExtractorFormPopup  updateExtractors={this.updateExtractor}  handleClose={this.handleCloseExtractor} isNew={this.state.isNew} id={this.state?.extractor_id}/>
                        </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <PlatformTabs
                        id={this.props.match.params.id}
                        url="/platform/platform-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="title"
                            ref={this.titleRef}
                            className={!this.state.titleError ? 'form-control' : 'form-control  is-invalid'}
                            value={this.state.title}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                              {this.state.titleError}
                            </div>
                          <small>Required. Length up to 255.</small>
                        </div>
                      </div>
                      <div className="col-md-4">
                                                <div className="form-group mb-2 position-relative">
                                                    <label>Extractor</label>
                                                    <div className="input-group ">
                                                    <input autoComplete="off" placeholder="Search" id="extractor_name" name="extractor_name" type="text" onChange={this.handleChange} value={this.state.extractor_name} className="form-control" />
                                                        {this.renderExtractor()}
                                                        {IsAuthorize([Role.Dev]) && <div className="input-group-append">
                                                        <button type="button" className="btn btn-success" onClick={this.handleShowExtractor}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            <button type="button"  className={`btn btn-primary`} disabled={this.state.extractor_id==0}  onClick={this.handleShowEditExtractor}><i className="far fa-edit"></i></button>                                                            
                                                        </div>}
                                                    </div>
                                                </div>
                                                <small>
                              Once this is set it cannot be changed.                           
                        </small>  
                                            </div>
                            
                                               
                                            <div className="col-md-4">                 
                      <div className="form-group shadow-sm p-3 mb-4 ">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="is_public"
                            checked={this.state.is_public}
                            onChange={this.handleChange}
                            className="custom-control-input"
                            id="is_public"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="is_public"
                          >
                            Is public
                          </label>
                        </div>
                        <small>
                          Check this to show the platform in the Connection
                          Manager.
                        </small>
                      </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  shadow-sm p-3 mb-0">
                          <div className="d-flex justify-content-between mb-2">
                          <label>Categories</label>
                          <button type="button" className="btn btn-success" onClick={this.handleShowCategory} ><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                          </div>
                          <input
                            type="text"
                            name="searchText"
                            value={this.state.searchText}
                            placeholder="Search"
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="row">
                          <div
                            className="col-md-6"
                            style={{ paddingRight: "0px" }}
                          >
                            <div className="form-group customScrollBar shadow-sm p-3 mb-2">
                              {this.state.unassigned_categories?.map(
                                (item, index) => {
                                  return (
                                    <div key={index}>
                                      <div>
                                        <input
                                          type="checkbox"
                                          style={{ display: "none" }}
                                          value={item.value || ""}
                                          checked={item.isChecked || false}
                                          onChange={this.onLeftSelectionChange.bind(
                                            this
                                          )}
                                          id={"checkbox" + item.value}
                                          name={"checkbox" + item.value}
                                        />
                                        <label
                                          htmlFor={"checkbox" + item.value}
                                        >
                                          {item.text}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <button
                              type="button"
                              onClick={this.selectAll.bind(this)}
                              className="btn btn-default btn-sm mr-2"
                            >
                              Choose All{" "}
                              <i className="fas fa-chevron-circle-right"></i>
                            </button>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group customScrollBar  shadow-sm p-3 mb-2">
                              {this.state.categories?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div>
                                      <input
                                        style={{ display: "none" }}
                                        type="checkbox"
                                        value={item.value || ""}
                                        checked={item.isChecked || false}
                                        onChange={this.onRightSelectionChange.bind(
                                          this
                                        )}
                                        id={"checkbox" + item.value}
                                        name={"checkbox" + item.value}
                                      />
                                      <label htmlFor={"checkbox" + item.value}>
                                        {item.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <button
                              type="button"
                              onClick={this.clearAll.bind(this)}
                              className="btn btn-default btn-sm"
                            >
                              <i className="fas fa-chevron-circle-left"></i>{" "}
                              Clear All
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="row">
                              <div className="col-12"><div className="form-group">
                          <label>Favicon Url</label>
                          <div className="input-group ">
                            <input
                              type="text"
                              maxLength={255}
                              name="favicon_url"
                              className={!this.state.favUrlError ? 'form-control' : 'form-control  is-invalid'}
                              value={this.state.favicon_url}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.favUrlError}
                            </div>
                          </div>
                        </div></div>
                        <div className="col-12">
                        <div className="form-group">
                          <label>Image Url</label>
                          <div className="input-group ">
                            <input
                              type="text"
                              maxLength={255}
                              name="image_url"
                              className={!this.state.imageUrlError ? 'form-control' : 'form-control  is-invalid'}
                              value={this.state.image_url}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.imageUrlError}
                            </div>
                          </div>
                        </div>
                        </div>
                         
                        <div className="col-12">
                        <div className="form-group">
                          <label>Info</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="info"
                            className="form-control"
                            value={this.state.info}
                            onChange={this.handleChange}
                          />
                          <small>
                            Describe the platform. Customers see this in
                            Connection Manager.
                          </small>
                        </div>
                      </div>
                        
                       </div> 
                        
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/platform/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
