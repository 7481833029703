import React, { Component } from 'react'
import { IFilterItemProps } from "./models/filter-item.props";
import { OperatorList } from "./models/post.filter.model";
import { Fragment } from "react";
import { FilterItemInputRenderType } from "./models";

interface IState{
    id:string,
    op:string,
    value:string
}

export class FilterItemNumber extends Component<IFilterItemProps,IState> {

    constructor(props){
        super(props);
    }

    oprators= OperatorList.number;
    inputRenderType:any=FilterItemInputRenderType.SingleBox;
    
    selectedOptionValue=this.oprators[0].operator;
    selectedValue='';
    inputValue2=''; 
    inputValue1=''; 

    handleDropDownChange = (event: any) => { 
        let selectedObject=this.oprators.find(x=>x.name==event.target.value);      
        this.selectedOptionValue = selectedObject!=null?selectedObject.operator:"";  
        this.inputRenderType=selectedObject?.renderType;
        if(this.selectedOptionValue=='is' || this.selectedOptionValue=='isnot'){
            this.selectedValue='null';
        }
        if(this.selectedOptionValue=='eq' && this.selectedValue=='null'){
            this.selectedValue='';
        } 
        if(this.selectedOptionValue=='range' && this.selectedValue=='null'){
            this.selectedValue='';
        }  
        this.brodCastValue();        
    }

    brodCastValue=()=>{
        this.setState({id:this.props.column.columnName,op:this.selectedOptionValue, value:this.selectedValue});
        if(this.inputRenderType==FilterItemInputRenderType.NoBox){
            this.props.onValueChange({column:this.props.column.columnName,op:this.selectedOptionValue,value:this.selectedValue});
        }
        else {
            if(this.selectedOptionValue!=''){
                if(this.selectedValue!=''){
                    this.props.onValueChange({column:this.props.column.columnName,op:this.selectedOptionValue,value:this.selectedValue});
                }else{
                    this.props.clearFilter(this.props.column.columnName);
                }

            }
        }
    }

    handleInputChange=(value1:string,value2:string)=>{
        this.inputValue1=value1;
        this.inputValue2=value2;
        this.selectedValue='';
        if(this.inputRenderType==FilterItemInputRenderType.SingleBox){
            this.selectedValue=this.inputValue1;
        }else if(this.inputRenderType==FilterItemInputRenderType.RangeBox){
            this.selectedValue=this.inputValue1+"::"+this.inputValue2;
        }

        this.brodCastValue();
    }

    render(){
    return (
        <div className="form-group mb-3">
            <label>{this.props.column.displayText}</label>
            <div className="row">
                <div className={(this.inputRenderType === FilterItemInputRenderType.RangeBox ? 'col-12' : 'col-5')}>
                    <select className="form-control form-control-sm mb-1" name={this.props.column.columnName} onChange={this.handleDropDownChange}>
                        {this.oprators.map((num, index) => {
                            return (
                                <option key={index} value={num.name} className='form-control'>{num.name}</option>
                            );
                        })}

                    </select>
                </div>
                {(this.inputRenderType === FilterItemInputRenderType.SingleBox) ?

                    (<div className="col pl-0"><input type="text" onChange={(e) => this.handleInputChange(e.target.value, this.inputValue2)} className="form-control form-control-sm" /></div>)
                    : null

                }

                {(this.inputRenderType === FilterItemInputRenderType.RangeBox) ?
                    (<Fragment>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm pr-2">
                                    <input type="text" onChange={(e) => this.handleInputChange(e.target.value, this.inputValue2)} className="form-control form-control-sm" />
                                </div>
                                <div className="col-sm pl-2">
                                    <input type="text" onChange={(e) => this.handleInputChange(this.inputValue1, e.target.value)} className="form-control form-control-sm" />
                                </div>
                            </div>
                            

                            
                        </div>
                    </Fragment>) : null
                }
            </div>
        
        </div>
    )
    }

}