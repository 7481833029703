import React, { Component, Fragment } from 'react'
import { AppletsDetailModel } from './models/applets.detail.model'
import { AppletsService } from './services/applets.services';
import { HttpResponse } from '../../../../../core';
import { AppletsTabs } from './AppletsTabs';
import { ConvertDateTime, HandleNotFoundResponse } from '../../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../../shared/enums';
import { Loader } from '../../../../../shared/loaders';

interface IState {
    result?: AppletsDetailModel;
    isLoading?: boolean;
}
export class AppletsDetail extends Component<any, IState> {
    private service: AppletsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AppletsService();
    }

    initialState: Partial<IState> = {
        result: {
            favicon: "",
            id: 0,
            name: "",
            report_name: "",
            style: "",
            user_name: "",
            account_name: "",
            created_at: new Date(),
            updated_at: new Date()
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getAppletsById(this.props.match.params.id)
            .then((res: HttpResponse<AppletsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Applets,this.props) 
            });
    }

    render() {
        return (
            <Fragment>
               <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Details for Applet '{this.state.result?.name===""?"-":this.state.result?.name}'</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <AppletsTabs id={this.props.match.params.id} url="/applet/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Id</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.id}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Name</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.name}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Style</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.style}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Favicon</b>
                                                </div>
                                                <div className="card-body"><img src={this.state.result?.favicon} width="16" height="16" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>User</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.user_name + " - " + this.state.result?.account_name}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Report View</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.report_name}</div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated At</b>
                                                </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
