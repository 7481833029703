import React, { Component, Fragment } from 'react'
import { ExtractionEnginesDetailModel, CustomConnectionsModel } from './models'
import { ExtractionEnginesService } from './services';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { ExtractionEnginesTabs } from '.';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ExtractionEnginesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ExtractionEnginesDetail extends Component<any, IState> {
    private service: ExtractionEnginesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ExtractionEnginesService();
    }

    initialState: Partial<IState> = {
        result: { 
            id: 0,
            auth_provider:"",
            description:"",
            image_url:"",
            is_public:false,
            label:"" ,
            custom_connections:[],
            extraction_scheduling_recipe_id:0,
            type:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getExtractionEnginesById(this.props.match.params.id)
            .then((res: HttpResponse<ExtractionEnginesDetailModel>) => {
                this.setLoading(false); 
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ExtractionEngine,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Extraction Engine '{this.state.result?.label==""?"-":this.state.result?.label}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <ExtractionEnginesTabs id={this.props.match.params.id} url="/extraction-engine/detail/" />
                            {!this.state.isLoading &&  <>  
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">
                            {this.state.result?.label &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Label</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.label}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.image_url &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Image</b>
                                        </div>
                                        <div className="card-body"><img src={this.state.result?.image_url} height="16"  /></div>
                                    </div>
                                </div>}
                                {this.state.result?.auth_provider &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Auth Provider</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.auth_provider}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.is_public && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Is Public</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    } 
                               
                             
                            {this.state.result?.description!="" && <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Description</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.description}
                                        </div>
                                    </div>
                                </div>}
                            {this.state.result?.type!="" && <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Type</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.type}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.extraction_scheduling_recipe_id!=0 &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Extraction Scheduling Recipe</b>
                                        </div>
                                        <div className="card-body">
                                        <NavLink to={"/extraction-scheduling-recipe/detail/" + this.state.result?.extraction_scheduling_recipe_id}>ExtractionSchedulingRecipe #{this.state.result?.extraction_scheduling_recipe_id}</NavLink>
                                        
                                        </div>
                                    </div>
                                </div>}
                            </div>

                                    {this.state.result?.custom_connections.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Custom Connections</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Account</th>                                                            
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">State</th>
                                                            <th scope="col">State Message</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.custom_connections.map((item: CustomConnectionsModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><NavLink to={"/custom-connection/detail/" + item.id}>{item.name}</NavLink></td>
                                                                    <td><NavLink to={"/account/detail/" + item.account.value}>{item.account.text}</NavLink></td>                                                                    
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>                                                                    
                                                                    <td>{item.state}</td>
                                                                    <td>{item.state_message}</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}
                                    </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 