import React, { Component, Fragment, useState } from 'react'

import { BrowserRouter } from "react-router-dom";
import { UserRoute } from '../../routing/UserRoutes'
import Breadcrumbs from '../../shared/Breadcrumbs';


export class UserPage extends Component<any> {
    render() {
        return (           
                <Fragment>
                    <Breadcrumbs />   
                    <UserRoute />    
                </Fragment>
        )
    }
}
