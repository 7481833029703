import React, { Component, Fragment } from "react";
import { CategoryDetailModel } from "./models/category.detail.model";
import { CategoryService } from "./services/category.service";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse
} from "../../../shared/helpers";
import { NotFoundResponseArea } from "../../../shared/enums";
import { CategoryTabs } from "./CategoryTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";

interface IState {
  result?: CategoryDetailModel;
  isLoading?: boolean;
}

export class CategoryDetail extends Component<any, IState> {
  private service: CategoryService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new CategoryService();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
      platforms: [],
    },
    isLoading: false,
  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }

  //function to load the initial view data coming from the API.
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
loadData() {
    this.setLoading(true);
    this.service
      .getCategoryById(this.props.match.params.id)
      .then((res: HttpResponse<CategoryDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Category, this.props);
      });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Category '{this.state.result?.name}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <CategoryTabs
                  id={this.props.match.params.id}
                  url="/category/detail/"
                />
                {!this.state.isLoading &&  <>         
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.name && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Name</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.name}
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="row">
                  {this.state.result?.platforms &&
                    this.state.result?.platforms.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Platforms</b>
                          </div>
                          <div className="card-body">
                            <ol className="d-flex flex-wrap">
                              {this.state.result?.platforms.map(
                                (item) => (
                                  <li key={item.value} className="col-4">
                                    <Link to={"/platform/detail/" + item.value}><div>{item.text}</div></Link>
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
