import React, { Component, Fragment } from 'react'
import { DropdownItemModel } from '../../../shared/models'
import { AccountDomainService } from './services/account.domain.service';
import { CommonService } from '../../../shared/services/common.service';
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../shared/helpers';
import { ButtonType, NotFoundResponseArea } from '../../../shared/enums';
import { Link, Route } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { AccountDomainPostModel, EditAccountDomainModel } from './models';
import { AccountDomainTabs } from '.';
import Modal from 'react-bootstrap/Modal';
import { AccountForm, AccountFormPopup } from '..';
import { createBrowserHistory } from "history";
import { IsAuthorize } from '../../../shared/authorization/check-access';
import { Role } from '../../../shared/authorization/enums';

const history = createBrowserHistory();


interface IState {
    showAccountForm?: boolean;
    accountDomain?: AccountDomainPostModel,
    editUser?: EditAccountDomainModel,
    id?: number,
    name?: string,
    account_id?: number,
    roleName?: string,
    accountList?: DropdownItemModel[],
    AccountName?: string, 
    nameError?: string,
    accountError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    showExportModal?: boolean;

    isNew?:boolean;
    isChildUpdate?:boolean;
}
export class AccountDomainForm extends Component<any, IState> {
    //declaring service type
    private service: AccountDomainService;
    private commonService: CommonService;

    private roleNetworkCall: any;
    private accountNetworkCall: any;

    constructor(props) {
        super(props)
        this.service = new AccountDomainService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.handleClose = this.handleClose.bind(this);
        this.updateAccount = this.updateAccount.bind(this);

    }

    initialState: Partial<IState> = {
        accountDomain: {
            id: 0,
            name: "",
            account_id: 0

        },
        id: 0,
        name: "",
        account_id: 0,
        AccountName: "",
        accountList: [],
        nameError: "",
        accountError: "",
        isShowtext: "New",
        isSave: "",
        isSaving: false,

        showAccountForm: false,
        isNew:false,
        isChildUpdate:false
    }

    updateAccount(isChildUpdate,name,id)
    {
      this.setState({isChildUpdate:isChildUpdate,AccountName:name,account_id:id})
    }

    handleClose = () => {
        this.setState({ showAccountForm: false }, () => {
        });
    }   

    handleShowAccount = () => {        
        this.setState({ showAccountForm: true, isNew:true }, () => {

        });
    }

    handleShowEditAccount = () => {        
        this.setState({ showAccountForm: true, isNew:false }, () => {

        });
    }



    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.path[0].id !== 'AccountName' && event.path[0].id !== 'account' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                accountList: []
            }, () => {

            })

        }
    }


    loadData() {
        this.service.editAccountDomain(this.props.match.params.id)
            .then((res: HttpResponse<EditAccountDomainModel>) => {
                if (res && res.result) {
                    this.setState({
                        AccountName: res.result?.account.text,
                        account_id: res.result?.account.value,
                        id: res.result?.id,
                        name: res.result?.name
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.AccountDomain,this.props)
            });
    }

    postData(accountDomain: AccountDomainPostModel) {
        this.setSavingFlag(true);
        this.service.postAccountDomain(accountDomain)
            .then((res: HttpResponse<AccountDomainPostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        accountDomain: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Account Domain successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/account-domain/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/account-domain/account-domain-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/account-domain/account-domain-form/' + this.state?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }

    updateData(accountDomain: AccountDomainPostModel) {
        this.setSavingFlag(true);
        this.service.updateAccountDomain(accountDomain)
            .then((res: HttpResponse<AccountDomainPostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        accountDomain: res.result
                    }, () => {
                        ShowSuccessMessage("Account Domain successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/account-domain/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/account-domain/account-domain-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/account-domain/account-domain-form/' + this.state?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }

    private getAccounts(text: string) {
        this.commonService.getAccountsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        accountList: res.result
                    });
                }
            });

    }

    //*************** * Accounts************************* //


    onAccountChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.accountNetworkCall) {
                clearTimeout(this.accountNetworkCall);
            }
            this.accountNetworkCall = setTimeout(() => {
                this.getAccounts(value)
            }, 600)
        }
        this.setState({
            AccountName: value,
            accountError: ""
        }, () => {
            // this.validate();
        })
    }

    selectedAccount(item) {
        this.setState({
            account_id: item.value,
            AccountName: item.text,
            accountList: []
        })
    }

    renderAccount = () => {
        if (this.state.accountList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.accountList?.map((item, index) => (<li id="account" key={index} onClick={() => this.selectedAccount(item)}>{item.text}</li>))
                }
            </ul>

            //  <datalist id="AccountNames" >
            //       {
            //         this.state.accountList?.map((item, index) => (<option id="account" key={index} onClick={() => this.selectedAccount(item)} value={item.text} >{item.text}</option>))
            //     }
            //   </datalist>
        );
    }
    //*************** * Accounts************************* //

    handleChange = (event: any) => {
        if (event.target.name == "AccountName") {
            this.onAccountChange(event);
        }
        else {
            const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            })
        }

    }

    validate = () => {
        let nameError = "";
        let accountError = "";
        if (!this.state.name) {
            nameError = "Name can't be blank";
        }
        if (!this.state.AccountName) {
            accountError = "Account can't be blank";
        }
        if (nameError || accountError) {
            this.setState({ nameError: nameError, accountError: accountError })
            return false;
        }
        else {
            nameError = "";
            accountError = "";
            this.setState({ nameError: nameError, accountError: accountError })
            return true;
        }
    }

    handleSubmit = event => {        
        event.preventDefault();        
        if(!this.state.isChildUpdate)
        { 
        const isValid = this.validate();
        let accountDomainData: AccountDomainPostModel = {
            account_id: this.state.account_id,
            name: this.state.name,
            id: this.state.id
        };

        if (isValid) {
            if (accountDomainData.id === 0) {
                this.postData(accountDomainData);
            }
            else {
                this.updateData(accountDomainData)
            }
        }
    }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value,isChildUpdate:false })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }


    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>{this.state.isShowtext}  Account Domain {this.state.name && <span>'{this.state.name}'</span>}</h4>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showAccountForm} onHide={this.handleClose}>
                            <AccountFormPopup  updateAccount={this.updateAccount}  handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.account_id}/>
                        </Modal>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <AccountDomainTabs id={this.props.match.params.id} name={this.state.name} url="/account-domain/edit/" />}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Name<span className=" text-danger">*</span></label>
                                                    <input type="text" maxLength={255} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-2 position-relative">
                                                    <label>Account<span className=" text-danger">*</span></label>
                                                    <div className="input-group ">
                                                        <input list="AccountNames" autoComplete="off" id="AccountName" name="AccountName" type="text" onChange={this.handleChange} value={this.state.AccountName}
                                                            className={!this.state.accountError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderAccount()}
                                                        {IsAuthorize([Role.TAM, Role.Dev,Role.AM, Role.Support ,Role.Manager]) &&<div className="input-group-append">
                                                        <button type="button" className="btn btn-success" onClick={this.handleShowAccount}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            <button type="button"  className={`btn btn-primary`} disabled={this.state.account_id==0}  onClick={this.handleShowEditAccount}><i className="far fa-edit"></i></button>                                                            
                                                        </div>}
                                                        <div className="invalid-feedback">
                                                            {this.state.accountError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showExportModal} onHide={this.handleClose}>
                                                <Modal.Body>
                                                    <AccountForm history={history} />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="text-center mt-3 mb-4">

                            {!this.state.isSaving && <Fragment>
                                <Link type="button" className="btn btn-lg btn-default" to="/account-domain/list">{ButtonType.Cancel}</Link>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" float="center" width="200px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
