import React, { Component, Fragment } from 'react'
import { ScopeSchemaChangesService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SchemaChangeType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { ScopeSchemaChangesDetailModel} from './models';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ScopeSchemaChangesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ScopeSchemaChangesDetail extends Component<any, IState> {
    private service: ScopeSchemaChangesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ScopeSchemaChangesService();
    }

    initialState: Partial<IState> = {
        result:{
            id: 0,
            scope_id: 0,
            scope: {
              text: "",
              value: ""
            },
            state: "",
            requested_by: "",
            created_at: new Date(),
            updated_at: new Date(),
            scope_field_schema_changes: [],
            data_engine_jobs: []
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getScopeSchemaChangesById(this.props.match.params.id)
            .then((res: HttpResponse<ScopeSchemaChangesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    }, () => {
                       
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ScopeSchemaChanges,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Scope Schema Change  &nbsp;'ScopeSchemaChange  #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">       
                        {!this.state.isLoading &&  <>               
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.id && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>ID</b>
                                            </div>
                                            <div className="card-body"> {this.state.result?.id} </div>
                                        </div>
                                </div>}
                                    
                                {this.state.result?.scope &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Scope</b>
                                        </div>
                                        <div className="card-body"><NavLink to={"/scope/detail/" + this.state.result?.scope.value}>{ this.state.result?.scope.text}</NavLink></div>
                                    </div>
                                </div>}

                                {this.state.result?.state &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>State</b>
                                        </div>
                                        <div className="card-body">{ this.state.result?.state}</div>
                                    </div>
                                </div>}
                                {this.state.result?.requested_by &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Requested by</b>
                                        </div>
                                        <div className="card-body">{ this.state.result?.requested_by}</div>
                                    </div>
                                </div>}

                                {this.state.result?.created_at &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Created at</b>
                                        </div>
                                        <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                    </div>
                                </div>}
                                {this.state.result?.updated_at &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Updated at</b>
                                        </div>
                                        <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                    </div>
                                </div>}
                               
                               
                            </div>
                        
                            {this.state.result?.scope_field_schema_changes.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Schema Changes</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Id</th>                                                                                                                   
                                                            <th scope="col">Change type	</th>
                                                            <th scope="col">Field</th>                                                            
                                                            <th scope="col">Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.scope_field_schema_changes.map((item, index) => {
                                                           
                                                            return (
                                                                <tr key={index}>                                                                   
                                                                    <td><NavLink to={"/scope-field-schema-change/detail/" + item.id}>{item.id}</NavLink></td>                                                                    
                                                                    <td>{item.change_type}</td>
                                                                    <td><NavLink to={"/field/detail/" + item.field?.value+"/"+this.state.result?.scope.text+"/"+ this.state.result?.scope_id}> {item.field?.text} </NavLink></td>                                                                    
                                                                    {item.change_type==SchemaChangeType.add &&<td>{item.new_type}</td>}
                                                                    {item.change_type==SchemaChangeType.change &&<td>from &nbsp;{item.old_type} to &nbsp;{item.new_type}</td>}
                                                                    {item.change_type==SchemaChangeType.rename &&<td>from &nbsp;{item.old_name} to &nbsp;{item.new_name}</td>}
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                </div>}

                                    {this.state.result?.data_engine_jobs?.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Scope Rebuild Jobs</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>                                                                                                                   
                                                            <th scope="col">Data source	</th>
                                                            <th scope="col">Scope</th>                                                            
                                                            <th scope="col">State</th>
                                                            <th scope="col">Created at</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.data_engine_jobs?.map((item, index) => {
                                                            return (
                                                                <tr key={index}>                                                                                                                                   
                                                                    <td><NavLink to={"/data-engine-job/detail/" + item.id}>{item.id}</NavLink></td>
                                                                    <td><NavLink to={"/custom-connection/detail/" + item.data_source.id}>{item.data_source.name}</NavLink></td>
                                                                    <td><NavLink to={"/scope/detail/" + item.scope.value}>{item.scope.text}</NavLink></td>
                                                                    <td> {item.state}</td>
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                                                                                                     

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                    {/* <div className="row">
                                    {this.state.result?.dependent_reports && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Dependents Reports</div>
                                                {this.state.result?.dependent_reports && <div className="card-body">
                                                    {this.state.result?.dependent_reports.map((item: DropdownItemModel, index) => {
                                                        return (
                                                            <span key={item.value}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}
                                    </div> */}
 </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
        
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 