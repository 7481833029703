import { IExportColumnModel } from "../../../../../shared/models";

export const InstalledApplicationColumnList: IExportColumnModel[] = [
    {
        isAllChecked: true,
        name: "Fields from installed applications",
        value: "installed_report_applications",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Options",
                value: "Options",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Run on report change",
                value: "Run on report change",
                checked: true,
            },
            {
                name: "Options key",
                value: "Options key",
                checked: true,
            },
            {
                name: "Options iv",
                value: "Options iv",
                checked: true,
            },
            {
                name: "Backup options",
                value: "Backup options",
                checked: true,
            },
            {
                name: "Billable",
                value: "Billable",
                checked: true,
            }
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated application",
        value: "applications",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Type",
                value: "Type",
                checked: true,
            },
            {
                name: "Code",
                value: "Code",
                checked: true,
            },
            {
                name: "Last pushed by",
                value: "Last pushed by",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Public",
                value: "Public",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Unit count",
                value: "Unit count",
                checked: true,
            }
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report",
        value: "reports",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Favicon url",
                value: "Favicon url",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Custom sql",
                value: "Custom sql",
                checked: true,
            },
            {
                name: "Checksum",
                value: "Checksum",
                checked: true,
            },
            {
                name: "Last generated at",
                value: "Last generated at",
                checked: true,
            },
            {
                name: "Total row count",
                value: "Total row count",
                checked: true,
            },
            {
                name: "Has row matching",
                value: "Has row matching",
                checked: true,
            },
            {
                name: "Time range",
                value: "Time range",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Edited at",
                value: "Edited at",
                checked: true,
            },
            {
                name: "Viewed at",
                value: "Viewed at",
                checked: true,
            },
            {
                name: "Query strategy",
                value: "Query strategy",
                checked: true,
            },
            {
                name: "Always rebuild",
                value: "Always rebuild",
                checked: true,
            },
            {
                name: "Runs in redshift",
                value: "Runs in redshift",
                checked: true,
            },
            {
                name: "Alert on failure",
                value: "Alert on failure",
                checked: true,
            },
            {
                name: "Skip when priority dependent job",
                value: "Skip when priority dependent job",
                checked: true,
            }
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report view",
        value: "report_views",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Filters",
                value: "Filters",
                checked: true,
            },

        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated database engine",
        value: "database_engines",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Db type",
                value: "Db type",
                checked: true,
            },
            {
                name: "Version",
                value: "Version",
                checked: true,
            },
            {
                name: "Env var",
                value: "Env var",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated control missions",
        value: "control_missions",
        columns: [
            {
                name: "State",
                value: "State",
                checked: true,
            }
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated invocation schedules",
        value: "invocation_schedules",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Run at time",
                value: "Run at time",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
            
        ]

    }
]