import React, { Component, Fragment } from 'react'
import { InboundEmailAddressesDetailsModel, InboundEmailAddressesListModel, RecentMessagesModel } from './models'
import { InboundEmailAddressesService } from './services/inbound.email.addresses.service';
import { HttpResponse } from '../../../../core';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { InboundEmailAddressesTabs } from './InboundEmailAddressesTabs';
import { Editor } from '../../../../shared';
import { Link, NavLink } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: InboundEmailAddressesDetailsModel;
    isLoading?: boolean;
}

export class InboundEmailAddressesDetails extends Component<any, IState> {
    private service: InboundEmailAddressesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new InboundEmailAddressesService();
    }

    // Defines the initial state for the values coming from API.
    initialState: Partial<IState> = {
        result: {
             id:0,
             to_address:"",
             connection_name:"",
             connection_id:0,
             connection_type:"",
             created_at:new Date(),
             updated_at:new Date(),
             recent_messages:[] 

        },
        isLoading: false,
    }



    //Get the detail from the API whenever the page loads.
    componentDidMount() {
        this.loadData();
    }


    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getInboudEmailAddressesById(this.props.match.params.id)
            .then((res: HttpResponse<InboundEmailAddressesDetailsModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.InboundEmailAddresses, this.props)
            });
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Inbound Email Address '{this.state.result?.to_address}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <InboundEmailAddressesTabs id={this.props.match.params.id} url="/inbound-email-address/detail/" />
                                {!this.state.isLoading &&  <>  
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    {this.state.result?.connection_name &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Connection</b>
                                                </div>
                                                <div className="card-body">
                                               {this.state.result?.connection_type == "CustomConnection" &&   <Link to={"/custom-connection/detail/" + this.state.result.connection_id}>   {this.state.result?.connection_name}</Link> }
                                               {this.state.result?.connection_type == "Connection" && <Link to={"/connection/detail/" + this.state.result.connection_id}>   {this.state.result?.connection_name}</Link>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.to_address &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>To address</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.to_address}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.created_at &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created at</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime( this.state.result?.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.updated_at &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated At</b>
                                                </div>
                                                <div className="card-body">
                                                {ConvertDateTime( this.state.result?.updated_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <h5>Recent Messages</h5>
                                {this.state.result?.recent_messages &&
                                                <div className="table-responsive">
                                                <table className="table table table-sm table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th> Created At</th>
                                                        <th>To Address</th>
                                                        <th>Attachment Count</th> 
                                                    </tr>
                                                    </thead>
                                                    {this.state.result?.recent_messages.length != 0 && <tbody>
                                                        {this.state.result?.recent_messages.map((item: RecentMessagesModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.id}</td>
                                                                    <td>{ConvertDateTime( item.created_at, 'LLL')}</td>
                                                                    <td><Link to={"/inbound-email-message/detail/" + item.id}>{item.to_address}</Link></td>
                                                                    <td>{item.attachments_count}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                                </div>
                                    }
                                     </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}       
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
