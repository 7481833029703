import React, { Component, Fragment } from 'react'
import { EditUpdateScopesModel } from './models'
import { ScopesService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { ScopesTabs } from './ScopesTabs';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';

interface IState {
    editScopes?: EditUpdateScopesModel,
    id?: number;
    name?:string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class ScopesEditForm extends Component<any, IState> {
    private service: ScopesService;
    private NetworkCall: any;

    constructor(props) {
        super(props)
        this.service = new ScopesService();
        this.state = this.initialState;
    }

    initialState: Partial<IState> = {
        editScopes: {           
            id: 0,            
            name:""
        },        
        id: 0,        
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        name:""
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
    }

    loadData() {
        this.service.editScopes(this.props.match.params.id)
            .then((res: HttpResponse<EditUpdateScopesModel>) => {
                if (res && res.result) {
                    this.setState({                       
                        id:res.result?.id,
                        name:res.result?.name
                    }, () => {
                        
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Scopes,this.props) 
            });
    }


    updateData(scope: EditUpdateScopesModel) {
        this.setSavingFlag(true);
        this.service.updateScopes(scope)
            .then((res: HttpResponse<EditUpdateScopesModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Scope successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/scope/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/scope/scope-form/' + this.state.id+"/"+this.props.match.params.schemaName);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        let scopedata: EditUpdateScopesModel = {                        
            id: this.state.id,
            name:this.state.name
        };
        this.updateData(scopedata);
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Scope  {this.state.id && <span> '{this.state.name}'</span>}</h5>
                        </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                {this.props.match.params.id != 0 && <ScopesTabs id={this.props.match.params.id} schemaName={this.props.match.params.schemaName} name={this.state.name}  isSchema={(this.props.match.params.schemaName== SourceType.CONNECTION.toLocaleLowerCase() ||this.props.match.params.schemaName== SourceType.CUSTOM_CONNECTION.toLocaleLowerCase() ||this.props.match.params.schemaName== SourceType.EXTRACTOR.toLocaleLowerCase())?true:false} url="/scope/scope-form/" />}
                                    <div className="row mt-4">
                                        <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Name</label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" maxLength={255} name="name" value={this.state.name} className="form-control" onChange={this.handleChange}/>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                        <Link type="button" className="btn btn-lg btn-default" to="/scope/list">{ButtonType.Cancel}</Link>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                            
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }
}
