
import { Role } from "../authorization/enums";
import { GetUserInfo } from "../helpers";
export const Get_Roles = () => {
    let roles = [
        { id: 'role_default', value: '' },
        // { id: 'super_admin', value: 'Super Admin' },
        // { id: 'admin', value: 'Admin' },
        { id: 'dev', value: 'Dev' },
        { id: 'tam', value: 'TAM' },
        { id: 'am', value: 'AM' },
        { id: 'sales', value: 'Sales' },
        { id: 'support', value: 'Support' },
        { id: 'manager', value: 'Manager' }
    ]
    let roleList=GetUserInfo().role;
    let userRole = roleList!=null?roleList[0]:null;
    if (userRole === Role.SuperAdmin) {
        roles.splice(1,0,{id: 'super_admin', value: 'Super Admin'})
        roles.splice(2,0,{id: 'admin', value: 'Admin'})
        return roles;
    }
    if (userRole === Role.Admin) {
        roles.splice(1,0,{id: 'admin', value: 'Admin'})
        return roles;
    }
    return roles 
 }
    
