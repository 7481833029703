import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ReportRunScheduleListResponseModel } from "../models/report.run.schedule.list.response.model";
import { ReportRunScheduleDetailModel, ReportRunScheduleModel, EditReportRunScheduleModel } from "../models";
import { DeleteResponseModel, LinkResourcesResponseModel } from "../../../../../shared/models";

export class ReportRunScheduleService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getReportRunScheduleList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ReportRunScheduleListResponseModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }

    postReportRunSchedule(reportRunSchedule: ReportRunScheduleModel) {
        return this.wrapper.post<ReportRunScheduleModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule', reportRunSchedule);
    }

    editReportRunSchedule(id: number) {
        return this.wrapper.get<EditReportRunScheduleModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule/edit/' + id);
    }

    updateReportRunSchedule(reportRunSchedule: ReportRunScheduleModel) {
        return this.wrapper.put<ReportRunScheduleModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule/' + reportRunSchedule.id, reportRunSchedule);
    }

    getReportRunScheduleById(id: number) {
        return this.wrapper.get<ReportRunScheduleDetailModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule/' + id);
    }


    exportReportRunSchedule(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_run_schedule/export', exportData);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule/' + id + '/linked_resources');
    }

    deleteRecordById(id: number) {
        return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/report_run_schedule/' + id);
    }

}