import React, { Component, Fragment } from "react";
import { userModel, EditUserModel } from "../user/models";
import { UserList } from "../../components/user/UserList";
import { UserService } from "./services";
import { CommonService } from "../../shared/services/common.service";
import { HttpResponse } from "../../core";
import { Link, Route } from "react-router-dom";
import { DropdownItemModel } from "../../shared/models";
import {
  ShowSuccessMessage,
  ShowException,
  HandleNotFoundResponse,
} from "../../shared/helpers";
import { ButtonType } from "../../shared/enums/button.enum";
import { Loader } from "../../shared/loaders";
import { UserTabs } from "../user/UserTabs";
import { NotFoundResponseArea } from "../../shared/enums";
import { Modal } from "react-bootstrap";
import { RoleFormPopup } from "../individual/roles/RoleFormPopup";
interface IState {
  user?: userModel;
  editUser?: EditUserModel;
  id?: number;
  name?: string; 
  account_id?: number;
  billable?: boolean;
  deactivated?: boolean;
  demo?: boolean;
  needs_setup?: boolean;
  phone?: string;
  role_id?: number;
  staq_admin?: boolean;
  title?: string;
  email?: string;
  roleList?: DropdownItemModel[];
  roleName?: string;
  accountList?: DropdownItemModel[];
  AccountName?: string;
  nameError?: string;
  emailError?: string;
  accountError?: string;
  roleError?: string;
  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;
  isChildUpdate?: boolean;
  showRoleForm?: boolean;
  isNew?: boolean;
}
export class UserForm extends Component<any, IState> {
  //declaring service type
  private userService: UserService;
  private commonService: CommonService;

  private roleNetworkCall: any;
  private accountNetworkCall: any;

  constructor(props) {
    super(props);
    this.userService = new UserService();
    this.commonService = new CommonService();
    this.state = this.initialState;
    this.updateRoles = this.updateRoles.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "RoleName" && event.path[0].id !== "role" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          roleList: [],
        },
        () => {}
      );
    }
  };

  updateRoles(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      roleName: name,
      role_id: id,
    });
  }

  handleClose = () => {
    this.setState({ showRoleForm: false }, () => {});
  };

  handleShowRoles = () => {
    this.setState({ showRoleForm: true, isNew: true }, () => {});
  };

  handleShowEditRoles = () => {
    this.setState({ showRoleForm: true, isNew: false }, () => {});
  };

  loadData() {
    this.userService
      .editUser(this.props.match.params.id)
      .then((res: HttpResponse<EditUserModel>) => {
        if (res && res.result) {
          this.setState({
            AccountName: res.result?.account.text,
            account_id: res.result?.account.value,
            billable: res.result?.billable,
            deactivated: res.result?.deactivated,
            demo: res.result?.demo,
            email: res.result?.email,
            id: res.result?.id,
            name: res.result?.name,
            needs_setup: res.result?.needs_setup,
            phone: res.result?.phone,
            roleName: res.result?.role.text,
            role_id: res.result?.role.value,
            staq_admin: res.result?.staq_admin,
            title: res.result?.title,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Users, this.props);
      });
  }

  postData(user: userModel) {
    this.setSavingFlag(true);
    this.userService.postUser(user).then(
      (res: HttpResponse<userModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              user: res.result,
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("User successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/user/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/user/user-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push("/user/user-form/" + this.state.user?.id);
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  updateData(user: userModel) {
    this.setSavingFlag(true);
    this.userService.updateuser(user).then(
      (res: HttpResponse<userModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              user: res.result,
            },
            () => {
              ShowSuccessMessage("User successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/user/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/user/user-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push("/user/user-form/" + this.state.user?.id);
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  private getRoles(text: string) {
    this.commonService
      .getRolesByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            roleList: res.result,
          });
        }
      });
  }

  private getAccounts(text: string) {
    this.commonService
      .getAccountsByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            accountList: res.result,
          });
        }
      });
  }

  initialState: Partial<IState> = {
    user: {
      id: 0,
      name: "",
      account_id: 0,
      billable: false,
      deactivated: false,
      demo: false,
      needs_setup: false,
      phone: "",
      role_id: 0,
      staq_admin: false,
      title: "",
      email: "",
    },
    id: 0,
    name: "",
    account_id: 0,
    billable: false,
    deactivated: false,
    demo: false,
    needs_setup: false,
    phone: "",
    role_id: 0,
    staq_admin: false,
    title: "",
    email: "",
    roleList: [],
    roleName: "",
    AccountName: "",
    accountList: [],
    nameError: "",
    emailError: "",
    accountError: "",
    roleError: "",
    isShowtext: "New",
    isSave: "",
    isSaving: false,
    isChildUpdate: false,
    showRoleForm: false,
    isNew: false,
  };

  onRoleChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.roleNetworkCall) {
        clearTimeout(this.roleNetworkCall);
      }
      this.roleNetworkCall = setTimeout(() => {
        this.getRoles(value);
      }, 600);
    }
    this.setState(
      {
        roleName: value,
        roleError: "",
      },
      () => {
        // this.validate();
      }
    );
  };

  selectedRoles(item) {
    this.setState({
      role_id: item.value,
      roleName: item.text,
      roleList: [],
    });
  }

  renderRoles = () => {
    if (this.state.roleList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.roleList?.map((item) => (
          <li
            id="role"
            key={item.value}
            onClick={() => this.selectedRoles(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  };
  //*************** * Roles************************* //

  //*************** * Accounts************************* //

  onAccountChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.accountNetworkCall) {
        clearTimeout(this.accountNetworkCall);
      }
      this.accountNetworkCall = setTimeout(() => {
        this.getAccounts(value);
      }, 600);
    }
    this.setState(
      {
        AccountName: value,
        accountError: "",
      },
      () => {
        // this.validate();
      }
    );
  };

  selectedAccount(item) {
    this.setState({
      account_id: item.value,
      AccountName: item.text,
      accountList: [],
    });
  }

  renderAccount = () => {
    if (this.state.accountList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.accountList?.map((item, index) => (
          <li key={index} onClick={() => this.selectedAccount(item)}>
            {item.text}
          </li>
        ))}
      </ul>
    );
  };
  //*************** * Accounts************************* //

  handleChange = (event: any) => {
    if (event.target.name == "RoleName") {
      this.onRoleChange(event);
    }
    if (event.target.name == "AccountName") {
      this.onAccountChange(event);
    } else {
      const isCheckbox = event.target.type === "checkbox";
      this.setState(
        {
          [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value,
        },
        () => {
          // this.validate();
        }
      );
    }
  };

  validate = () => {
    let nameError = "";
    let emailError = "";
    let roleError = "";
    let accountError = "";
    let emailvel = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9._%+-]+\.[a-z]{2,15}/g
    ).test(this.state.email!);

    if (!this.state.name) {
      nameError = "Name can't be blank";
    }

    if (!emailvel) {
      emailError = "Email is not valid.";
    }

    if (!this.state.roleName) {
      roleError = "Role can't be blank";
    }
    if (!this.state.account_id) {
      accountError = "Account can't be blank";
    }

    if (nameError || roleError || accountError || emailError) {
      this.setState({
        nameError: nameError,
        roleError: roleError,
        accountError: accountError,
        emailError: emailError,
      });
      return false;
    } else {
      emailError = "";
      nameError = "";
      accountError = "";
      roleError = "";
      this.setState({
        nameError: nameError,
        roleError: roleError,
        accountError: accountError,
        emailError: emailError,
      });
      return true;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate)
    {
    const isValid = this.validate();
    let userdata: userModel = {
      account_id: this.state.account_id,
      billable: this.state.billable,
      deactivated: this.state.deactivated,
      demo: this.state.demo,
      needs_setup: this.state.needs_setup,
      phone: this.state.phone,
      role_id: this.state.role_id,
      staq_admin: this.state.staq_admin,
      title: this.state.title,
      email: this.state.email,
      name: this.state.name,
      id: this.state.id,
    };

    if (isValid) {
      if (userdata.id === 0) {
        this.postData(userdata);
      } else {
        this.updateData(userdata);
      }
    }
  }
  };

  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };
  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>
                {this.state.isShowtext} User{" "}
                <span>
                  {" "}
                  '{this.state.name === "" ? this.state.email : this.state.name}
                  '
                </span>
              </h4>
            </div>
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.showRoleForm}
              onHide={this.handleClose}
            >
              <RoleFormPopup
                updateRoles={this.updateRoles}
                handleClose={this.handleClose}
                isNew={this.state.isNew}
                id={this.state?.role_id}
              />
            </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.match.params.id != 0 && (
                      <UserTabs
                        id={this.props.match.params.id}
                        url="/user/user-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Name<span className=" text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            maxLength={255}
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            className={
                              !this.state.nameError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.nameError}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Email<span className=" text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            maxLength={255}
                            value={this.state.email}
                            onChange={this.handleChange}
                            disabled
                            className={
                              !this.state.emailError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.emailError}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Phone</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="phone"
                            value={this.state.phone || ""}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            name="title"
                            maxLength={255}
                            value={this.state.title || ""}
                            type="text"
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Role<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group mb-3">
                            <input
                              autoComplete="off"
                              id="RoleName"
                              name="RoleName"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.roleName}
                              className={
                                !this.state.roleError
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderRoles()}
                            <div className="input-group-append">                                                            
                            <button type="button" className="btn btn-success" onClick={this.handleShowRoles}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                            <button type="button"  className={`btn btn-primary`} disabled={this.state.role_id==0}  onClick={this.handleShowEditRoles}><i className="far fa-edit"></i></button>    
                                                            </div>
                            <div className="invalid-feedback">
                              {this.state.roleError}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Account<span className=" text-danger">*</span>
                          </label>
                          {this.state.id != 0 && (
                            <div>
                              {" "}
                              <Link
                                to={"/account/detail/" + this.state.account_id}
                                className="badge badge-light"
                              >
                                {this.state.AccountName}
                              </Link>
                            </div>
                          )}
                          {/* <div className="input-group ">
                                                        {this.state.id === 0 && <input autoComplete="off" id="AccountName" name="AccountName" type="text" disabled={this.state.id != 0} onChange={this.handleChange} value={this.state.AccountName}
                                                            className={!this.state.accountError ? 'form-control' : 'form-control  is-invalid'} />}
                                                        {this.renderAccount()}
                                                        {this.state.id === 0 && <div className="input-group-append">
                                                            <button type="button" className="btn btn-success mr-1"><i className="fas fa-plus"></i></button>
                                                            <button disabled type="button" className="btn btn-primary"><i className="far fa-edit"></i></button>
                                                        </div>}
                                                        <div className="invalid-feedback">
                                                            {this.state.accountError}
                                                        </div>
                                                    </div> */}
                          <div className="hint-text">
                            Once this is set it cannot be changed.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md">
                        <div className="form-group  shadow-sm p-3 mb-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="deactivated"
                              checked={this.state.deactivated}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="Deactivated"
                              disabled
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Deactivated"
                            >
                              Deactivated
                            </label>
                          </div>
                          <small>
                            If this is checked, the User cannot login, they wont
                            receive shared reports or dashboards or email
                            notifications
                          </small>
                        </div>
                        <div className="form-group  shadow-sm p-3 mb-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="billable"
                              checked={this.state.billable}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="Billable"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Billable"
                            >
                              Billable
                            </label>
                          </div>
                          <small>
                            If you set this flag, this user will be included in
                            the count on account management page in staqweb.
                          </small>
                        </div>
                        <div className="form-group  shadow-sm p-3 mb-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="staq_admin"
                              checked={this.state.staq_admin}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="Staqadmin"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Staqadmin"
                            >
                              Staq Admin
                            </label>
                          </div>
                          <small>
                            If you set this flag the user will be treated as a
                            STAQ admin and can bypass account restrictions
                          </small>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group  shadow-sm p-3 mb-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="needs_setup"
                              checked={this.state.needs_setup}
                              className="custom-control-input"
                              onChange={this.handleChange}
                              id="Needsetup"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Needsetup"
                            >
                              Need setup
                            </label>
                          </div>
                          <small>
                            Users will be redirected to their profile page to
                            fill-in required fields.
                          </small>
                        </div>
                        <div className="form-group  shadow-sm p-3 mb-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="demo"
                              checked={this.state.demo}
                              className="custom-control-input"
                              onChange={this.handleChange}
                              id="Demo"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Demo"
                            >
                              Demo
                            </label>
                          </div>
                          <small>Select it for Demo</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mt-3 mb-4">
              <Link
                type="button"
                className="btn btn-lg btn-default"
                to="/user/list"
              >
                {ButtonType.Cancel}
              </Link>
              {!this.state.isSaving && (
                <Fragment>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  {/* <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} /> */}
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="right"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
        <Route path="/user/list" component={UserList} />
      </Fragment>
    );
  }
}
