import React, { Component, Fragment } from "react";
import { SubscriptionsService } from "./services/subscriptions.service";
import { EditSubscriptionsModel } from "./models/edit.subscriptions.model";
import { HttpResponse } from "../../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../../shared/helpers";
import {
  NotFoundResponseArea,
  ButtonType,
} from "../../../../shared/enums";
import { PostSubscriptionsModel } from "./models";
import { SubscriptionsTabs } from "./SubscriptionsTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../shared/loaders";
import { DropdownItemModel } from "../../../../shared/models";
import { CommonService } from "../../../../shared/services/common.service";
import { Modal } from "react-bootstrap";
import { SubscriptionCategoryFormPopup } from "../subscription-categories/SubscriptionCategoryFormPopup";
import {EmailTemplatesFormPopup} from '../../external-roles-communications/email-templates'

interface IState {
  id?: number;
  name?: string;
  visible?: boolean;
  description?: string;
  subscription_category_id?: number;
  email_template_id?: number;
  category?: DropdownItemModel;
  email_template?: DropdownItemModel 
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  name_error?: string;
  category_error?:string;
  email_template_error?: string;
  description_error?: string;
  category_list?: DropdownItemModel[];
  email_template_list?: DropdownItemModel[];
  category_name?: string;
  email_template_name?: string;
  isChildUpdate?: boolean;
  showCategoryForm?: boolean;
  showEmailTemplateForm?: boolean;
  isNew?: boolean;
}

export class SubscriptionsForm extends Component<any, IState> {
  private service: SubscriptionsService;
  private commonService: CommonService;
  private networkCall: any;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new SubscriptionsService();
    this.commonService = new CommonService();
    this.updateCategories = this.updateCategories.bind(this);
    this.updateEmailTemplate = this.updateEmailTemplate.bind(this);
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    name: "",
    visible: true,
    description: "",
    subscription_category_id: 0,
    email_template_id: 0,
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    name_error:"",
    category_error: "",
    email_template_error: "",
    description_error: "",
    category_list: [],
    email_template_list: [],
    category_name: "",
    email_template_name: "",
    isChildUpdate: false,
    showCategoryForm: false,
    showEmailTemplateForm:false,
    isNew: false,
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (Number(this.props.match.params.id) > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "Category_name" && event.path[0].id !== "category" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          category_list: [],
        },
        () => {}
      );
    }
    if (event.path[0].id !== "email_template" && event.path[0].id !== "email" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          email_template_list: [],
        },
        () => {}
      );
    }
  };

  updateCategories(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      category_name: name,
      subscription_category_id: id,
    });
  }

  handleClose = () => {
    this.setState({ showCategoryForm: false }, () => {});
  };

  handleShowCategory = () => {
    this.setState({ showCategoryForm: true, isNew: true }, () => {});
  };

  handleShowEditCategory = () => {
    this.setState({ showCategoryForm: true, isNew: false }, () => {});
  };


  updateEmailTemplate(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      email_template_name: name,
      email_template_id: id,
    });
  }

  handleCloseEmailTemplate = () => {
    this.setState({ showEmailTemplateForm: false }, () => {});
  };

  handleShowEmailTemplate = () => {
    this.setState({ showEmailTemplateForm: true, isNew: true }, () => {});
  };

  handleShowEditEmailTemplate = () => {
    this.setState({ showEmailTemplateForm: true, isNew: false }, () => {});
  };

  private getCategoriesByName(text: string) {
    this.commonService
      .getCategoriesByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            category_list: res.result,
          });
        }
      });
  }

  private getEmailTemplatesByName(text: string) {
    this.commonService
      .getEmailTemplatesByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            email_template_list: res.result,
          });
        }
      });
  }


  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editSubscription(this.props.match.params.id)
      .then((res: HttpResponse<EditSubscriptionsModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            name:
              res.result?.name === null ? "": res.result?.name,
              description:
              res.result?.description === null ? "" : res.result?.description,
              category_name: res.result?.category === null ? "" : res.result?.category?.text,
              email_template_name: res.result?.email_template === null ? "" : res.result?.email_template?.text,
              visible:
              res.result?.visible === null ? true : res.result?.visible,
              subscription_category_id:
              res.result?.subscription_category_id === null ? 0 : res.result?.subscription_category_id,
              email_template_id:
              res.result?.email_template_id === null ? 0 : res.result?.email_template_id,
              category:res.result?.category,
              email_template:res.result?.email_template,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.Subscriptions,
          this.props
        );
      });
  }

  validate = () => {
    let name_error = "";
    let category_error = ""
    let email_template_error = ""
    let description_error = ""
    if (!this.state.name && this.state.isSave) {
      name_error = "Name Cant be blank";
    }
    if(this.state.subscription_category_id == 0 && this.state.isSave){
      category_error = "Category Cant be blank"
    }
    if(this.state.email_template_id == 0 && this.state.isSave){
      email_template_error = "Email template Cant be blank"
    }
    if(!this.state.description && this.state.isSave){
      description_error = "Description Cant be blank"
    }
    if (name_error || category_error ||email_template_error||description_error) {
      this.setState({
        name_error: name_error,
        category_error: category_error,
        email_template_error: email_template_error,
        description_error:description_error
      });
      return false;
    } else {
      name_error = "";
      category_error=""
      email_template_error=""
      description_error = ""
      this.setState({
        name_error: name_error,
        category_error: category_error,
        email_template_error: email_template_error,
        description_error:description_error
      });
      return true;
    }
  };

  onCategoryChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getCategoriesByName(value);
      }, 600);
    }
    this.setState({
      subscription_category_id: value === "" ? 0 : value,
      category_name: value,
      category_error: "",
    });
  };

  onEmailTemplateChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getEmailTemplatesByName(value);
      }, 600);
    }
    this.setState({
      email_template_id: value === "" ? 0 : value,
      email_template_name: value,
      email_template_error: "",
    });
  };

  handleChange = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    if (event.target.name == "Category_name") {
      this.onCategoryChange(event);
    }
    if (event.target.name == "email_template") {
      this.onEmailTemplateChange(event);
    }
    this.setState(
      {
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      },
      () => {
        this.validate();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate){
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      name: this.state.name,
      visible: this.state.visible,
      subscription_category_id: this.state.subscription_category_id,
      email_template_id: this.state.email_template_id,
      description: this.state.description,
    };
    if (isValid) {
      if (payload.id === 0) {
        this.postData(payload);
      } else {
        this.updateData(payload);
      }
    }
  }
  };

  postData(subscription: PostSubscriptionsModel) {
    this.setSavingFlag(true);
    this.service.postSubscription(subscription).then(
      (res: HttpResponse<PostSubscriptionsModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Subscription successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/subscription/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/subscription/subscription-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/subscription/subscription-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(subscription: PostSubscriptionsModel) {
    this.setSavingFlag(true);
    this.service.updateSubscription(subscription).then(
      (res: HttpResponse<PostSubscriptionsModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Subscriptions successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/subscription/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/subscription/subscription-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/subscription/subscription-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  selectedCategory(item) {
    this.setState({
      subscription_category_id: item.value,
      category_name: item.text,
      category_list: [],
    });
  }
  selectedEmailTemplate(item) {
    this.setState({
      email_template_id: item.value,
      email_template_name: item.text,
      email_template_list: [],
    });
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value, isChildUpdate: false });
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  renderCategory() {
    if (this.state.category_list?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.category_list?.map((item, index) => (
          <li
            id="category"
            key={index}
            onClick={() => this.selectedCategory(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  }

  renderEmailTemplates() {
    if (this.state.email_template_list?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.email_template_list?.map((item, index) => (
          <li
            id="email"
            key={index}
            onClick={() => this.selectedEmailTemplate(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Subscription
                {this.state.isShowtext === "Edit" && (
                  <span> '{this.state.name}'</span>
                )}
              </h5>
            </div>
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.showCategoryForm}
              onHide={this.handleClose}
            >
              <SubscriptionCategoryFormPopup
                updateCategories={this.updateCategories}
                handleClose={this.handleClose}
                isNew={this.state.isNew}
                id={this.state?.subscription_category_id}
              />
            </Modal>

            <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showEmailTemplateForm} onHide={this.handleCloseEmailTemplate}>
                            <EmailTemplatesFormPopup  updateEmailTemplate={this.updateEmailTemplate}  handleClose={this.handleCloseEmailTemplate} isNew={this.state.isNew} id={this.state?.email_template_id}/>
                        </Modal>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <SubscriptionsTabs
                        id={this.props.match.params.id}
                        url="/subscription/subscription-form/"
                      />
                    )}
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                          <label>Name<span className=" text-danger">*</span></label>
                          <div className="input-group ">
                          <input
                            type="text"
                            maxLength={255}
                            name="name"
                            className={
                              !this.state.name_error
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                              {this.state.name_error}
                            </div>
                          </div>
                          <small>This value is used in our code to determine if a user is subscribed to receive an email notification.</small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Subscription Category<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              autoComplete="off"
                              id="Category_name"
                              name="Category_name"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.category_name}
                              className={
                                !this.state.category_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderCategory()}
                            <div className="input-group-append">
                              <button className="btn btn-success" onClick={this.handleShowCategory} type="button">
                                <i
                                  className="fas fa-plus"
                                  style={{ color: "white" }}
                                ></i>
                              </button>
                              <button
                                className={`btn btn-primary ${
                                  this.state.subscription_category_id == 0 || null
                                    ? "disabled"
                                    : ""
                                }`}
                                type="button"
                                onClick={this.handleShowEditCategory}
                                disabled={this.state.subscription_category_id == 0}
                              >
                                <i className="far fa-edit"></i>
                              </button>
                            </div>
                            <div className="invalid-feedback">
                              {this.state.category_error}
                            </div>
                          </div>
                          <small>The category is only used to group the available subscriptions on the account notification settings page</small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Email Template<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              list="email_template"
                              autoComplete="off"
                              id="email_template"
                              name="email_template"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.email_template_name}
                              className={
                                !this.state.email_template_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderEmailTemplates()}
                            <div className="input-group-append">
                            <button type="button" className="btn btn-success" onClick={this.handleShowEmailTemplate}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                            <button type="button"  className={`btn btn-primary `} disabled={this.state.email_template_id==0}  onClick={this.handleShowEditEmailTemplate}><i className="far fa-edit"></i></button>
                            </div>
                            <div className="invalid-feedback">
                              {this.state.email_template_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div  className="col-md-4">
                      <div className="form-group shadow-sm p-3 mb-4 ">
                      <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="visible"
                            checked={this.state.visible}
                            onChange={this.handleChange}
                            className="custom-control-input"
                            id="visible"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="visible"
                          >
                            Visible
                          </label>
                          </div>
                          <small>Should this subscription be visible to users on account notifiation settings page?</small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Description<span className=" text-danger">*</span></label>
                          <div className="input-group ">
                          <input
                            type="text"
                            maxLength={255}
                            name="description"
                            className={
                              !this.state.description_error
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                            value={this.state.description}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                              {this.state.description_error}
                            </div>
                          </div>
                          <small>This value is displayed in web on the account notification settings page</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/subscription/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  {!this.state.isSaving && (
                    <Fragment>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
