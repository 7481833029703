import React, { Component } from 'react'
import { IFilterItemProps, OperatorList, FilterItemInputRenderType } from './models'
import { JobType } from '../enums';


interface IState {
    id: string,
    op: string,
    value: string
}
export class FilterItemSchemasType extends Component<IFilterItemProps, IState> {
    constructor(props) {
        super(props);
    }

    oprators = OperatorList.schemasType;
    inputRenderType: any = FilterItemInputRenderType.NoBox;

    selectedOptionValue = this.oprators[0].operator;
    selectedValue = '';

    handleDropDownChange = (event: any) => {
        let selectedObject = this.oprators.find(x => x.name == event.target.value);
        this.selectedOptionValue = selectedObject != null ? selectedObject.operator : "";
        this.inputRenderType = selectedObject?.renderType;
        if (this.selectedOptionValue == 'is' || this.selectedOptionValue == 'isnot') {
            this.selectedValue = 'null';
        }
        if (this.selectedOptionValue === 'eq') {
            switch (selectedObject?.name) {
                case "Extractor":
                    this.selectedValue = "1"
                    break;
                case "Report":
                    this.selectedValue = "2"
                    break;
                case "Custom Connection":
                    this.selectedValue = "3"
                    break;
            }

        }
        this.brodCastValue();        
    }

    brodCastValue = () => {
        this.setState({ id: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
        this.props.onValueChange({ column: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
    }
    render() {
        return (
            <div className="form-group mb-3">
                <label>{this.props.column.displayText}</label>
                <div className="row">
                    <div className="col-5">
                        <select className="form-control form-control-sm mb-1" name={this.props.column.columnName} onChange={this.handleDropDownChange}>
                            {this.oprators.map((num, index) => {
                                return (
                                    <option key={index} value={num.name} className='form-control'>{num.name}</option>
                                );
                            })}

                        </select>
                    </div>
                </div>
            </div>
        )
    }
}
