import React, { Component, Fragment } from 'react'
import { DeleteResponseModel } from '../../../shared/models'
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, ConvertDateTime, HandleNotFoundResponse } from '../../../shared/helpers';
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from '../../../shared/enums';
import { CustomConnectionsService } from './services/custom.connection.service';
import { LinkResourcesResponseModel, LinkReportsListModel } from '../connections/models'
import { CustomConnectionTabs } from './CustomConnectionTabs';
import { DeleteCustomConnectionModel } from './models';


interface IState {
    result?: LinkResourcesResponseModel;
    deleteResult?: DeleteResponseModel;
    isDoubleleClick?: boolean;
    buttonText?:string;
    isShowButton?:boolean;
    isRecords?:boolean;
    isShowCommonText?:boolean;
}
export  class DeleteCustomConnection extends Component<any, IState> {
    private service: CustomConnectionsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new CustomConnectionsService();
        this.deleteRecord = this.deleteRecord.bind(this);
    } 

    initialState: Partial<IState> = {
        result: {
            id: 0,
            name: "",
            deleted:false,
            reports:[] 
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false,
        buttonText:"",
        isShowButton:true,
        isRecords:false,
        isShowCommonText:false

    }
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<LinkResourcesResponseModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        buttonText:"Delete Custom Connection "+ this.props.match.params.id+ " ("+res.result.name+")",  
                        isRecords:res.result.reports.length==0?false:true ,
                        isShowCommonText:res.result.deleted?false:true                     
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,this.props)
            });
    }


   

    deleteRecord() {        
        if (!this.state.isDoubleleClick) {           
            this.setState({ isDoubleleClick: true,buttonText:"deleting..." })
            let data: DeleteCustomConnectionModel={
                id:this.props.match.params.id,
                name:this.state.result?.name,
                deleted:true
            };
            this.service.deleteRecordById(data,this.props.match.params.id)
                .then((res: HttpResponse<DeleteResponseModel>) => {
                    if (res && res.result) {                         
                        this.setState({                            
                            buttonText:"",
                            isShowButton:false
                        }, () => {                            
                            ShowSuccessMessage("Custom Connection "+ this.state.result?.name +" has been marked for deletion. It may take several minutes to remove from our system.");
                            this.props.history.push('/custom-connection/list');                           
                        });
                    }
                });
 
        }
    }
    render() {
        return (
            <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-2">
                {this.state.result?.name &&<h5>Delete Custom Connection '{this.state.result?.name}'</h5>}
            </div>
            {!this.state.result?.deleted &&<div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <CustomConnectionTabs id={this.props.match.params.id} url="/custom-connection/delete/" />
      {this.state?.isShowCommonText &&  <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> Custom Connections aren't deleted immediately. It can take several minutes to remove a custom connection.</p>}

                        </div>
                    </div>
                   {this.state.isShowButton && !this.state.isRecords &&  this.state.buttonText &&   <div className="row mb-4">
                        <div className="mx-auto">
                          <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick}onClick={() => {if(window.confirm('Are you sure to delete this record?')){this.deleteRecord()};}}>{this.state.buttonText}</button>
                            
                        </div>
                    </div>}


                    {this.state.result?.reports.length != 0 && <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <p>The following reports depend on Custom Connection {this.props.match.params.id} ({this.state.result?.name}). You first must remove this custom connection before deleting</p>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Report</th>
                                                        <th scope="col">Last Generated At</th>
                                                        <th scope="col">Row Count</th>
                                                        <th scope="col">Earliest Data</th>
                                                        <th scope="col">Latest Data</th>
                                                        <th scope="col">Number of Dependents</th>
                                                    </tr>
                                                </thead>
                                              <tbody>
                                                    {this.state.result?.reports.map((item: LinkReportsListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                 <td> <NavLink to={"/report/detail/" + item.id}><span className="sortlength" title={item.name}> {item.name}</span></NavLink></td>
                                                                <td>{ConvertDateTime(item.last_generated_at, 'LLL')}</td>
                                                                <td>{item.row_count}</td>
                                                                <td>{ConvertDateTime(item.earliest_data, 'LLL')}</td>
                                                                <td>{ConvertDateTime(item.latest_data, 'LLL')}</td>
                                                                <td>{item.number_of_dependents}</td>                                                                
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                </div>

            </div>}

          {this.state.result?.deleted && <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                        <CustomConnectionTabs id={this.props.match.params.id} url="/custom-connection/delete/" />
<p>Connection {this.props.match.params.id} ({this.state.result?.name}) has already been marked for deletion. It should be removed soon.</p>
                            </div>
                            </div>
                            </div>
                            </div>}
            
        </Fragment>
        )
    }
}
