import React, { Component, Fragment } from 'react'
import { ConfigurationsService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../shared/enums';
import { HttpResponse } from '../../../core';
import { NavLink } from "react-router-dom";

import { ConfigurationsDetailModel } from './models';
import { ConfigurationsTabs } from './ConfigurationsTabs';
import { DropdownItemModel } from '../../../shared/models';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: ConfigurationsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ConfigurationsDetail extends Component<any, IState> {
    private service: ConfigurationsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ConfigurationsService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,
            name: "",
            value: ""
        },
        isLoading: false,
    }

    

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getConfigurationsById(this.props.match.params.id)
            .then((res: HttpResponse<ConfigurationsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Configurations,this.props)
            });
    }
    
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Configuration &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <ConfigurationsTabs id={this.props.match.params.id} url="/configuration/detail/" /> 
                        {!this.state.isLoading &&  <>                                
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                                    {this.state.result?.id && <div className="col-md-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Id</b>
                                            </div>
                                            <div className="card-body">{this.state.result?.id}</div>
                                        </div>
                                    </div>}
                            {this.state.result?.name && <div className="col-md-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.name} 
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.value && <div className="col-md-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Value</b>
                                            </div>
                                            <div className="card-body">
                                           {this.state.result?.value}
                                        </div></div>
                                    </div>}                             
                             
                                   
                                 
                            </div>                          
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 