import React, { Component, Fragment } from 'react'
import { PlanCategoriesService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { PlanCategoriesDetailModel } from './models';
import { PlanCategoriesTabs } from './PlanCategoriesTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: PlanCategoriesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class PlanCategoriesDetail extends Component<any, IState> {
    private service: PlanCategoriesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new PlanCategoriesService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,
            label: "",
            order: 0,
            free: "",
            basic: "",
            professional: "",
            standard: "",
            enterprise: "",
            usage: true,
            visible: true,
            name:"",
            descriptions: "",
            created_at: new Date(),
            updated_at: new Date()
        },
        isLoading: false,
    }

    

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getPlanCategoriesById(this.props.match.params.id)
            .then((res: HttpResponse<PlanCategoriesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.PlanCategories,this.props)
            });
    }
    
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Plan Category &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <PlanCategoriesTabs id={this.props.match.params.id} url="/plan-category/detail/" />     
                        {!this.state.isLoading &&  <>                           
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.name && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.name} 
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.label && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Label</b>
                                            </div>
                                            <div className="card-body">
                                             {this.state.result?.label}
                                        </div></div>
                                    </div>}                             
                             
                                   
                                    {this.state.result?.descriptions && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Description</b>
                                            </div>
                                            <div className="card-body">{this.state.result?.descriptions}</div>
                                        </div>
                                    </div>}
                                     <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Order</b>
                                            </div>
                                            <div className="card-body">{this.state.result?.order}</div>
                                        </div>
                                    </div>
                                                           
                            {this.state.result?.free && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Free</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.free} 
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.basic && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Basic</b>
                                            </div>
                                            <div className="card-body">
                                             {this.state.result?.basic}
                                        </div></div>
                                    </div>}                             
                             
                                   
                                    {this.state.result?.professional && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Professional</b>
                                            </div>
                                            <div className="card-body">{this.state.result?.professional}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.standard && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Standard</b>
                                            </div>
                                            <div className="card-body">{this.state.result?.standard}</div>
                                        </div>
                                    </div>}
                                                                  
                            {this.state.result?.enterprise && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Enterprise</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.enterprise} 
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.usage && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>usage</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.usage && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                            {!this.state.result?.usage && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} 
                                        </div></div>
                                    </div>}                             
                             
                                   
                                    {this.state.result?.visible && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Visible</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.visible && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                            {!this.state.result?.visible && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} 
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.created_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                                              
                                             
                                    {this.state.result?.updated_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>                          
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 