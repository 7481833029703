import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { FieldClassificationListResponseModel, FieldClassificationDetailModel, AddUpdateFieldClassificationModel } from "../models";



export class FieldClassificationService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getFieldClassificationList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<FieldClassificationListResponseModel>(AppConfig.apiEndpoint + '/v1/field_classifications/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getFieldClassificationById(id: number) {
    return this.wrapper.get<FieldClassificationDetailModel>(AppConfig.apiEndpoint + '/v1/field_classifications/' + id);
  }  

  postFieldClassification(fieldClassification: AddUpdateFieldClassificationModel) {
    return this.wrapper.post<AddUpdateFieldClassificationModel>(AppConfig.apiEndpoint + '/v1/field_classifications', fieldClassification);
}

editFieldClassification(id?: number) {
    return this.wrapper.get<AddUpdateFieldClassificationModel>(AppConfig.apiEndpoint + '/v1/field_classifications/edit/' + id);
}

updateFieldClassification(fieldClassification: AddUpdateFieldClassificationModel) {
    return this.wrapper.put<AddUpdateFieldClassificationModel>(AppConfig.apiEndpoint + '/v1/field_classifications/' + fieldClassification.id, fieldClassification);
}

  exportFieldClassification(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/field_classifications/export', exportData);
  }


} 