import React, { Component, Fragment } from 'react'
import { ReportColumnFiltersDetailModel } from './models'
import { ReportColumnFiltersService } from './services';
import { HttpResponse } from '../../../../../core';
import { NavLink } from "react-router-dom";
import { ReportColumnFiltersTabs } from './ReportColumnFiltersTabs'
import { HandleNotFoundResponse } from '../../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../../shared/enums';
import { Loader } from '../../../../../shared/loaders';

interface IState {
    result?: ReportColumnFiltersDetailModel;
    isLoading?: boolean;
}
export class ReportColumnFiltersDetail extends Component<any, IState> {
    private service: ReportColumnFiltersService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportColumnFiltersService();
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            display_order: 0,
            report_column: {
                text: "",
                value: 0
            },
            report_column_id: 0,
            report_filter_id: 0,
            report_filter: {
                comparator: "",
                filter_type: "",
                id: 0,
                logical_operator: "",
                operator: "",
                values: []
            }

        },
        isLoading: false,

    }
    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getReportColumnFiltersById(this.props.match.params.id)
            .then((res: HttpResponse<ReportColumnFiltersDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    }, () => {
                      
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportColumnFilters,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for Report Column Filter 'ReportColumnFilter #{this.state.result?.id}'</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportColumnFiltersTabs id={this.props.match.params.id} url="/report-column-filter/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Report Column </div>
                                                {this.state.result?.report_column && <div className="card-body">  <NavLink to={"/report-column/detail/" + this.state.result?.report_column.value}>{this.state.result?.report_column.text}</NavLink></div>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Filter Type </div>
                                                <div className="card-body">  {this.state.result?.report_filter.filter_type}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Comparator </div>
                                                <div className="card-body"> {this.state.result?.report_filter.comparator}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Operator </div>
                                                <div className="card-body"> {this.state.result?.report_filter.operator}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Logical Operator </div>
                                                <div className="card-body"> {this.state.result?.report_filter.logical_operator}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Display Order </div>
                                                <div className="card-body"> {this.state.result?.display_order}</div>
                                            </div>
                                        </div>
                                    {this.state.result?.report_filter.values.length != 0 && 
                                        <div className="col-md-4">
                                             <div className="card card-silver shadow-sm">
                                             <div className="card-header font-weight-bold"> Values</div>
                                             <div className="card-body">
                                                    {this.state.result?.report_filter.values.map((item: any) => {
                                                        return (
                                                            <span  key={item.value}>
                                                                    ["{item}"]
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
