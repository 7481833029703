import React, { Component, Fragment } from 'react'
import { EditUpdateFieldsModel } from './models'
import { FieldsService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { FieldsTabs } from './FieldsTabs';
import { NotFoundResponseArea, FieldColumnType, SourceType } from '../../../../shared/enums';
import { DropdownItemModel } from '../../../../shared/models';
import { Editor } from '../../../../shared';
import { CommonService } from '../../../../shared/services/common.service';
import { ValidateYaml } from '../../../../shared/helpers/yaml.helper';
import { Modal } from 'react-bootstrap';
import { FieldClassificationFormPopup } from '../field-classification';

interface IState {
    showFieldClassificationForm?: boolean;
    editFields?: EditUpdateFieldsModel,    
    id?: number;  
    scope_id?: number;
    scope?:DropdownItemModel;      
    name?: string; 
    custom_load_sql?:string;
    exclude_from_load?:boolean;  
    field_classification?:DropdownItemModel;
    label?:string;
    type?:string;
    column_type?:string;
    is_unique_key?:boolean;
    scaling_factor?:number;    
    options?:JSON |null;        
    parsedData?:string;
    format?:string;

    field_classification_id?:number;
    field_classification_name?:string;
    classificationList?: DropdownItemModel[];

    optionsError?: string;

    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;

    isNew?:boolean;
    isChildUpdate?:boolean;
}
export class FieldsEditForm extends Component<any, IState> {
    private service: FieldsService;
    private commonService: CommonService;
    private NetworkCall: any;

    constructor(props) {
        super(props)
        this.service = new FieldsService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.editorhandleOptions = this.editorhandleOptions.bind(this);
        this.updateFieldClassification = this.updateFieldClassification.bind(this);
    }

    initialState: Partial<IState> = {
        editFields: {           
            id: 0,            
            name:""
        },        
        id: 0,        
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        optionsError: "",
        scaling_factor:1.0,
        field_classification_id:0,
        field_classification_name:"",
        classificationList:[],
        column_type:FieldColumnType.dimension,
        custom_load_sql:"",
        exclude_from_load:false,
        field_classification:{
          text:"",
          value:0
        },
        format:"",
        is_unique_key:false,
        label:"",
        name:"",
        options:JSON,
        scope:{
          text:"",
          value:0
        },
        scope_id:0,
        type:"",
        parsedData:"",

        showFieldClassificationForm: false,
        isNew:false,
        isChildUpdate:false
    }


    updateFieldClassification(isChildUpdate,name,id)
    {
      this.setState({isChildUpdate:isChildUpdate,field_classification_name:name,field_classification_id:id})
    }

    handleClose = () => {
        this.setState({ showFieldClassificationForm: false }, () => {
        });
    }      

    handleShowFieldClassification = () => {        
        this.setState({ showFieldClassificationForm: true, isNew:true }, () => {

        });
    }

    handleShowEditFieldClassification = () => {        
        this.setState({ showFieldClassificationForm: true, isNew:false }, () => {

        });
    }


    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.path[0].id !== 'field_classification_name' && event.path[0].id !== 'classification' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                classificationList: []
            }, () => {

            })

        }
    }

    loadData() {
        this.service.editFields(this.props.match.params.id)
            .then((res: HttpResponse<EditUpdateFieldsModel>) => {
                if (res && res.result) {                  
                    this.setState({                       
                        id:res.result?.id,
                        name:res.result?.name,
                        column_type:res.result?.column_type,
                        custom_load_sql:res.result?.custom_load_sql,
                        exclude_from_load:res.result?.exclude_from_load,
                        field_classification:res.result?.field_classification,
                        field_classification_id:res.result?.field_classification==null?0:res.result?.field_classification.value,
                        field_classification_name:res.result?.field_classification?.text,                        
                        is_unique_key:res.result?.is_unique_key,
                        label:res.result?.label,
                        options:res.result?.options,                       
                        scaling_factor:res.result?.scaling_factor,
                        scope:res.result?.scope,
                        scope_id:res.result?.scope_id,
                        type:res.result?.type,
                        format:res.result?.format
                    }, () => {
                        this.setState({
                            parsedData: JSON.stringify(this.state.options, null, '\t')
                            }); 
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Fields,this.props) 
            });
    }

    private getClassifications(text: string) {
        this.commonService.getClassificationByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        classificationList: res.result
                    });

                }
            });

    }



//*************** * Field classification ************************* //

    onClassificationChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.NetworkCall) {
                clearTimeout(this.NetworkCall);
            }
            this.NetworkCall = setTimeout(() => {
                this.getClassifications(value)
            }, 600)
        }
        this.setState({
            field_classification_name: value            
        }, () => {
            // this.validate();
        })


    }



    selectedClassification(item) {
        this.setState({
            field_classification_id: item.value,
            field_classification_name: item.text,
            classificationList: []
        });
    }

    renderClassification = () => {
        if (this.state.classificationList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.classificationList?.map(item => (<li id="classification" key={item.value} onClick={() => this.selectedClassification(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** * Field classification ************************* //

    updateData(scope: EditUpdateFieldsModel) {
        this.setSavingFlag(true);
        this.service.updateFields(scope)
            .then((res: HttpResponse<EditUpdateFieldsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Field successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/field/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/field/field-form/' + this.state.id+"/"+this.props.match.params.schemaName+"/"+this.props.match.params.scopeId);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    handleChange = (event: any) => {
        if (event.target.name == "field_classification_name") {
            this.onClassificationChange(event);
        }
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        if(!this.state.isChildUpdate)
        { 
        const isValid = this.isJson(this.state.parsedData);
        let fielddata: EditUpdateFieldsModel = {                        
            id: this.state.id,            
            custom_load_sql:this.state.custom_load_sql,
            exclude_from_load:this.state.exclude_from_load,
            field_classification_id:this.state.field_classification_id==0?null:this.state.field_classification_id,
            label:this.state.label,
            column_type:this.state.column_type,
            scaling_factor:this.state.scaling_factor,
            format:this.state.format,
            options: JSON.parse(JSON.stringify(this.state.parsedData))
        };        
        if (isValid) {
        this.updateData(fielddata);
        }
    }
    }

     isJson(str) {
        try {            
            JSON.parse(str);
            this.setState({ optionsError: "" });
           return true;
        } catch (e) {
            this.setState({
                optionsError: "Options is not valid please type valid JSON",
              });
              return false;
        }        
    }

    editorhandleOptions(data){        
        this.setState({
            parsedData: data,
        },()=>{
            this.isJson(data)
        });
      }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value,isChildUpdate:false })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }
    

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Field  {this.state.id && <span> '{this.state.name}'</span>}</h5>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showFieldClassificationForm} onHide={this.handleClose}>
                            <FieldClassificationFormPopup  updateFieldClassification={this.updateFieldClassification}  handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.field_classification_id}/>
                        </Modal>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">                                
                                {this.props.match.params.id != 0 && <FieldsTabs id={this.props.match.params.id} schemaName={this.props.match.params.schemaName}  scopeId={this.props.match.params.scopeId} isSchema={(this.props.match.params.schemaName== SourceType.CONNECTION.toLocaleLowerCase() ||this.props.match.params.schemaName== SourceType.CUSTOM_CONNECTION.toLocaleLowerCase())?true:false} url="/field/field-form/" />}
                                    <div className="row mt-4">
                                        <div className="col-md-4">
                        <div className="form-group mb-2 position-relative">
                          <label>
                          Scope<span className=" text-danger">*</span>
                          </label>                          
                          {this.props.match.params.id != 0 &&  <div>                              
                              <Link
                                to={"/scope/detail/" + this.state.scope?.value}
                                className="badge badge-light"
                              >
                                {this.state.scope?.text}
                              </Link>
                          </div>}                          
                          </div>
                          </div>

                                        <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                            <label>Name</label>
                                             <div>
                                                  {this.state.name}<br/>
                                                  <small>Please change this with Schema Manager</small> 
                                                  </div>
                                            
                                            </div>  
                                                                             
                                        </div>
                                        <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Custom Load Sql</label>
                          <input
                            type="text"
                            name="custom_load_sql"
                            value={this.state.custom_load_sql}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          <small>If you change this, it will break things. Talk to John.</small>
                          </div>
                      </div> 
                                       
                                    </div>
                                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="exclude_from_load"
                              checked={this.state.exclude_from_load}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="exclude_from_load"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="exclude_from_load"
                            >
                             Exclude From Load
                            </label>
                          </div>
                        </div>
                      </div>
                     

                      <div className="col-md-4 ">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <label>
                          Field Classification
                            <span className=" text-danger"></span>
                          </label>
                          <div className="input-group ">
                            <input
                              autoComplete="off"
                              id="field_classification_name"
                              name="field_classification_name"
                              type="text"
                              onChange={this.handleChange}
                              value={
                                this.state.field_classification_name
                              }
                              className="form-control"
                            />
                           {this.renderClassification()}
                                                        <div className="input-group-append">
                                                        <button type="button" className="btn btn-success" onClick={this.handleShowFieldClassification}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                        <button type="button"  className={`btn btn-primary `} disabled={this.state.field_classification_id==0}  onClick={this.handleShowEditFieldClassification}><i className="far fa-edit"></i></button>
                                                        </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Label</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="label"
                            value={this.state.label}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          <small>Please change this with Schema Manager</small>
                        </div>
                      </div>
                    </div>


                    <div className="row mt-4">
                                            <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                            <label>Type</label>
                                            <div> {this.state.type}</div>
                                            </div>                                    
                                        </div>
                                        

                                        <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                        <label>Column Type <span className=" text-danger">*</span></label>
                          <select
                            className="form-control"
                            name="column_type"
                            value={this.state.column_type}
                            onChange={this.handleChange}                          >
                             <option value={FieldColumnType.dimension}>{FieldColumnType.dimension}</option>
                             <option value={FieldColumnType.metric}>{FieldColumnType.metric}</option>
                          </select>                       
                        </div>
                      </div>

                                        <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Scaling Factor</label>
                          <input
                            type="text"
                            name="scaling_factor"
                            value={this.state.scaling_factor}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          <small>Field value is automatically multipled by this number. Useful for percentages expressed as numbers less than 1 or currencies expressed in pennies rather than dollars. See <a href='https://www.pivotaltracker.com/story/show/90104312'>#90104312</a></small>
                          </div>
                      </div> 
                                       
                                    </div>

                                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group shadow-sm p-3 mb-0">
                          <h6>Options </h6>
                          <Editor
                            StringData={this.state.parsedData}
                            FormateType="json"
                            onChange={this.editorhandleOptions}
                          />
                          {this.state.optionsError != "" && (
                            <div style={{ color: "red" }}>
                              {this.state.optionsError}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Format</label>
                          <input
                            type="text"
                            name="format"
                            value={this.state.format}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          <small>
                          Specify a date or time format string, and it will be used to create time or date objects more efficiently and process data more quickly. See <a href='http://ruby-doc.org/core-2.2.0/Time.html#method-i-strftime'>strftime</a> for details.
                          </small>
                          </div>
                      </div> 
                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                        <Link type="button" className="btn btn-lg btn-default" to="/field/list">{ButtonType.Cancel}</Link>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                            
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }
}
