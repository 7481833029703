import React, { Component, Fragment } from 'react';

import { FeatureFlagsService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { FeatureFlagsTabs } from './FeatureFlagsTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { AddUpdateFeatureFlagsModel } from './models';
import { DropdownItemModel, FlaggleItems } from '../../../../shared/models';
import { CommonService } from '../../../../shared/services/common.service';
import { OverlayTrigger, Tooltip, Popover, Button } from 'react-bootstrap';

interface IState {
    featureFlags?: AddUpdateFeatureFlagsModel;
    // editFeatureFlags?: AddUpdateFeatureFlagsModel;
    id?: number;
    name?: string;
    flaggable_type?: string;
    flaggable_id?: number;
    nameError?: string;
    flaggableError?:string;
    flaggableTypeError?: string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    flaggableObjectType?:FlaggleItems;

}
export class FeatureFlagsForm extends Component<any, IState> {
    private service: FeatureFlagsService;
     commonService: CommonService;
    constructor(props) {
        super(props)
        this.service = new FeatureFlagsService();
        this.commonService = new CommonService();
        this.state = this.initialState;
      this.getAllFlaggableObjetcs()
    }

    initialState: Partial<IState> = {
        // featureFlags: {
        //     name: "",
        //     flaggable_type: "",
        //     flaggable_id: 0,
        //     model_classes:[],
        //     id: 0
        // },
        name: "",
        flaggable_type: "",
        flaggable_id: 0,
        id: 0,
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        flaggableObjectType:{item:[]},
        nameError: "",
        flaggableTypeError:"",
        flaggableError:"",
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
              
            });
        }
        // document.addEventListener("mousedown", this.handleClickOutside);
    }
    // componentWillUnmount() {
    //     document.removeEventListener("mousedown", this.handleClickOutside);
    //   }

    //   handleClickOutside = (event) => {
    //     if (event.path[0].id !== "flaggable_type" && event.path[0].id !== "tag_location_category" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
    //       this.setState(
    //         {
    //             flaggableObjectType: [],
    //         },
    //         () => { }
    //       );
    //     }
    //    console.log('eve', event);
    //   };

 

    loadData() {
        this.service.editFeatureFlags(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdateFeatureFlagsModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        name: res.result?.name,
                        flaggable_type: res.result?.flaggable_type,
                        flaggable_id: res.result?.flaggable_id,
                    }, () => {
                       
                    });
                   
                }
                
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.FeatureFlags, this.props)
            });
            
    }


    updateData(data: AddUpdateFeatureFlagsModel) {
        this.setSavingFlag(true);
        this.service.updateFeatureFlags(data)
            .then((res: HttpResponse<AddUpdateFeatureFlagsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        // featureFlags: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Feature Flag Category successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/feature-flag/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/feature-flag/feature-flag-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/feature-flag/feature-flag-form/0');
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateFeatureFlagsModel) {
        this.setSavingFlag(true);
        this.service.postFeatureFlags(data)
            .then((res: HttpResponse<AddUpdateFeatureFlagsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        // featureFlags: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Feature Flag successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/feature-flag/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/feature-flag/feature-flag-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/feature-flag/feature-flag-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            
            });;
    }


    handleChange = (event: any) => {
    //     if (event.target.name == "flaggable_type") {
    //         this.getAllConnection(event.target.value);
    //   } 
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }
    getAllFlaggableObjetcs() {
        this.commonService.getflaggableObjectsType()
            .then((res: HttpResponse<FlaggleItems>) => {
                if (res) {
                    this.setState({
                        flaggableObjectType: res.result
                    });
                    
                }
                 });
        
     
    }
  

    // selectedTagLocationCategory(item) {
    //     // console.log('sected', item )
    //     this.setState({
    //         tag_location_category_id: item.value,
    //         flaggable_type: item.text,
    //         tag_location_category:item,
    //         tagLocationCategoriesSearch: [],
    //     });
    //   }

    //   renderTagLocationCategory = () => {
    //     if (this.state.flaggableObjectType?.length === 0) {
    //       return null;
    //     }
    //     return (
    //       <ul className="list-unstyled auto-suggest">            
    //          { this.state.flaggableObjectType?.map((item, index) => (<li id="tag_location_category" key={index} value={item.value} onClick={() => this.selectedTagLocationCategory(item)}>{item.text}</li>))}
    //       </ul>
    //     );
    // };


    validate = () => {
        let nameError = "";
        let flaggableTypeError = "";
        let flaggableError = "";
        if (!this.state.name) {
            nameError = "Name can't be blank";
        }
        if (this.state.name?.match(' ') || this.state.name?.match('-')) {
            nameError = "Name must be Snake-cased, e.g. advanced_scheduling";
        }
        if (this.state.flaggable_id == 0) {
            flaggableError = "Id must be greater than 0";
        }

        if (this.state.flaggable_type == '') {
            flaggableTypeError = "Flaggable Object Type must be selected";
        }
        if (nameError || flaggableTypeError || flaggableError) {
            this.setState({ nameError: nameError,  flaggableTypeError:flaggableTypeError, flaggableError:flaggableError })
            return false;
        }
        else {
            nameError = "";
            flaggableTypeError = "";
            flaggableError = ""
            this.setState({ nameError: nameError, flaggableTypeError:flaggableTypeError, flaggableError:flaggableError })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdateFeatureFlagsModel = {
            // flaggable_type: this.state.flaggable_type,
            // tag_location_category_id: this.state.tag_location_category_id,
            id: this.state.id,
            name: this.state.name?.toLowerCase(),
            flaggable_type: this.state.flaggable_type,
            flaggable_id: this.state.flaggable_id
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else {
                this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
                return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Feature Flag '{this.state.name}'</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <FeatureFlagsTabs id={this.props.match.params.id} url="/feature-flag/feature-flag-form/" />}
                                    <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Name<span className=" text-danger">*</span> 
                                                    <OverlayTrigger  overlay={
                                                        <Tooltip id="button-tooltip">
                                                            Snake-cased name of the flag our code should check for, e.g. advanced_scheduling. 
                                                            <strong>
                                                                <a href="https://sites.google.com/a/staq.com/staqnowledged/home/5---our-processes/deployment-upgrades/feature-flags"> Read this </a>
                                                            </strong> 
                                                            for help on feature flags.
                                                        </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> 
                                                    </label>
                                                        <input type="text" maxLength={255} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                        <div className="invalid-feedback">
                                                            {this.state.nameError}
                                                        </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group p-3 shadow-sm">

                                                <label>Flaggable Object Type <span className=" text-danger">*</span>
                                                <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                    To which type of object should we attach your flag?
                                                    </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> 
                                                </label>
                                                <div className="input-group ">
                                                    {/* <input type="text" maxLength={255}  autoComplete="off" placeholder="Search"  id="flaggable_type"  name="flaggable_type" value={this.state.flaggable_type} 
                                                    onChange={this.handleChange} className={!this.state.flaggableTypeError ? 'form-control' : 'form-control  is-invalid'} /> */}
                                                     {/* {this.renderTagLocationCategory()} */}
                                                     <select name="flaggable_type" value={this.state.flaggable_type} onChange={this.handleChange} className={!this.state.flaggableTypeError ? 'form-control' : 'form-control  is-invalid'} >
                                                     <option value="select">Select</option>
                                                         {this.state.flaggableObjectType?.item?.sort().map((item:string, index)=>{  
                                                             return(
                                                             <option key={index}>{item}</option>
                                                             )
                                                         })}    
                                                     </select>
                                                    <div className="invalid-feedback">
                                                        {this.state.flaggableTypeError}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            
                                            <div className="col-md-4">
                                            <div className="form-group p-3 shadow-sm">
                                                    <label>Flaggable Object Id<span className=" text-danger">*</span> <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                    What is the ID number of the object to which we should attach your flag?
                                                    </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="number" maxLength={255} id="flaggable_id" name="flaggable_id" value={this.state.flaggable_id} onChange={this.handleChange} className={!this.state.flaggableError ? 'form-control' : 'form-control  is-invalid'}  />
                                                    <div className="invalid-feedback">
                                                        {this.state.flaggableError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/feature-flag/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
