import React, { Component, Fragment } from 'react'
import { UserReportsService } from './services';
import { HttpResponse } from '../../../core';
import { ViewReportDataResponseModel, ViewReportDataDisplayModel, DataEngineJobPostModel } from '../reports/models'
import { ReportTabs } from './ReportTabs';
import { ButtonType } from '../../../shared/enums/button.enum';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ConvertDateTime, GetUserInfo, HandleNotFoundResponse, ShowSuccessMessage } from './../../../shared/helpers'
import { PaginationRecordInfo, Pagination } from '../../../shared';
import { FilterModel } from '../../../shared/filter/models';
import { Loader } from '../../../shared/loaders'
import { NotFoundResponseArea } from '../../../shared/enums';
import { FileUploadResponseModel } from '../../../shared/models';
import { NavLink } from "react-router-dom";

interface IState {
    result?: ViewReportDataDisplayModel;    
    isRegenerate?: boolean;
    filters: FilterModel[];
    currentPage: number;
    pageSize: number;
    pageCount: number;
    totalItems: number;
    isLoading: boolean;


    
    trigger_dependencies?:boolean;    
    time_range?:string;
}
export class ViewReportData extends Component<any, IState> {
    private service: UserReportsService;

    constructor(props) {
        super(props);
        this.state = {            
            trigger_dependencies: true,
            time_range: "",
            isRegenerate: false,
            filters: [],
            currentPage: 1,
            pageSize: 20,
            pageCount: 0,
            totalItems: 0,
            isLoading: false

        }
        this.service = new UserReportsService();
        this.pageChange = this.pageChange.bind(this);
    }

    initialState: Partial<IState> = {        
        trigger_dependencies: true,
        time_range: "",
        isRegenerate: false,
        filters: [],
        currentPage: 1,
        pageSize: 20,
        pageCount: 0,
        totalItems: 0,
        isLoading: false 

    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setLoading(true);
        this.service.getLatestReportDataEngineJobsList(this.props.match.params.id)
            .then((res: HttpResponse<ViewReportDataDisplayModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        isRegenerate:false
                    }, () => {
                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });
    }

    // getControlMission() {
    //     this.setLoading(true);
    //     this.service.geReportViewDataById(this.props.match.params.id, this.state.currentPage, this.state.pageSize, this.state.filters)
    //         .then((res: HttpResponse<ViewReportDataResponseModel>) => {
    //             this.setLoading(false);
    //             if (res && res.result) {
    //                 this.setState({
    //                     result: res.result
    //                 }, () => {
    //                     console.log(res.result)
    //                 });
    //             }
    //         })
    //         .catch(ex => {
    //             HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
    //         });
    // }

    handleChange = (event: any) => {

        if (event.target.type === "checkbox") {
            this.setState({ trigger_dependencies: event.target.checked })
        }
        else {
            this.setState({ time_range: event.target.value })
        }
    }

    handleSubmit = event => {
        event.preventDefault();

        let email =GetUserInfo().email;
        let jobdata:DataEngineJobPostModel={
            priority:0,
            report_id:this.props.match.params.id,
            triggered_by:email,
            time_range:this.state.time_range,
            trigger_dependencies:this.state.trigger_dependencies                     
            
        };        
        this.service.createDataEngineJob(jobdata)
        .then((res: HttpResponse<FileUploadResponseModel>) => {
            if (res && res.result) {
                if(res.result.jid)
                {         
                    ShowSuccessMessage("Created Data Engine Jobs Successfully")       
                    this.setState({                        
                        isRegenerate: true
                    }, () => {                        
                        setTimeout(() => {
                            this.setState({ isRegenerate: false })
                        }, 600)
                        this.loadData()
                    })
      
                }
            }
        });


    
    }

    pageChange(page: number) {
        if (this.state.currentPage == page) return;
        this.setState(
            { currentPage: page },
            () => {
                this.loadData();
            }
        )
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }

    render() {
        return (
            <Fragment>
                {/* {this.state.result?.id == this.props.match.params.id && <div> */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4>View Report Data</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <ReportTabs id={this.props.match.params.id} url="/report/report_data/" />
                                <div className="row">
                                    <div className="col">
                                        <div className="alert alert-light">
                                            <i className="fas fa-exclamation-circle text1"></i> The time range is optional. It should have a start and finish date and be in the format of a range. By default, sources will trigger dependent jobs unless the checkbox is unchecked.
                                            </div>
                                    </div>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row mt-4 mb-4" >
                                        <div className="col-md-6 mx-auto shadow-sm">
                                            <div className="form-group">
                                                <label className="col-12">Time Range:</label>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input type="text" name="time_range" value={this.state.time_range} onChange={this.handleChange} className="form-control" />
                                                        <small>Pick a time range that covers only the data that needs to be regenerated.</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox mt-1 ml-3">
                                                    <input type="checkbox" name="trigger_dependencies" checked={this.state?.trigger_dependencies} onChange={this.handleChange} className="custom-control-input" id="trigger_dependencies" />
                                                    <label className="custom-control-label" htmlFor="trigger_dependencies">Trigger Dependencies?  (If Applicable)</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input type="submit" className="btn btn-primary ml-3" value={ButtonType.Regenerate} />
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div className="row ">
                                    <div className="col-md-12 mt-3">
                                        <div className="table-responsive">
                                            <table className="table table-md table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Job ID</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Updated At</th>
                                                        <th scope="col">Triggered By</th>
                                                        <th scope="col">Report Rows</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.isRegenerate && <tr>
                                                        <td>a new job has been submitted. Refreshthis <br /> page otherwise this status will update in 30 <br />seconds</td>
                                                    </tr>}
                                                    {!this.state.isLoading && this.state.result &&<tr>
                                                        <td><NavLink to={"/data-engine-job/detail/" + this.state.result.id}>{this.state.result.id}</NavLink></td>
                                                                <td>{this.state.result.state}	</td>
                                                                <td>{ConvertDateTime(this.state.result.updated_at, 'LLL')}</td>
                                                                <td>{this.state.result.triggered_by}</td>
                                                                <td>{this.state.result.runtime}</td>
                                                    </tr> }                                                       
                                                </tbody>
                                            </table>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* </div>} */}
            </Fragment >
        )
    }
}
