import { HttpWrapper, AppConfig } from '../../../../../core'
import { InvocationSchedulesListResponseModel,InvocationScheduleDetailModel,PostInvocationSchedulesModel,EditInvocationSchedulesModel } from '../models';
import {  DeleteResponseModel, LinkResourcesResponseModel } from '../../../../../shared/models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
export class InvocationSchedulesService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getInvocationSchedulesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<InvocationSchedulesListResponseModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules/get_all_invocation_schedules?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getInvocationScheduleById(id: number) {
      return this.wrapper.get<InvocationScheduleDetailModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules/' + id);
    }
  
    postInvocationSchedule(invocation_schedule: PostInvocationSchedulesModel) {
      return this.wrapper.post<PostInvocationSchedulesModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules', invocation_schedule);
    }
  
    editInvocationSchedule(id: number) {
      return this.wrapper.get<EditInvocationSchedulesModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules/edit/' + id);
    }
  
    updateInvocationSchedule(invocation_schedule: PostInvocationSchedulesModel) {
      return this.wrapper.put<PostInvocationSchedulesModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules/' + invocation_schedule.id, invocation_schedule);
    }
  
    getLinkedResourcesById(id: number) {
      return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules/' + id + '/linked_resources');
    } 

    deleteInvocationScheduleById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules/' + id);
    }
    
    exportInvocationSchedules(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/invocation_schedules/export', exportData);
    }
  
  
  }