import { HttpWrapper, AppConfig } from "../../../../../../core";
import { PostExportModel } from "../../../../../../shared/export/post.export.model";
import { ReportColumnFieldsDetailModel, ReportColumnFieldsListResponseModel } from "../models";

export class ReportColumnFieldsService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getReportColumnFieldsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ReportColumnFieldsListResponseModel>(AppConfig.apiEndpoint + '/v1/report_column_field/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getReportColumnFieldsById(id: number) {
        return this.wrapper.get<ReportColumnFieldsDetailModel>(AppConfig.apiEndpoint + '/v1/report_column_field/' + id);
    }
    
    exportReportColumnFields(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_column_field/export', exportData);
    }

}