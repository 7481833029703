import { AppConfig } from "../../core"
import { Decrypt, Encrypt } from "./crypto.helper";
import { UserDetailResponseModel } from "../../components/auth/models";

export const GetUserInfo=()=>{
    let storage=localStorage.getItem(AppConfig.UserInfoKey);
    if(storage==null) return {} as UserDetailResponseModel;
    let userInfo= Decrypt<UserDetailResponseModel>(storage)
    if(userInfo=="") return {} as UserDetailResponseModel;
    return userInfo as UserDetailResponseModel;
}
export const SetUserInfo=(model:UserDetailResponseModel)=>{
        localStorage.setItem(AppConfig.UserInfoKey,Encrypt(model));  
}