import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal';
import { AppConfig, HttpResponse } from '../../../core';
import { CollectionMissionService } from './services/collection.mission.service';
import { Loader } from '../../../shared/loaders';
import {
    ShowSuccessMessage
} from "../../../shared/helpers";

interface IState {
    task: any;
    files: any;
    generatingScopes: any;
}

export default class CollectionMissionTaskFiles extends Component<any, IState> {
    private service: CollectionMissionService;
    constructor(props) {
        super(props);
        this.service = new CollectionMissionService();
        this.state = {
            task: {
                id: props.taskId
            },
            files: [],
            generatingScopes: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        console.log("Loading Task");
        this.service.getCollectionMissionTask(this.state.task.id)
            .then((res: HttpResponse<any>) =>{
                this.setState({
                    task: res.result.task,
                    files: res.result.files
                })
            })
    }

    handleClose = event => {
        event.preventDefault();

        this.props.handleClose();
    }

    handleGenerateClick = (resourceUrl) => {
        this.setState({
            generatingScopes: [resourceUrl]
        })
        this.service.generateBasicScope(this.state.task.id, resourceUrl)
            .then((res: HttpResponse<any>) =>{
                this.setState({
                    generatingScopes: []
                }, ()=>{
                    ShowSuccessMessage("Scope successfully created.");
                    this.loadData();
                });
            })
            .catch(ex => {
                
            });
    }

    render() {
        return <Fragment>
            <Modal.Header closeButton onClick={this.handleClose}>

            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Files for Task: '{this.state.task.name}'</h5>
                </div>
                <table className="table table-sm table-striped">
                    <tbody>
                    {this.state.files.map((f, idx)=>{
                        return (
                            <tr key={idx}>
                                <td>
                                    <a href={f.url} target="_blank">
                                        {f.s3resourceUrl}
                                    </a>
                                </td>
                                <td>
                                    {(f.enable_scope_create && !this.state.generatingScopes.includes(f.s3resourceUrl) ) &&
                                        <button onClick={() => this.handleGenerateClick(f.s3resourceUrl)} className="btn btn-sm  btn-info">
                                            Generate Basic Scope
                                        </button>
                                    }
                                    {(f.enable_scope_create && this.state.generatingScopes.includes(f.s3resourceUrl) ) &&
                                        <Loader 
                                            loading={this.state.generatingScopes.includes(f.s3resourceUrl)} 
                                            marginBottom="0px"
                                            marginTop="8px"
                                            width="50px"    
                                        />
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </Modal.Body>
        </Fragment>
    }
}
