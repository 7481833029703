
import React, { Component, Fragment } from 'react'
import { ReportBackupsResponseModel, ReportBackupsModel } from './models'
import { UserReportsService } from './services';
import { HttpResponse } from '../../../core';
import { Loader } from '../../../shared/loaders'
import { PaginationRecordInfo, Pagination } from '../../../shared';
import { ReportTabs } from './ReportTabs';
import { ConvertDateTime, HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';

interface IState {
    result: ReportBackupsResponseModel;
    currentPage: number;
    pageSize: number;
    pageCount: number;
    totalItems: number;
    isLoading: boolean;
}
export class ReportBackups extends Component<any, IState> {
    private service: UserReportsService;
    private currentColumn: string = '';

    constructor(props: any) {
        super(props);
        this.state = {
            result: {
                paging: {
                    current_page: 0,
                    page_count: 0,
                    page_size: 0,
                    total_items: 0
                },
                records: []
            },
            currentPage: 1,
            pageSize: 20,
            pageCount: 0,
            totalItems: 0,
            isLoading: false,
        };

        this.service = new UserReportsService();
        this.pageChange = this.pageChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }

    private loadData() {
        this.setLoading(true);
        this.service.geReportBackupsById(this.props.match.params.id, this.state.currentPage, this.state.pageSize)
            .then((res: HttpResponse<ReportBackupsResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        currentPage: res.result.paging.current_page,
                        pageSize: res.result.paging.page_size
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });

    }

    pageChange(page: number) {
        if (this.state.currentPage == page) return;
        this.setState(
            { currentPage: page },
            () => {
                this.loadData();
            }
        )
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Backups</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportTabs id={this.props.match.params.id} url="/report/backups/" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                {!this.state.isLoading && this.state.result.records.length > 0 && (<table className="table table-md table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Created At</th>
                                                            <th>Scope</th>
                                                            <th>S3 Path</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.result.records?.map((item: ReportBackupsModel) => {
                                                                return (
                                                                    <tr key={item.id}>
                                                                        <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                        <td>{item.scope_name}</td>
                                                                        <td>{item.s3_path}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>)}
                                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                                                {!this.state.isLoading && this.state.result.records.length === 0 &&
                                                    <div className="text-danger text-center"> <img src="../../no-data.jpg" className="img-fluid" alt="No data found" /></div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex justify-content-between">
                                            <PaginationRecordInfo currentPage={this.state.currentPage} totalRecords={this.state.result?.paging.total_items} pageSize={this.state.pageSize} />
                                            <Pagination currentPage={this.state.currentPage} pageCount={this.state.result?.paging.page_count} onChangePage={this.pageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
