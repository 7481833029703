import React, { Component, Fragment } from 'react'
import { DashboardDetailModel, AppletsModel } from '../dashboard/models'
import { UserDashboardService } from './services/user.dashboard.service';
import { HttpResponse } from '../../../core';
import { ConvertDateTime, HandleNotFoundResponse } from '../../../shared/helpers';
import { Editor } from '../../../shared'
import { DashboardTabs } from './DashboardTabs';
import { Link, NavLink } from "react-router-dom";
import { NotFoundResponseArea } from '../../../shared/enums';
import { Loader } from '../../../shared/loaders';
interface IState {
    result?: DashboardDetailModel;
    isLoading?: boolean;
}
export class DashboardDetail extends Component<any, IState> {

    private service: UserDashboardService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new UserDashboardService();
    }

    initialState: Partial<IState> = {
        result: {
            created_at: new Date(),
            updated_at: new Date(),
            id: 0,
            name: "",
            user_email: "",
            settings: "",
            user_id: 0,
            applets: []
        },
        isLoading: false,
    }
    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getUserDashboardById(this.props.match.params.id)
            .then((res: HttpResponse<DashboardDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    }, () => {                      
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Dashboards,this.props)
            });
    }


    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for Dashboard '{this.state.result?.name === "" ? "-" : this.state.result?.name}'</h4>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <DashboardTabs id={this.props.match.params.id} url="/dashboard/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Name </div>
                                                <div className="card-body">{this.state.result?.name}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">User</div>
                                                <div className="card-body"><NavLink to={"/user/detail/" + this.state.result?.user_id}>{this.state.result?.user_email}</NavLink></div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Created At </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Updated At </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.result?.id == this.props.match.params.id && this.state.result?.settings != null &&
                                        // <div className="row">
                                        //     <div className="col">
                                        //         <div className="card card-silver shadow-sm">
                                        //             <div className="card-header font-weight-bold"> Settings </div>
                                        //             <div className="card-body"><Editor StringData={this.state.result?.settings} FormateType="json" /></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12"><h5>Settings</h5>  </div>
                                            <div className="col-md-6"><Editor StringData={this.state.result?.settings} FormateType="json" /></div>
                                        </div>
                                    }
                                      {this.state.result?.applets.length != 0 && <div className="row mt-3">
                        <div className="col-md-12"><h6>Widgets</h6></div>
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-md table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Report View</th>
                                            <th scope="col">Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.result?.applets.map((item: AppletsModel, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><NavLink to={"/applet/detail/" + item.id}>{item.name}</NavLink></td>
                                                    <td><NavLink to={"/report-view/detail/" + item.report_view_id}>{item.report_name}</NavLink></td>
                                                    <td>{ConvertDateTime(item?.created_at, 'LLL')}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </Fragment>
        )
    }
}
