import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ExtractionSchedulingRecipesChangesListResponseModel, ExtractionSchedulingRecipesChangesDetailModel } from "../models";



export class ExtractionSchedulingRecipesChangesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getExtractionSchedulingRecipesChangesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ExtractionSchedulingRecipesChangesListResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipe_changes/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getExtractionSchedulingRecipesChangesById(id: number) {
    return this.wrapper.get<ExtractionSchedulingRecipesChangesDetailModel>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipe_changes/' + id);
  }  

  exportExtractionSchedulingRecipesChanges(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipe_changes/export', exportData);
  }


}