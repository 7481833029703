import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { EmailTemplatesRoute } from '../../routing'

export class EmailTemplatesPage extends Component {
    render() {
        return (
            <Fragment>
            <Breadcrumbs />
            <EmailTemplatesRoute />
          </Fragment>
        )
    }
}
