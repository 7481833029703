import React, { Component, Fragment } from 'react'
import { EditSourceReportModel } from './models'
import { SourceReportService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Loader } from '../../../../shared/loaders';
import Modal from 'react-bootstrap/Modal';

interface IProps {
    handleClose: Function,
    id?:number; 
    isNew?:boolean;    
    updateSourceReport:Function
}
interface IState {
    editSourceReport?: EditSourceReportModel,
    id?: number;
    ignore_health?: boolean;
    edited_sonnection?: boolean;
    name?:string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class SourceReportEditFormPopup extends Component<IProps, IState> {
    private service: SourceReportService;
    private NetworkCall: any;

    constructor(props:IProps) {
        super(props)
        this.service = new SourceReportService();
        this.state = this.initialState;
    }

    initialState: Partial<IState> = {
        editSourceReport: {
            edited_connection: false,
            id: 0,
            ignore_health: false,
            name:""
        },
        edited_sonnection: false,
        id: 0,
        ignore_health: false,
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        name:""
    }


    handleClose = event => {
        event.preventDefault();
        this.props.handleClose();        
    }
    componentDidMount() {
                this.loadData();                    
    } 

    loadData() {
        this.service.editSourceReport(this.props.id)
            .then((res: HttpResponse<EditSourceReportModel>) => {
                if (res && res.result) {
                    this.setState({
                        ignore_health: res.result?.ignore_health,
                        edited_sonnection:res.result?.edited_connection,
                        id:res.result?.id,
                        name:res.result?.name
                    }, () => {
                       
                     });
                }
            });           
    }


    updateData(report: EditSourceReportModel) {
        this.setSavingFlag(true);
        this.service.updateSourceReport(report)
            .then((res: HttpResponse<EditSourceReportModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Source Report successfully updated");
                        if (this.state.isSave === ButtonType.Save) {                            
                            this.setState(this.initialState);
                            this.props.updateSourceReport(true);
                            this.props.handleClose();
                        }
                       
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        let reportdata: EditSourceReportModel = {            
            ignore_health: this.state.ignore_health,
            id: this.state.id
        };
        this.updateData(reportdata);
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value },()=>{
            this.props.updateSourceReport(true);
        })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>Edit Source Report {this.state.id && <span> '{this.state.name}'</span>}</h5>
                        </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">                                
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Ignore Health</label>
                                                <div className="custom-control custom-checkbox">
                                                    <input disabled={this.state?.edited_sonnection} type="checkbox" name="ignore_health" checked={this.state.ignore_health} onChange={this.handleChange} className="custom-control-input" id="enabled" />
                                                    <label className="custom-control-label" htmlFor="enabled"></label>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                    <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                                        
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
                </Modal.Body>
            </form>
        </Fragment>
        )
    }
}
