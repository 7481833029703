import { HttpWrapper, AppConfig } from '../../../../../../../core'
import { ExtractorTimeZoneSpecificationsListResponseModel,ExtractorTimeZoneSpecificationsDetailModel } from '../models';
import { PostExportModel } from "../../../../../../../shared/export/post.export.model";

export class ExtractorTimeZoneSpecificationsService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ExtractorTimeZoneSpecificationsListResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_time_zone_specifications/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getExtractorTimeZoneSpecificationsById(id: number) {
      return this.wrapper.get<ExtractorTimeZoneSpecificationsDetailModel>(AppConfig.apiEndpoint + '/v1/extractor_time_zone_specifications/' + id);
    }
    
    exportExtractorTimeZoneSpecifications(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extractor_time_zone_specifications/export', exportData);
    }
  
  
  }