import React, { Component, Fragment } from 'react'
import { AccountSourcesModel, AccountSourcesListModel } from './models'
import { AccountService } from './services/account.service';
import { HttpResponse } from '../../core';
import AccountTabs from './AccountTabs';
import { ConvertDateTime, formatBytes, HandleNotFoundResponse } from '../../shared/helpers';
import { NavLink } from "react-router-dom";
import { Pagination, PaginationRecordInfo } from '../../shared';
import { NotFoundResponseArea } from '../../shared/enums';

interface IState {
    result: AccountSourcesModel;
    currentPage: number;
    pageSize: number;
    pageCount: number;
    totalItems: number;
    isLoading: boolean;
}
export class AccountSources extends Component<any, IState>{
    private service: AccountService;
    constructor(props: any) {
        super(props);
        this.state = {
            result: {
                paging: {
                    current_page: 0,
                    page_count: 0,
                    page_size: 0,
                    total_items: 0
                },
                sources: [],
                id: 0
            },
            currentPage: 1,
            pageSize: 20,
            pageCount: 0,
            totalItems: 0,
            isLoading: false,
        };

        this.service = new AccountService();
        this.pageChange = this.pageChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    pageChange(page: number) {
        if (this.state.currentPage == page) return;
        this.setState(
            { currentPage: page },
            () => {
                this.loadData();
            }
        )
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }

    private loadData() {
        this.setLoading(true);
        this.service.getAccountSourcesById(this.props.match.params.id, this.state.currentPage, this.state.pageSize)
            .then((res: HttpResponse<AccountSourcesModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        currentPage: res.result.paging.current_page,
                        pageSize: res.result.paging.page_size
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Accounts,this.props) 
            });

    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Sources</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountTabs id={this.props.match.params.id} url="/account/sources/" />
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Report</th>
                                                        <th scope="col">Last Generated At</th>
                                                        {/* <th scope="col">Fresh?</th> */}
                                                        <th scope="col">Earliest Date</th>
                                                        <th scope="col">Latest Date</th>
                                                        <th scope="col">Row Count</th>
                                                        <th scope="col">Size On Disk</th>

                                                    </tr>
                                                </thead>
                                                {this.state.result?.sources.length != 0 && <tbody>
                                                    {this.state.result?.sources.map((item: AccountSourcesListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><NavLink to={"/report/detail/" + item.id}> {item.name}</NavLink></td>
                                                                <td>{item.last_generated_at != null ? ConvertDateTime(item.last_generated_at, 'LLL') : ""}</td>
                                                                <td>{item.min_date != null ? ConvertDateTime(item.min_date, 'LLL') : ""}</td>
                                                                <td>{item.max_date != null ? ConvertDateTime(item.max_date, 'LLL') : ""}</td>
                                                                <td>{item.row_count != null ? item.row_count.toLocaleString('en') : "0"}</td>
                                                                <td>{item.size_on_disk!=null?formatBytes(item.size_on_disk):"0 Bytes"}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-flex justify-content-between">
                                        <PaginationRecordInfo currentPage={this.state.currentPage} totalRecords={this.state.result?.paging.total_items} pageSize={this.state.pageSize} />
                                        <Pagination currentPage={this.state.currentPage} pageCount={this.state.result?.paging.page_count} onChangePage={this.pageChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
