import React, { Component, Fragment } from 'react'
import { EditSourceReportModel } from './models'
import { SourceReportService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { SourceReportTabs } from './SourceReportTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';

interface IState {
    editSourceReport?: EditSourceReportModel,
    id?: number;
    ignore_health?: boolean;
    edited_sonnection?: boolean;
    name?:string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class SourceReportEditForm extends Component<any, IState> {
    private service: SourceReportService;
    private NetworkCall: any;

    constructor(props) {
        super(props)
        this.service = new SourceReportService();
        this.state = this.initialState;
    }

    initialState: Partial<IState> = {
        editSourceReport: {
            edited_connection: false,
            id: 0,
            ignore_health: false,
            name:""
        },
        edited_sonnection: false,
        id: 0,
        ignore_health: false,
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        name:""
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
    }

    loadData() {
        this.service.editSourceReport(this.props.match.params.id)
            .then((res: HttpResponse<EditSourceReportModel>) => {
                if (res && res.result) {
                    this.setState({
                        ignore_health: res.result?.ignore_health,
                        edited_sonnection:res.result?.edited_connection,
                        id:res.result?.id,
                        name:res.result?.name
                    }, () => {
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.SourceReports,this.props) 
            });
    }


    updateData(report: EditSourceReportModel) {
        this.setSavingFlag(true);
        this.service.updateSourceReport(report)
            .then((res: HttpResponse<EditSourceReportModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Source Report successfully updated");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/source-report/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/source-report/edit/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        let reportdata: EditSourceReportModel = {            
            ignore_health: this.state.ignore_health,
            id: this.state.id
        };
        this.updateData(reportdata);
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Source Report {this.state.id && <span> '{this.state.name}'</span>}</h5>
                        </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                {this.props.match.params.id != 0 && <SourceReportTabs id={this.props.match.params.id} url="/source-report/edit/" />}
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Ignore Health</label>
                                                <div className="custom-control custom-checkbox">
                                                    <input disabled={this.state?.edited_sonnection} type="checkbox" name="ignore_health" checked={this.state.ignore_health} onChange={this.handleChange} className="custom-control-input" id="enabled" />
                                                    <label className="custom-control-label" htmlFor="enabled"></label>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                        <Link type="button" className="btn btn-lg btn-default" to="/source-report/list">{ButtonType.Cancel}</Link>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                            
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }
}
