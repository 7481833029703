import { HttpWrapper, AppConfig } from '../../../../core'
import { RoleListResponseModel,RoleDetailModel,PostRoleModel,EditRoleModel } from '../models';
import { PostExportModel } from "../../../../shared/export/post.export.model";

export class RoleService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getRoleList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<RoleListResponseModel>(AppConfig.apiEndpoint + '/v1/roles/get_all_roles?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getRoleById(id: number) {
      return this.wrapper.get<RoleDetailModel>(AppConfig.apiEndpoint + '/v1/roles/' + id);
    }
  
    postRole(role: PostRoleModel) {
      return this.wrapper.post<PostRoleModel>(AppConfig.apiEndpoint + '/v1/roles', role);
    }
    getAllOperations() {
      return this.wrapper.get<any>(AppConfig.apiEndpoint + '/v1/roles/get_all_operations');
    }
  
    editRole(id: number) {
      return this.wrapper.get<EditRoleModel>(AppConfig.apiEndpoint + '/v1/roles/edit/' + id);
    }
  
    updateRole(role: PostRoleModel) {
      return this.wrapper.put<PostRoleModel>(AppConfig.apiEndpoint + '/v1/roles/' + role.id, role);
    }
    
    exportRole(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/roles/export', exportData);
    }
  
  
  }