import React, { Component, Fragment } from "react";
import { RoleService } from "./services/role.service";
import {
  EditRoleModel,
  RoleProtectedOperation,
} from "./models/edit.role.model";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../shared/enums";
import { PostRoleModel } from "./models";
import { RoleTabs } from "./RoleTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import Modal from "react-bootstrap/Modal";

interface IProps {
  handleClose: Function;
  id?: number;
  isNew?: boolean;
  updateRoles: Function;
}

interface IState {
  id?: number;
  name?: string;
  description?: string;
  active?: boolean;
  protected_operations?: Array<RoleProtectedOperation>;
  all_protected_operations?: Array<RoleProtectedOperation>;
  protected_operations_ids?: Array<number>;
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  test?: Array<any>;
  role_name_error?: string;
}

export class RoleFormPopup extends Component<IProps, IState> {
  private service: RoleService;

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
    this.service = new RoleService();
    this.handleClose = this.handleClose.bind(this);   
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    name: "",
    description: "",
    active: false,
    protected_operations: [],
    all_protected_operations: [],
    protected_operations_ids: [],
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    test: [],
    role_name_error: "",
  };

  handleClose = (event) => {
    event.preventDefault();
    this.props.handleClose();
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (!this.props.isNew) {
      this.setState(
        { id: Number(this.props.id), isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    } else {
      this.loadOperations();
    }
  }

  loadOperations() {
    this.service.getAllOperations().then((res) => {
      if (res) {
        this.setState({
          all_protected_operations: res.result,
        });
      }
      const new_ops = this.convert_protected_operations(
        this.state.all_protected_operations
      );
      this.setState({ test: new_ops });
    });
  }

  convert_protected_operations(all_operations) {
    const new_ops = all_operations?.map((ops) => {
      let operation: any = [];
      ops.operations.map((item) => {
        let a = {
          name: "",
          status: false,
        };
        a.name = item;
        return operation.push(a);
      });
      return {
        ids: ops.ids,
        operations: operation,
        record_type: ops.record_type,
      };
    });
    return new_ops;
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editRole(Number(this.props.id))
      .then((res: HttpResponse<EditRoleModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            name: res.result?.name,
            description: res.result?.description,
            active: res.result?.active,
            protected_operations: res.result?.protected_operations,
            all_protected_operations: res.result?.all_protected_operations,
          });
        }
        const difference: any = [];
        const difference_name: any = [];
        const operations_name: any = [];
        const final: any = [];
        this.state.protected_operations?.map((ops) => {
          operations_name.push(ops.record_type);
          this.state.all_protected_operations?.map((ele) => {
            if (ele.record_type === ops.record_type) {
              const results = ele.operations.filter(
                (id1) => !ops.operations.some((id2) => id2 === id1)
              );
              difference.push({ name: ele.record_type, value: results });
            }
            return ele;
          });
          return ops;
        });
        difference.forEach((diff) => difference_name.push(diff.name));
        this.state.all_protected_operations?.map((ops) => {
          if (difference_name.includes(ops.record_type)) {
            difference.forEach((diff) => {
              if (diff.name === ops.record_type) {
                if (diff.value.length === 0) {
                  let a: any = [];
                  ops.operations.map((op) =>
                    a.push({ name: op, status: true })
                  );
                  ops.operations = a;
                  return final.push(ops);
                } else {
                  let a: any = [];
                  ops.operations.map((op) => {
                    if (diff.value.includes(op)) {
                      a.push({ name: op, status: false });
                    } else {
                      a.push({ name: op, status: true });
                    }
                    ops.operations = a;
                  });
                  return final.push(ops);
                }
              }
            });
          } else {
            let a: any = [];
            ops.operations.map((op) => a.push({ name: op, status: false }));
            ops.operations = a;
            return final.push({
              ids: ops.ids,
              record_type: ops.record_type,
              operations: a,
            });
          }
        });
        this.setState({ test: final });
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Roles, this.props);
      });
  }

  validate = () => {
    let role_name_error = "";
    if (!this.state.name) {
      role_name_error = "Name cannot be blank.";
    }
    if (role_name_error) {
      this.setState({
        role_name_error: role_name_error,
      });
      return false;
    } else {
      role_name_error = "";
      this.setState({
        role_name_error: "",
      });
      return true;
    }
  };

  handleChange = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    this.setState(
      {
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      },
      () => {
        this.validate();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    const ids: any = [];
    this.state.test &&
      this.state.test.length > 0 &&
      this.state.test.map((ele) => {
        ele.operations.forEach((op, i) => {
          if (op.status) {
            ids.push(ele.ids[i]);
          }
        });
      });
    let payload: PostRoleModel = {
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      active: this.state.active,
      protected_operations_ids: ids,
    };
    if (isValid) {
      if (payload.id === 0) {
        this.postData(payload);
      } else {
        this.updateData(payload);
      }
    }
  };

  postData(role: PostRoleModel) {
    this.setSavingFlag(true);
    this.service.postRole(role).then(
      (res: HttpResponse<PostRoleModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Role successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updateRoles(
                  true,
                  this.state.name === "" ? "New Role" : this.state.name,
                  this.state.id
                );
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(role: PostRoleModel) {
    this.setSavingFlag(true);
    this.service.updateRole(role).then(
      (res: HttpResponse<PostRoleModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Role successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updateRoles(
                  true,
                  this.state.name === "" ? "New Role" : this.state.name,
                  this.state.id
                );
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value },()=>{
      this.props.updateRoles(true,this.state.name,this.state.id);
  });
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  handlePermittedOperation(ops, ele) {
    const old_state = this.state.test;
    old_state?.find((op) => {
      if (op.record_type === ops.record_type) {
        op.operations.find((element) => {
          if (element.name === ele.name) {
            element.status = !element.status;
            return element;
          }
        });
        return op;
      }
    });
    this.setState({ test: old_state });
  }

  renderAllOperationsPermitted() {
    return (
      <div className="col-md-12">
        <div className="card card-silver shadow-sm">
          <div className="card-header">
            <b>Protected operations permitted by this role</b>
          </div>
          <div className="card-body d-flex flex-wrap">
            {this.state.test &&
              this.state.test.length > 0 &&
              this.state.test.map((ops) => (
                <div className="col-12" key={ops.record_type}>
                  <h6 className="mt-2">{ops.record_type}</h6>
                  {ops.operations.map((ele) => (
                    <span  className="mb-1 mr-3 float-left" key={`${ops.record_type}-${ele.name}`}  >
                      <input
                        type="checkbox"
                        name={`${ops.record_type}-${ele.name}`}
                        checked={ele.status}
                        onChange={() => this.handlePermittedOperation(ops, ele)}
                        id={`${ops.record_type}-${ele.name}`}
                      /> 
                      <label  style={{ textTransform: 'capitalize', marginLeft:'5px', fontWeight:'normal'}}
                        htmlFor={`${ops.record_type}-${ele.name}`}
                      > {ele.name}
                      </label>
                    </span>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
          <Modal.Body>
            <input type="hidden" value={this.state.id} />
            <div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>
                  {this.state.isShowtext} Role
                  {this.state.name && <span> '{this.state.name}'</span>}
                </h5>
              </div>
              <div className="row">
                <div className="col-md-12">
                 
                      {/* {Number(this.props.match.params.id) !== 0 && (
                        <RoleTabs
                          id={this.props.match.params.id}
                          url="/role/role-form/"
                        />
                      )} */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group  shadow-sm p-3 ">
                            <label>Name</label>
                            <div className="input-group ">
                              <input
                                type="text"
                                maxLength={255}
                                name="name"
                                className={
                                  !this.state.role_name_error
                                    ? "form-control"
                                    : "form-control  is-invalid"
                                }
                                value={this.state.name}
                                onChange={this.handleChange}
                              />
                              <div className="invalid-feedback">
                                {this.state.role_name_error}
                              </div>
                            </div>
                            <small>Required. Length up to 255.</small>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="active"
                              checked={this.state.active}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="active"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="active"
                            >
                              Active
                            </label>
                          </div>
                          <small>
                            If you set this flag, Users will see this Role as an
                            option in the User management section
                          </small>
                        </div>
                        </div>
                        
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea
                              rows={10}
                              name="description"
                              value={this.state.description}
                              onChange={this.handleChange}
                              className="form-control"
                              style={{ height: "160px" }}
                            ></textarea>
                            <small>
                              User-visible general description of what the role
                              can do.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {this.state.all_protected_operations &&
                          this.state.all_protected_operations.length > 0 &&
                          this.renderAllOperationsPermitted()}
                      </div>
                   
                </div>
              </div>
              <div className="text-center mt-3 mb-4">
                {!this.state.isSaving && (
                  <Fragment>
                     <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                     <input
                      type="submit"
                      className="btn btn-lg btn-primary  ml-3"
                      onClick={this.handleClick}
                      value={ButtonType.Save}
                    />
                  </Fragment>
                )}
                <Loader
                  loading={this.state.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  float="center"
                  width="368px"
                ></Loader>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Fragment>
    );
  }
}
