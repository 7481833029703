import React, { Component, Fragment } from 'react'
import { ReportColumnsListResponseModel, ReportColumnsListModel, ReportColumnsColumnList } from './models'
import { Columns, FilterModel, ColumnType } from '../../../../shared/filter/models'
import { PostExportModel } from '../../../../shared/export/post.export.model'
import { ReportColumnsService } from './services/report.columns.service'
import { HttpResponse } from '../../../../core'
import { FileDownloadService } from '../../../../shared/services/file-download.service'
import { ShowErrorMessage, ConvertDateTime } from '../../../../shared/helpers'
import { ButtonType } from '../../../../shared/enums/button.enum'
import ExportComponent from '../../../../shared/export/ExportComponent'
import Modal from 'react-bootstrap/Modal'
import { Sorting, PaginationRecordInfo, Pagination } from '../../../../shared'
import { Loader } from '../../../../shared/loaders'
import Filter from '../../../../shared/filter/Filter'
import { Link, NavLink } from "react-router-dom";
import { StickyTable, Row, Cell, Wrapper } from 'react-sticky-table';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../../../redux/navigation'



interface IState {
    result: ReportColumnsListResponseModel;
    currentPage: number;
    pageSize: number;
    sortBy: string;
    sortOrder: boolean;
    currentColumn: string;
    pageCount: number;
    totalItems: number;
    Columns: Columns[];
    showExportModal?: boolean;
    filters?: FilterModel[];
    isShowFilter: boolean;
    export?: PostExportModel;
    isLoading: boolean;
    Ids: number[];
    selectedIds: FilterModel;
    isAllChecked: boolean;
    isExporting: boolean;
    isSelectedAll?: boolean;
    encodeToName?: string;
    exportTo?: string;
    columnSeparator?: string;
    isNoHeader?: boolean;
}
interface IProps {    
    current_route: string;
    ChangePage: Function;    
    currentPage: number;    
    ChangeFilters: Function;
    filters:[];
}
export class ReportColumnsList extends Component<IProps, IState> {
    private service: ReportColumnsService;
    private currentColumn: string = '';
    columnList = ReportColumnsColumnList;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isSelectedAll: true,
            isNoHeader: false,
            columnSeparator: ",",
            encodeToName: "utf-8",
            exportTo: "csv",
            result: {
                paging: {
                    current_page: this.props.currentPage,
                    page_count: 0,
                    page_size: 0,
                    total_items: 0
                },
                records: []
            },
            currentPage:  this.props.currentPage,
            pageSize: 20,
            sortBy: "id",
            sortOrder: true,
            currentColumn: "",
            pageCount: 0,
            totalItems: 0,
            Columns: [
                { id: 1, displayText: "Id", columnName: "id", value: '', type: ColumnType.Number },
                { id: 2, displayText: "Report", columnName: "report_name", value: '', type: ColumnType.String },
                { id: 3, displayText: "Column", columnName: "column_id", value: '', type: ColumnType.String },
                { id: 4, displayText: "Label", columnName: "label", value: '', type: ColumnType.String },
                { id: 5, displayText: "Type", columnName: "type", value: '', type: ColumnType.String },
                { id: 6, displayText: "Column Type", columnName: "column_type", value: '', type: ColumnType.String },
                { id: 7, displayText: "Definition", columnName: "definition", value: '', type: ColumnType.String },
                { id: 8, displayText: "Style", columnName: "style", value: '', type: ColumnType.String },
                { id: 9, displayText: "Hide", columnName: "hide", value: '', type: ColumnType.String },
                { id: 10, displayText: "Default", columnName: "default", value: '', type: ColumnType.String },
                { id: 11, displayText: "Sort Direction", columnName: "sort_direction", value: '', type: ColumnType.String },
                { id: 12, displayText: "Sort Priority", columnName: "sort_priority", value: '', type: ColumnType.Number },
                { id: 13, displayText: "Summarization", columnName: "summarization", value: '', type: ColumnType.String },
                { id: 14, displayText: "Total", columnName: "total", value: '', type: ColumnType.String },
                { id: 15, displayText: "Steps", columnName: "steps", value: '', type: ColumnType.String },
                { id: 16, displayText: "Display Order", columnName: "display_order", value: '', type: ColumnType.Number },
                { id: 17, displayText: "Field", columnName: "field_name", value: '', type: ColumnType.String },
                { id: 18, displayText: "Is Unique key", columnName: "is_unique_key", value: '', type: ColumnType.Boolean },
                { id: 19, displayText: "Precision", columnName: "precision", value: '', type: ColumnType.Number },
                { id: 20, displayText: "Group By", columnName: "group_by", value: '', type: ColumnType.Boolean },
                { id: 21, displayText: "Base Type", columnName: "base_type", value: '', type: ColumnType.String },
                { id: 22, displayText: "Created At", columnName: "created_at", value: '', type: ColumnType.Date },
                { id: 23, displayText: "Updated At", columnName: "updated_at", value: '', type: ColumnType.Date },
            ],
            isShowFilter: false,
            export: {
                csv_setting: { encoding: "", header: false, separator: "" },
                filters: [],
                file_type: "",
                included_properties: {},
                is_desc: false,
                sort_by: ""
            },
            isLoading: false,
            Ids: [],
            selectedIds: { name: "", op: "", value: [] },
            filters:this.props.current_route.includes("/report-column/")?this.props.filters: [],
            isAllChecked: false,
            isExporting: false

        };

        this.service = new ReportColumnsService();

        this.sortList = this.sortList.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.exportTo = this.exportTo.bind(this);
        this.clearExportModal = this.clearExportModal.bind(this);
    }

    applyFilter = (filter) => {
        this.setState({ filters: filter, currentPage: 1 }, () => {
            this.props.ChangeFilters(filter);
            this.props.ChangePage(1);
            this.loadData();
        });
    }


    clearFilter = () => {
        this.setState({ filters: [], currentPage: 1, isShowFilter: false, selectedIds: { name: "", op: "", value: [] } }, () => {
            this.props.ChangeFilters([]);
            this.props.ChangePage(1);
            this.loadData();
        });
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }

    private loadData() {
        this.setLoading(true);
        this.service.getReportColumnsList(this.state.currentPage, this.state.pageSize, this.state.sortBy, this.state.sortOrder, this.state.filters)
            .then((res: HttpResponse<ReportColumnsListResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        currentPage: res.result.paging.current_page,
                        pageSize: res.result.paging.page_size
                    });

                }
            });
    }

    pageChange(page: number) {
        if (this.state.currentPage == page) return;
        this.setState(
            {
                currentPage: page,
                isAllChecked: false,
                selectedIds: { name: "", op: "", value: [] }
            },
            () => {
                this.props.ChangePage(page);
                this.loadData();
            }
        )
    }

    sortList(sortColumn: string, sortOrder: boolean) {
        this.currentColumn = sortColumn;
        this.setState(
            { sortBy: sortColumn, sortOrder: sortOrder, isAllChecked: false }, () => {
                this.loadData();
            }
        )
    }

    handleClose = () => {
        this.setState({ showExportModal: false }, () => {
        });
    }

    handleShow = () => {
        this.setState({ showExportModal: true }, () => {

        });
    }

    toggleFilter = () => {
        this.setState({ isShowFilter: !this.state.isShowFilter })
    }

    private setIsExporting(exporting: boolean) {
        this.setState({ isExporting: exporting });
    }

    clearExportModal(data) {
        this.setState({
            isSelectedAll: data.isSelectedAll,
            isNoHeader: data.isNoHeader,
            columnSeparator: data.columnSeparator,
            encodeToName: data.encodeToName,
            exportTo: data.exportTo,
        })
    }

    export() {
        this.setIsExporting(true);
        this.service.exportReportColumns(this.state.export)
            .then((res) => {
                this.setIsExporting(false);
                if (res) {
                    new FileDownloadService().downloadFileByStream(res, this.state.export?.exportFileName || 'ReportColumns.csv');
                    this.handleClose();
                }
            }, () => {
                this.setIsExporting(false);
                ShowErrorMessage();
            });
    }

    exportTo = data => {
        let filterdata = this.state.filters?.slice();
        if (this.state.selectedIds.value.length != 0) {
            filterdata?.push(this.state.selectedIds);
        }
        this.setState({
            export: {
                filters: filterdata,
                file_type: data.file_type.toLowerCase(),
                csv_setting: { encoding: data.csv_setting.encoding.toLowerCase(), header: !data.csv_setting.header, separator: data.csv_setting.separator.toLowerCase() },
                included_properties: data.included_properties,
                exportFileName: data.exportFileName,
                is_desc: this.state.sortOrder,
                sort_by: this.state.sortBy
            },
            isSelectedAll: data.isSelectedAll
            // isNoHeader: data.isNoHeader
        }, () => {
            this.export();
        });
    }

    onSelectionChange(e: any) {
        // current array of ids
        let ids = this.state.Ids
        if (e.target.name === "all") {
            ids = []
            if (e.target.checked) {
                this.state.result.records.forEach(p => { p.isChecked = true })
                this.setState({ result: this.state.result, isAllChecked: true, Ids: ids }, () => {
                    this.state.result.records.forEach((p) => { ids.push(p.id) })
                })
            }
            else {
                this.state.result.records.forEach(p => { p.isChecked = false })
                this.setState({ result: this.state.result, isAllChecked: false, Ids: ids })
            }
        }
        else {
            let isAll;
            let index
            let checkedIndex = this.state.result.records.findIndex(p => p.id == e.target.value);
            this.state.result.records[checkedIndex]["isChecked"] = e.target.checked;
            if (e.target.checked) {
                ids.push(+e.target.value)
                if (this.state.result.records.filter(p => p.isChecked == true).length === this.state.result.records.length) {
                    isAll = true;
                }
            } else {
                // remove the value from the unchecked checkbox from the array
                index = ids.indexOf(+e.target.value)
                ids.splice(index, 1)
                isAll = false;
            }
            this.setState({ result: this.state.result, isAllChecked: isAll })
        }
        // update the state with the new array of ids
        let selectedIds = { name: "id", op: "in", value: ids }
        this.setState({ selectedIds: selectedIds })
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="flex-fill">
                        <div className="row">
                            <div className="col-md-8"> <h4>List of Report Columns</h4></div>
                            <div className="col-md-4 text-right">
                                <button type="button" className="btn btn-secondary ml-3" onClick={this.handleShow}><i className="fas fa-download"></i> {ButtonType.Export}</button>
                                <button type="button" className="btn btn-primary ml-3" onClick={this.toggleFilter}><i className="fas fa-filter"></i> {ButtonType.AddFilter}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showExportModal} onHide={this.handleClose}>
                    <ExportComponent isSelectedAll={this.state.isSelectedAll} isNoHeader={this.state.isNoHeader} columnList={this.columnList} handleClose={this.handleClose} exportTo={this.exportTo} isExporting={this.state.isExporting} fileNamePrefix='reportColumns_list_' clearExportModal={this.clearExportModal} />
                </Modal>
                <div className="d-flex">
                    <div className="flex-fill">
                        <div className="card" >
                            <div className="card-body p-0">
                                <div className="sticky-table-wrapper" >
                                    <StickyTable borderWidth={0} rightStickyColumnCount={1} wrapperRef={Wrapper}>
                                        <Row className="sticky-table-header">
                                            <Cell><div className="custom-checkbox custom-control">
                                                <input type="checkbox" className="custom-control-input"
                                                    value="all" onChange={this.onSelectionChange.bind(this)} id="all" name="all" checked={this.state.isAllChecked} />
                                                <label htmlFor="all" className="custom-control-label">&nbsp;</label>
                                            </div></Cell>
                                            <Sorting columnName="id" displayName="Id" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="report_name" displayName="Report" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="column_id" displayName="Column" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="label" displayName="Label" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="type" displayName="Type" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="column_type" displayName="Column Type" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="definition" displayName="Definition" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="style" displayName="Style" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="hide" displayName="Hide" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="default" displayName="Default" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="sort_direction" displayName="Sort Direction" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="sort_priority" displayName="Sort Priority" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="summarization" displayName="Summarization" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="total" displayName="Total" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="steps" displayName="Steps" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="display_order" displayName="Display Order" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="field_name" displayName="Field" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="is_unique_key" displayName="Is Unique key" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="precision" displayName="Precision" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="group_by" displayName="Group By" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="base_type" displayName="Base Type" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="created_at" displayName="Created At" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Sorting columnName="updated_at" displayName="Updated At" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                            <Cell>Report Column Fields</Cell>
                                            <Cell>Report column Filters</Cell>
                                            <Cell>Alert Metric Report Columns</Cell>
                                            <Cell>Alert Metrics</Cell>
                                            <Cell>Action</Cell>
                                        </Row>
                                        {!this.state.isLoading && this.state.result.records.length > 0 && (
                                            this.state.result.records?.map((item: ReportColumnsListModel) => {
                                                return (
                                                    <Row key={item.id} >
                                                        <Cell className="p-0 text-center">
                                                            <div className="custom-checkbox custom-control">
                                                                <input type="checkbox" className="custom-control-input"
                                                                    value={item.id || ''} checked={item.isChecked || false} onChange={this.onSelectionChange.bind(this)} id={"checkbox" + item.id} name={"checkbox" + item.id} />
                                                                <label htmlFor={"checkbox" + item.id} className="custom-control-label">&nbsp;</label>
                                                            </div>
                                                        </Cell>
                                                        <Cell>{item.id}</Cell>
                                                        <Cell><Link to={"/report/detail/" + item.report.value}>{item.report.text}</Link></Cell>
                                                        <Cell>{item.column_id}</Cell>
                                                        <Cell>{item.label}</Cell>
                                                        <Cell>{item.type}</Cell>
                                                        <Cell>{item.column_type}</Cell>
                                                        <Cell><span className="sortlength" title={item.definition}>{item.definition}</span></Cell>
                                                        <Cell>{item.style}</Cell>
                                                        <Cell>{item.hide}</Cell>
                                                        <Cell>{item.default}</Cell>
                                                        <Cell>{item.sort_direction}</Cell>
                                                        <Cell>{item.sort_priority}</Cell>
                                                        <Cell>{item.summarization}</Cell>
                                                        <Cell>{item.total}</Cell>
                                                        <Cell>{item.steps}</Cell>
                                                        <Cell>{item.display_order}</Cell>
                                                        <Cell>{item.feild}</Cell>
                                                        {item.is_unique_key && <Cell><i className="fas fa-check text-success text1"></i></Cell>}
                                                        {!item.is_unique_key && <Cell><i className="fas fa-times text-danger text1"></i></Cell>}
                                                        <Cell>{item.precision}</Cell>
                                                        {item.group_by && <Cell> <i className="fas fa-check text-success text1"></i></Cell>}
                                                        {!item.group_by && <Cell> <i className="fas fa-times text-danger text1"></i></Cell>}
                                                        <Cell>{item.base_type}</Cell>
                                                        <Cell>{ConvertDateTime(item.created_at, 'LLL')}</Cell>
                                                        <Cell>{ConvertDateTime(item.updated_at, 'LLL')}</Cell>

                                                        {item.report_column_fields && <Cell>
                                                            {item.report_column_fields.map((feild: any, i, arr) => {
                                                                return (
                                                                    <span key={feild.value}>
                                                                        {feild && <NavLink to={"/report-column-field/detail/" + feild.value}>{feild.text}{i != (arr.length-1) ? ', ' : ''}</NavLink>}
                                                                    </span>
                                                                );
                                                            })}
                                                        </Cell>}
                                                        {!item.report_column_fields && <Cell></Cell>}
                                                        {item.report_column_filters && <Cell>
                                                            {item.report_column_filters.map((data: any, i, arr) => {
                                                                return (
                                                                    <span key={data}>
                                                                        {data && <NavLink to={"/report-column-filter/detail/" + data}>ReportColumnFilter #{data} {i != (arr.length-1) ? ', ' : ''}</NavLink>}
                                                                    </span>
                                                                );
                                                            })}
                                                        </Cell>}
                                                        {!item.report_column_filters && <Cell></Cell>}
                                                        {item.alert_metric_report_columns && <Cell>
                                                            {item.alert_metric_report_columns.map((data: any, i, arr) => {
                                                                return (
                                                                    <span key={data}>
                                                                        <a href="#">AlertMetricReportColumn #{data}{i != (arr.length-1) ? ', ' : ''}</a>
                                                                    </span>
                                                                );
                                                            })}
                                                        </Cell>}
                                                        {!item.alert_metric_report_columns && <Cell></Cell>}
                                                        {item.alert_metrics && <Cell>
                                                            {item.alert_metrics.map((data: any, i, arr) => {
                                                                return (
                                                                    <span key={data.value}>
                                                                        <a href="#">{data.text}{i != (arr.length-1) ? ', ' : ''}</a>
                                                                    </span>
                                                                );
                                                            })}
                                                        </Cell>}
                                                        {!item.alert_metrics && <Cell></Cell>}
                                                        <Cell>
                                                            <Link to={"/report-column/detail/" + item.id} className="fas fa-info-circle"></Link>
                                                        </Cell>
                                                    </Row>
                                                );
                                            })
                                        )}
                                    </StickyTable>
                                    {!this.state.isLoading && this.state.result.records.length === 0 && <div className="text-danger text-center"> <img src="../../no-data.jpg" className="img-fluid" alt="No Data Found" /></div>}
                                    {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between">
                                <PaginationRecordInfo currentPage={this.state.currentPage} totalRecords={this.state.result?.paging.total_items} pageSize={this.state.pageSize} />
                                <Pagination currentPage={this.state.currentPage} pageCount={this.state.result?.paging.page_count} onChangePage={this.pageChange} />
                            </div>
                        </div>
                    </div>
                    {this.state.isShowFilter &&
                        <div className="page-filter">
                            <div className="d-flex justify-content-between">
                                <h6>Filter by</h6>
                                {/* <div onClick={this.toggleFilter} className="closebtn">
                                    <i className="fa fa-times"></i>
                                </div> */}
                                <button className="btn btn-default btn-sm" onClick={this.toggleFilter}> <i className="fa fa-times"></i></button>
                            </div>
                            <hr />
                            <Filter columnList={this.state.Columns} applyFilter={this.applyFilter} clearFilter={this.clearFilter} />
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportColumnsList);