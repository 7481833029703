import React, { Component, Fragment } from 'react'
import { ReportColumnDetailModel } from './models/report.column.detail.model'
import { ReportColumnsService } from './services/report.columns.service';
import { HttpResponse } from '../../../../core';
import { ConvertDateTime, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ReportColumnTabs } from './ReportColumnTabs';
import { NavLink } from "react-router-dom";
import { Editor } from '../../../../shared';
import { DropdownItemModel } from '../../../../shared/models';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ReportColumnDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ReportColumnDetail extends Component<any, IState>{
    private service: ReportColumnsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportColumnsService();
    }

    initialState: Partial<IState> = {
        result: {
            base_type: "",
            column_id: "",
            column_type: "",
            default: "",
            definition: JSON,
            display_order: 0,
            field: {
                text: "",
                value: 0
            },
            field_id: 0,
            group_by: false,
            hide: "",
            id: 0,
            is_unique_key: false,
            label: "",
            precision: 0,
            report: {
                text: "",
                value: 0
            },
            report_column_fields: [],
            report_id: 0,
            sort_direction: "",
            sort_priority: 0,
            steps: "",
            style: "",
            summarization: "",
            total: "",
            type: ""
        },
        parsedData: "",
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getReportColumnsById(this.props.match.params.id)
            .then((res: HttpResponse<ReportColumnDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    }, () => {
                        this.setState({
                            parsedData: JSON.stringify(this.state.result?.definition, null, '\t')
                        })
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportColumns,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for Report Columns  '{this.state.result?.label === "" ? "-" : this.state.result?.label}'</h4>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportColumnTabs id={this.props.match.params.id} url="/report-column/detail/" />
                                    {!this.state.isLoading &&  <>
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        {this.state.result?.report &&
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header font-weight-bold"> Report </div>
                                                    <div className="card-body"><NavLink to={"/report/detail/" + this.state.result?.report.value}>{this.state.result?.report.text}</NavLink></div>
                                                </div>
                                            </div>
                                        }
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Column </div>
                                                <div className="card-body">{this.state.result?.column_id}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Label </div>
                                                <div className="card-body">{this.state.result?.label}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Type </div>
                                                <div className="card-body">{this.state.result?.type}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Base Type </div>
                                                <div className="card-body">{this.state.result?.base_type}</div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Column Type </div>
                                                <div className="card-body">{this.state.result?.column_type}</div>
                                            </div>
                                        </div>
                                        {this.state.result?.style && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Style </div>
                                                <div className="card-body">{this.state.result?.style}</div>
                                            </div>
                                        </div>}
                                        {this.state.result?.sort_direction && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Sort Direction </div>
                                                <div className="card-body">{this.state.result?.sort_direction}</div>
                                            </div>
                                        </div> }

                                    </div>
                                    <div className="row">

                                    {this.state.result?.summarization  && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Summarization </div>
                                                <div className="card-body">{this.state.result?.summarization}</div>
                                            </div>
                                        </div>}
                                        {this.state.result?.total && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Total </div>
                                                <div className="card-body">{this.state.result?.total}</div>
                                            </div>
                                        </div>}
                                        {this.state.result?.report_column_fields && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Report Column Field</div>
                                                {this.state.result?.report_column_fields && <div className="card-body">
                                                    {this.state.result?.report_column_fields.map((item: DropdownItemModel, index) => {
                                                        return (
                                                            <span key={item.value}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/report-column-field/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}

                                        {this.state.result?.display_order && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Display Order </div>
                                                <div className="card-body">{this.state.result?.display_order}</div>
                                            </div>
                                        </div>}

                                    </div>
                                    <div className="row">

                                    {this.state.result?.steps && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Steps </div>
                                                <div className="card-body">{this.state.result?.steps}</div>
                                            </div>
                                                </div> }


                                        {this.state.result?.field && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Field</div>
                                                <div className="card-body">{this.state.result?.field.text}</div>
                                            </div>
                                        </div>}

                                         <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Precision </div>
                                                <div className="card-body">{this.state.result?.precision}</div>
                                            </div>
                                        </div>

                                        {this.state.result?.group_by && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold"> Group By</div>
                                                <div className="card-body"> <i className="fas fa-check text-success text2"></i></div>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="row">
                                        {this.props.match.params.id && this.state.result?.definition != null && this.state.result?.definition && <div className="col-md-9">
                                            <h5>Definition</h5>
                                            <Editor StringData={this.state.parsedData} FormateType="json" />
                                        </div>}
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
