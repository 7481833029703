import React, { Component, Fragment, createRef } from "react";
import { ExtractorDetailModel } from "./models";
import { ExtractorService } from "./services/extractor.service";
import { HttpResponse } from "../../../../../core";
import {
  HandleNotFoundResponse,
  ConvertDateTime,
} from "../../../../../shared/helpers";
import { NotFoundResponseArea } from "../../../../../shared/enums";
import { ExtractorTabs } from "./ExtractorTabs";
import { NavLink, Link } from "react-router-dom";
import { Accordion, Card, Button, Form } from "react-bootstrap";
import { Editor } from "../../../../../shared";
import { Loader } from '../../../../../shared/loaders';

interface IState {
  result?: ExtractorDetailModel;
  isLoading?: boolean;
}

export class ExtractorDetail extends Component<any, IState> {
  private service: ExtractorService;
  private time_range: any;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ExtractorService();
    this.time_range = createRef();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      type: "",
      available: false,
      schema_id: 0,
      last_pushed_by: "",
      platforms: {
        text: "",
        value: 0,
      },
      tags: [],
      extraction_scheduling_recipe_id: 0,
      extractor_rate_limit_id: 0,
      semaphore_id: 0,
      extractor_time_zone_specification: {
        id: 0,
        is_global: false,
        configurable: false,
        global_default: "",
        configurable_choices: [],
      },
      scope_details: [],
      dependent_reports: [],
      exclude_from_last_month_collection: false,
      exclude_from_last_seven_collection: false,
      exclude_from_automatic_scheduling: false,
      exclude_from_multiple_daily_collection: false,
      use_date_for_reporting: false,
      auth_provider: "",
      prompts: [],
      history: [],
      instructions: "",
      schedule_locked: false,
      dateless: false,
      issues: [],
      fetch: "",
      parse: "",
      available_fields: {},
      extractor_assets: [],
      rebuild_connection: false,
      created_at: new Date(),
      updated_at: new Date(),
    },
    isLoading: false,
  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
  //function to load the initial view data coming from the API.
  loadData() {
    this.setLoading(true);
    this.service
      .getExtractorById(this.props.match.params.id)
      .then((res: HttpResponse<ExtractorDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Extractor, this.props);
      });
  }

  renderTags(tags) {
    const new_tags: any = [];
    tags.map((tag) => new_tags.push(tag.text));
    return <p>{new_tags.join(", ")}</p>;
  }

  renderAvailableDomais(scopes) {
    let available_domain: any = [];
    scopes.map((sc) => available_domain.push(sc.text));
    return <p>{JSON.stringify(available_domain)}</p>;
  }

  renderAvailableFields(fields) {
    const new_fields = JSON.stringify(fields, null, "\t");
    // return Object.keys(fields).map(item => (
    //   `{"${item}" =>
    //       [${fields[item].map(ele => (
    //         JSON.stringify(ele)
    //       ))}]
    //   }`
    // ));
    return <pre>{new_fields}</pre>;
  }

  selectScope(e: any) {
    
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Extractor '{this.state.result?.type}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <ExtractorTabs
                  id={this.props.match.params.id}
                  url="/extractor/detail/"
                />
                 {!this.state.isLoading &&  <>  
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.type && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Type</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.type}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-4">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Available</b>
                      </div>
                      <div className="card-body">
                        {this.state.result?.available ? (
                          <i className="fas fa-check text-success text2"></i>
                        ) : (
                          <i
                            className="fas fa-times text-danger text1"
                            title="&#x2718;"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.result?.schema_id && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Schema</b>
                        </div>
                        <div className="card-body">
                          <Link
                            to={
                              "/schema/detail/" + this.state.result?.schema_id
                            }
                          >
                            Extractor {this.state.result?.schema_id}{" "}
                            {this.state.result?.type}
                          </Link>
                          {this.state.result?.scope_details &&
                            this.state.result?.scope_details.length > 0 && (
                              <div>
                                <b>Scopes</b>
                                <ul>
                                  {this.state.result?.scope_details.map(
                                    (scope) => (
                                      <li key={scope.value}>
                                        <Link
                                          to={"/scope/detail/" + scope.value}
                                        >
                                          {scope.text}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.last_pushed_by && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Last pushed by</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.last_pushed_by}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.platforms && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Platforms</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.platforms && (
                            <Link
                              to={
                                "/platform/detail/" +
                                this.state.result?.platforms.value
                              }
                            >
                              {this.state.result?.platforms.text}
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.tags &&
                    this.state.result?.tags.length > 0 && (
                      <div className="col-4">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Tags</b>
                          </div>
                          <div className="card-body">
                            {this.renderTags(this.state.result?.tags)}
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.result?.extraction_scheduling_recipe_id && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Extraction scheduling recipe</b>
                        </div>
                        <div className="card-body">
                          <Link
                            to={
                              "/extraction-scheduling-recipe/detail/" +
                              this.state.result?.extraction_scheduling_recipe_id
                            }
                          >
                            ExtractionSchedulingRecipe #
                            {this.state.result?.extraction_scheduling_recipe_id}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-4">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Extraction rate limit</b>
                      </div>
                      <div className="card-body">
                        {this.state.result?.extractor_rate_limit_id === null ? (
                          <p>
                            None set. Uses{" "}
                            <a href="https://github.com/staqapp/staq_model-concern/blob/master/lib/staq_model/utils/default_rate_limit.rb">
                              default
                            </a>
                          </p>
                        ) : (
                          <Link
                            to={
                              "/extractor-rate-limit/detail/" +
                              this.state.result?.extractor_rate_limit_id
                            }
                          >
                            ExtractionRateLimit #
                            {this.state.result?.extractor_rate_limit_id}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.result?.semaphore_id && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Extraction Semaphore</b>
                        </div>
                        <div className="card-body">
                          <Link
                            to={
                              "/extractor-semaphore/detail/" +
                              this.state.result?.semaphore_id
                            }
                          >
                            ExtractionSemaphore #
                            {this.state.result?.semaphore_id}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.extractor_time_zone_specification && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Extraction time zone specification</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.extractor_time_zone_specification
                            .is_global ? (
                            <Link
                              to={
                                "/extractor-time-zone-specification/detail/" +
                                this.state.result
                                  ?.extractor_time_zone_specification.id
                              }
                            >
                              Extractor Time Zone Specification: global('
                              {
                                this.state.result
                                  ?.extractor_time_zone_specification
                                  .global_default
                              }
                              ')(ID{" "}
                              {
                                this.state.result
                                  ?.extractor_time_zone_specification.id
                              }
                              )
                            </Link>
                          ) : (
                            <Link
                              to={
                                "/extractor-time-zone-specification/detail/" +
                                this.state.result
                                  ?.extractor_time_zone_specification.id
                              }
                            >
                              Extractor Time Zone Specification: configurable (
                              {
                                this.state.result
                                  ?.extractor_time_zone_specification
                                  ?.configurable_choices?.length
                              }
                              )(ID{" "}
                              {
                                this.state.result
                                  ?.extractor_time_zone_specification.id
                              }
                              )
                            </Link>
                          )}
                          <br/>
                          <br />
                        <a href="https://sites.google.com/a/staq.com/staqnowledged/infrastructure/production/time-zones">What is this?</a>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.exclude_from_last_month_collection && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Exclude from last month collection</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.exclude_from_automatic_scheduling && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Exclude from automatic scheduling</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.exclude_from_last_seven_collection && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Exclude from last seven collection</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result
                    ?.exclude_from_multiple_daily_collection && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Exclude from multiple daily collection</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.use_date_for_reporting && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Use data for reporting</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.auth_provider && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Auth provider</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.auth_provider}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.prompts &&
                    this.state.result?.prompts.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Prompts</b>
                          </div>
                          <div className="card-body">
                            <pre>
                              {JSON.stringify(
                                this.state.result?.prompts,
                                null,
                                "\t"
                              )}
                            </pre>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.result?.rebuild_connection && (
                    <div className="col-12">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Rebuild Connection data</b>
                        </div>
                        <div className="card-body">
                          <div
                            className="col-md-6"
                            style={{ paddingRight: "0px" }}
                          >
                            <h6 className="m-b-2">Scope(s): </h6>
                            <Form.Group controlId="ControlSelect2">
                              <Form.Control
                                as="select"
                                multiple
                                style={{ height: "120px" }}
                              >
                                <option selected>All Scope</option>
                                {this.state.result?.scope_details?.map(
                                  (scope) => (
                                    <option key={scope.value}>
                                      {scope.text}
                                    </option>
                                  )
                                )}
                              </Form.Control>
                            </Form.Group>
                            <h6 className="m-b-2">Time Range: </h6>
                            <input
                              type="text"
                              maxLength={255}
                              name="type"
                              className="form-control"
                              ref={this.time_range}
                            />
                            <br />
                            <button
                              type="button"
                              className="btn btn-lg btn-default"
                            >
                              Rebuild
                            </button>
                          </div>
                          <small>
                              * Choose one or more scopes and an optional time
                              range. To rebuild all scopes just click "Rebuild".
                              Time range should have a start/finish date and be
                              in the format of a range. Rebuilding for all time
                              is necessary if the schema has been changed.
                              Otherwise try to pick a time range that covers
                              only the data that needs rebuilding.
                            </small>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.dependent_reports &&
                    this.state.result?.dependent_reports.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Dependent Reports</b>
                          </div>
                          <div className="card-body">
                            {this.state.result?.dependent_reports.map(
                              (report) => (
                                <Link
                                  className="badge badge-light m-1"
                                  to={"/report/detail/" + report.value}
                                >
                                  {report.text}
                                </Link>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                {this.state.result?.history &&
                  this.state.result?.history.length > 0 && (
                    <div>
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>History</b>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive" style={{'maxHeight':'600px'}}>
                              <table className="table table-md table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Author</th>
                                    <th scope="col">Commit</th>
                                  </tr>
                                </thead>
                                {this.state.result?.history && (
                                  <tbody>
                                    {this.state.result?.history.map(
                                      (item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{item.Date}</td>
                                            <td>
                                              <p>
                                                <a
                                                  href={
                                                    "mailto:" +
                                                    item["Author Email"]
                                                  }
                                                >
                                                  {item["Author Name"]}
                                                </a>
                                              </p>
                                              {item["Committer Name"] ==
                                              "Github" ? (
                                                <small>
                                                  {item["Committer Name"]}
                                                </small>
                                              ) : null}
                                            </td>
                                            <td>
                                              <a
                                                target="_blank"
                                                href={
                                                  "https://github.com/staqapp/extractors/commit/" +
                                                  item.Hash
                                                }
                                              >
                                                {item.Commit}
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                )}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
               
                <div className="row">
                  {this.state.result?.instructions && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Instructions</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.instructions}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.schedule_locked && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Schedule locked</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.dateless && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Dateless</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.created_at && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Created at</b>
                        </div>
                        <div className="card-body">
                          {ConvertDateTime(
                            this.state.result?.created_at,
                            "LLL"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.updated_at && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Updated at</b>
                        </div>
                        <div className="card-body">
                          {ConvertDateTime(
                            this.state.result?.updated_at,
                            "LLL"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.scope_details &&
                    this.state.result?.scope_details.length > 0 && (
                      <div className="col-4">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Available domain</b>
                          </div>
                          <div className="card-body">
                            {this.renderAvailableDomais(
                              this.state.result?.scope_details
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row">
                  {this.state.result?.available_fields && (
                    <div className="col-12">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Available fields</b>
                        </div>
                        <div className="card-body"  style={{'maxHeight':'600px', 'overflowY':'auto'}}>
                          {this.renderAvailableFields(
                            this.state.result?.available_fields
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <h5>Fetch</h5>
                      <Editor
                        StringData={
                          this.state.result?.fetch == null
                            ? ""
                            : this.state.result?.fetch
                        }
                        FormateType="yaml"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <h5>Parse</h5>
                      <Editor
                        StringData={
                          this.state.result?.parse == null
                            ? ""
                            : this.state.result?.parse
                        }
                        FormateType="yaml"
                      />
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  {this.state.result?.extractor_assets &&
                    this.state.result?.extractor_assets.length > 0 && (
                      <div>
                        <div className="col-12">
                          <div className="card card-silver shadow-sm">
                            <div className="card-header">
                              <b>Extractor assets</b>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-md table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Created at</th>
                                      <th scope="col">Updated at</th>
                                      <th scope="col">File</th>
                                    </tr>
                                  </thead>
                                  {this.state.result?.extractor_assets && (
                                    <tbody>
                                      {this.state.result?.extractor_assets.map(
                                        (item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                {ConvertDateTime(
                                                  item.created_at,
                                                  "LLL"
                                                )}
                                              </td>
                                              <td>
                                                {ConvertDateTime(
                                                  item.updated_at,
                                                  "LLL"
                                                )}
                                              </td>
                                              <td>
                                                <a href="">{item.file_name}</a>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
