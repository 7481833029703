import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { TabScrolling } from '../../../shared'
import { IsAuthorize } from '../../../shared/authorization/check-access';
import { Role } from '../../../shared/authorization/enums';
interface IProps {
    id: number,
    url: string
}
export class ConnectionTabs extends Component<IProps> {
    render() {
        return (
            <Fragment> 
                
                    <ul className="nav nav-tabs" id="myTab largetab" role="tablist" >
                        {IsAuthorize([Role.TAM, Role.Dev, Role.AM, Role.Support]) && <li className="nav-item">
                            <NavLink to={"/connection/connection-form/" + this.props.id} className={this.props.url == "/connection/connection-form/" + this.props.id ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                                aria-controls="edit" aria-selected={this.props.url == "/connection/connection-form/" + this.props.id ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                        </li>}
                        {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support, Role.Manager]) && <li className="nav-item">
                            <NavLink to={"/connection/detail/" + this.props.id} className={this.props.url == "/connection/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                                aria-controls="show" aria-selected={this.props.url == "/connection/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                        </li>}
                        {IsAuthorize([Role.TAM, Role.Dev]) && <li className="nav-item">
                            <NavLink to={"/connection/maintain/" + this.props.id} className={this.props.url == "/connection/maintain/" ? "nav-link active" : "nav-link"} id="connection-maintain-tab" data-toggle="tab" href="#connection-maintain" role="tab"
                                aria-controls="connection-maintain" aria-selected={this.props.url == "/connection/maintain/" ? "true" : "false"}><i className="fas fa-check"></i>&nbsp;Maintain</NavLink>
                        </li>}
                        {/* <li className="nav-item">
                            <NavLink to={"/connection/visualize/" + this.props.id} className={this.props.url == "/connection/visualize/" ? "nav-link active" : "nav-link"} id="data-tab" data-toggle="tab" href="#data" role="tab"
                                aria-controls="data" aria-selected={this.props.url == "/connection/visualize/" ? "true" : "false"}><i className="fas fa-eye"></i>&nbsp; Visualize</NavLink>
                        </li> */}
                          {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) &&  <li className="nav-item">
                            <NavLink to={"/connection/delete/" + this.props.id} className={this.props.url == "/connection/delete/" ? "nav-link active" : "nav-link"} id="storage-tab" data-toggle="tab" href="#storage" role="tab"
                                aria-controls="storage" aria-selected={this.props.url == "/connection/delete/" ? "true" : "false"}><i className="far fa-trash-alt"></i>&nbsp;Delete</NavLink>
                        </li>}
                        <li className="nav-item">
                            <NavLink to={"/connection/data-engine-jobs/" + this.props.id} className={this.props.url == "/connection/data-engine-jobs/" ? "nav-link active" : "nav-link"} id="jobs-tab" data-toggle="tab" href="#jobs" role="tab"
                                aria-controls="jobs" aria-selected={this.props.url == "/connection/data-engine-jobs/" ? "true" : "false"}><i className="fas fa-cog"></i>&nbsp;Data Engine Jobs</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/connection/backups/" + this.props.id} className={this.props.url == "/connection/backups/" ? "nav-link active" : "nav-link"} id="backups-tab" data-toggle="tab" href="#backups" role="tab"
                                aria-controls="backups" aria-selected={this.props.url == "/connection/backups/" ? "true" : "false"}><i className="fas fa-archive"></i>&nbsp;Backups</NavLink>
                        </li>
                    </ul>
                <br />
            </Fragment>
        )
    }
}
