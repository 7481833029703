import React, { Component, Fragment, createRef } from "react";
import { ConnectionSemaphoresPostModel } from "./models/connection.semaphores.post.model";
import { EditConnectionSemaphoresModel } from "./models/edit.connection.semaphores.model";
import { DropdownItemModel } from "../../../../shared/models";
import { ConnectionsSemaphoresService } from "./services/connection.semaphores.service";
import { CommonService } from "../../../../shared/services/common.service";
import { HttpResponse } from "../../../../core";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import { ButtonType } from "../../../../shared/enums";
import { ConnectionSemaphoresTabs } from "./ConnectionSemaphoresTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../shared/loaders";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConnectionFormPopup } from "..";
import { Modal } from 'react-bootstrap';
import { IsAuthorize } from "../../../../shared/authorization/check-access";
import { Role } from "../../../../shared/authorization/enums";
interface IState {
  connectionSemaphores?: ConnectionSemaphoresPostModel;
  editconnectionSemaphores?: EditConnectionSemaphoresModel;
  id?: number;
  timeout_seconds?: number; 
  size?: number;

  timeSecondError?: string;
  sizeError?: string;

  connection_id?: number;
  connectionList?: DropdownItemModel[];
  connectionName?: string;
  connectionError?: string;

  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;

  showConnectionForm?: boolean;
    isNew?:boolean;
    isChildUpdate?:boolean;
}
export class ConnectionSemaphoresForm extends Component<any, IState> {
  //declaring service type
  private service: ConnectionsSemaphoresService;
  private commonService: CommonService;

  private networkCall: any;
  private timeSecondRef: any;
  private sizeRef: any;

  constructor(props) {
    super(props);
    this.service = new ConnectionsSemaphoresService();
    this.commonService = new CommonService();
    this.state = this.initialState;
    this.timeSecondRef = createRef();
    this.sizeRef = createRef();
    this.updateConnection = this.updateConnection.bind(this);
  }

  initialState: Partial<IState> = {
    connectionSemaphores: {
      id: 0,
      connection_id: 0,
      size: 0,
      timeout_seconds: 0,
    },
    id: 0,
    size: 1,
    timeout_seconds: 3600,

    connection_id: 0,
    connectionName: "",
    connectionList: [],
    connectionError: "",
    sizeError: "",
    timeSecondError: "",

    isShowtext: "New",
    isSave: "",
    isSaving: false,

    showConnectionForm:false,
    isNew:false,
    isChildUpdate:false
  };

  componentDidMount() {
    if (this.props.match.params.id > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      event.path[0].id !== "connectionName" &&
      event.path[0].id !== "connection" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
    ) {
      this.setState(
        {
          connectionList: [],
        },
        () => {}
      );
    }
  }; 

  updateConnection(isChildUpdate,name,id)
  {
    this.setState({isChildUpdate:isChildUpdate,connectionName:name,connection_id:id},()=>{      
    })
  }

  handleClose = () => {
      this.setState({ showConnectionForm: false }, () => {
      });
  }

  handleShowConnection = () => {        
      this.setState({ showConnectionForm: true, isNew:true }, () => {

      });
  }

  handleShowEditConnection = () => {        
      this.setState({ showConnectionForm: true, isNew:false }, () => {

      });
  }

  loadData() {
    this.service
      .editConnectionSemaphores(this.props.match.params.id)
      .then((res: HttpResponse<EditConnectionSemaphoresModel>) => {
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            connectionName:
              res.result?.connection == null ? "" : res.result?.connection.text,
            connection_id:
              res.result?.connection == null ? 0 : res.result?.connection.value,
            size: res.result?.size,
            timeout_seconds: res.result?.timeout_seconds,
          });
        }
      })
      .catch((ex) => {
        if (ex.status == 404) {
          this.props.history.push("/connection-semaphore/list");
        }
      });
  }

  postData(connectionSemaphores: ConnectionSemaphoresPostModel) {
    this.setSavingFlag(true);
    this.service.postConnectionSemaphores(connectionSemaphores).then(
      (res: HttpResponse<ConnectionSemaphoresPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              connectionSemaphores: res.result,
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Connection Semaphore Successfully Created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/connection-semaphore/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/connection-semaphore/connection-semaphore-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/connection-semaphore/connection-semaphore-form/" +
                    this.state?.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  updateData(connectionSemaphores: ConnectionSemaphoresPostModel) {
    this.setSavingFlag(true);
    this.service.updateConnectionSemaphores(connectionSemaphores).then(
      (res: HttpResponse<ConnectionSemaphoresPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              connectionSemaphores: res.result,
            },
            () => {
              ShowSuccessMessage("Connection Semaphore successfully Updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/connection-semaphore/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/connection-semaphore/connection-semaphore-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/connection-semaphore/connection-semaphore-form/" +
                    this.state?.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  private getConnections(text: string) {
    this.commonService
      .getConnectionsByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            connectionList: res.result,
          });
        }
      });
  }

  //*************** * Connection************************* //

  onConnectionChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getConnections(value);
      }, 600);
    }
    this.setState({
      connectionName: value,
      connection_id: value === "" ? 0 : value,
      connectionError: "",
    });
  };

  selectedConnection(item) {
    this.setState({
      connection_id: item.value,
      connectionName: item.text,
      connectionList: [],
    });
  }

  renderConnection = () => {
    if (this.state.connectionList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.connectionList?.map((item, index) => (
          <li
            id="connection"
            key={index}
            onClick={() => this.selectedConnection(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  };
  //*************** *Connection************************* //

  handleChange = (event: any) => {
    if (event.target.name == "connectionName") {
      this.onConnectionChange(event);
    } else {
      const isCheckbox = event.target.type === "checkbox";
      this.setState({
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      });
    }
    if (this.timeSecondRef.current.value) {
      this.setState({ timeSecondError: "" });
    }
    if (this.sizeRef.current.value) {
      this.setState({ sizeError: "" });
    }
  };

  validate = () => {
    let connectionError = "";
    let timeSecondError = "";
    let sizeError = "";
    if (!this.state.connection_id) {
      connectionError = "Connection  can't be blank";
    }

    if (!this.timeSecondRef.current.value) {
      timeSecondError = "Timeout Seconds can't be blank";
    }

    if (!this.sizeRef.current.value) {
      sizeError = "Size can't be blank";
    }

    if (connectionError || timeSecondError || sizeError) {
      this.setState({
        connectionError: connectionError,
        timeSecondError: timeSecondError,
        sizeError:sizeError
      });
      return false;
    }

    if (!connectionError || !timeSecondError || !sizeError) {
      connectionError = "";
      timeSecondError = "";
      sizeError="";
      this.setState({ connectionError: connectionError,timeSecondError:timeSecondError,sizeError:sizeError });
      return true;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate)
    {
    const isValid = this.validate();
    let connectionSemaphoreData: ConnectionSemaphoresPostModel = {
      id: this.state.id,
      connection_id:
        this.state.connection_id === 0 ? null : this.state.connection_id,
      size: this.state.size,
      timeout_seconds: this.state.timeout_seconds,
    };

    if (isValid) {
      if (connectionSemaphoreData.id === 0) {
        this.postData(connectionSemaphoreData);
      } else {
        this.updateData(connectionSemaphoreData);
      }
    }
  }
  };

  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };
  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>
                {this.state.isShowtext} Connection Semaphore{" "}
                {this.props.match.params.id != 0 && (
                  <span>
                    {" "}
                    'AccountSourceOverride #{this.props.match.params.id}'
                  </span>
                )}
              </h4>
            </div>
            <Modal backdrop='static' keyboard={false} size="xl" show={this.state.showConnectionForm} onHide={this.handleClose}>
                            <ConnectionFormPopup  updateConnection={this.updateConnection}  handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.connection_id}/>
                        </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.match.params.id != 0 && (
                      <ConnectionSemaphoresTabs
                        id={this.props.match.params.id}
                        url="/connection-semaphore/edit/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group mb-2 position-relative ">
                          <label>
                            Connection<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group mt-2">
                            <input 
                              autoComplete="off"
                              id="connectionName"
                              name="connectionName"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.connectionName}
                              className={
                                !this.state.connectionError
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderConnection()}
                            {IsAuthorize([Role.Dev, Role.TAM, Role.AM, Role.Support]) &&  <div className="input-group-append">
                            <button type="button" className="btn btn-success" onClick={this.handleShowConnection}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                            {IsAuthorize([Role.Dev, Role.TAM,Role.AM, Role.Support, Role.Manager]) &&  <button type="button"  className={`btn btn-primary`} disabled={this.state.connection_id==0}  onClick={this.handleShowEditConnection}><i className="far fa-edit"></i></button>}
                            </div>}
                            <div className="invalid-feedback">
                              {this.state.connectionError}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Timeout Seconds
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  We consider a locked semaphore stale after
                                  this many seconds. Should be set to something
                                  larger than what the typical mission is
                                  expected to take. If a lock goes stale, it
                                  automatically gets released.
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            ref={this.timeSecondRef}
                            name="timeout_seconds"
                            value={this.state.timeout_seconds}
                            onChange={this.handleChange}
                            className={
                              !this.state.timeSecondError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.timeSecondError}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Size
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  This is the maximum number of simultaneous
                                  connections we allow for this one connection
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            name="size"
                            ref={this.sizeRef}
                            value={this.state.size}
                            onChange={this.handleChange}
                            className={
                              !this.state.sizeError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.sizeError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/connection-semaphore/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="200px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
