import React, { Component, Fragment } from 'react'
import { FieldsService } from './services';
import {  ConvertDateTime, HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { FieldsDetailModel, ScopeFieldSchemaChangeModel, ReportColumnFieldModel, ReportDataSourceFilterModel, ReportColumnFielViewdModel, ReportDataSourceFilterViewModel, ReportDataSourceModel } from './models';
import { DropdownItemModel } from '../../../../shared/models';
import { Editor } from '../../../../shared';
import { FieldsTabs } from '.';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: FieldsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class FieldsDetail extends Component<any, IState> {
    private service: FieldsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new FieldsService();
    }

    initialState: Partial<IState> = {
        result:{
            id:0,
            column_type:"",
            custom_load_sql:"",
            exclude_from_load:false,
           field_classification:{
               text:"",
               value:""
           },
            format:"",
            is_unique_key:false,
            label:"",
            name:"",            
            report_column:{
                id:0,
                label:"",
                report_id:0
            },
            report_column_fields:[],
            report_data_source_filters:[],
            scaling_factor:0,
            scope:{
                text:"",
                value:""
            },
            scope_field_schema_changes:[],
            type:"",
            options:JSON
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getFieldsById(this.props.match.params.id)
            .then((res: HttpResponse<FieldsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    },()=>{
                        this.setState({
                            parsedData: JSON.stringify(this.state.result?.options, null, '\t')
                            }); 
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Fields,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Field   &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">                           
                        <FieldsTabs id={this.props.match.params.id}  scopeId={this.props.match.params.scopeId} schemaName={this.props.match.params.schemaName} isSchema={(this.props.match.params.schemaName== SourceType.CONNECTION.toLocaleLowerCase() ||this.props.match.params.schemaName== SourceType.CUSTOM_CONNECTION.toLocaleLowerCase())?true:false} url="/field/detail/" />                                               
                        {!this.state.isLoading &&  <> 
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row"> 
                            {this.state.result?.scope &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Scope</b>
                                        </div>
                                        <div className="card-body"><NavLink to={"/scope/detail/" + this.state.result?.scope.value}>{this.state.result?.scope.text}</NavLink></div>
                                       
                                    </div>
                                </div>}                           
                                {this.state.result?.name && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.name}
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.field_classification && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Field Classification</b>
                                            </div>
                                            <div className="card-body"><NavLink to={"/field-classification/detail/" + this.state.result?.field_classification.value}>{this.state.result?.field_classification.text}</NavLink></div>
                                        </div>
                                    </div>}

                                    {this.state.result?.type && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Type</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.type}
                                            </div>
                                        </div>
                                    </div>}                                  
                               
                            
                            {this.state.result?.column_type && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Column Type</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.column_type}
                                            </div>
                                        </div>
                                    </div>}  

                                    {this.state.result?.label && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Label</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.label}
                                            </div>
                                        </div>
                                    </div>}  

                                    {this.state.result?.scaling_factor!=0 && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Scaling Factor</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.scaling_factor}
                                            </div>
                                        </div>
                                    </div>}  

                                    {this.state.result?.format && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Format</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.format}
                                            </div>
                                        </div>
                                    </div>}  

                            </div>

                            <div className="row"> 
                            <div className="col-md-6 mt-4  mb-4   ">
                                            <h5>Options</h5>
                                            <Editor StringData={this.state.parsedData} FormateType="json" />
                                        </div>
                            </div>

                            <div className="row"> 
                            {this.state.result?.custom_load_sql && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Custom Load Sql</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.custom_load_sql}
                                            </div>
                                        </div>
                                    </div>}  

                                    {this.state.result?.report_column && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Source Report Column</b>
                                            </div>
                                     <div className="card-body"><NavLink to={"/report-column/detail/" + this.state.result?.report_column.id}>{this.state.result?.report_column.label}&nbsp;(Report #{this.state.result?.report_column.report_id})</NavLink></div>
                                        </div>
                                    </div>}

                            {this.state.result?.is_unique_key && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Is Unique Key</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    } 
                                     {this.state.result?.exclude_from_load && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Exclude From Load</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    } 
                            </div>


                            <div className="row"> 
                            {this.state.result?.scope_field_schema_changes.length != 0 && <div className="col-12">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Schema Changes</div>
                                                {this.state.result?.scope_field_schema_changes && <div className="card-body">
                                                    {this.state.result?.scope_field_schema_changes.map((item: ScopeFieldSchemaChangeModel, index) => {
                                                        return (
                                                            <span key={item.id}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/scope-field-schema-change/detail/" + item.id}><strong>{index + 1}:</strong> {item.change_type}</NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}

                                        {this.state.result?.report_column_fields && <div className="col-12">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Dependent Report Column Fields</div>
                                                {this.state.result?.report_column_fields && <div className="card-body">
                                                    {this.state.result?.report_column_fields.map((item: ReportColumnFielViewdModel, index) => {
                                                        return (
                                                            <span key={index}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/report-column-field/detail/" + item.id}><strong>{index + 1}:</strong>{item.report_column.label}&nbsp;(Report&nbsp;{item.report_column.report_id}) </NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}

                                        
                                {this.state.result?.report_data_source_filters && <div className="col-12">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Dependent Report Data Source Filters</div>
                                                {this.state.result?.report_data_source_filters && <div className="card-body">
                                                    {this.state.result?.report_data_source_filters.map((item: ReportDataSourceModel, index) => {
                                                        return (
                                                            <span key={item.report_data_source.report.value}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.report_data_source.report.value}><strong>{index + 1}:</strong> {item.report_data_source.report.text}</NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}
                            </div>  
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}                               
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 