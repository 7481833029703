import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { FeatureFlagsListResponseModel, FeatureFlagsDetailModel, AddUpdateFeatureFlagsModel, FeatureFlagsDeleteResponseModel, FeatureFlagsDeleteModel } from "../models";



export class FeatureFlagsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getFeatureFlagsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<FeatureFlagsListResponseModel>(AppConfig.apiEndpoint + '/v1/feature_flags/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getFeatureFlagsById(id: number) {
    return this.wrapper.get<FeatureFlagsDetailModel>(AppConfig.apiEndpoint + '/v1/feature_flags/' + id);
  }  

  postFeatureFlags(FeatureFlags: AddUpdateFeatureFlagsModel) {
    return this.wrapper.post<AddUpdateFeatureFlagsModel>(AppConfig.apiEndpoint + '/v1/feature_flags', FeatureFlags);
}

editFeatureFlags(id?: number) {
    return this.wrapper.get<AddUpdateFeatureFlagsModel>(AppConfig.apiEndpoint + '/v1/feature_flags/edit/' + id);
}

updateFeatureFlags(FeatureFlags: AddUpdateFeatureFlagsModel) {
    return this.wrapper.put<AddUpdateFeatureFlagsModel>(AppConfig.apiEndpoint + '/v1/feature_flags/' + FeatureFlags.id, FeatureFlags);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<FeatureFlagsDeleteModel>(AppConfig.apiEndpoint + '/v1/feature_flags/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<FeatureFlagsDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/feature_flags/' + id);
}

  exportFeatureFlags(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/feature_flags/export', exportData);
  }


} 