import React, { Component, Fragment } from 'react'
import { StaqContactTabs } from '.';
import { HttpResponse } from '../../../core';
import { ButtonType, NotFoundResponseArea } from '../../../shared/enums';
import { HandleNotFoundResponse, ShowSuccessMessage } from '../../../shared/helpers';
import { DeleteResponseModel, DropdownItemModel, LinkResourcesResponseModel } from '../../../shared/models'
import { StaqContactService } from './services/staq.contact.service';
import { NavLink } from "react-router-dom";
import { StaqContactDeleteModel } from './models';

interface IState {
    result?: StaqContactDeleteModel;
    deleteResult?: DeleteResponseModel;
    isDoubleleClick?: boolean;
}
export class DeleteStaqContact extends Component<any, IState> {
    private service: StaqContactService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new StaqContactService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            name: "",
            account_domains: [],
            accounts_admins: [],
            connections: [],
            custom_connections: [],
            report_change_histories: []
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false
    }
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<StaqContactDeleteModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.StaqContacts, this.props)
            });
    }


    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<DeleteResponseModel>) => {
                    if (res && res.result) {
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Staq Contact Successfully Deleted.");
                                this.props.history.push('/admin/list');
                            }
                        });
                    }
                });

        }
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Delete Staq Contact '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <StaqContactTabs id={this.props.match.params.id} name={this.props.match.params.name} url="/admin/delete/" />
                                <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this staq contact  <strong>“{this.props.match.params.name}”</strong> ?</div>

                                <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>
                                <div className="row ">
                                    <div className="col">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Staq Contact</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/admin/detail/" + this.props.match.params.id}>{this.state.result?.name}</NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.result?.account_domains.length != 0 && <div className="col">
                                        {this.state.result?.account_domains.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-info shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Account Domains </h4> </div>
                                                    <div className="card-body">
                                                        <NavLink className="badge badge-light text-link" to={"/account-domain/detail/" + item.value}>{item.text}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.accounts_admins.length != 0 && <div className="col">
                                        {this.state.result?.accounts_admins.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-danger shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Accounts Admins </h4> </div><div className="card-body">
                                                        <div className="badge badge-light text-link">Accounts Admins #{item.value}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.connections.length != 0 && <div className="col">
                                        {this.state.result?.connections.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-warning shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Connections</h4> </div><div className="card-body">
                                                        <NavLink className="badge badge-light text-link" to={"/connection/detail/" + item.value}>{item.text}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.custom_connections.length != 0 && <div className="col">
                                        {this.state.result?.custom_connections.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-success shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Custom Connections </h4> </div><div className="card-body">
                                                        <NavLink className="badge badge-light text-link" to={"/custom-connection/detail/" + item.value}>{item.text}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.report_change_histories.length != 0 && <div className="col">
                                        {this.state.result?.report_change_histories.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-success shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Report Change Histories </h4> </div><div className="card-body">
                                                        <NavLink className="badge badge-light text-link" to={"/report-change-history/detail/" + item.value}>Report Change History #{item.value}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}

                                </div>

                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick} onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/admin/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}
