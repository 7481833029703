import React, { Component, Fragment } from 'react'
import { TagLocationService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { TagLocationDetailModel } from './models';
import { TagLocationTabs } from './TagLocationTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: TagLocationDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class TagLocationDetail extends Component<any, IState> {
    private service: TagLocationService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new TagLocationService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,
            location: "",
            tag_location_category_id: 0,
            position: 0,
            enabled: true,
            tag_location_category:{},
            created_at: new Date(),
            updated_at: new Date()
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getTagLocationById(this.props.match.params.id)
            .then((res: HttpResponse<TagLocationDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.TagLocationCategory,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Tag Location &nbsp;'TagLocation #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <TagLocationTabs id={this.props.match.params.id} url="/tag-location/detail/" />                           
                        {!this.state.isLoading &&  <>  
                        <h5 className="mb-3">Basic Info</h5> 
                        <div className="row">                                      
                            {this.state.result?.location && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Location</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.location} 
                                            {/* <NavLink className="badge badge-light text-link" to={"/tag-location-category/detail/" + this.state.result?.id}>{this.state.result?.name} </NavLink> */}
                                            </div>
                                        </div>
                                    </div>}                             
                            {this.state.result?.tag_location_category && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Tag location category</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink className="badge badge-light text-link" to={"/tag-location-category/detail/" + this.state.result?.tag_location_category_id}>{this.state.result?.tag_location_category.text} </NavLink>
                                            </div>
                                        </div>
                                    </div>}                             
                             <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Position</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.position} 
                                            </div>
                                        </div>
                                    </div>                         
                              <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Enabled</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.enabled == true ? <i className="fas fa-check text-success text1"></i> : <i className="fas fa-times text-danger text1"></i>}
                                            </div>
                                        </div>
                                    </div>                            
                                   
                                    {this.state.result?.created_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                        </div> 
                        </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}         
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 