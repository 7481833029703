import React, { Component, Fragment } from "react";
import { ConnectionTabs } from ".";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ConnectionsService } from "./services";
import { HttpResponse } from "../../../core";
import {
  CollectionMissionPostModel,
  LinkResponseConnectionMaintain,
  CancelCollectionMission, CollectionMissionResponseModel, VerifyCollectionMissionPostModel, VerifyCollectionMissionResponseModel
} from "./models";
import { ConvertDateTime, ShowSuccessMessage, Decrypt, GetUserInfo, HandleNotFoundResponse, ShowErrorMessage, GetCollectionMissionInterestIntervalList } from "../../../shared/helpers";
import { InterestIntervalEnum, NotFoundResponseArea } from "../../../shared/enums";
import moment from "moment";
import { DropdownItemOptionModel } from "../../../shared/models";

interface IState {
  linkConnection?: LinkResponseConnectionMaintain;
  id?: number;
  isShow?: boolean;

  connection_id?: number;
  triggered_by?: string;
  interactive?: boolean;
  rerun?: boolean;
  spread_missions?: boolean;
  mission_start_at?: Date | null;
  mission_end_at?: Date | null;
  interest_interval?: string;
  selected_interest_interval?: string;
  override_worker_name?: string;
  connection_type?: string;
  currentDate?: Date;
}
export class ConnectionMaintain extends Component<any, IState> {
  private service: ConnectionsService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ConnectionsService();
    this.verifyConnection = this.verifyConnection.bind(this);
    this.cancelMission = this.cancelMission.bind(this);
  }

  initialState: Partial<IState> = {
    id: 0,
    isShow: true,


    connection_id: 0,
    triggered_by: "",
    connection_type: "connection",
    interactive: false,
    rerun: false,
    spread_missions: true,
    mission_end_at: new Date(),
    mission_start_at: new Date(),
    currentDate: new Date(),
    interest_interval: "UserDefined",
    selected_interest_interval: "UserDefined",
    override_worker_name: "",
    
  };

  componentDidMount() {
    this.getLinkConnectionMission();
    let currentDate = moment().subtract(1,"days").toDate();
    this.setState({mission_end_at:currentDate,mission_start_at:currentDate,currentDate:currentDate})
  }

  getLinkConnectionMission() {
    this.service
      .getLinkConnectionMaintainById(this.props.match.params.id)
      .then((res: HttpResponse<LinkResponseConnectionMaintain>) => {
        if (res && res.result) {
          this.setState({
            linkConnection: res.result,
            isShow:res.result.available
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex,NotFoundResponseArea.Connection,this.props) 
      });
  }


  verifyConnection() {
    let email =GetUserInfo().email;    
    let data: VerifyCollectionMissionPostModel = {            
      connection_id: this.props.match.params.id,
      triggered_by: email,   
      connection_type: "connection"      
    };
    this.service
      .verifyConnectionData(data)
      .then((res: HttpResponse<VerifyCollectionMissionResponseModel>) => {
        if (res && res.result) {
          ShowSuccessMessage("We are verifying your connection. Please wait.");
        }
      });
  }

  postData(data: CollectionMissionPostModel) {
    this.service.postCollectionMission(data).then((res: HttpResponse<CollectionMissionResponseModel>) => {
      if (res && res.result) {
        if (this.state?.selected_interest_interval == InterestIntervalEnum.user_defined) {
          ShowSuccessMessage("Mission created for " + ConvertDateTime(this.state.mission_start_at, "LLL") + "... " + ConvertDateTime(this.state.mission_end_at, "LLL"))
        }
        if (this.state?.selected_interest_interval == InterestIntervalEnum.last_month) {
          ShowSuccessMessage("Mission created for " + InterestIntervalEnum.last_month)
        }
        if (this.state?.selected_interest_interval == InterestIntervalEnum.last_seven) {
          ShowSuccessMessage("Mission created for " + InterestIntervalEnum.last_seven)
        }
        if (this.state?.selected_interest_interval == InterestIntervalEnum.recent_missing_data) {
          ShowSuccessMessage("Mission created for " + InterestIntervalEnum.recent_missing_data)
        }
        if (this.state?.selected_interest_interval == InterestIntervalEnum.this_month) {
          ShowSuccessMessage("Mission created for " + InterestIntervalEnum.this_month)
        }
        if (this.state?.selected_interest_interval == InterestIntervalEnum.ytd_missing_data) {
          ShowSuccessMessage("Mission created for " + InterestIntervalEnum.ytd_missing_data)
        }
      }
    })
      .catch((ex) => {
        ShowErrorMessage(this.state.selected_interest_interval + " missions require a specific mission start and end date. Please try again.");
      });
  }

  
  cancelMission()
  {
    this.service
    .cancelCollectionMissionById(this.props.match.params.id,0)
    .then((res: HttpResponse<CancelCollectionMission>) => {
      if (res && res.result) {
        ShowSuccessMessage(res.result.mission_count +" missions and "+res.result.task_count+" tasks for this connection have been canceled.");
        this.props.history.push("/connection/detail/"+this.props.match.params.id);
      }
    })    
  }

  handleSubmit = (event) => {
    let email =GetUserInfo().email;
    event.preventDefault();
    // let startDate = moment(this.state.mission_start_at).date;
    // let endDate = moment(this.state.mission_end_at).date;   
   
    // if(startDate > endDate)
    // {
    //   ShowErrorMessage("UserDefined missions require a specific mission start and end date. Please try again.");
    // }
    let collectionMissionData: CollectionMissionPostModel = {            
      connection_id: this.props.match.params.id,
      triggered_by: email,
      interactive: false,
      rerun: false,
      spread_missions: this.state.spread_missions,
      connection_type: "connection",
      interest_interval: this.state.interest_interval,
      mission_end_at: this.state?.selected_interest_interval == InterestIntervalEnum.user_defined ? this.state.mission_end_at?.toISOString() : null,
      mission_start_at: this.state?.selected_interest_interval == InterestIntervalEnum.user_defined ? this.state.mission_start_at?.toISOString() : null,
      override_worker_name: this.state.override_worker_name
      
    };
    
    this.postData(collectionMissionData);
  };

  

  handleChange = (event: any) => {
    if (event.target.name = "interest_interval") {
      const { options, selectedIndex } = event.target;
      this.setState({ selected_interest_interval: event.target.selectedOptions[0].text })

      let currentDate = moment().toDate();
      let currentDate2 = moment().subtract(1, "days").toDate();
      if (event.target.selectedOptions[0].text == InterestIntervalEnum.last_month) {
        let previousMonthFirstDate = moment().subtract(1, "months").startOf('month').toDate();
        let previousMonthLastDate = moment().subtract(1, "months").endOf('month').toDate();
        this.setState({ mission_start_at: previousMonthFirstDate, mission_end_at: previousMonthLastDate })
      }


      if (event.target.selectedOptions[0].text == InterestIntervalEnum.user_defined) {
        this.setState({ mission_start_at: currentDate2, mission_end_at: currentDate2 })
      }
      else {
        this.setState({ mission_start_at: null, mission_end_at: null })
      }
    }
    const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value,
    });
  };

  handleDateChange = (date, name) => {
    if (name == "mission_start_at") {
      this.setState({ mission_start_at: date });
    }
    if (name == "mission_end_at") {
      this.setState({
        mission_end_at: date,
      });
    }
  };

  

  
  render() {
    return (
      <Fragment>
        {this.state.isShow && (
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>Maintain</h4>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ConnectionTabs
                      id={this.props.match.params.id}
                      url="/connection/maintain/"
                    />
                    <div className="row">
                      <div className="col">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <h6>Connection Verification</h6>
                          </div>
                          <div className="card-body">
                           
                              <button
                                onClick={this.verifyConnection}
                                className="btn btn-primary" >
                                Verify
                              </button>
                              <p>
                                Note: Custom Connections cannot currently be
                                verified
                              </p>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                          <div className="card card-silver shadow-sm">
                            <div className="card-header">
                              <h6>Run New Collection Missions</h6>
                            </div>
                            <div className="card-body">
                            <form  onSubmit={this.handleSubmit}>
                              <div className="row">
                              <div className="col form-group ">
                                <label>
                                  * Interest Interval
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        These are the same categories used when
                                        automatically scheduling missions.
                                        Typically you want UserDefined, but be
                                        sure to set the fields below. Note that
                                        'CurrentDay' extractors will only ever
                                        get missions for today's date.
                                      </Tooltip>
                                    }
                                  >
                                    <a href="javascript:void(0)" className="btn btn-outline-dark border-0 btn-sm ml-1">
                                      <i className="fas fa-info-circle"></i>
                                    </a>
                                  </OverlayTrigger>
                                </label>
                                <select
                                    className="form-control "
                                    id="interest_interval"
                                    name="interest_interval"
                                    value={this.state.interest_interval} 
                                    onChange={this.handleChange}
                                  >
                                    {GetCollectionMissionInterestIntervalList.map((item: DropdownItemOptionModel, index) => {
                                      return (
                                        <option key={index} value={item.text}>{item.text}</option>

                                      );
                                    })}
                                  </select>
                              </div>
                              <div className="col form-group">
                                <label>
                                  * Override Sidekiq Worker Name
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Overrides the normal behavior of
                                        staqcollect's MissionWorkerFinder. Don't
                                        type anything here unless you know what
                                        you're doing ☺
                                      </Tooltip>
                                    }
                                  >
                                    <a href="javascript:void(0)" className="btn btn-outline-dark border-0 btn-sm ml-1">
                                      <i className="fas fa-info-circle"></i>
                                    </a>
                                  </OverlayTrigger>
                                </label>
                                <input
                                  type="text"
                                  maxLength={255}
                                  name="override_worker_name"
                                  value={this.state.override_worker_name}
                                  onChange={this.handleChange}
                                  className="form-control"
                                />
                              </div>
                              </div>
                              <div className="row">
                                <div className="col form-group">
                                  <label>* UserDefined Interval Start At</label>
                                  <DatePicker
                                    popperModifiers={{
                                      preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport",
                                      },
                                    }}
                                    dateFormat="yyyy MMMM d"
                                    peekNextMonth
                                    maxDate={this.state.currentDate}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={this.state.mission_start_at}
                                    onChange={(date) =>
                                      this.handleDateChange(
                                        date,
                                        "mission_start_at"
                                      )
                                    }
                                    className="form-control "
                                    readOnly={this.state.interest_interval == "UserDefined" ? false : true}
                                  />
                                  <small>
                                    Only used for the UserDefined interval
                                </small>
                                </div>

                                <div className="col form-group">
                                  <label>* UserDefined Interval End At</label>
                                  <DatePicker
                                    popperModifiers={{
                                      preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport",
                                      },
                                    }}
                                    dateFormat="yyyy MMMM d"
                                    maxDate={this.state.currentDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={this.state.mission_end_at}
                                    onChange={(date) =>
                                      this.handleDateChange(
                                        date,
                                        "mission_end_at"
                                      )
                                    }
                                    className="form-control "
                                    readOnly={this.state.interest_interval == "UserDefined" ? false : true}
                                  />
                                  <small>
                                    Only used for the UserDefined interval
                                </small>
                                </div>
                              </div>
                              <div className="custom-control custom-checkbox mb-4">
                                <input
                                  type="checkbox"
                                  name="spread_missions"
                                  checked={this.state.spread_missions}
                                  onChange={this.handleChange}
                                  className="custom-control-input"
                                  id="spread_missions"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="spread_missions"
                                >
                                  Spread multiple missions out{" "}
                                  {
                                    this.state.linkConnection
                                      ?.spread_mission_period
                                  }{" "}
                                  minutes apart.
                                </label>
                              </div>

                              
                              <button
                                className="btn btn-success">
                                Run
                              </button>
                              </form>
                            </div>
                          </div>
                      </div>
                      
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <h6>Cancel Missions</h6>
                          </div>
                          <div className="card-body">
                            
                              <p>
                                Fail all missions which are either new or
                                in_progress. Don't do this unless you know what
                                you're doing and have spoken with a member of
                                the Integrations team.
                              </p>
                              <button onClick={this.cancelMission}
                                className="btn  btn-danger">
                                Cancel
                              </button>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

{!this.state.isShow && 
          <div>
<div className="d-flex justify-content-between align-items-center mb-3">
              <h4>Maintain</h4>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ConnectionTabs
                      id={this.props.match.params.id}
                      url="/connection/maintain/"
                    />
                     <p>This connection is not available (or does not have an extractor), and thus cannot run any collection or verification missions.</p>
                    </div>
                    </div>
                    </div>        
           
          </div>
          </div>}
        
      </Fragment>
    );
  }
}
