import React, { Component, Fragment } from 'react'
import { DatabaseEngineDetailModel } from './models'
import { DatabaseEngineService } from './services/database.engines.service';
import { HttpResponse } from '../../../core';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { NavLink } from "react-router-dom";
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: DatabaseEngineDetailModel;
    isLoading?: boolean;
}
export  class DatabaseEgineDetail extends Component<any, IState>  {
    private service: DatabaseEngineService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new DatabaseEngineService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,             
            created_at:null,                                                                        
            name:"",
            updated_at:null,            
            db_type:"",
            env_var:"",                        
            version:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getDatabaseEngineById(this.props.match.params.id)
            .then((res: HttpResponse<DatabaseEngineDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.DatabaseEngine,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Database Engine '{this.state.result?.name==""?"-":this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {/* <CustomerFaviconTabs id={this.props.match.params.id} url="/customer-favicon/detail/" /> */}
                            {!this.state.isLoading &&  <>  
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">
                            {this.state.result?.name &&    <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Name</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.name}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.db_type &&  <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Db Type</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.db_type}
                                        </div>
                                    </div>
                                </div>}
                               {this.state.result?.version && <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Version</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.version}
                                        </div>
                                    </div>
                                </div>}                              
                            </div>

                            
                            
                            <div className="row">  
                            {this.state.result?.env_var && <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Env Var</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.env_var}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.created_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 