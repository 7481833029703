import React, { Component, useRef } from 'react';

//import brace from 'brace';
//import  ace from 'ace-builds'
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
//import extendYamlMode from './custom-editor/extendYamlMode'
// import 'brace/mode/json';
// import 'brace/mode/yaml';
// import 'brace/mode/mysql';
// import 'brace/theme/monokai';

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-mysql';
//extendYamlMode(ace);


interface IProps {
  StringData?: string;
  FormateType: string;
  onChange?: Function;
  Height?:string
}
export class Editor extends Component<IProps, any> {
  editor:any;
  randomNumber:string='';
  constructor(props)
  {
    super(props);
    this.onChange = this.props.onChange?.bind(this)
    this.randomNumber=this.getRandomNumber();
  }
  getRandomNumber(){
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
  }
 
  onChange(value) {     
    this.props.onChange?.bind(value)
}
initAceEditor () {
  // this.editor = ace.edit("ace-editor-"+this.randomNumber, {
  //   mode: `ace/mode/${this.props.FormateType}`,
  //   theme: 'ace/theme/monokai',
  //   maxLines: 37,
  //   minLines: 10,
  //   fontSize: 18,
  //   tabSize: 2,
  //   value:this.props.StringData,
  //   showPrintMargin: true,
  // });
}

  render() {
    return (
      <div>
        
        {/* <div id={"ace-editor-"+this.randomNumber} style={{width:"800px",height:this.props.Height||"250px"}}></div> */}
        
        <AceEditor mode={this.props.FormateType} theme="monokai" 
        name={"ace-editor-"+this.randomNumber} fontSize={14}
         onChange={this.onChange}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          height={this.props.Height||"250px"}
          width="100%"
          value={this.props.StringData}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            mode:this.props.FormateType
          }}                
        />
      </div>
    )
  }
  componentDidMount(){
    //this.initAceEditor();
  }
 
 }
  

