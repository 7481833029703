export const GetDaysNumber = (value: any) => {
    let day = '';
    switch (value) {
        case "Sunday":
            return day = "0"

        case "Monday":
            return day = "1"

        case "Tuesday":
            return day = "2"

        case "Wednesday":
            return day = "3"

        case "Thursday":
            return day = "4"

        case "Friday":
            return day = "5"            
        case "Saturday":
            return day = "6"

        case "All":
            return day = "7"
    }
    return day;

}