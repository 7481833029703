

 export const GetStrategyList = [
    { text: "CUSTOM", value: "Custom" },
    { text: "CURRENT_DAY", value: "CurrentDay" },
    { text: "DAILY", value: "Daily" },
    { text: "DFA", value: "DFA" },
    { text: "MONTHLY", value: "Monthly" },
    { text: "YESTERDAY", value: "Yesterday" }
    
]

    
