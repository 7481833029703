import React, { Component, Fragment } from "react";
import { DeleteResponseModel } from "../../../../shared/models";
import { SubscriptionCategoryService } from "./services/subscription.category.service";
import { HttpResponse } from "../../../../core";
import {
  ShowSuccessMessage,
  HandleNotFoundResponse,
} from "../../../../shared/helpers";
import { SubscriptionCategoryTabs } from "./SubscriptionCategoryTabs";
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from "../../../../shared/enums";
import { SubscriptionCategoryLinkResourcesResponseModel } from "./models/subscription.category,link.resource.responses.model";
import {Link} from 'react-router-dom'

interface IState {
  result?: SubscriptionCategoryLinkResourcesResponseModel;
  deleteResult?: DeleteResponseModel;
  isDoubleleClick?: boolean;
}
export class DeleteSubscriptionCategory extends Component<any, IState> {
  private service: SubscriptionCategoryService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new SubscriptionCategoryService();
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
      label: "",
      subscriptions: [],
    },
    deleteResult: { isdeleted: false },
    isDoubleleClick: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.service
      .getLinkedResourcesById(this.props.match.params.id)
      .then(
        (res: HttpResponse<SubscriptionCategoryLinkResourcesResponseModel>) => {
          if (res && res.result) {
            this.setState({
              result: res.result,
            });
          }
        }
      )
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.SubscriptionCategory,
          this.props
        );
      });
  }

  deleteRecord() {
    if (!this.state.isDoubleleClick) {
      this.setState({ isDoubleleClick: true });
      this.service
        .deleteSubscriptionCategoryById(this.props.match.params.id)
        .then((res: HttpResponse<DeleteResponseModel>) => {
          if (res && res.result) {
            this.setState(
              {
                deleteResult: res.result,
              },
              () => {
                if (this.state.deleteResult?.isdeleted) {
                  ShowSuccessMessage(
                    "Subscription Category Successfully Deleted."
                  );
                  this.props.history.push("/subscription-category/list");
                }
              }
            );
          }
        });
    }
  }
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Delete Subscription Category '{this.state.result?.name}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <SubscriptionCategoryTabs
                  id={this.props.match.params.id}
                  url="/subscription-category/delete/"
                />
                <div className="alert font-weight-bold  alert-light">
                  {" "}
                  <i className="fas fa-exclamation-circle text1"></i> Are you
                  sure you want to delete this Subscription Category{" "}
                  <strong>"{this.state.result?.name}”</strong> ?
                </div>

                <p className="mt-4 text-danger">
                  <i className="fas fa-exclamation-triangle text1"></i> The
                  following related items may be deleted or orphaned:
                </p>
                <div className="row ">
                  <div className="col-4">
                    <div className="card card-primary shadow-sm">
                      <div className="card-header">
                        <h4>Subscription Category</h4>
                      </div>
                      <div className="card-body">
                        <NavLink
                          className="badge badge-light text-link"
                          to={
                            "/subscription-category/detail/" +
                            this.props.match.params.id
                          }
                        >
                          SubscriptionCategory '
                          {!this.state.result?.name
                            ? this.state.result?.id
                            : this.state.result?.name}
                          '
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    {this.state.result?.subscriptions &&
                      this.state.result?.subscriptions.length > 0 && (
                        <div className="col-12">
                          <div className="card card-silver shadow-sm">
                            <div className="card-header">
                              <h4>Subscriptions</h4>
                            </div>
                            <div className="card-body">
                              <ol>
                                {this.state.result?.subscriptions.map(
                                  (item) => (
                                    <li key={item.value}><Link to={"/subscription/detail/" + item.value}>{item.text}</Link></li>
                                  )
                                )}
                              </ol>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="mx-auto">
                <button
                  className="btn btn-lg btn-danger"
                  disabled={this.state.isDoubleleClick}
                  onClick={this.deleteRecord}
                >
                  {" "}
                  {ButtonType.Delete}
                </button>
                <NavLink
                  type="button"
                  className="btn btn-lg btn-default ml-3"
                  to="/subscription-category/list"
                >
                  {ButtonType.Cancel}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
