import React from "react";
import { Route } from "react-router-dom";
import { AccountForm, AccountDetail, AccountConnections, AccountSources, AccountInstalledApps, AccountDataEngineJobs } from "../components/accounts";
import { AccountDomainForm, AccountDomainDetail, DeleteAccountDomain } from "../components/accounts/account-domain";
import { AccountSourceOverridesForm, AccountSourceOverridesDetail, DeleteAccountSourceOverrides } from "../components/accounts/account-source-overrides";
import AccountList from "../components/accounts/AccountList";
import AccountDomainList from '../components/accounts/account-domain/AccountDomainList'
import AccountSourceOverridesList from "../components/accounts/account-source-overrides/AccountSourceOverridesList";
import ConnectionList from "../components/accounts/connections/ConnectionList";
import { ConnectionForm, ConnectionDetails, DeleteConnection, ConnectionBackups, ConnectionDataEngineJobs, ConnectionMaintain, ConnectionVisualize } from "../components/accounts/connections";
import CustomConnectionList from "../components/accounts/custom-connection/CustomConnectionList";
import CustomConnectionMaintain from "../components/accounts/custom-connection/CustomConnectionMaintain";
import CustomConnectionBackups from "../components/accounts/custom-connection/CustomConnectionBackups";
import CustomConnectionDataEngineJobs from "../components/accounts/custom-connection/CustomConnectionDataEngineJobs";
import FileUpload from "../components/accounts/custom-connection/FileUpload";
import ConnectionSemaphoresList from "../components/accounts/connections/connections-semaphores/ConnectionSemaphoresList";
import { ConnectionSemaphoresForm,ConnectionSemaphoresDetail,DeleteConnectionSemaphores } from "../components/accounts/connections/connections-semaphores";
import {CustomConnectionForm, DeleteCustomConnection, CustomConnectionDetails} from '../components/accounts/custom-connection'
import InboundFilesList from '../components/accounts/custom-connection/inbound-files/InboundFilesList'
import { InboundFilesDetail } from "../components/accounts/custom-connection/inbound-files/InboundFilesDetail";
import  InboundEmailAddressesList from "../components/accounts/custom-connection/inbound-email-addresses/InboundEmailAddressesList";
import { InboundEmailAddressesDetails} from "../components/accounts/custom-connection/inbound-email-addresses/InboundEmailAddressesDetails";
import { InboundEmailAddressesForm } from "../components/accounts/custom-connection/inbound-email-addresses/InboundEmailAddressesForm";
import { InboundEmailAddressesDelete } from "../components/accounts/custom-connection/inbound-email-addresses";
import ExtractorRateLimitList from "../components/accounts/connections/platforms/Extractor/ExtractorRateLimit/ExtractorRateLimitList"
import {ExtractorRateLimitDetail} from "../components/accounts/connections/platforms/Extractor/ExtractorRateLimit/ExtractorRateLimitDetail"
import {ExtractorRateLimitForm} from "../components/accounts/connections/platforms/Extractor/ExtractorRateLimit/ExtractorRateLimitForm"
import {DeleteExtractorRateLimit} from "../components/accounts/connections/platforms/Extractor/ExtractorRateLimit/DeleteExtractorLimitDetail"
import ExtractorSemaphoresList from "../components/accounts/connections/platforms/Extractor/ExtractorSemaphores/ExtractorSemaphoresList"
import {ExtractorSemaphoresDetail} from "../components/accounts/connections/platforms/Extractor/ExtractorSemaphores/ExtractorSemaphoresDetail"
import {ExtractorSemaphoresForm} from "../components/accounts/connections/platforms/Extractor/ExtractorSemaphores/ExtractorSemaphoresForm"
import {DeleteExtractorSemaphores} from "../components/accounts/connections/platforms/Extractor/ExtractorSemaphores/DeleteExtractorSemaphoresDetail"
import  InboundEmailMessagesList from "../components/accounts/custom-connection/inbound-email-addresses/inbound-email-messages/InboundEmailMessagesList";
import { InboundEmailMessagesDetails} from "../components/accounts/custom-connection/inbound-email-addresses/inbound-email-messages/InboundEmailMessagesDetails";
import  ExtractorTimeZoneSpecificationsList from "../components/accounts/connections/platforms/Extractor/ExtractorTimeZoneSpecifications/ExtractorTimeZoneSpecificationsList"
import {ExtractorTimeZoneSpecificationsDetail} from "../components/accounts/connections/platforms/Extractor/ExtractorTimeZoneSpecifications/ExtractorTimeZoneSpecificationsDetail"
import { ExtractorForm } from "../components/accounts/connections/platforms/Extractor/ExtractorForm";
import { ExtractorDetail } from "../components/accounts/connections/platforms/Extractor/ExtractorDetail";
import ExtractorList from "../components/accounts/connections/platforms/Extractor/ExtractorList";
import CollectionMission from "../components/accounts/collection-mission/CollectionMission";
import CollectionMissionStateTransitions from '../components/accounts/collection-mission/CollectionMissionStateTransitions';
import CollectionMissionTask from "../components/accounts/collection-mission/CollectionMissionTask";
import ControlMission from '../components/accounts/control-mission/ControlMission'
import ControlMissionTask from "../components/accounts/control-mission/ControlMissionTask";

export function CollectionMissionStateTransitionRoute() {
  return (
    <div className="compLoader">
      <Route path="/collection-mission-state-transition/:id" exact component={CollectionMissionStateTransitions} />      
    </div>
  )
}
export function CollectionMissionTaskRoute() {
  return (
    <div className="compLoader">      
      <Route path="/collection-mission-task/:id" exact component={CollectionMissionTask} />      
    </div>
  )  
}

export function ControlMissionRoute() {
  return (
    <div className="compLoader">
      <Route path="/control_mission/:id" exact component={ControlMission} />      
      <Route path="/control_mission/control_mission_task/:id" exact component={ControlMissionTask} />
    </div>
  )
}

export function AccountRoute() {
  return (
    <div className="compLoader">
      <Route path="/account/list" exact component={AccountList} />
      <Route path="/account/account-form/:id" exact component={AccountForm} />
      <Route path="/account/detail/:id" exact component={AccountDetail} />
      <Route path="/account/connections/:id" exact component={AccountConnections} />
      <Route path="/account/sources/:id" exact component={AccountSources} />
      <Route path="/account/installed-apps/:id" exact component={AccountInstalledApps} />
      <Route path="/account/data-engine-jobs/:id" exact component={AccountDataEngineJobs} />
    </div>
  )
}


export function AccountDomainRoute() {
  return (
    <div className="compLoader">
      <Route path="/account-domain/list" exact component={AccountDomainList} />
      <Route path="/account-domain/account-domain-form/:id" exact component={AccountDomainForm} />
      <Route path="/account-domain/detail/:id" exact component={AccountDomainDetail} />
      <Route path="/account-domain/delete/:id/:name" exact component={DeleteAccountDomain} />
    </div>
  )
}

export function AccounSourceOverridesRoute() {
  return (
    <div className="compLoader">
      <Route path="/account-source-override/list" exact component={AccountSourceOverridesList} />
      <Route path="/account-source-override/account-source-override-form/:id" exact component={AccountSourceOverridesForm} />
      <Route path="/account-source-override/detail/:id" exact component={AccountSourceOverridesDetail} />
      <Route path="/account-source-override/delete/:id" exact component={DeleteAccountSourceOverrides} />
    </div>
  )
}

export function ConnectionsRoute() {
  return (
    <div className="compLoader">
      <Route path="/connection/list" exact component={ConnectionList} />
      <Route path="/connection/connection-form/:id" exact component={ConnectionForm} />
      <Route path="/connection/detail/:id" exact component={ConnectionDetails} />
      <Route path="/connection/delete/:id" exact component={DeleteConnection} />
      <Route path="/connection/backups/:id" exact component={ConnectionBackups} />
      <Route path="/connection/data-engine-jobs/:id" exact component={ConnectionDataEngineJobs} />
      <Route path="/connection/maintain/:id" exact component={ConnectionMaintain} />
      <Route path="/connection/visualize/:id" exact component={ConnectionVisualize} />
      <Route path="/connection/collection-mission/:id" exact component={CollectionMission} />
    </div>
  )
}

export function CustomConnectionsRoute() {
  return (
    <div className="compLoader">
      <Route path="/custom-connection/list" exact component={CustomConnectionList} />
      <Route path="/custom-connection/custom-connection-form/:id" exact component={CustomConnectionForm} />      
      <Route path="/custom-connection/delete/:id" exact component={DeleteCustomConnection} />
      <Route path="/custom-connection/detail/:id" exact component={CustomConnectionDetails} />      
      <Route path="/custom-connection/backups/:id" exact component={CustomConnectionBackups} />
      <Route path="/custom-connection/data-engine-jobs/:id" exact component={CustomConnectionDataEngineJobs} />
      <Route path="/custom-connection/maintain/:id" exact component={CustomConnectionMaintain} />      
      <Route path="/custom-connection/file-upload/:id" exact component={FileUpload} />    
      <Route path="/custom-connection/collection-mission/:id" exact component={CollectionMission} />  
    </div>
  )
}

  export function ConnectionsSemaphoresRoute() {
    return (
      <div className="compLoader">
        <Route path="/connection-semaphore/list" exact component={ConnectionSemaphoresList} />
        <Route path="/connection-semaphore/connection-semaphore-form/:id" exact component={ConnectionSemaphoresForm} />
        <Route path="/connection-semaphore/detail/:id" exact component={ConnectionSemaphoresDetail} />
        <Route path="/connection-semaphore/delete/:id" exact component={DeleteConnectionSemaphores} />       
      </div>
    )
}

export function InboundFilesRoute() {
    return (
      <div className="compLoader">
        <Route path="/inbound-file/list" exact component={InboundFilesList} />
        <Route path="/inbound-file/detail/:id" exact component={InboundFilesDetail} />
        {/* <Route path="/connection-semaphore/connection-semaphore-form/:id" exact component={ConnectionSemaphoresForm} />
        <Route path="/connection-semaphore/detail/:id" exact component={ConnectionSemaphoresDetail} />
        <Route path="/connection-semaphore/delete/:id" exact component={DeleteConnectionSemaphores} />        */}
      </div>
    )
}

export function InboundEmailAddressesRoute() {
  return (
    <div className="compLoader">
      <Route path="/inbound-email-address/list" exact component={InboundEmailAddressesList} />
      <Route path="/inbound-email-address/detail/:id" exact component={InboundEmailAddressesDetails} />
      <Route path="/inbound-email-address/inbound-email-address-form/:id" exact component={InboundEmailAddressesForm} />
      <Route path="/inbound-email-address/delete/:id" exact component={InboundEmailAddressesDelete} />
    </div>
  )
}

export function InboundEmailMessagesRoute() {
  return (
    <div className="compLoader">
      <Route path="/inbound-email-message/list" exact component={InboundEmailMessagesList} />
      <Route path="/inbound-email-message/detail/:id" exact component={InboundEmailMessagesDetails} />
    </div>
  )
}

export function ExtractorRoute() {
  return (
    <div className="compLoader">
      <Route path="/extractor/list" exact component={ExtractorList} />
      <Route path="/extractor/detail/:id" exact component={ExtractorDetail} />
      <Route path="/extractor/extractor-form/:id" exact component={ExtractorForm} />
    </div>
  )
}

export function ExtractorRateLimitRoute() {
  return (
    <div className="compLoader">
      <Route path="/extractor-rate-limit/list" exact component={ExtractorRateLimitList} />
      <Route path="/extractor-rate-limit/detail/:id" exact component={ExtractorRateLimitDetail} />
      <Route path="/extractor-rate-limit/extractor-rate-limit-form/:id" exact component={ExtractorRateLimitForm} />
      <Route path="/extractor-rate-limit/delete/:id" exact component={DeleteExtractorRateLimit} />
    </div>
  )
}

export function ExtractorTimeZoneSpecificationsRoute() {
  return (
    <div className="compLoader">
      <Route path="/extractor-time-zone-specification/list" exact component={ExtractorTimeZoneSpecificationsList} />
      <Route path="/extractor-time-zone-specification/detail/:id" exact component={ExtractorTimeZoneSpecificationsDetail} />
      </div>
  )
}

export function ExtractorSemaphoresRoute() {
  return (
    <div className="compLoader">
      <Route path="/extractor-semaphore/list" exact component={ExtractorSemaphoresList} />
      <Route path="/extractor-semaphore/detail/:id" exact component={ExtractorSemaphoresDetail} />
      <Route path="/extractor-semaphore/extractor-semaphore-form/:id" exact component={ExtractorSemaphoresForm} />
      <Route path="/extractor-semaphore/delete/:id" exact component={DeleteExtractorSemaphores} />
    </div>
  )

  
}