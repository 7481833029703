import { HttpResponse } from './HttpResponse'
import { ShowException, Decrypt } from '../shared/helpers';
import { AppConfig } from './app.config';



export async function http<T>(
  request: RequestInfo
): Promise<HttpResponse<T>> {
  let response: HttpResponse<T> = {} as HttpResponse<T>;
  let actualResponse: any;

  response = await fetch(
    request
  ).then(x => {
    actualResponse = x;
    return actualResponse;
  }).then(x => x.json()).then(x => {
    actualResponse.result = x;
    return actualResponse;
  })
    .catch(ex => {
      return Promise.reject(ex?.message);
    });

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  if (!response.ok) {
    if (response.status == 401) {
      ShowException(response.result ? response.result : response);
      localStorage.clear();
      let currentUrl = window.location.pathname;
      let rtu = getParameterByName("rtu", window.location.search)
      if (rtu) {
        currentUrl = rtu;
      }
      window.location.href = window.location.origin + '/login?rtu=' + currentUrl;
    }
    else if (response.status == 403) {
      ShowException({ "detail": "Your role does not allow to perform this action" });
    }
    else if (response.status == 400 && window.location.pathname == "/login-callback") {
      window.location.href = window.location.origin + '/';
    }
    else {
      ShowException(response.result ? response.result : response);
    }


    // return Promise.reject(response.result ? response.result : response);
    return Promise.reject(response);
  }

  return response;

}


export class HttpWrapper {
  get<T>(
    path: string,
    args: RequestInit = {
      method: "get"
    }
  ): Promise<HttpResponse<T>> {
    this.setHeader(args);
    return http<T>(new Request(path, args));
  };

  delete<T>(
    path: string,
    body?: any,
    args: RequestInit = {
      method: "delete",
      body: JSON.stringify(body)
    }
  ): Promise<HttpResponse<T>> {
    this.setHeader(args);
    return http<T>(new Request(path, args));
  };

  async post<T>(
    path: string,
    body: any,
    args: RequestInit = {
      method: "post", body: JSON.stringify(body)
    }
  ): Promise<HttpResponse<T>> {
    this.setHeader(args);
    return await http<T>(new Request(path, args));
  };

  async put<T>(
    path: string,
    body: any,
    args: RequestInit = {
      method: "put", body: JSON.stringify(body)
    }
  ): Promise<HttpResponse<T>> {
    this.setHeader(args);
    return await http<T>(new Request(path, args));
  };

  async getFileByPost(
    path: string,
    body: any,
    args: RequestInit = {
      method: "post", body: JSON.stringify(body)
    }
  ): Promise<any> {
    this.setHeader(args);
    return await fetch(
      new Request(path, args)
    ).then(x => x.arrayBuffer());
  };

  private setHeader(args: RequestInit) {
    let token = Decrypt<string>(localStorage.getItem(AppConfig.TokenKey)) as string;
    let headers = new Headers();
    headers.append("Content-Type", 'application/json');
    if (token) {
      headers.append('Authorization', `bearer ${token}`)
    }
    args.headers = headers;
  }
  
  async uploadFileByPost<T>(
    path: string,
    body: FormData,
    args: RequestInit = {
      method: "post", body: body
    }
  ): Promise<HttpResponse<T>> {
    let token = Decrypt<string>(localStorage.getItem(AppConfig.TokenKey)) as string;
    let headers = new Headers();
    if (token) {
      headers.append('Authorization', `bearer ${token}`)
    }
    args.headers = headers;
    return await http<T>(new Request(path, args));
  };
}