import {  toast } from 'react-toastify';

export const ShowSuccessMessage=(message:string)=>{
    toast.success(message);    
}
export const ShowErrorMessage=(message?:string)=>{
    toast.error(message?message:"Sorry, an exception occured.");    
}
export const ShowException=(ex:any)=>{    
    console.error(ex);
    if(ex && ex.detail){
        toast.error(ex.detail);    
    }else{
        ShowErrorMessage();
    }
    
}
export const ShowWarningMessage=(message:string)=>{    
    toast.warn(message);    
}
