import { IExportColumnModel } from "../../../../../../shared/models";

export const ReportColumnFiltersColumnList: IExportColumnModel[] = [
    {
        isAllChecked: true,
        name: "Fields from report column filters",
        value: "report_column_filters",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },           
            {
                name: "Display order",
                value: "Display order",
                checked: true,
            },
                    
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report column",
        value: "report_columns",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },           
            {
                name: "Column",
                value: "Column id",
                checked: true,
            },
            {
                name: "Label",
                value: "Label",
                checked: true,
            },
            {
                name: "Type",
                value: "Type",
                checked: true,
            },
            {
                name: "Column type",
                value: "Column type",
                checked: true,
            },
            {
                name: "Definition",
                value: "Definition",
                checked: true,
            },
            {
                name: "Style",
                value: "Style",
                checked: true,
            },
            {
                name: "Hide",
                value: "Hide",
                checked: true,
            },
            {
                name: "Default",
                value: "Default",
                checked: true,
            },
            {
                name: "Sort direction",
                value: "Sort direction",
                checked: true,
            },
            {
                name: "Sort priority",
                value: "Sort priority",
                checked: true,
            },
            {
                name: "Summarization",
                value: "Summarization",
                checked: true,
            },
            {
                name: "Total",
                value: "Total",
                checked: true,
            },
            {
                name: "Steps",
                value: "Steps",
                checked: true,
            },
            {
                name: "Display order",
                value: "Display order",
                checked: true,
            },
            {
                name: "Is unique key",
                value: "Is unique key",
                checked: true,
            },
            {
                name: "Precision",
                value: "Precision",
                checked: true,
            },
            {
                name: "Group by",
                value: "Group by",
                checked: true,
            },
            {
                name: "Base type",
                value: "Base type",
                checked: true,
            },      
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report filter",
        value: "report_filters",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            }, 
            {
                name: "Filter type",
                value: "Filter type",
                checked: true,
            },
            {
                name: "Comparator",
                value: "Comparator",
                checked: true,
            },
            {
                name: "Operator",
                value: "Operator",
                checked: true,
            },
            {
                name: "Logical operator",
                value: "Logical operator",
                checked: true,
            }
        ]
    }
]