import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { EmailTemplatesListResponseModel, EmailTemplatesDetailModel, AddUpdateEmailTemplatesModel, EmailTemplatesDeleteResponseModel, EmailTemplatesDeleteModel } from "../models";



export class EmailTemplatesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getEmailTemplatesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<EmailTemplatesListResponseModel>(AppConfig.apiEndpoint + '/v1/email_templates/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getEmailTemplatesById(id: number) {
    return this.wrapper.get<EmailTemplatesDetailModel>(AppConfig.apiEndpoint + '/v1/email_templates/' + id);
  }  

  postEmailTemplates(EmailTemplates: AddUpdateEmailTemplatesModel) {
    return this.wrapper.post<AddUpdateEmailTemplatesModel>(AppConfig.apiEndpoint + '/v1/email_templates', EmailTemplates);
}

editEmailTemplates(id?: number) {
    return this.wrapper.get<AddUpdateEmailTemplatesModel>(AppConfig.apiEndpoint + '/v1/email_templates/edit/' + id);
}

updateEmailTemplates(EmailTemplates: AddUpdateEmailTemplatesModel) {
    return this.wrapper.put<AddUpdateEmailTemplatesModel>(AppConfig.apiEndpoint + '/v1/email_templates/' + EmailTemplates.id, EmailTemplates);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<EmailTemplatesDeleteModel>(AppConfig.apiEndpoint + '/v1/email_templates/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<EmailTemplatesDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/email_templates/' + id);
}

  exportEmailTemplates(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/email_templates/export', exportData);
  }


} 