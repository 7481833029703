import { IExportColumnModel } from "../../../../shared/models";

export const ReportColumnList: IExportColumnModel[] = [
    {
        isAllChecked: true,
        name: "Fields from reports",
        value: "reports",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "name",
                checked: true,
            },
            {
                name: "Favicon url",
                value: "Favicon url",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Custom sql",
                value: "Custom sql",
                checked: true,
            },
            {
                name: "Checksum",
                value: "Checksum",
                checked: true,
            },
            {
                name: "Last generated at",
                value: "Last generated at",
                checked: true,
            },
            {
                name: "Total row count",
                value: "Total row count",
                checked: true,
            },
            {
                name: "Has row matching",
                value: "Has row matching",
                checked: true,
            },
            {
                name: "Time range",
                value: "Time range",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Edited at",
                value: "Edited at",
                checked: true,
            },
            {
                name: "Viewed at",
                value: "Viewed at",
                checked: true,
            },
            {
                name: "Query strategy",
                value: "Query strategy",
                checked: true,
            },
            {
                name: "Always rebuild",
                value: "Always rebuild",
                checked: true,
            },
            {
                name: "Runs in redshift",
                value: "Runs in redshift",
                checked: true,
            },
            {
                name: "Alert on failure",
                value: "Alert on failure",
                checked: true,
            },
            {
                name: "Skip when priority dependent job",
                value: "Skip when priority dependent job",
                checked: true,
            }


        ]
    },
    {
        isAllChecked: true,
        name: "Fields from associated user",
        value: "users",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "name",
                checked: true,
            },
            {
                name: "Email",
                value: "email",
                checked: true,
            },
            {
                name: "Title",
                value: "title",
                checked: true,
            },
            {
                name: "Phone",
                value: "phone",
                checked: true,
            },
            {
                name: "Remember Created At",
                value: "remember created at",
                checked: true,
            },
            {
                name: "Sign in count",
                value: "sign in count",
                checked: true,
            },
            {
                name: "Current sign in at",
                value: "current sign in at",
                checked: true,
            },
            {
                name: "Last sign in at",
                value: "last sign in at",
                checked: true,
            },
            {
                name: "Current sign in ip",
                value: "current sign in ip",
                checked: true,
            },
            {
                name: "Last sign in ip",
                value: "last sign in ip",
                checked: true,
            },
            {
                name: "Created At",
                value: "created at",
                checked: true,
            },
            {
                name: "Updated At",
                value: "updated at",
                checked: true,
            },
            {
                name: "Account",
                value: "account",
                checked: true,
            },
            {
                name: "Welcomed At",
                value: "welcomed at",
                checked: true,
            },

            {
                name: "Demo",
                value: "demo",
                checked: true,
            },
            {
                name: "Needs Setup",
                value: "needs setup",
                checked: true,
            },
            {
                name: "Failed Attempts",
                value: "failed attempts",
                checked: true,
            },
            {
                name: "Locked At",
                value: "locked at",
                checked: true,
            },
            {
                name: "Invited By User",
                value: "invited by user id",
                checked: true,
            },
            {
                name: "Billable",
                value: "billable",
                checked: true,
            },
            {
                name: "Staq Admin",
                value: "staq admin",
                checked: true,
            }

        ]
    },
    {
        isAllChecked: true,
        name: "Fields from associated edited by user",
        value: "edited_by_user",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "name",
                checked: true,
            },
            {
                name: "Email",
                value: "email",
                checked: true,
            },
            {
                name: "Title",
                value: "title",
                checked: true,
            },
            {
                name: "Phone",
                value: "phone",
                checked: true,
            },
            {
                name: "Remember Created At",
                value: "remember created at",
                checked: true,
            },
            {
                name: "Sign in count",
                value: "sign in count",
                checked: true,
            },
            {
                name: "Current sign in at",
                value: "current sign in at",
                checked: true,
            },
            {
                name: "Last sign in at",
                value: "last sign in at",
                checked: true,
            },
            {
                name: "Current sign in ip",
                value: "current sign in ip",
                checked: true,
            },
            {
                name: "Last sign in ip",
                value: "last sign in ip",
                checked: true,
            },
            {
                name: "Created At",
                value: "created at",
                checked: true,
            },
            {
                name: "Updated At",
                value: "updated at",
                checked: true,
            },
            {
                name: "Account",
                value: "account",
                checked: true,
            },
            {
                name: "Welcomed At",
                value: "welcomed at",
                checked: true,
            },

            {
                name: "Demo",
                value: "demo",
                checked: true,
            },
            {
                name: "Needs Setup",
                value: "needs setup",
                checked: true,
            },
            {
                name: "Failed Attempts",
                value: "failed attempts",
                checked: true,
            },
            {
                name: "Locked At",
                value: "locked at",
                checked: true,
            },
            {
                name: "Invited By User",
                value: "invited by user id",
                checked: true,
            },
            {
                name: "Billable",
                value: "billable",
                checked: true,
            },
            {
                name: "Staq Admin",
                value: "staq admin",
                checked: true,
            }

        ]
    },
    {
        isAllChecked: true,
        name: "Fields from associated dependent reports",
        value: "dependent_reports",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Favicon url",
                value: "Favicon url",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Custom sql",
                value: "Custom sql",
                checked: true,
            },
            {
                name: "Checksum",
                value: "Checksum",
                checked: true,
            },
            {
                name: "Last generated at",
                value: "Last generated at",
                checked: true,
            },
            {
                name: "Total row count",
                value: "Total row count",
                checked: true,
            },
            {
                name: "Has row matching",
                value: "Has row matching",
                checked: true,
            },
            {
                name: "Time range",
                value: "Time range",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Edited at",
                value: "Edited at",
                checked: true,
            },
            {
                name: "Viewed at",
                value: "Viewed at",
                checked: true,
            },
            {
                name: "Query strategy",
                value: "Query strategy",
                checked: true,
            },
            {
                name: "Always rebuild",
                value: "Always rebuild",
                checked: true,
            },
            {
                name: "Runs in redshift",
                value: "Runs in redshift",
                checked: true,
            },
            {
                name: "Alert on failure",
                value: "Alert on failure",
                checked: true,
            },
            {
                name: "Skip when priority dependent job",
                value: "Skip when priority dependent job",
                checked: true,
            },


        ]
    }
    
    ,
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated connections",
    //     value: "associatedconnections",
    //     columns: [
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated override connections",
    //     value: "associatedoverrideconnections",
    //     columns: [
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         }

    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated custom connections",
    //     value: "associatedcustomconnections",
    //     columns: [
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated override custom connections",
    //     value: "associatedoverridecustomconnections",
    //     columns: [
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         }
    //     ]
    // },
    
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated installed applications",
    //     value: "associatedinstalledapplications",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "Options",
    //             value: "Options",
    //             checked: true,
    //         },
    //         {
    //             name: "Enabled",
    //             value: "Enabled",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Run on report change",
    //             value: "Run on report change",
    //             checked: true,
    //         },
    //         {
    //             name: "Options key",
    //             value: "Options key",
    //             checked: true,
    //         },
    //         {
    //             name: "Options iv",
    //             value: "Options iv",
    //             checked: true,
    //         },
    //         {
    //             name: "Backup options",
    //             value: "Backup options",
    //             checked: true,
    //         },
    //         {
    //             name: "Billable",
    //             value: "Billable",
    //             checked: true,
    //         }

    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated platforms",
    //     value: "associatedplatforms",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Title",
    //             value: "Title",
    //             checked: true,
    //         },
    //         {
    //             name: "Extractor type",
    //             value: "Extractor type",
    //             checked: true,
    //         },
    //         {
    //             name: "Is public",
    //             value: "Is public",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated report columns",
    //     value: "associatedreportcolumns",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Column",
    //             value: "Column",
    //             checked: true,
    //         },
    //         {
    //             name: "Label",
    //             value: "Label",
    //             checked: true,
    //         },
    //         {
    //             name: "Type",
    //             value: "Type",
    //             checked: true,
    //         },
    //         {
    //             name: "Column type",
    //             value: "Column type",
    //             checked: true,
    //         },
    //         {
    //             name: "Definition",
    //             value: "Definition",
    //             checked: true,
    //         },
    //         {
    //             name: "Style",
    //             value: "Style",
    //             checked: true,
    //         },
    //         {
    //             name: "Hide",
    //             value: "Hide",
    //             checked: true,
    //         },
    //         {
    //             name: "Default",
    //             value: "Default",
    //             checked: true,
    //         },
    //         {
    //             name: "Sort direction",
    //             value: "Sort direction",
    //             checked: true,
    //         },
    //         {
    //             name: "Sort priority",
    //             value: "Sort priority",
    //             checked: true,
    //         },
    //         {
    //             name: "Summarization",
    //             value: "Summarization",
    //             checked: true,
    //         },
    //         {
    //             name: "Total",
    //             value: "Total",
    //             checked: true,
    //         },
    //         {
    //             name: "Steps",
    //             value: "Steps",
    //             checked: true,
    //         },
    //         {
    //             name: "Display order",
    //             value: "Display order",
    //             checked: true,
    //         },
    //         {
    //             name: "Is unique key",
    //             value: "Is unique key",
    //             checked: true,
    //         },
    //         {
    //             name: "Precision",
    //             value: "Precision",
    //             checked: true,
    //         },
    //         {
    //             name: "Group by",
    //             value: "Group by",
    //             checked: true,
    //         },
    //         {
    //             name: "Base type",
    //             value: "Base type",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         }

    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated ordered report columns",
    //     value: "associatedorderedreportcolumns",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Column",
    //             value: "Column",
    //             checked: true,
    //         },
    //         {
    //             name: "Label",
    //             value: "Label",
    //             checked: true,
    //         },
    //         {
    //             name: "Type",
    //             value: "Type",
    //             checked: true,
    //         },
    //         {
    //             name: "Column type",
    //             value: "Column type",
    //             checked: true,
    //         },
    //         {
    //             name: "Definition",
    //             value: "Definition",
    //             checked: true,
    //         },
    //         {
    //             name: "Style",
    //             value: "Style",
    //             checked: true,
    //         },
    //         {
    //             name: "Hide",
    //             value: "Hide",
    //             checked: true,
    //         },
    //         {
    //             name: "Default",
    //             value: "Default",
    //             checked: true,
    //         },
    //         {
    //             name: "Sort direction",
    //             value: "Sort direction",
    //             checked: true,
    //         },
    //         {
    //             name: "Sort priority",
    //             value: "Sort priority",
    //             checked: true,
    //         },
    //         {
    //             name: "Summarization",
    //             value: "Summarization",
    //             checked: true,
    //         },
    //         {
    //             name: "Total",
    //             value: "Total",
    //             checked: true,
    //         },
    //         {
    //             name: "Steps",
    //             value: "Steps",
    //             checked: true,
    //         },
    //         {
    //             name: "Display order",
    //             value: "Display order",
    //             checked: true,
    //         },
    //         {
    //             name: "Is unique key",
    //             value: "Is unique key",
    //             checked: true,
    //         },
    //         {
    //             name: "Precision",
    //             value: "Precision",
    //             checked: true,
    //         },
    //         {
    //             name: "Group by",
    //             value: "Group by",
    //             checked: true,
    //         },
    //         {
    //             name: "Base type",
    //             value: "Base type",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         }


    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated report data sources",
    //     value: "associatedreportdatasources",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Lookup column",
    //             value: "Lookup column",
    //             checked: true,
    //         },
    //         {
    //             name: "Most recent data at",
    //             value: "Most recent data at",
    //             checked: true,
    //         },
    //         {
    //             name: "Last successful collection at",
    //             value: "Last successful collection at",
    //             checked: true,
    //         },
    //         {
    //             name: "Lookup join type",
    //             value: "Lookup join type",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated data engine jobs",
    //     value: "associateddataenginejobs",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Job type",
    //             value: "Job type",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         },
    //         {
    //             name: "Definition",
    //             value: "Definition",
    //             checked: true,
    //         },
    //         {
    //             name: "Sql",
    //             value: "Sql",
    //             checked: true,
    //         },
    //         {
    //             name: "Time range override",
    //             value: "Time range override",
    //             checked: true,
    //         },
    //         {
    //             name: "Priority",
    //             value: "Priority",
    //             checked: true,
    //         },
    //         {
    //             name: "Trigger dependencies",
    //             value: "Trigger dependencies",
    //             checked: true,
    //         },
    //         {
    //             name: "Triggered by",
    //             value: "Triggered by",
    //             checked: true,
    //         },
    //         {
    //             name: "Duration",
    //             value: "Duration",
    //             checked: true,
    //         },
    //         {
    //             name: "Backend pid",
    //             value: "Backend pid",
    //             checked: true,
    //         },
    //         {
    //             name: "Row count",
    //             value: "Row count",
    //             checked: true,
    //         },
    //         {
    //             name: "Size on disk",
    //             value: "Size on disk",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Recollection",
    //             value: "Recollection",
    //             checked: true,
    //         },
    //         {
    //             name: "User initiated",
    //             value: "User initiated",
    //             checked: true,
    //         }


    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated report views",
    //     value: "associatedreportviews",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Filters",
    //             value: "Filters",
    //             checked: true,
    //         }

    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated report run schedules",
    //     value: "associatedreportrunschedules",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Run at",
    //             value: "Run at",
    //             checked: true,
    //         },
    //         {
    //             name: "Time range",
    //             value: "Time range",
    //             checked: true,
    //         },
    //         {
    //             name: "Priority",
    //             value: "Priority",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Day of week",
    //             value: "Day of week",
    //             checked: true,
    //         },
    //         {
    //             name: "Trigger dependencies",
    //             value: "Trigger dependencies",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated report change history",
    //     value: "associatedreportchangehistory",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Description",
    //             value: "Description",
    //             checked: true,
    //         },
    //         {
    //             name: "Last definition",
    //             value: "Last definition",
    //             checked: true,
    //         },
    //         {
    //             name: "Ip",
    //             value: "Ip",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated account",
    //     value: "associatedaccount",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "Domain",
    //             value: "Domain",
    //             checked: true,
    //         },
    //         {
    //             name: "Logo url",
    //             value: "Logo url",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Url",
    //             value: "Url",
    //             checked: true,
    //         },
    //         {
    //             name: "Priority",
    //             value: "Priority",
    //             checked: true,
    //         },
    //         {
    //             name: "Favicon url",
    //             value: "Favicon url",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         },
    //         {
    //             name: "Trial",
    //             value: "Trial",
    //             checked: true,
    //         },
    //         {
    //             name: "Active",
    //             value: "Active",
    //             checked: true,
    //         },
    //         {
    //             name: "Sftp account name",
    //             value: "Sftp account name",
    //             checked: true            
    //         },
    //         {
    //             name: "Notes",
    //             value: "Notes",
    //             checked: true,
    //         },
    //         {
    //             name: "Contract unit count",
    //             value: "Contract unit count",
    //             checked: true,
    //         },
    //         {
    //             name: "Contract unit price",
    //             value: "Contract unit price",
    //             checked: true,
    //         },
    //         {
    //             name: "Contract discount",
    //             value: "Contract discount",
    //             checked: true,
    //         },
    //         {
    //             name: "Contract billing schedule",
    //             value: "Contract billing schedule",
    //             checked: true,
    //         },
    //         {
    //             name: "Contract date",
    //             value: "Contract date",
    //             checked: true,
    //         },
    //         {
    //             name: "Contract renewal date",
    //             value: "Contract renewal date",
    //             checked: true,
    //         },
    //         {
    //             name: "Report status",
    //             value: "Report status",
    //             checked: true,
    //         },
    //         {
    //             name: "Monthly offset",
    //             value: "Monthly offset",
    //             checked: true,
    //         },
    //         {
    //             name: "Backup day",
    //             value: "Backup day",
    //             checked: true,
    //         },
    //         {
    //             name: "Pause scheduled reports",
    //             value: "Pause scheduled reports",
    //             checked: true,
    //         },
    //         {
    //             name: "Report schedule start",
    //             value: "Report schedule start",
    //             checked: true,
    //         },
    //         {
    //             name: "Report schedule stop",
    //             value: "Report schedule stop",
    //             checked: true,
    //         }
    //     ]
    // },

    // {
    //     isAllChecked: true,
    //     name: "Fields from associated database engine",
    //     value: "fieldsfromassociateddatabaseengine",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Name",
    //             value: "Name",
    //             checked: true,
    //         },
    //         {
    //             name: "Db type",
    //             value: "Db type",
    //             checked: true,
    //         },
    //         {
    //             name: "Version",
    //             value: "Version",
    //             checked: true,
    //         },
    //         {
    //             name: "Env var",
    //             value: "Env var",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated base report view",
    //     value: "associatedbasereportview",
    //     columns: [
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Filters",
    //             value: "Filters",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated source report",
    //     value: "associatedsourcereport",
    //     columns: [          
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Ignore health",
    //             value: "Ignore health",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated parent source report data sources",
    //     value: "associatedparentsourcereportdatasources",
    //     columns: [          
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Type",
    //             value: "Type",
    //             checked: true,
    //         }
    //     ]
    // },
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated parent source reports",
    //     value: "associatedparentsourcereports",
    //     columns: [          
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Ignore health",
    //             value: "Ignore health",
    //             checked: true,
    //         }
    //     ]
    // },
            
    // {
    //     isAllChecked: true,
    //     name: "Fields from associated parent data engine jobs",
    //     value: "associatedparentdataenginejobs",
    //     columns: [           
    //         {
    //             name: "Id",
    //             value: "Id",
    //             checked: true,
    //         },
    //         {
    //             name: "Job type",
    //             value: "Job type",
    //             checked: true,
    //         },
    //         {
    //             name: "State",
    //             value: "State",
    //             checked: true,
    //         },
    //         {
    //             name: "Definition",
    //             value: "Definition",
    //             checked: true,
    //         },
    //         {
    //             name: "Sql",
    //             value: "Sql",
    //             checked: true,
    //         },
    //         {
    //             name: "Time range override",
    //             value: "Time range override",
    //             checked: true,
    //         },
    //         {
    //             name: "Priority",
    //             value: "Priority",
    //             checked: true,
    //         },
    //         {
    //             name: "Trigger dependencies",
    //             value: "Trigger dependencies",
    //             checked: true,
    //         },
    //         {
    //             name: "Triggered by",
    //             value: "Triggered by",
    //             checked: true,
    //         },
    //         {
    //             name: "Duration",
    //             value: "Duration",
    //             checked: true,
    //         },
    //         {
    //             name: "Backend pid",
    //             value: "Backend pid",
    //             checked: true,
    //         },
    //         {
    //             name: "Row count",
    //             value: "Row count",
    //             checked: true,
    //         },
    //         {
    //             name: "Size on disk",
    //             value: "Size on disk",
    //             checked: true,
    //         },
    //         {
    //             name: "Created at",
    //             value: "Created at",
    //             checked: true,
    //         },
    //         {
    //             name: "Updated at",
    //             value: "Updated at",
    //             checked: true,
    //         },
    //         {
    //             name: "Recollection",
    //             value: "Recollection",
    //             checked: true,
    //         },
    //         {
    //             name: "User initiated",
    //             value: "User initiated",
    //             checked: true,
    //         }

    //     ]
    // },
    

]