import React, { Component } from 'react';
import $ from 'jquery';

export class TabScrolling extends Component {
    componentDidMount() {
        setTimeout(function () {
            var tabscrollPos = $('.tabscroll .nav-tabs .active').position()
            $('.tabscroll ').scrollLeft(tabscrollPos.left - 50);
            if ($('.tabscroll').parent().width() < 999) {
                $('.tabscroll .nav-tabs').css('display', 'inline-block')
            } else {
                $('.tabscroll .nav-tabs').css('display', 'block')
            }
        }, 0)
    }
    render() {
        return null
    }
}

// export class SetHeight extends Component {
    
//     componentDidMount() {
//         setTimeout(function () {
           
//                 var h = $(window).height();
//                 if ($(".sticky-table .sticky-table-row").length >= 2) {
//                     alert($(".sticky-table .sticky-table-row").length)
//                     $(".sticky-table-wrapper").height(h - 250);
//                 } else {
//                     $(".sticky-table-wrapper").height('auto');
//                 }
         
//         }, 0)

//     }
//     render() {   
//         return null
//     }
// }
