import { IExportColumnModel } from "../../../../shared/models";

export const DashboardColumnList: IExportColumnModel[] =[

        {
            isAllChecked: true,
            name: "Fields from dashboards",
            value: "dashboards",
            columns: [
                {
                    name: "Id",
                    value: "id",
                    checked: true,
                },
                {
                    name: "Name",
                    value: "name",
                    checked: true,
                },
                {
                    name: "Created at",
                    value: "created at",
                    checked: true,
                },                
                {
                    name: "Updated at",
                    value: "updated at",
                    checked: true,
                },
                {
                    name: "Settings",
                    value: "settings",
                    checked: true,
                }

            ]
        },
        {
            isAllChecked: true,
            name: "Fields from associated user",
            value: "users",
            columns: [
                {
                    name: "Id",
                    value: "id",
                    checked: true,
                },
                {
                    name: "Name",
                    value: "name",
                    checked: true,
                },
                {
                    name: "Email",
                    value: "email",
                    checked: true,
                },
                {
                    name: "Title",
                    value: "title",
                    checked: true,
                },
                {
                    name: "Phone",
                    value: "phone",
                    checked: true,
                },
                {
                    name: "Remember Created At",
                    value: "remember created at",
                    checked: true,
                },
                {
                    name: "Signin Count",
                    value: "sign in count",
                    checked: true,
                },
                {
                    name: "Current SignIn At",
                    value: "current sign in at",
                    checked: true,
                },
                {
                    name: "Last SignIn At",
                    value: "last sign in at",
                    checked: true,
                },
                {
                    name: "Current SignIn Ip",
                    value: "current sign in ip",
                    checked: true,
                },
                {
                    name: "Last SignIn IP",
                    value: "last sign in ip",
                    checked: true,
                },
                {
                    name: "Created At",
                    value: "created at",
                    checked: true,
                },
                {
                    name: "Updated At",
                    value: "updated at",
                    checked: true,
                },
                {
                    name: "Account",
                    value: "account",
                    checked: true,
                },
                {
                    name: "Welcomed At",
                    value: "welcomed at",
                    checked: true,
                },

                {
                    name: "Demo",
                    value: "demo",
                    checked: true,
                },
                {
                    name: "Needs Setup",
                    value: "needs setup",
                    checked: true,
                },
                {
                    name: "Failed Attempts",
                    value: "failed attempts",
                    checked: true,
                },
                {
                    name: "Locked At",
                    value: "locked at",
                    checked: true,
                },
                {
                    name: "Invited By User",
                    value: "invited by user id",
                    checked: true,
                },
                {
                    name: "Billable",
                    value: "billable",
                    checked: true,
                },
                {
                    name: "Staq Admin",
                    value: "staq admin",
                    checked: true,
                }

            ]
        },
        {
            isAllChecked: true,
            name: "Fields from associated applets",
            value: "applets",
            columns: [
                {
                    name: "Id",
                    value: "id",
                    checked: true,
                },
                {
                    name: "Name",
                    value: "name",
                    checked: true,
                },
                {
                    name: "Created at",
                    value: "created at",
                    checked: true,
                },                
                {
                    name: "Updated at",
                    value: "updated at",
                    checked: true,
                },
                {
                    name: "Settings",
                    value: "settings",
                    checked: true,
                },
                {
                    name: "Order",
                    value: "order",
                    checked: true,
                }

            ]
        },
        {
            isAllChecked: true,
            name: "Fields from associated dashboard users",
            value: "dashboard_users",
            columns: [
                {
                    name: "Id",
                    value: "id",
                    checked: true,
                },               
                {
                    name: "Created at",
                    value: "created at",
                    checked: true,
                },                
                {
                    name: "Updated at",
                    value: "updated at",
                    checked: true,
                },
                {
                    name: "Shared settings",
                    value: "shared settings",
                    checked: true,
                },
                {
                    name: "Custom settings",
                    value: "custom settings",
                    checked: true,
                }

            ]
        },
        {
            isAllChecked: true,
            name: "Fields from associated creator dashboard user",
            value: "creator_dashboard_user",
            columns: [
                {
                    name: "Id",
                    value: "id",
                    checked: true,
                },               
                {
                    name: "Created at",
                    value: "created at",
                    checked: true,
                },                
                {
                    name: "Updated at",
                    value: "updated at",
                    checked: true,
                },
                {
                    name: "Shared settings",
                    value: "shared settings",
                    checked: true,
                },
                {
                    name: "Custom settings",
                    value: "custom settings",
                    checked: true,
                }

            ]
        }    
]





