import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { DeleteResponseModel, LinkResourcesResponseModel, RerunCollectionMissionPostModel, RerunCollectionMissionResponseModel } from "../../../../shared/models";
import { CancelCollectionMission } from "../../connections/models";
import { CollectionMissionResponseModel } from "../models/collection.mission.response.model";
import { CollectionMissionStateTransitionsModel } from "../models/collection.mission.state.transitions.model";
import { CollectionMissionTaskResponseModel } from "../models/collection.mission.task.response.model";

export class CollectionMissionService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

 
  getCollectionMissionById(id: number) {
    return this.wrapper.get<CollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/' + id);
  }

  getCollectionMissionTasks(id: number) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + '/v1/collection_missions/' + id + '/tasks');
  }

  getCollectionMissionTask(id: number) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + '/v1/collection_mission_tasks/' + id);
  }

  getCollectionMissionStateTransitionsById(id: number) {
    return this.wrapper.get<CollectionMissionStateTransitionsModel>(AppConfig.apiEndpoint + '/v1/collection_mission_state_transitions/' + id);
  }

  cancConnectionelCollectionMissionById(id: number, missionId?:number) {
    return this.wrapper.put<CancelCollectionMission>(AppConfig.apiEndpoint + '/v1/connections/'+ id +'/cancel_collection_missions?mission_id='+missionId,{});
  }

  cancelCollectionMissionById(id: any, missionId?:number) {
    return this.wrapper.put<CancelCollectionMission>(AppConfig.apiEndpoint + '/v1/custom_connections/'+ id +'/cancel_collection_missions?mission_id='+missionId,{});
  }

  rerunCollectionMissionById(data:RerunCollectionMissionPostModel) {
    return this.wrapper.post<RerunCollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/rerun', data);
  }

  getCollectionMissionTasksDetail(id: number) {
    return this.wrapper.get<CollectionMissionTaskResponseModel>(AppConfig.apiEndpoint + '/v1/collection_mission_tasks/' + id);
  }
  generateBasicScope(taskId: number, resourceUrl: any) {
    let data = {
      taskId,
      resourceUrl
    }
    return this.wrapper.post<any>(AppConfig.apiEndpoint + '/v1/scopes/generateBasicScope', data);
  }

 


}