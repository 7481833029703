import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
 let authed = localStorage.getItem('lg')=='true';   
 let currentUrl = window.location.pathname;
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login?rtu='+currentUrl, state: { from: props.location } }} />}
    />
  )
}

