import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import ReactCrop from "react-image-crop"

interface IProps {
  show: boolean
  handleClose: any
  handleCorpComplete: any
  handleOnCrop: any
  crop: any
  handleImageLoaded: any
  onSelectFile: any
  imgSrc: any
  getCroppedImg: any
  err: string
  setIsLoading: boolean
}

function ModalComp(props: IProps) {
  const {
    show,
    handleClose,
    handleCorpComplete,
    handleOnCrop,
    crop,
    handleImageLoaded,
    onSelectFile,
    imgSrc,
    getCroppedImg,
    err,
    setIsLoading,
  } = props

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modalCls"
      >
        <Modal.Header className="Modal_Login_header">
          <Modal.Title
            className=" Modal_Login_header_title col-md-8"
            style={{ padding: 0 }}
          >
            Upload image
          </Modal.Title>
          <Button
            className="col-md-2"
            onClick={() => handleClose()}
            disabled={setIsLoading}
          >
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          {imgSrc !== null ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <div>
                  <ReactCrop
                    keepSelection={true}
                    crop={crop}
                    onChange={(c) => handleOnCrop(c)}
                    onComplete={(crop, pixalCrop) =>
                      handleCorpComplete(crop, pixalCrop)
                    }
                  >
                    <img src={imgSrc} onLoad={(e) => handleImageLoaded(e)} />
                  </ReactCrop>
                </div>
                <div>
                  <Button
                    style={{ marginTop: "10px" }}
                    onClick={getCroppedImg}
                    disabled={setIsLoading}
                  >
                    {setIsLoading ? "Uploading ..." : "Upload Image "}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                id="image_logo"
              />
              <p style={{ padding: "20px 0px", color: "red" }}>{err}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalComp